import React from 'react';
import ArticleBody from '../ArticleBody';
const ImpactOfCultureOnMentalHealth = () => (
  <div>
    <ArticleBody>
      To understand the impact of culture on mental health, we must first define
      what organizational culture is. Organizational culture is a set of values
      and behaviours that contribute to the unique social and psychological
      environment of an organization. It includes the organization’s
      expectations, experiences, philosophy and values that hold it together. It
      is based on shared attitudes, beliefs, and customs, and written and
      unwritten rules that have developed over time and are considered valid.
      <br />
      <br />
      This set of values and beliefs can help determine whether an employee
      feels integrated and engaged, and if the employee’s values and beliefs are
      aligned with those of the organization. However, the mental health
      significance of workplace culture goes beyond value alignment.
      Organizational culture is one of the 13 workplace factors that are known
      to impact psychological health and safety, as identified in the
      psychological health and safety standard. When a positive, psychologically
      safe workplace culture exists, it fosters mental wellbeing, job
      satisfaction and commitment. Conversely, a negative workplace culture can
      lead to stress and burnout, and may exasperate existing mental health
      issues.
      <br />
      <br />
      To have workplace culture that supports mental health and safety, an
      organization must create a culture of caring. Not only do employees need
      to feel safe at work, but they need to have a trusting relationship with
      their employer. To do this, leaders need to encourage open conversations
      about mental health and do their part in reducing stigma.
      <br />
      <br />
      Additionally, to develop a successful corporate culture, and one that
      promotes mental wellbeing, you need to consider the following seven
      characteristics:3
      <br />
      <br />
      <ol>
        <li>Purpose-driven</li>
        <li>Effective communication patterns</li>
        <li>Positive and continuous feedback</li>
        <li>Embracing diversity</li>
        <li>Encouraging teamwork</li>
        <li>Fostering engagement and loyalty</li>
        <li>Ensuring opportunities for growth and development</li>
      </ol>
      <br />
      <br />
      <b>1. Purpose-driven</b>:<br />
      When an employee’s purpose is well-defined and clear and they know and
      understand their short and long term goals, they will perform better,
      resulting in higher productivity. Furthermore, when a company’s purpose is
      well established by its leaders, employees become inspired. This
      translates into engaged and motivated employees and a culture that becomes
      the engine behind the organization’s purpose.
      <br />
      <br />
      <b>2. Effective communication patterns</b>: Communications must be clear,
      transparent and direct so that they are heard and can be processed
      properly. Messages must be delivered with respect and civility so that the
      individuals receive the message with minimal risk of being hurt. Lastly,
      avoid engaging with the past, which can then lead to accusations and
      blaming; instead, be proactive and forward thinking in your communication.
      Discussions involving the future are more favorable to positive outcomes
      such as finding solutions, working together to achieve goals and improving
      teamwork.
      <br />
      <br />
      <b>3. Positive and continuous feedback</b>: Encourages employees to
      continuously improve, perform better and be highly productive. A feedback
      oriented company culture will foster a work environment where people are
      receptive to giving and receiving feedback while also understanding when
      and how to give it.
      <br />
      <br />
      <b>4. Embracing diversity</b>: An organization that is aware of cultural
      differences and able to communicate and interact without discriminating,
      making assumptions or judging, promotes tolerance and acceptance of others
      while enhancing teamwork and collaboration.
      <br />
      <br />
      <b>5. Teamwork</b>: A positive culture encourages collaboration between
      colleagues, focusing on the team’s accomplishments rather than the
      individuals’ success. In order for teamwork to be successful, all members
      need to be aware of the expectations, and these expectations need to be
      reinforced along with the roles of each member. Teamwork allows for all
      members to achieve their tasks more efficiently, resulting in the team
      meeting its goals.
      <br />
      <br />
      <b>6. Engagement and loyalty</b>: Employees become more emotionally and
      physically engaged in their jobs when they believe their work is
      worthwhile and makes a difference. Understanding the context of how what
      they do contributes to the overall purpose of the organization is
      critical. It provides role clarity and enhances feelings of respect.
      <br />
      <br />
      <b>7. Growth and development</b>: Companies offering opportunities for
      training and personal development will nurture a culture of success and
      engagement. Acquiring new skills and new abilities fosters performance and
      job satisfaction. Make sure employees understand their own path in the
      organization and how their career can progress.
      <br />
      <br />
      These seven characteristics will ensure a successful company culture.
      Positive organizational culture is the backbone to a happy, productive
      workplace. It creates an environment where employees feel safe and
      comfortable to reach out for assistance.
      <br />
      <br />
      <b>Other resources:</b>
      <br />
      <br />
      “How to build a positive company culture”, Aug. 14, 2018
      <br />
      Allan Kohll, Contributor, Leadership Strategy
      <br />
      <a href="https://www.forbes.com/sites/alankohll/2018/08/14/how-to-build-a-positive-company-culture/#616b092349b5">
        https://www.forbes.com/sites/alankohll/2018/08/14/how-to-build-a-positive-company-culture/#616b092349b5
      </a>
      “15 Best Ways to build a company culture that thrives”, Forbes Coaches
      Council, Jan. 29, 2018.
      <br />
      <a href=" https://www.forbes.com/sites/forbescoachescouncil/2018/01/29/15-best-ways-to-build-a-company-culture-that-thrives/#660e26c61b96">
        https://www.forbes.com/sites/forbescoachescouncil/2018/01/29/15-best-ways-to-build-a-company-culture-that-thrives/#660e26c61b96
      </a>
    </ArticleBody>
  </div>
);

ImpactOfCultureOnMentalHealth.propTypes = {};

export default ImpactOfCultureOnMentalHealth;
