import React from 'react';
import H3 from 'components/H3';
import styled from 'styled-components';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { BBDEAP } from 'utils/environment';
import Card from './Card';
import messages from './messages';

const FaqWrapper = styled.div`
  .header {
    padding: 0px;
    font-family: 'Montserrat';
    font-size: 22px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: #1f275b;
    margin-bottom: 20px;
  }
  .body {
    font-family: 'Lato';
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: #0f2045;
    margin-bottom: 80px;
  }
  .note {
    font-family: 'Lato';
    font-size: 12px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: #0f2045;
    margin-bottom: 80px;
  }
`;

const FaqSection = ({ question, answer }) => (
  <>
    <h1 className="header">
      <FormattedMessage {...question} />
    </h1>
    <div className="body">
      <FormattedHTMLMessage tagName="p" {...answer} />
    </div>
  </>
);

const FaqEmployeeEap = () => (
  <Card>
    <H3 className="title">
      <FormattedMessage {...messages.faq} />
    </H3>
    <FaqWrapper>
      <FaqSection
        question={messages.eapWhatIsPeople}
        answer={messages.eapWhatIsPeopleBody}
      />
      <FaqSection
        question={messages.eapConfidentiality}
        answer={messages.eapConfidentialityBody}
      />
      <FaqSection
        question={messages.eapCanFamily}
        answer={messages.eapCanFamilyBody}
      />
      {!BBDEAP && (
        <FaqSection
          question={messages.eapHowDoIAccess}
          answer={messages.eapHowDoIAccessBody}
        />
      )}
      <FaqSection
        question={messages.eapHowDoesThisCompare}
        answer={messages.eapHowDoesThisCompareBody}
      />
      <FaqSection
        question={messages.eapDoIGetToMeet}
        answer={messages.eapDoIGetToMeetBody}
      />
      <FaqSection
        question={messages.eapWhoPays}
        answer={messages.eapWhoPaysBody}
      />
      <FaqSection
        question={messages.eapRunOut}
        answer={messages.eapRunOutBody}
      />
      <FaqSection
        question={messages.eapReligion}
        answer={messages.eapReligionBody}
      />
      <FaqSection
        question={messages.eapAdvisoryServices}
        answer={messages.eapAdvisoryServicesBody}
      />
      <FaqSection
        question={messages.eapPractitioner}
        answer={messages.eapPractitionerBody}
      />
      <FaqSection
        question={messages.eapPsychiatrist}
        answer={messages.eapPsychiatristBody}
      />
      <FaqSection
        question={messages.eapWhoDoIContact}
        answer={messages.eapWhoDoIContactBody}
      />
    </FaqWrapper>
  </Card>
);

export default FaqEmployeeEap;
