/*
 *
 * MarketingPage constants
 *
 */

export const RESET_PASSWORD = 'app/ForgotPassword/RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS =
  'app/ForgotPassword/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'app/ForgotPassword/RESET_PASSWORD_ERROR';
