/**
 *
 * PanelCard Content
 *
 */

import styled from 'styled-components';

const Content = styled.div`
  height: ${props => (props.height ? props.height : '66%')};
  padding: 20px 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  h4 {
    margin-bottom: 15px;
    max-height: 400px;
  }
  p {
    height: 60px;
    align-self: flex-start;
    margin-bottom: 20px;
  }
`;

export default Content;
