/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Switch, withRouter, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import Signup from 'containers/Signup';
import SignIn from 'containers/SignIn';
import Dashboard from 'containers/Dashboard';
import EmployeeResources from 'containers/EmployeeResources';
import ManagerResources from 'containers/ManagerResources';
import TrainingResources from 'containers/TrainingResources';
import Assessment from 'containers/Assessment';
import PlanSponsor from 'containers/PlanSponsor';
import Articles from 'containers/Articles';
import ConfirmSignUp from 'components/ConfirmSignUp';
import MarketingPage from 'containers/MarketingPage';
import Conditions from 'containers/MarketingPage/Conditions';
import Privacy from 'containers/MarketingPage/Privacy';
import VirtualCounselling from 'containers/VirtualCounselling';
import CriticalIncidentServices from 'containers/CriticalIncidentServices';
import SpecializedServices from 'containers/SpecializedServices';
import SettingsHistory from 'containers/SettingsHistory';
import EmailConfirmation from 'containers/EmailConfirmation';
import ForgotPassword from 'containers/ForgotPassword';
import ResetPassword from 'containers/ResetPassword';
import SupportFaq from 'containers/SupportFaq';
import LogEventOnRoute from 'components/LogEventOnRoute';
import Metrics from 'containers/Metrics';
import Onboarding from 'containers/Onboarding';
import ManagementConsulting from 'containers/ManagementConsulting';
import HealthNavigation from 'containers/HealthNavigation';
import CancerAssistance from 'containers/CancerAssistance';
import SecondOpinionServices from 'containers/SecondOpinionServices';
import FinancialLegalServices from 'containers/FinancialLegalServices';
import Carepath from 'containers/Carepath';
import BusinessAssistance from 'containers/BusinessAssistance';
import ExternalTokenRedirect from './ExternalTokenRedirect';
import AuthRoute from './AuthRoute';
import NotificationManager from './NotificationManager';
import UnauthRoute from './UnauthRoute';
import reducer from './reducer';
import saga from './saga';

const AppWrapper = styled.div`
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

// eslint-disable-next-line react/prefer-stateless-function
class App extends React.Component {
  render() {
    const eventProperties = {
      location: window.location.pathname,
      domain: window.location.hostname,
    };

    return (
      <AppWrapper>
        <Helmet
          titleTemplate="%s - People Corporation"
          defaultTitle="People Corp"
        >
          <meta name="description" content="People Corp Portal" />
        </Helmet>
        <NotificationManager />
        <LogEventOnRoute
          eventType="page view"
          eventProperties={eventProperties}
        >
          <Switch>
            <Route exact path="/" component={MarketingPage} />
            <Route exact path="/ACRC" component={MarketingPage} />
            <Route exact path="/acrc" component={MarketingPage} />
            <Route exact path="/EAP" component={MarketingPage} />
            <Route exact path="/eap" component={MarketingPage} />
            <Route exact path="/PAE" component={MarketingPage} />
            <Route exact path="/pae" component={MarketingPage} />
            <Route
              exact
              path="/integratedsolutions"
              component={MarketingPage}
            />
            <Route exact path="/conditions" component={Conditions} />
            <Route exact path="/privacy" component={Privacy} />
            <Route exact path="/forgot_password" component={ForgotPassword} />
            <Route
              exact
              path="/reset_password/:token"
              component={ResetPassword}
            />
            <Redirect from="/meditation" to="/" />
            <Route path="/redirect/:token" component={ExternalTokenRedirect} />
            <AuthRoute exact path="/dashboard" component={Dashboard} />
            <AuthRoute exact path="/assessment" component={Assessment} />
            <AuthRoute
              exact
              path="/employee_resources"
              component={EmployeeResources}
            />
            <AuthRoute exact path="/articles/:id" component={Articles} />
            <UnauthRoute exact path="/signup" component={Signup} />
            <UnauthRoute
              exact
              path="/onboarding/:eligibility_token"
              component={Onboarding}
            />
            <UnauthRoute path="/unlink/:reset_token" component={Signup} />
            <UnauthRoute exact path="/signin" component={SignIn} />
            <UnauthRoute
              path="/confirmation/:confirmation_token"
              component={EmailConfirmation}
            />
            <UnauthRoute exact path="/confirmation" component={ConfirmSignUp} />
            <AuthRoute exact path="/training" component={TrainingResources} />
            <AuthRoute exact path="/plan_sponsors" component={PlanSponsor} />
            <AuthRoute exact path="/settings" component={SettingsHistory} />
            <AuthRoute
              exact
              path="/virtual_counselling"
              component={VirtualCounselling}
            />
            <AuthRoute
              exact
              path="/specialized_services"
              component={SpecializedServices}
            />
            <AuthRoute
              exact
              path="/critical_incident_services"
              component={CriticalIncidentServices}
            />
            <AuthRoute
              exact
              path="/health_navigation"
              component={HealthNavigation}
            />
            <AuthRoute
              exact
              path="/cancer_assistance"
              component={CancerAssistance}
            />
            <AuthRoute exact path="/carepath" component={Carepath} />
            <AuthRoute
              exact
              path="/business_assistance"
              component={BusinessAssistance}
            />
            <AuthRoute
              exact
              path="/second_opinion"
              component={SecondOpinionServices}
            />
            <AuthRoute
              exact
              path="/financial_legal_services"
              component={FinancialLegalServices}
            />
            <AuthRoute
              exact
              path="/management_consulting"
              component={ManagementConsulting}
            />
            <AuthRoute
              exact
              path="/manager_resources"
              component={ManagerResources}
            />
            <AuthRoute exact path="/faq" component={SupportFaq} />
            <AuthRoute exact path="/metrics" component={Metrics} />
          </Switch>
        </LogEventOnRoute>
      </AppWrapper>
    );
  }
}

const mapStateToProps = createStructuredSelector({});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'app', reducer });
const withSaga = injectSaga({ key: 'app', saga });

export default withRouter(
  compose(
    withReducer,
    withSaga,
    withConnect,
  )(App),
);
