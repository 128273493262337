import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the metrics state domain
 */

const selectMetricsDomain = state => state.get('metrics', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by Metrics
 */

const makeSelectMetrics = () =>
  createSelector(selectMetricsDomain, substate => substate.get('metrics'));

const makeSelectCompanies = () =>
  createSelector(selectMetricsDomain, substate =>
    substate.getIn(['companies', 'data']),
  );

export { makeSelectMetrics, makeSelectCompanies };
