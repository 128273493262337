/*
 * ManagerResources Messages
 *
 * This contains all the text for the ManagerResources container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.ManagerResources';

export default defineMessages({
  peopleLeaderResources: {
    id: `${scope}.peopleLeaderResources`,
    defaultMessage: 'People leader resources',
  },
  readMore: {
    id: `${scope}.readMore`,
    defaultMessage: 'Read more',
  },
  playVideo: {
    id: `${scope}.playVideo`,
    defaultMessage: 'Play video',
  },
  playAudio: {
    id: `${scope}.playAudio`,
    defaultMessage: 'Play audio',
  },
});
