/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import AudioBody from './AudioBody';
import DeepBreathingMp3 from './IntroductionToDeepAbdominalBreathing.mp3';
import DeepBreathingFrMp3 from './IntroductionToDeepAbdominalBreathingFr.mp3';

const IntroDeepBreathing = () => {
  function getLocaleFromPath() {
    if (
      window.location.hostname === 'peoplevousconnecte.com' ||
      window.location.hostname === 'lessoinspeople.com'
    ) {
      return 'fr';
    }
    return 'en';
  }
  return (
    <div>
      <AudioBody>
        <audio
          controls
          src={
            getLocaleFromPath() === 'en' ? DeepBreathingMp3 : DeepBreathingFrMp3
          }
        />
      </AudioBody>
    </div>
  );
};
export default IntroDeepBreathing;
