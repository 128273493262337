/*
 *
 * EmployeeResources reducer
 *
 */

import { fromJS } from 'immutable';
import { DEFAULT_ACTION, TRACK_USER_CLICK, TRACK_USER_CLICK_SUCCESS, TRACK_USER_CLICK_ERROR, } from './constants';

export const initialState = fromJS({
  article_name: '',
});

function employeeResourcesReducer(state = initialState, action) {
  switch (action.type) {
    case DEFAULT_ACTION:
      return state;
    case TRACK_USER_CLICK:
      return state.set('loading', true);
    case TRACK_USER_CLICK_SUCCESS:
      return state.set('loading', false).set('article_name', action.article_name);
    case TRACK_USER_CLICK_ERROR:
      return state.set('loading', false).set('error', action.error);
    default:
      return state;
  }
}

export default employeeResourcesReducer;
