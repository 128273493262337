/*
 * Booking Messages
 *
 * This contains all the text for the Booking container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.MarketingPage';

export default defineMessages({
  tackle: {
    id: `${scope}.tackle`,
    defaultMessage:
      'People Connect is People Corporation’s mental health resource centre to tackle workplace wellness.',
  },
  tackleEap: {
    id: `${scope}.tackleEap`,
    defaultMessage:
      'People Connect EAP is People Corporation’s counselling resource centre to help tackle personal and workplace health.',
  },
  getStarted: {
    id: `${scope}.getStarted`,
    defaultMessage: 'Get Started',
  },
  overview: {
    id: `${scope}.overview`,
    defaultMessage: 'People Connect EAP Overview',
  },
});
