/**
 *
 * SelectInput
 *
 */

import React from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import PropTypes from 'prop-types';

import P from 'components/P';
import { deepSeaBlue, errorRed } from 'global-styles';

const Label = styled.p`
  color: ${deepSeaBlue};
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.1px;
  line-height: normal;
  margin-bottom: 14px;
  text-transform: uppercase;
`;

const StyledP = styled(P)`
  margin: 10px 0 0 0;
  font-size: 14px;
`;

const customStyles = {
  control: provided => ({
    ...provided,
    backgroundColor: 'rgba(192, 208, 223, 0.26)',
    border: 'none',
    boxShadow: 'none',
    height: '40px',
    borderRadius: 0,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: deepSeaBlue,
    svg: {
      height: 12,
      width: 12,
    },
  }),
  indicatorSeparator: () => ({}),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#ecf9f9' : 'white',
    color: '#0f2045',
    fontFamily: '"Montserrat", sans-serif',
    fontSize: '14px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: state.isFocused ? '600' : 'normal',
    letterSpacing: '0.1px',
    lineHeight: 'normal',
    overflow: 'hidden',
    width: '100% !important',
  }),
  placeholder: provided => ({
    ...provided,
    color: '#8e8e8e',
    fontFamily: '"Montserrat", sans-serif',
    fontSize: '14px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: 'normal',
    lineHeight: 'normal',
  }),
  valueContainer: provided => ({
    ...provided,
    backgroundColor: 'rgba(240, 242, 245, 0.79)',
    border: 'none',
    color: '#0f2045',
    fontFamily: '"Montserrat", sans-serif',
    fontSize: '16px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '0.1px',
    lineHeight: 'normal',
  }),
  menuList: provided => ({
    ...provided,
    width: '100% !important',
  }),
};

function SelectInput({
  input,
  options,
  multi,
  label,
  onInputChange,
  meta,
  isSearchable,
  placeholder = '',
  filterOption,
  peopleStyles,
  style,
}) {
  const { name, value, onChange, onFocus } = input;
  const { touched, error } = meta;
  const transformedValue = transformValue(value, options, multi);

  return (
    <div style={style}>
      <Label>{label}</Label>
      <Select
        valueKey="value"
        isSearchable={isSearchable}
        blurInputOnSelect={false}
        name={name}
        value={transformedValue}
        multi={multi}
        options={options}
        onInputChange={onInputChange}
        onChange={
          multi ? multiChangeHandler(onChange) : singleChangeHandler(onChange)
        }
        onBlur={() => {}}
        onFocus={onFocus}
        styles={peopleStyles ? customStyles : {}}
        placeholder={placeholder}
        filterOption={filterOption}
      />
      {touched &&
        error && <StyledP color={errorRed}>{touched ? error : ''}</StyledP>}
    </div>
  );
}

/**
 * onChange from Redux Form Field has to be called explicity.
 */
function singleChangeHandler(func) {
  return function handleSingleChange(value) {
    func(value ? value.value : '');
  };
}

/**
 * onBlur from Redux Form Field has to be called explicity.
 */
function multiChangeHandler(func) {
  return function handleMultiHandler(values) {
    func(values.map(value => value.value));
  };
}

/**
 * For single select, Redux Form keeps the value as a string, while React Select
 * wants the value in the form { value: "grape", label: "Grape" }
 *
 * * For multi select, Redux Form keeps the value as array of strings, while React Select
 * wants the array of values in the form [{ value: "grape", label: "Grape" }]
 */
function transformValue(value, options, multi) {
  if (multi && typeof value === 'string') return [];

  const filteredOptions = options.filter(
    option =>
      multi ? value.indexOf(option.value) !== -1 : option.value === value,
  );

  return multi ? filteredOptions : filteredOptions[0];
}

SelectInput.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
  }).isRequired,
  options: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  multi: PropTypes.bool,
  onInputChange: PropTypes.func,
  placeholder: PropTypes.string,
  isSearchable: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  filterOption: PropTypes.func,
  peopleStyles: PropTypes.bool,
  style: PropTypes.object,
};

SelectInput.defaultProps = {
  multi: false,
  onInputChange: () => {},
  peopleStyles: false,
};

export default SelectInput;
