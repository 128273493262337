import React from 'react';
import ChampionImg from 'images/articles/banner/champion.png';
import GratitudeImg from 'images/articles/banner/gratitude.png';
import LaughterImg from 'images/articles/banner/laughter.png';
import MeditationImg from 'images/articles/banner/meditation.png';
import SocialMediaImg from 'images/articles/banner/social-media.png';
import MhConversationImg from 'images/articles/banner/mh-conversation.png';
import StressManagementImg from 'images/articles/banner/stress-management.png';
import WorkLifeImg from 'images/articles/banner/work-life.png';
import HelpingCoWorkersImg from 'images/articles/banner/helping-coworkers.png';
import BossStressImg from 'images/articles/banner/boss-stress.png';
import ProtectBullyingImg from 'images/articles/banner/protect-bullying.png';
import AnxietyInTimeOfCoronaImg from 'images/articles/banner/anxiety-corona.png';
import HowWeEatHealthyImg from 'images/articles/banner/eat-healthy.png';
import RoleDietPlaysImg from 'images/articles/banner/diet-role.png';
import ReturnToWorkPrepImg from 'images/articles/banner/return-to-work-prep.jpg';
import BingeDrinkingImg from 'images/articles/banner/binge-drinking.jpg';
import GroundhogDayImg from 'images/articles/banner/groundhog-day.jpg';
import HolidaysBackTogetherImg from 'images/articles/banner/holidays-back-together.png';
import HomeAndWorkBalanceImg from 'images/articles/banner/home-and-work-balance.png';
import StartingAConversationAboutMhImg from 'images/articles/banner/starting-a-conversation-about-mh.png';
import TheNextNormalImg from 'images/articles/banner/the-next-normal.png';
import BustingMythsAroundMentalIllnessImg from 'images/articles/banner/busting-myths-around-mental-illness.png';
import HowToPrepareForYourFirstImg from 'images/articles/banner/how-to-prepare-for-your-first.jpg';
import GlobalConflictImg from 'images/articles/banner/global-conflict.jpg';
import { FormattedMessage } from 'react-intl';
import RespondingWithEmpathyImg from '../../../images/articles/banner/responding-empathy.png';
import benefitsSelfCareImg from '../../../images/articles/banner/benefits-selfcare.png';
import WhatIsQuietQuittingImg from '../../../images/articles/banner/what-is-quiet-quitting.png';

import BeAChampionForChange from './ArticleContents/BeAChampionForChange';
import Gratitude from './ArticleContents/Gratitude';
import Laughter from './ArticleContents/Laughter';
import MindfulMeditation from './ArticleContents/MindfulMeditation';
import SocialMedia from './ArticleContents/SocialMedia';

import StartingAMentalHealthConversation from './ArticleContents/StartingAMentalHealthConversation';
import StressManagement from './ArticleContents/StressManagement';
import WorkLifeBalance from './ArticleContents/WorkLifeBalance';
import HelpingTroubledCoWorkers from './ArticleContents/HelpingTroubledCoWorkers';
import MyBossIsStressingMeOut from './ArticleContents/MyBossIsStressingMeOut';
import Authors from './Authors';
import ProtectOurselvesAgainstBullying from './ArticleContents/ProtectOurselvesAgainstBullying';
import AnxietyInTimeOfCorona from './ArticleContents/AnxietyInTimeOfCorona';
import HowWeEatHealthy from './ArticleContents/HowWeEatHealthy';
import ReturnToWorkPrep from './ArticleContents/ReturnToWorkPrep';
import RoleDietPlays from './ArticleContents/RoleDietPlays';
import BingeDrinking from './ArticleContents/BingeDrinking';
import GroundhogDay from './ArticleContents/GroundhogDay';
import HolidaysBackTogether from './ArticleContents/HolidaysBackTogether';
import HomeAndWorkBalance from './ArticleContents/HomeAndWorkBalance';
import StartingAConversationAboutMh from './ArticleContents/StartingAConversationAboutMh';
import TheNextNormal from './ArticleContents/TheNextNormal';
import BustingMythsAroundMentalIllness from './ArticleContents/BustingMythsAroundMentalIllness';
import HowToPrepareForYourFirst from './ArticleContents/HowToPrepareForYourFirst';

import messages from '../messages';
import GlobalConflictQA from './ArticleContents/GlobalConflictQuestionAnswer';
import GlobalConflictArticle from './ArticleContents/GlobalConflictArticle';
import RespondingWithEmpathy from './ArticleContents/RespondingWithEmpathy';
import BenefitsOfSelfCare from './ArticleContents/BenefitsSelfCare';
import WhatIsQuietQuitting from './ArticleContents/WhatIsQuietQuitting/Index';

const EmployeeArticles = {
  how_to_prepare_for_your_first: {
    id: 'how_to_prepare_for_your_first',
    title: <FormattedMessage {...messages.howToPrepareForYourFirstTitle} />,
    description: (
      <FormattedMessage {...messages.howToPrepareForYourFirstDesc} />
    ),
    ArticleBody: BustingMythsAroundMentalIllness,
    bannerImage: HowToPrepareForYourFirstImg,
  },
  busting_myths_around_mental_illness: {
    id: 'busting_myths_around_mental_illness',
    title: (
      <FormattedMessage {...messages.bustingMythsAroundMentalIllnessTitle} />
    ),
    description: (
      <FormattedMessage {...messages.bustingMythsAroundMentalIllnessDesc} />
    ),
    ArticleBody: HowToPrepareForYourFirst,
    bannerImage: BustingMythsAroundMentalIllnessImg,
  },
  holidays_back_together: {
    id: 'holidays_back_together',
    title: <FormattedMessage {...messages.holidaysBackTogetherTitle} />,
    description: <FormattedMessage {...messages.holidaysBackTogetherDesc} />,
    ArticleBody: HolidaysBackTogether,
    bannerImage: HolidaysBackTogetherImg,
  },
  home_and_work_balance: {
    id: 'home_and_work_balance',
    title: <FormattedMessage {...messages.homeAndWorkBalanceTitle} />,
    description: <FormattedMessage {...messages.homeAndWorkBalanceDesc} />,
    ArticleBody: HomeAndWorkBalance,
    bannerImage: HomeAndWorkBalanceImg,
  },
  starting_a_conversation_about_mh: {
    id: 'starting_a_conversation_about_mh',
    title: <FormattedMessage {...messages.startingAConversationAboutMhTitle} />,
    description: (
      <FormattedMessage {...messages.startingAConversationAboutMhDesc} />
    ),
    ArticleBody: StartingAConversationAboutMh,
    bannerImage: StartingAConversationAboutMhImg,
  },
  the_next_normal: {
    id: 'the_next_normal',
    title: <FormattedMessage {...messages.theNextNormalTitle} />,
    description: <FormattedMessage {...messages.theNextNormalDesc} />,
    ArticleBody: TheNextNormal,
    bannerImage: TheNextNormalImg,
  },

  groundhog_day: {
    id: 'groundhog_day',
    title: <FormattedMessage {...messages.groundhogDayTitle} />,
    description: <FormattedMessage {...messages.groundhogDayDesc} />,
    ArticleBody: GroundhogDay,
    bannerImage: GroundhogDayImg,
    author: Authors.judyPlotkinTwo,
  },

  binge_drinking: {
    id: 'binge_drinking',
    title: <FormattedMessage {...messages.bingeDrinkingTitle} />,
    description: <FormattedMessage {...messages.bingeDrinkingDesc} />,
    ArticleBody: BingeDrinking,
    bannerImage: BingeDrinkingImg,
    author: [Authors.judyPlotkinTwo, Authors.andreaBassettTwo],
  },
  return_to_work_prep: {
    id: 'return_to_work_prep',
    title: <FormattedMessage {...messages.returnToWorkPrepTitle} />,
    description: <FormattedMessage {...messages.returnToWorkPrepDesc} />,
    ArticleBody: ReturnToWorkPrep,
    bannerImage: ReturnToWorkPrepImg,
  },
  anxiety_in_time_of_corona: {
    id: 'anxiety_in_time_of_corona',
    title: <FormattedMessage {...messages.anxietyInTimeOfCoronaTitle} />,
    description: <FormattedMessage {...messages.anxietyInTimeOfCoronaDesc} />,
    author: Authors.judyPlotkin,
    ArticleBody: AnxietyInTimeOfCorona,
    bannerImage: AnxietyInTimeOfCoronaImg,
  },
  how_we_eat_healthy: {
    id: 'how_we_eat_healthy',
    title: <FormattedMessage {...messages.howWeEatHealthyTitle} />,
    description: <FormattedMessage {...messages.howWeEatHealthyDesc} />,
    author: Authors.dannaHurst,
    ArticleBody: HowWeEatHealthy,
    bannerImage: HowWeEatHealthyImg,
  },
  diet_plays_a_role: {
    id: 'diet_plays_a_role',
    title: <FormattedMessage {...messages.roleDietPlaysTitle} />,
    description: <FormattedMessage {...messages.roleDietPlaysDesc} />,
    author: Authors.melissaFallis,
    ArticleBody: RoleDietPlays,
    bannerImage: RoleDietPlaysImg,
  },
  be_a_champion_for_change: {
    id: 'be_a_champion_for_change',
    title: <FormattedMessage {...messages.aChampionForChangeTitle} />,
    description: <FormattedMessage {...messages.aChampionForChangeDesc} />,
    author: Authors.dannaHurst,
    bannerImage: ChampionImg,
    ArticleBody: BeAChampionForChange,
  },
  gratitude: {
    id: 'gratitude',
    title: <FormattedMessage {...messages.gratitudeTitle} />,
    description: <FormattedMessage {...messages.gratitudeDesc} />,
    author: Authors.andreaBassett,
    bannerImage: GratitudeImg,
    ArticleBody: Gratitude,
  },
  laughter: {
    id: 'laughter',
    title: <FormattedMessage {...messages.laughterTitle} />,
    description: <FormattedMessage {...messages.laughterDesc} />,
    author: Authors.andreaBassett,
    bannerImage: LaughterImg,
    ArticleBody: Laughter,
    sources: [
      ' https://www.mayoclinic.org/healthy-lifestyle/stress-management/in-depth/stress-relief/art-20044456 ',
    ],
  },
  mindful_meditation: {
    id: 'mindful_meditation',
    title: <FormattedMessage {...messages.mindfulMeditationTitle} />,
    description: <FormattedMessage {...messages.mindfulMeditationDesc} />,

    author: Authors.andreaBassett,
    bannerImage: MeditationImg,
    ArticleBody: MindfulMeditation,
    sources: [
      'https://www.canada.ca/en/public-health/services/publications/diseases-conditions/mood-anxiety-disorders-canada.html',
      'https://www.healthline.com/nutrition/12-benefits-of-meditation#section2',
      'https://www.sciencedaily.com/releases/2014/05/140529142313.htm',
    ],
  },
  social_media: {
    id: 'social_media',
    title: <FormattedMessage {...messages.socialMediaTitle} />,
    description: <FormattedMessage {...messages.socialMediaDesc} />,
    author: Authors.melissaFallis,
    bannerImage: SocialMediaImg,
    ArticleBody: SocialMedia,
    sources: [
      'https://employees.viu.ca/sites/default/files/dangersofsocialmediaonyourmentalhealthq12017.pdf',
      'http://www.bbc.com/future/story/20180104-is-social-media-bad-for-you-the-evidence-and-the-unknowns',
      'https://www.theweek.co.uk/checked-out/90557/is-social-media-bad-for-your-mental-health',
      'http://www.tristanharris.com/2016/01/distracted-in-2016-welcome-to-mindfulness-bootcamp-for-your-iphone/',
    ],
  },
  starting_a_mental_health_conversation: {
    id: 'starting_a_mental_health_conversation',
    title: <FormattedMessage {...messages.mentalHealthConversationTitle} />,
    description: (
      <FormattedMessage {...messages.mentalHealthConversationDesc} />
    ),
    author: Authors.andreaBassett,
    bannerImage: MhConversationImg,
    ArticleBody: StartingAMentalHealthConversation,
    sources: ['https://cmha.ca/about-cmha/fast-facts-about-mental-illness'],
  },
  stress_management: {
    id: 'stress_management',
    title: <FormattedMessage {...messages.stressManagementTitle} />,
    description: <FormattedMessage {...messages.stressManagementDesc} />,
    author: Authors.andreaBassett,
    bannerImage: StressManagementImg,
    ArticleBody: StressManagement,
  },
  work_life_balance: {
    id: 'work_life_balance',
    title: <FormattedMessage {...messages.workLifeBalanceTitle} />,
    description: <FormattedMessage {...messages.workLifeBalanceDesc} />,
    author: Authors.andreaBassett,
    bannerImage: WorkLifeImg,
    ArticleBody: WorkLifeBalance,
  },
  helping_troubled_co_workers: {
    id: 'helping_troubled_co_workers',
    title: <FormattedMessage {...messages.helpingTroubledTitle} />,
    description: <FormattedMessage {...messages.helpingTroubledDesc} />,
    author: Authors.maryAnnBaynton,
    ArticleBody: HelpingTroubledCoWorkers,
    bannerImage: HelpingCoWorkersImg,
  },
  my_boss_is_stressing_me_out: {
    id: 'my_boss_is_stressing_me_out',
    title: <FormattedMessage {...messages.bossIsStressTitle} />,
    description: <FormattedMessage {...messages.bossIsStressDesc} />,
    author: Authors.maryAnnBaynton,
    ArticleBody: MyBossIsStressingMeOut,
    bannerImage: BossStressImg,
  },
  protect_ourselves_against_bullying: {
    id: 'protect_ourselves_against_bullying',
    title: <FormattedMessage {...messages.protectAgainstBullyingTitle} />,
    description: <FormattedMessage {...messages.protectAgainstBullyingDesc} />,
    author: Authors.maryAnnBaynton,
    ArticleBody: ProtectOurselvesAgainstBullying,
    bannerImage: ProtectBullyingImg,
  },
  global_conflict_qa: {
    id: 'global_conflict_qa',
    title: <FormattedMessage {...messages.globalConflictQATitle} />,
    description: <FormattedMessage {...messages.globalConflictQADesc} />,
    // author
    ArticleBody: GlobalConflictQA,
    bannerImage: GlobalConflictImg,
    bannerImagePosition: 'top',
  },
  global_conflict_reactions: {
    id: 'global_conflict_reactions',
    title: <FormattedMessage {...messages.globalConflictReactionsTitle} />,
    description: <FormattedMessage {...messages.globalConflictReactionsDesc} />,
    author: Authors.judyPlotkin,
    ArticleBody: GlobalConflictArticle,
    bannerImage: GlobalConflictImg,
    bannerImagePosition: 'top',
  },
  responding_with_empathy_article: {
    id: 'responding_with_empathy_article',
    title: <FormattedMessage {...messages.respondingWithEmpathyArticleTitle} />,
    description: (
      <FormattedMessage {...messages.respondingWithEmpathyArticleDesc} />
    ),
    ArticleBody: RespondingWithEmpathy,
    bannerImage: RespondingWithEmpathyImg,
    excludeManager: true,
  },
  benefits_of_self_care: {
    id: 'benefits_of_self_care',
    title: <FormattedMessage {...messages.benefitsSelfCareTitle} />,
    description: <FormattedMessage {...messages.benefitsSelfCareDesc} />,
    ArticleBody: BenefitsOfSelfCare,
    bannerImage: benefitsSelfCareImg,
    bannerImagePosition: 'top',
    excludeManager: true,
  },
  what_is_quiet_quitting: {
    id: 'what_is_quiet_quitting',
    author: Authors.andreaBassettTwo,
    title: <FormattedMessage {...messages.whatIsQuietQuittingTitle} />,
    description: <FormattedMessage {...messages.whatIsQuietQuittingDesc} />,
    ArticleBody: WhatIsQuietQuitting,
    bannerImage: WhatIsQuietQuittingImg,
    excludeManager: true,
  },
};

export default EmployeeArticles;
