import { takeLatest, put, call } from 'redux-saga/effects';
import { post } from 'utils/request';
import { API_URL } from 'utils/environment';
import { trackUserClickSuccess, trackUserClickError } from './actions';
import { TRACK_USER_CLICK } from './constants';

function* trackUserClick(values) {
  const requestURL = `${API_URL}/people/user_clicks`;
  try {
    const response = yield call(post, requestURL, values.article_name);
    yield put(trackUserClickSuccess(values.article_name));
    if (values.callback) yield call(values.callback);
  } catch (error) {
    yield put(trackUserClickError(error));
  }
}

// Individual exports for testing
export default function* employeeResourcesSaga() {
  // See example in containers/HomePage/saga.js
  yield takeLatest(TRACK_USER_CLICK, trackUserClick);
}
