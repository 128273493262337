import React from 'react';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';
import { InkblotTheme, Button } from 'inkblot-ui';
import { BBDBAP, BBDEAP } from 'utils/environment';
import Spinner from './spinner.svg';
import { deepSeaBlue } from '../../global-styles';
const LabelWrapper = styled.span`
  .isvg {
    display: inline-block;
    margin-right: 8px;
    width: 20px;
    svg {
      width: 100%;
    }
  }
`;
const StyledButton = styled(Button)`
  &:focus {
    outline: 1px solid ${deepSeaBlue};
    box-shadow: 0 6px 8px rgb(0 0 0 / 0.2);
  }

  background-image: ${props => props.BBDOrBAP && 'none'};
  background-color: #587abc;
`;

function SpinnerButton(props) {
  const { label, loading, BBDOrBAP, ...rest } = props;

  const buttonLabel = loading ? (
    <LabelWrapper>
      <SVG src={Spinner} />
      {label}
    </LabelWrapper>
  ) : (
    label
  );

  return (
    <InkblotTheme>
      <StyledButton
        BBDOrBAP={BBDEAP || BBDBAP}
        label={buttonLabel}
        disabled={loading}
        {...rest}
      />
    </InkblotTheme>
  );
}

export default SpinnerButton;
