/*
 *
 * Assessment actions
 *
 */

import {
  FETCH_ASSESSMENT,
  FETCH_ASSESSMENT_SUCCESS,
  FETCH_ASSESSMENT_ERROR,
  UPDATE_ASSESSMENT,
  UPDATE_ASSESSMENT_SUCCESS,
  UPDATE_ASSESSMENT_ERROR,
  FETCH_REPORT,
  FETCH_REPORT_SUCCESS,
  FETCH_REPORT_ERROR,
  UPLOAD_VIDEO,
  UPLOAD_VIDEO_ERROR,
  UPLOAD_VIDEO_SUCCESS,
  DOWNLOAD_REPORT,
  DOWNLOAD_REPORT_ERROR,
  DOWNLOAD_REPORT_SUCCESS,
  CLEAR_ASSESSMENT,
  CLEAR_ASSESSMENT_SUCCESS,
  CLEAR_ASSESSMENT_ERROR,
  EMAIL_REPORT,
  EMAIL_REPORT_SUCCESS,
  EMAIL_REPORT_ERROR,
  CLEAR_EMAIL_REPORT,
} from './constants';

export function fetchAssessment(callback) {
  return {
    type: FETCH_ASSESSMENT,
    callback,
  };
}
export function fetchAssessmentSuccess(assessment) {
  return {
    type: FETCH_ASSESSMENT_SUCCESS,
    assessment,
  };
}
export function fetchAssessmentError(error) {
  return {
    type: FETCH_ASSESSMENT_ERROR,
    error,
  };
}

export function clearAssessment(id, callback) {
  return {
    type: CLEAR_ASSESSMENT,
    id,
    callback,
  };
}

export function clearAssessmentSuccess(assessment) {
  return {
    type: CLEAR_ASSESSMENT_SUCCESS,
    assessment,
  };
}

export function clearAssessmentError(error) {
  return {
    type: CLEAR_ASSESSMENT_ERROR,
    error,
  };
}

export function updateAssessment(
  id,
  questionCode = '',
  answers,
  paths,
  currentScreen,
  callback,
) {
  return {
    type: UPDATE_ASSESSMENT,
    id,
    questionCode,
    answers,
    paths,
    currentScreen,
    callback,
  };
}

export function updateAssessmentSuccess(assessment) {
  return {
    type: UPDATE_ASSESSMENT_SUCCESS,
    assessment,
  };
}

export function updateAssessmentError(error) {
  return {
    type: UPDATE_ASSESSMENT_ERROR,
    error,
  };
}

export function fetchReport(id, lines, report, callback) {
  return {
    type: FETCH_REPORT,
    id,
    lines,
    report,
    callback,
  };
}
export function fetchReportSuccess(assessment, callback) {
  return {
    type: FETCH_REPORT_SUCCESS,
    assessment,
    callback,
  };
}
export function fetchReportError(error) {
  return {
    type: FETCH_REPORT_ERROR,
    error,
  };
}

export function uploadVideo(params, callback) {
  return {
    type: UPLOAD_VIDEO,
    params,
    callback,
  };
}

export function uploadVideoError(error) {
  return {
    type: UPLOAD_VIDEO_ERROR,
    error,
  };
}

export function uploadVideoSuccess(assessment) {
  return {
    type: UPLOAD_VIDEO_SUCCESS,
    assessment,
  };
}

export function downloadReport(id, lines, callback) {
  return {
    type: DOWNLOAD_REPORT,
    id,
    lines,
    callback,
  };
}

export function downloadReportSuccess(link) {
  return {
    type: DOWNLOAD_REPORT_SUCCESS,
    link,
  };
}

export function downloadReportError(error) {
  return {
    type: DOWNLOAD_REPORT_ERROR,
    error,
  };
}

export function emailReport(id, lines, callback) {
  return {
    type: EMAIL_REPORT,
    id,
    lines,
    callback,
  };
}

export function emailReportSuccess(link) {
  return {
    type: EMAIL_REPORT_SUCCESS,
    link,
  };
}

export function emailReportError(error) {
  return {
    type: EMAIL_REPORT_ERROR,
    error,
  };
}

export function clearEmailReport() {
  return {
    type: CLEAR_EMAIL_REPORT,
  };
}
