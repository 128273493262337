/**
 *
 * UpdatePopover
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';

import H4 from 'components/H4';
import makeSelectUserSettings from './selectors';
import reducer from './reducer';
import saga from './saga';
import messages from './messages';
import { fetchUserSettings, updateUserSettings } from './actions';

const PopoverWrapper = styled.div`
  position: fixed;
  left: 0;
  bottom: 30px;
  min-height: 125px;
  background-color: #fff;
  box-shadow: 0 0 50px 0 rgba(40, 71, 140, 0.2);
  min-width: 250px;
  z-index: 900;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export class UpdatePopover extends React.Component {
  componentDidMount() {
    this.props.fetchUserSettings();
  }

  /* eslint-disable no-restricted-globals */
  updateSettings = () => {
    this.props.updateUserSettings({ update_available_yn: false }, () => {
      location.reload(true);
    });
  };

  render() {
    const { settings } = this.props;
    if (settings.get('update_available_yn')) {
      return (
        <PopoverWrapper>
          <H4 bold style={{ marginBottom: 20 }}>
            <FormattedMessage {...messages.updateAvailable} />
          </H4>
          <Button isButton onClick={() => this.updateSettings()}>
            {<FormattedMessage {...messages.refresh} />}
          </Button>
        </PopoverWrapper>
      );
    }
    return <noscript />;
  }
}

UpdatePopover.propTypes = {
  fetchUserSettings: PropTypes.func,
  settings: PropTypes.object,
  updateUserSettings: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  settings: makeSelectUserSettings(),
});

function mapDispatchToProps(dispatch) {
  return {
    fetchUserSettings: callback => dispatch(fetchUserSettings(callback)),
    updateUserSettings: (data, callback) =>
      dispatch(updateUserSettings(data, callback)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'user_updates', reducer });
const withSaga = injectSaga({ key: 'user_updates', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(UpdatePopover);
