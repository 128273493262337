import React from 'react';
import { Player } from 'video-react';
import VideoBody from './VideoBody';
import StigmaVideo from './Stigma.mp4';

const Stigma = () => (
  <div>
    <VideoBody>
      <Player playsInline src={StigmaVideo} />
    </VideoBody>
  </div>
);
export default Stigma;
