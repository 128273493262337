/*
 *
 * Assessment constants
 *
 */

export const FETCH_ASSESSMENT = 'app/Assessment/FETCH_ASSESSMENT';
export const FETCH_ASSESSMENT_SUCCESS =
  'app/Assessment/FETCH_ASSESSMENT_SUCCESS';
export const FETCH_ASSESSMENT_ERROR = 'app/Assessment/FETCH_ASSESSMENT_ERROR';

export const UPDATE_ASSESSMENT = 'app/Assessment/UPDATE_ASSESSMENT';
export const UPDATE_ASSESSMENT_SUCCESS =
  'app/Assessment/UPDATE_ASSESSMENT_SUCCESS';
export const UPDATE_ASSESSMENT_ERROR = 'app/Assessment/UPDATE_ASSESSMENT_ERROR';

export const FETCH_REPORT = 'app/Assessment/FETCH_REPORT';
export const FETCH_REPORT_SUCCESS = 'app/Assessment/FETCH_REPORT_SUCCESS';
export const FETCH_REPORT_ERROR = 'app/Assessment/FETCH_REPORT_ERROR';

export const UPLOAD_VIDEO = 'app/Assessment/UPLOAD_VIDEO';
export const UPLOAD_VIDEO_ERROR = 'app/Assessment/UPLOAD_VIDEO_ERROR';
export const UPLOAD_VIDEO_SUCCESS = 'app/Assessment/UPLOAD_VIDEO_SUCCESS';

export const DOWNLOAD_REPORT = 'app/Assessment/DOWNLOAD_REPORT';
export const DOWNLOAD_REPORT_ERROR = 'app/Assessment/DOWNLOAD_REPORT_ERROR';
export const DOWNLOAD_REPORT_SUCCESS = 'app/Assessment/DOWNLOAD_SUCCESS';

export const CLEAR_ASSESSMENT = 'app/Assessment/CLEAR_ASSESSMENT';
export const CLEAR_ASSESSMENT_ERROR = 'app/Assessment/CLEAR_ASSESSMENT_ERROR';
export const CLEAR_ASSESSMENT_SUCCESS =
  'app/Assessment/CLEAR_ASSESSMENT_SUCCESS';

export const EMAIL_REPORT = 'app/Assessment/EMAIL_REPORT';
export const EMAIL_REPORT_ERROR = 'app/Assessment/EMAIL_REPORT_ERROR';
export const EMAIL_REPORT_SUCCESS = 'app/Assessment/EMAIL_REPORT_SUCCESS';

export const CLEAR_EMAIL_REPORT = 'app/Assessment/CLEAR_EMAIL_REPORT';
