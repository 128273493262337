/*
 *
 * Onboarding constants
 *
 */

export const DEFAULT_ACTION = 'app/Onboarding/DEFAULT_ACTION';

export const ONBOARD = 'app/Onboarding/ONBOARD';
export const ONBOARD_SUCCESS = 'app/Onboarding/ONBOARD_SUCCESS';
export const ONBOARD_ERROR = 'app/Onboarding/ONBOARD_ERROR';

export const FETCH_STATUSES = 'app/Onboarding/FETCH_STATUSES';
export const FETCH_STATUSES_SUCCESS = 'app/Onboarding/FETCH_STATUSES_SUCCESS';
export const FETCH_STATUSES_ERROR = 'app/Onboarding/FETCH_STATUSES_ERROR';

export const FETCH_ONBOARD = 'app/Onboarding/FETCH_ONBOARD';
export const FETCH_ONBOARD_SUCCESS = 'app/Onboarding/FETCH_ONBOARD_SUCCESS';
export const FETCH_ONBOARD_ERROR = 'app/Onboarding/FETCH_ONBOARD_ERROR';
