/*
 *
 * MarketingPage reducer
 *
 */

import { fromJS } from 'immutable';
import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_SUCCESS,
} from './constants';

export const initialState = fromJS({
  authenticated: false,
  message: '',
});

function resetPasswordReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_PASSWORD:
      return state.set('loading', true);
    case CHANGE_PASSWORD_ERROR:
      return state
        .set('loading', false)
        .setIn(['error', 'loginError'], action.error.data.error);
    case CHANGE_PASSWORD_SUCCESS:
      return state
        .set('loading', false)
        .set('message', action.user.message)
        .setIn(['error', 'loginError'], '');
    default:
      return state;
  }
}

export default resetPasswordReducer;
