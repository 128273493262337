/*
 *
 * SettingHistory constants
 *
 */

export const FETCH_STATUSES = 'app/SettingHistory/FETCH_STATUSES';
export const FETCH_STATUSES_SUCCESS =
  'app/SettingHistory/FETCH_STATUSES_SUCCESS';
export const FETCH_STATUSES_ERROR = 'app/SettingHistory/FETCH_STATUSES_ERROR';
export const CHANGE_PASSWORD = 'app/SettingHistory/CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS =
  'app/SettingHistory/CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'app/SettingHistory/CHANGE_PASSWORD_ERROR';
export const FETCH_ASSESSMENTS = 'app/SettingsHistory/FETCH_ASSESSMENTS';
export const FETCH_ASSESSMENTS_SUCCESS =
  'app/SettingsHistory/FETCH_ASSESSMENTS_SUCCESS';
export const FETCH_ASSESSMENTS_ERROR =
  'app/SettingsHistory/FETCH_ASSESSMENTS_ERROR';
