/*
 *
 * MarketingPage actions
 *
 */

import { API_URL } from 'utils/environment';
import {
  ONBOARD,
  ONBOARD_SUCCESS,
  ONBOARD_ERROR,
  FETCH_ONBOARD,
  FETCH_ONBOARD_SUCCESS,
  FETCH_ONBOARD_ERROR,
  FETCH_STATUSES,
  FETCH_STATUSES_SUCCESS,
  FETCH_STATUSES_ERROR,
} from './constants';

export function onboard(user, url = API_URL, callback, errCallback) {
  return {
    type: ONBOARD,
    user,
    url,
    callback,
    errCallback,
  };
}
export function onboardSuccess(user, callback) {
  return {
    type: ONBOARD_SUCCESS,
    user,
    callback,
  };
}
export function onboardError(error) {
  return {
    type: ONBOARD_ERROR,
    error,
  };
}

export function fetchStatuses() {
  return {
    type: FETCH_STATUSES,
  };
}

export function fetchStatusesSuccess(statuses) {
  return {
    type: FETCH_STATUSES_SUCCESS,
    statuses,
  };
}

export function fetchStatusesError(error) {
  return {
    type: FETCH_STATUSES_ERROR,
    error,
  };
}

export function fetchOnboard(token, callback) {
  return {
    type: FETCH_ONBOARD,
    token,
    callback,
  };
}

export function fetchOnboardSuccess(user) {
  return {
    type: FETCH_ONBOARD_SUCCESS,
    user,
  };
}

export function fetchOnboardError(error) {
  return {
    type: FETCH_ONBOARD_ERROR,
    error,
  };
}
