/**
 *
 * LanguageSelect
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { changeLocale } from 'containers/LanguageProvider/actions';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { updateUser as updateUserAction } from 'containers/App/actions';
import { ENVIRONMENT, BBDBAP, BBDEAP } from 'utils/environment';
import Container from './Container';
import LanguageButton from './LanguageButton';

const redirectURL = (newLocale, token, eap) => {
  if (ENVIRONMENT === 'DEVELOPMENT') return;

  if (ENVIRONMENT === 'STAGING') {
    if (eap) {
      if (newLocale === 'fr') {
        window.location.href = `https://staging.peoplevousconnecte.com/pae${
          token ? `/redirect/${token}` : ''
        }`;
      } else {
        window.location.href = `https://staging.pcpeopleconnect.com/eap${
          token ? `/redirect/${token}` : ''
        }`;
      }
    } else if (BBDBAP) {
      if (newLocale === 'fr') {
        window.location.href = `https://bbdcarepath-bap-stg.peoplevousconnecte.com${
          token ? `/redirect/${token}` : ''
        }`;
      } else {
        window.location.href = `https://bbdcarepath-bap-stg.pcpeopleconnect.com${
          token ? `/redirect/${token}` : ''
        }`;
      }
    } else if (BBDEAP) {
      if (newLocale === 'fr') {
        window.location.href = `https://bbdeap-stg.peoplevousconnecte.com${
          token ? `/redirect/${token}` : ''
        }`;
      } else {
        window.location.href = `https://bbdeap-stg.pcpeopleconnect.com${
          token ? `/redirect/${token}` : ''
        }`;
      }
    } else if (newLocale === 'fr') {
      window.location.href = `https://staging.peoplevousconnecte.com${
        token ? `/redirect/${token}` : ''
      }`;
    } else {
      window.location.href = `https://staging.pcpeopleconnect.com${
        token ? `/redirect/${token}` : ''
      }`;
    }
  }

  if (ENVIRONMENT === 'PRODUCTION') {
    if (eap) {
      if (newLocale === 'fr') {
        window.location.href = `https://peoplevousconnecte.com/pae${
          token ? `/redirect/${token}` : ''
        }`;
      } else {
        window.location.href = `https://pcpeopleconnect.com/eap${
          token ? `/redirect/${token}` : ''
        }`;
      }
    } else if (BBDBAP) {
      if (newLocale === 'fr') {
        window.location.href = `https://bbdcarepath-pap.peoplevousconnecte.com${
          token ? `/redirect/${token}` : ''
        }`;
      } else {
        window.location.href = `https://bbdcarepath-bap.pcpeopleconnect.com${
          token ? `/redirect/${token}` : ''
        }`;
      }
    } else if (BBDEAP) {
      if (newLocale === 'fr') {
        window.location.href = `https://bbdpae.peoplevousconnecte.com${
          token ? `/redirect/${token}` : ''
        }`;
      } else {
        window.location.href = `https://bbdeap.pcpeopleconnect.com${
          token ? `/redirect/${token}` : ''
        }`;
      }
    } else if (newLocale === 'fr') {
      window.location.href = `https://peoplevousconnecte.com${
        token ? `/redirect/${token}` : ''
      }`;
    } else {
      window.location.href = `https://pcpeopleconnect.com${
        token ? `/redirect/${token}` : ''
      }`;
    }
  }
};
function LanguageSelect({ locale, changeLanguage, updateUser, eap }) {
  const handleLanguageChange = async selectedLocale => {
    if (selectedLocale === locale) return;
    const Token = localStorage.getItem('token');
    localStorage.setItem('initLocale', selectedLocale);
    await changeLanguage(selectedLocale);
    if (!Token) {
      redirectURL(selectedLocale, Token, eap);
      return;
    }
    await updateUser({ locale: selectedLocale }, () => {
      redirectURL(selectedLocale, Token, eap);
    });
  };

  return (
    <Container>
      <LanguageButton
        aria-label={
          locale === 'en' ? 'Current Language: English' : 'Select English'
        }
        selected={locale === 'en'}
        onClick={() => handleLanguageChange('en')}
      >
        EN
      </LanguageButton>
      <div style={{ margin: '0 10px' }}>|</div>
      <LanguageButton
        aria-label={
          locale === 'fr' ? 'Current Language: French' : 'Select French'
        }
        selected={locale === 'fr'}
        onClick={() => handleLanguageChange('fr')}
      >
        FR
      </LanguageButton>
    </Container>
  );
}

LanguageSelect.propTypes = {
  locale: PropTypes.string.isRequired,
  changeLanguage: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  eap: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  locale: makeSelectLocale(),
});

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: locale => dispatch(changeLocale(locale)),
    updateUser: (user, callback) => dispatch(updateUserAction(user, callback)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect)(LanguageSelect);
