import { takeLatest, put, call } from 'redux-saga/effects';
import { post } from 'utils/request';
import { signInSuccess, signInError } from './actions';
import { SIGN_IN } from './constants';

function* signIn(values) {
  const requestURL = `${values.url}/people/auths/login`;
  try {
    const response = yield call(post, requestURL, values.user);
    yield put(signInSuccess(response));
    if (response.access_token) {
      values.callback(response.access_token);
    }
  } catch (error) {
    yield put(signInError(error));
    if (values.errCallback) {
      values.errCallback();
    }
  }
}

export default function* signupSaga() {
  yield takeLatest(SIGN_IN, signIn);
}
