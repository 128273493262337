import { takeLatest, call, put } from 'redux-saga/effects';

import { API_URL } from 'utils/environment';
import { get } from 'utils/request';

import { FETCH_METRICS, FETCH_COMPANIES } from './constants';
import {
  fetchMetricsSuccess,
  fetchMetricsError,
  fetchCompaniesError,
  fetchCompaniesSuccess,
} from './actions';

function* fetchMetrics({ company, startRange, endRange, callback }) {
  const requestURL = `${API_URL}/people/metrics?company=${company}&start_range=${startRange}&end_range=${endRange}`;

  try {
    yield call(get, requestURL);
    yield put(fetchMetricsSuccess());
    if (callback) callback();
  } catch (error) {
    yield put(fetchMetricsError(error));
    if (callback) callback(null, error);
  }
}

function* fetchCompanies({ query, callback }) {
  const requestURL = `${API_URL}/people/people_care_companies?query=${query}&include_life_inc=true`;

  try {
    const companies = yield call(get, requestURL);
    yield put(fetchCompaniesSuccess(companies));
    if (callback) yield call(callback, companies);
  } catch (error) {
    yield put(fetchCompaniesError(error));
  }
}

export default function* metricsSaga() {
  yield takeLatest(FETCH_METRICS, fetchMetrics);
  yield takeLatest(FETCH_COMPANIES, fetchCompanies);
}
