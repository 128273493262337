import styled from 'styled-components';

const Card = styled.div`
  background-color: #ffffff;
  width: 100%;
  box-shadow: 0 0 14px 0 rgba(11, 118, 188, 0.18);
  padding: 40px;
  margin-bottom: 40px;
  word-break: break-word;
`;

export default Card;
