import React from 'react';
import { FormattedMessage } from 'react-intl';

import TwelveStepsImg from 'images/articles/banner/12-steps.png';
import ComplexAccomImg from 'images/articles/banner/complex-accom.png';
import ExecutiveConvoImg from 'images/articles/banner/executive-convo.png';
import UnderstandingThreeImg from 'images/articles/banner/understanding-three.png';
import NationalStandardImg from 'images/articles/banner/nat-standard.png';
import EchoPandemicImage from 'images/articles/banner/echo-pandemic.jpg';

import messages from '../messages';
import Authors from './Authors';
import TwelveSteps from './ArticleContents/TwelveSteps';
import ComplexAccomodations from './ArticleContents/ComplexAccomodations';
import ExecutiveConversations from './ArticleContents/ExecutiveConversations';
import UnderstandingThreeStandards from './ArticleContents/UnderstandingThreeStandards';
import NationalPsychSafetyStandard from './ArticleContents/NationalPsychSafetyStandard';
import EchoPandemic from './ArticleContents/EchoPandemic';

export const PlanSponsorArticles = {
  echo_pandemic: {
    id: 'echo_pandemic',
    title: <FormattedMessage {...messages.echoPandemicTitle} />,
    description: <FormattedMessage {...messages.echoPandemicDesc} />,
    author: Authors.judyPlotkinTwo,
    bannerImage: EchoPandemicImage,
    ArticleBody: EchoPandemic,
    supportsFrench: true,
  },

  twelve_steps_to_implementing: {
    id: 'twelve_steps_to_implementing',
    title:
      '12 Steps to Implementing the National Standard for Psychological Health and Safety',
    description:
      'Organizations that implement the Standard are, on average, better performers in all key performance categories...',
    author: Authors.scottWallace,
    bannerImage: TwelveStepsImg,
    ArticleBody: TwelveSteps,
    sources: [
      'National Psychological Health and Safety Standard. MHCC (2013). Retrieved November 26, 2018 from: https://www.mentalhealthcommission.ca/English/what-we-do/workplace/national-standard.',
      'Psychological Health and Safety in the Workplace: Prevention, promotion, and a staged implementation. CSA Group (2013).',
      'Assembling the Pieces –An Implementation Guide to the National Standard for Psychological Health and Safety. CSA Group (2014).',
    ],
    supportsFrench: false,
  },
  complex_accomodations: {
    id: 'complex_accomodations',
    title: <FormattedMessage {...messages.complexAccomodationsTitle} />,
    description: <FormattedMessage {...messages.complexAccomodationsDesc} />,
    author: Authors.judyPlotkin,
    bannerImage: ComplexAccomImg,
    ArticleBody: ComplexAccomodations,
    supportsFrench: true,
  },
  executive_conversations: {
    id: 'executive_conversations',
    title: <FormattedMessage {...messages.executiveConversationsTitle} />,
    description: <FormattedMessage {...messages.executiveConversationsDesc} />,
    author: Authors.judyPlotkin,
    bannerImage: ExecutiveConvoImg,
    ArticleBody: ExecutiveConversations,
    supportsFrench: true,
  },
  understanding_three_standards: {
    id: 'understanding_three_standards',
    title: <FormattedMessage {...messages.understandingThreeStandardsTitle} />,
    description: (
      <FormattedMessage {...messages.understandingThreeStandardsDesc} />
    ),
    author: Authors.isabelleLipari,
    bannerImage: UnderstandingThreeImg,
    ArticleBody: UnderstandingThreeStandards,
    supportsFrench: true,
  },
  national_psych_safety_standard: {
    id: 'national_psych_safety_standard',
    title:
      'The national standard of Canada for psychological health and well-being',
    description:
      'The national standard of Canada for psychological health safety in the workplace (the standard), is a set of voluntary guidelines...',
    author: Authors.scottWallace,
    bannerImage: NationalStandardImg,
    ArticleBody: NationalPsychSafetyStandard,
    supportsFrench: false,
  },
  specialized_services: {
    id: 'specialized_services',
    title: '',
    description: <FormattedMessage {...messages.specializedServices} />,
    type: 'noButton',
    supportsFrench: true,
  },
};

export default PlanSponsorArticles;
