import { css } from 'styled-components';
import { deepSeaBlue } from '../../global-styles';

const buttonStyles = css`
  width: 100%;
  height: 30px;
  background-color: #d7f4f9;
  color: ${deepSeaBlue};
  border-radius: 20px;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.6px;
  cursor: pointer;

  &:active {
    color: #fff;
  }
`;

export default buttonStyles;
