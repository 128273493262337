/*
 * CriticalIncidentServices Messages
 *
 * This contains all the text for the CriticalIncidentServices container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.SecondOpinionServices';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Second opinion services',
  },
  body1: {
    id: `${scope}.body1`,
    defaultMessage:
      'Providing medical second opinions by a specialist to ensure that your recommended treatment plan is consistent with best practice clinical guidelines. A full assessment and written report is provided. Powered by Cleveland Clinic.',
  },
  body2: {
    id: `${scope}.body2`,
    defaultMessage: 'How it works:',
  },
  body3: {
    id: `${scope}.body3`,
    defaultMessage:
      'All services are delivered virtually via telephone or secure video conferencing, which allows for timely, safe, and efficient support. Your nurse case manager is here to assist you through diagnosis, treatment and plan of care. Our program works in parallel to the public health care system and does not replace your current health care team.',
  },
  body4: {
    id: `${scope}.body4`,
    defaultMessage:
      'When you access support, you will be connected with our intake team who will ask you some general questions and you will receive consent forms to complete.',
  },
  body5: {
    id: `${scope}.body5`,
    defaultMessage:
      'A nurse case manager will then contact you within 1-2 business days to conduct a thorough review of your medical history, current diagnosis, associated symptoms, and to discuss any other questions you may have about your condition.',
  },
  body6: {
    id: `${scope}.body6`,
    defaultMessage:
      'For more information about what is included in Carepath services, <a href="https://peopleconnectcarepath.ca/">click here.</a>',
  },
  button: {
    id: `${scope}.button`,
    defaultMessage: 'Access health care now',
  },
  body7: {
    id: `${scope}.body7`,
    defaultMessage: 'Or call 1-866-883-5956',
  },
});
