/**
 *
 * Dialog
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';

import Close from 'images/dialog/close.svg';
import { phoneSize } from 'global-styles';

const Container = styled.div`
  display: ${({ open }) => (open ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9000;
`;

const Overlay = styled.div`
  display: ${({ open }) => (open ? 'block' : 'none')};
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(15, 32, 69, 0.6);
`;

const Body = styled.div`
  display: ${({ open }) => (open ? 'block' : 'none')};
  z-index: 9001;
  position: relative;
  width: 100%;
  max-width: 511px;
  box-shadow: 0 0 50px 0 rgba(40, 71, 140, 0.2);
  background-color: #ffffff;
  padding: 60px 0;

  @media (max-width: ${phoneSize}) {
    margin: 0 20px;
  }

  .close {
    position: absolute;
    cursor: pointer;
    right: 20px;
    top: 15px;
  }
`;

function Dialog({ children, closeHidden, onRequestClose, open }) {
  document.body.classList.toggle('no-scroll', open);

  return (
    <Container open={open}>
      <Overlay onClick={onRequestClose} open={open} />
      <Body className="dialog-body" open={open}>
        {!closeHidden && (
          <div className="close" onClick={onRequestClose} role="presentation">
            <SVG src={Close} />
          </div>
        )}
        {children}
      </Body>
    </Container>
  );
}

Dialog.propTypes = {
  children: PropTypes.node,
  closeHidden: PropTypes.bool,
  onRequestClose: PropTypes.func,
  open: PropTypes.bool,
};

Dialog.defaultProps = {
  closeHidden: false,
  onRequestClose: () => {},
  open: false,
};

export default Dialog;
