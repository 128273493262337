import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';

export const genOptions = [
  { label: <FormattedMessage {...messages.gen1} />, fieldName: 'gen1' },
  { label: <FormattedMessage {...messages.gen2} />, fieldName: 'gen2' },
  { label: <FormattedMessage {...messages.gen3} />, fieldName: 'gen3' },
  { label: <FormattedMessage {...messages.gen4} />, fieldName: 'gen4' },
  { label: <FormattedMessage {...messages.gen5} />, fieldName: 'gen5' },
];

export const physOptions = [
  { label: <FormattedMessage {...messages.phys1} />, fieldName: 'phys1' },
  { label: <FormattedMessage {...messages.phys2} />, fieldName: 'phys2' },
  { label: <FormattedMessage {...messages.phys3} />, fieldName: 'phys3' },
  { label: <FormattedMessage {...messages.phys4} />, fieldName: 'phys4' },
  { label: <FormattedMessage {...messages.phys5} />, fieldName: 'phys5' },
];

export const dxOptions = [
  { label: <FormattedMessage {...messages.dx1} />, fieldName: 'dx1' },
  { label: <FormattedMessage {...messages.dx2} />, fieldName: 'dx2' },
  { label: <FormattedMessage {...messages.dx3} />, fieldName: 'dx3' },
  { label: <FormattedMessage {...messages.dx4} />, fieldName: 'dx4' },
  { label: <FormattedMessage {...messages.dx5} />, fieldName: 'dx5' },
  { label: <FormattedMessage {...messages.dx6} />, fieldName: 'dx6' },
  { label: <FormattedMessage {...messages.dx7} />, fieldName: 'dx7' },
  { label: <FormattedMessage {...messages.dx8} />, fieldName: 'dx8' },
  { label: <FormattedMessage {...messages.dx9} />, fieldName: 'dx9' },
  { label: <FormattedMessage {...messages.dx10} />, fieldName: 'dx10' },
  { label: <FormattedMessage {...messages.dx11} />, fieldName: 'dx11' },
  { label: <FormattedMessage {...messages.dx12} />, fieldName: 'dx12' },
  { label: <FormattedMessage {...messages.dx13} />, fieldName: 'dx13' },
];

export const stressOptions = [
  { label: <FormattedMessage {...messages.stress1} />, fieldName: 'stress1' },
  { label: <FormattedMessage {...messages.stress2} />, fieldName: 'stress2' },
  { label: <FormattedMessage {...messages.stress3} />, fieldName: 'stress3' },
  { label: <FormattedMessage {...messages.stress4} />, fieldName: 'stress4' },
  { label: <FormattedMessage {...messages.stress5} />, fieldName: 'stress5' },
  { label: <FormattedMessage {...messages.stress6} />, fieldName: 'stress6' },
  { label: <FormattedMessage {...messages.stress7} />, fieldName: 'stress7' },
  { label: <FormattedMessage {...messages.stress8} />, fieldName: 'stress8' },
  { label: <FormattedMessage {...messages.stress9} />, fieldName: 'stress9' },
  { label: <FormattedMessage {...messages.stress10} />, fieldName: 'stress10' },
  { label: <FormattedMessage {...messages.stress11} />, fieldName: 'stress11' },
  { label: <FormattedMessage {...messages.stress12} />, fieldName: 'stress12' },
  { label: <FormattedMessage {...messages.stress13} />, fieldName: 'stress13' },
  { label: <FormattedMessage {...messages.stress14} />, fieldName: 'stress14' },
  { label: <FormattedMessage {...messages.stress15} />, fieldName: 'stress15' },
  { label: <FormattedMessage {...messages.stress16} />, fieldName: 'stress16' },
  { label: <FormattedMessage {...messages.stress17} />, fieldName: 'stress17' },
];

export const workOptions = [
  { label: <FormattedMessage {...messages.work1} />, fieldName: 'work1' },
  { label: <FormattedMessage {...messages.work2} />, fieldName: 'work2' },
  { label: <FormattedMessage {...messages.work3} />, fieldName: 'work3' },
  { label: <FormattedMessage {...messages.work4} />, fieldName: 'work4' },
  { label: <FormattedMessage {...messages.work5} />, fieldName: 'work5' },
  { label: <FormattedMessage {...messages.work6} />, fieldName: 'work6' },
  { label: <FormattedMessage {...messages.work7} />, fieldName: 'work7' },
  { label: <FormattedMessage {...messages.work8} />, fieldName: 'work8' },
  { label: <FormattedMessage {...messages.work9} />, fieldName: 'work9' },
  { label: <FormattedMessage {...messages.work10} />, fieldName: 'work10' },
  { label: <FormattedMessage {...messages.work11} />, fieldName: 'work11' },
  { label: <FormattedMessage {...messages.work12} />, fieldName: 'work12' },
  { label: <FormattedMessage {...messages.work13} />, fieldName: 'work13' },
];

export const schoolOptions = [
  { label: <FormattedMessage {...messages.school1} />, fieldName: 'school1' },
  { label: <FormattedMessage {...messages.school2} />, fieldName: 'school2' },
  { label: <FormattedMessage {...messages.school3} />, fieldName: 'school3' },
  { label: <FormattedMessage {...messages.school4} />, fieldName: 'school4' },
  { label: <FormattedMessage {...messages.school5} />, fieldName: 'school5' },
  { label: <FormattedMessage {...messages.school6} />, fieldName: 'school6' },
  { label: <FormattedMessage {...messages.school7} />, fieldName: 'school7' },
  { label: <FormattedMessage {...messages.school8} />, fieldName: 'school8' },
  { label: <FormattedMessage {...messages.school9} />, fieldName: 'school9' },
  { label: <FormattedMessage {...messages.school10} />, fieldName: 'school10' },
  { label: <FormattedMessage {...messages.school11} />, fieldName: 'school11' },
  { label: <FormattedMessage {...messages.school12} />, fieldName: 'school12' },
];

export const crossP1S1Options = [
  {
    label: <FormattedMessage {...messages.crossDepression1} />,
    fieldName: 'cross_depression1',
  },
  {
    label: <FormattedMessage {...messages.crossBipolar1} />,
    fieldName: 'cross_bipolar1',
  },
  {
    label: <FormattedMessage {...messages.crossDepression2} />,
    fieldName: 'cross_depression2',
  },
  {
    label: <FormattedMessage {...messages.crossAnxiety1} />,
    fieldName: 'cross_anxiety1',
  },
];

export const crossP1S2Options = [
  {
    label: <FormattedMessage {...messages.crossAnxiety2} />,
    fieldName: 'cross_anxiety2',
  },
  {
    label: <FormattedMessage {...messages.crossBipolar2} />,
    fieldName: 'cross_bipolar2',
  },
  {
    label: <FormattedMessage {...messages.crossAnxiety3} />,
    fieldName: 'cross_anxiety3',
  },
  {
    label: <FormattedMessage {...messages.crossSubstance1} />,
    fieldName: 'cross_substance1',
  },
];

export const crossP1S3Options = [
  {
    label: <FormattedMessage {...messages.crossBipolar3} />,
    fieldName: 'cross_bipolar3',
  },
  {
    label: <FormattedMessage {...messages.crossSubstance2} />,
    fieldName: 'cross_substance2',
  },
  {
    label: <FormattedMessage {...messages.crossPtsd1} />,
    fieldName: 'cross_ptsd1',
  },
];

export const crossP2S1Options = [
  {
    label: <FormattedMessage {...messages.crossOcd1} />,
    fieldName: 'cross_ocd1',
  },
  {
    label: <FormattedMessage {...messages.crossPhobia1} />,
    fieldName: 'cross_phobia1',
  },
  {
    label: <FormattedMessage {...messages.crossPsychosis1} />,
    fieldName: 'cross_psychosis1',
  },
  {
    label: <FormattedMessage {...messages.crossOcd2} />,
    fieldName: 'cross_ocd2',
  },
  {
    label: <FormattedMessage {...messages.crossGenanx1} />,
    fieldName: 'cross_genanx1',
  },
];

export const crossP2S2Options = [
  {
    label: <FormattedMessage {...messages.crossPsychosis3} />,
    fieldName: 'cross_psychosis3',
  },
  {
    label: <FormattedMessage {...messages.crossDissociation1} />,
    fieldName: 'cross_dissociation1',
  },
  {
    label: <FormattedMessage {...messages.crossPsychosis2} />,
    fieldName: 'cross_psychosis2',
  },
  {
    label: <FormattedMessage {...messages.crossPersonality1} />,
    fieldName: 'cross_personality1',
  },
  {
    label: <FormattedMessage {...messages.crossAgoraphobia1} />,
    fieldName: 'cross_agoraphobia1',
  },
];

export const crossP2S3Options = [
  {
    label: <FormattedMessage {...messages.crossPersonality2} />,
    fieldName: 'cross_personality2',
  },
  {
    label: <FormattedMessage {...messages.crossSomatic1} />,
    fieldName: 'cross_somatic1',
  },
  {
    label: <FormattedMessage {...messages.crossSomatic2} />,
    fieldName: 'cross_somatic2',
  },
  {
    label: <FormattedMessage {...messages.crossSocial1} />,
    fieldName: 'cross_social1',
  },
  {
    label: <FormattedMessage {...messages.crossSeparation} />,
    fieldName: 'cross_separation',
  },
];
