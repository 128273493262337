import React from 'react';
import PropTypes from 'prop-types';
import { InkblotTheme, Button } from 'inkblot-ui';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import H1 from 'components/H1';
import Breadcrumbs from 'components/Breadcrumbs';
import Sources from './Sources';
import messages from './messages';

import AuthorSection from './AuthorSection';
import AuthorSectionHead from './AuthorSectionHead';

const ArticleWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 100px;
  h1 {
    padding: 0px;
    padding-left: 25px;
  }
  .author-section {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 25px;
    display: flex;
    align-items: center;
    img {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      object-fit: cover;
      object-position: top;
    }
    .no-avatar {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background-color: #e8eff5;
    }
    p {
      padding-left: 10px;
      display: inline-block;
      font-family: 'Lato';
      font-size: 12px;
      font-weight: normal;
      font-style: italic;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #444e69;
      margin: 0px;
    }
  }
`;

const BannerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  img {
    object-fit: cover;
    object-position: ${props => props.bannerImagePosition && props.bannerImagePosition};
    width: 100%;
    height: 200px;
    position: absolute;
  }
  .no-banner {
    width: 100%;
    height: 200px;
    position: absolute;
    background-color: #2256aa;
  }
`;

const ArticleBodyWrapper = styled.div`
  padding: 20px;
  padding-bottom: 60px;
  .article-body {
    font-family: 'Lato';
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #444e69;
    a {
      color: #005790;
    }
  }
`;

const ResourcesLinkWrapper = styled.div`
  text-align: center;
`;

const Article = ({
  title,
  author,
  bannerImage,
  ArticleBody,
  sources,
  goBack,
  bannerImagePosition,
}) => (
  <ArticleWrapper>
    <div style={{ paddingLeft: '20px' }}>
      <Breadcrumbs
        names={[
          <FormattedMessage {...messages.dashboard} />,
          <FormattedMessage {...messages.resources} />,
        ]}
      />
    </div>
    <H1 style={{ color: '#1f275b' }}>{title}</H1>

    <div className="author-section">
      {author &&
        // if author exists
        (Array.isArray(author) ? (
          // if author exists & if there are multiple authors
          author.map(singleAuthor => (
            <AuthorSectionHead author={singleAuthor} />
          ))
        ) : (
          // if author exists & if there is a single author
          <AuthorSectionHead author={author} />
        ))}
    </div>

    <BannerWrapper
      bannerImagePosition={bannerImagePosition}
    >
      {bannerImage ? (
        <img src={bannerImage} alt="banner" />
      ) : (
        <div className="no-banner" />
      )}
    </BannerWrapper>
    <ArticleBodyWrapper>
      <ArticleBody />
    </ArticleBodyWrapper>

    {author &&
      // if author exists
      (Array.isArray(author) ? (
        // if author exists & if there are multiple authors
        author.map(singleAuthor => <AuthorSection {...singleAuthor} />)
      ) : (
        // if author exists & if there is a single author
        <AuthorSection {...author} />
      ))}

    <Sources sources={sources} />
    <ResourcesLinkWrapper>
      <InkblotTheme>
        <Button
          people
          label={<FormattedMessage {...messages.returnToResources} />}
          style={{ textTransform: 'uppercase' }}
          onClick={goBack}
        />
      </InkblotTheme>
    </ResourcesLinkWrapper>
  </ArticleWrapper>
);

Article.propTypes = {
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  bannerImage: PropTypes.string,
  bannerImagePosition: PropTypes.string,
  sources: PropTypes.array,
  ArticleBody: PropTypes.any,
};

export default Article;
