import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import ArticleBody from '../ArticleBody';
import messages from '../../../messages';
const BestQualitiesOfAManager = () => (
  <div>
    <ArticleBody>
      <FormattedHTMLMessage tagName="p" {...messages.bestQualitiesBody} />
      {/* Effective managers are the key to a healthy and successful business.
      Managers move teams through change, guide teams to success, lend a helping
      hand and are there for tough and good times. Managers are the key to
      business success and employee engagement. Successful employees that move
      into leadership do not always make good managers. Managers that inspire
      and create engagement often have very unique skills. Pinpointing these
      skills and traits can be difficult and challenging for leadership.
      <br />
      <br />
      As an auditor for the Healthy Enterprise Standard, I have had to interview
      over 500 employees from different organizations, positions and education
      backgrounds. Some of the questions in the interview pertained to the
      management styles, behaviour and relationships with their team members.
      From those interviews, I discovered three key qualities that would make a
      manager better able to reach the team’s goals and instill an overall sense
      of wellbeing in the workplace.
      <br />
      <br />
      Here are the three qualities most cited by the employees interviewed:
      <br />
      <br />
      <ul>
        <li>Empathy</li>
        <li>Encouragement</li>
        <li>Active Listening</li>
      </ul>
      <br />
      <br />
      <b>Empathy</b>: the action of understanding, being aware of, being
      sensitive to, and vicariously experiencing the feelings, thoughts, and
      experience of another, in the past or present, without having the
      feelings, thoughts, and experience fully communicated in an objectively
      explicit manner.1
      <br />
      <br />
      Empathy builds on emotional self-awareness and is fundamental to “people
      skills”. It is recognized as a basic skill for better management and one
      of the five basic skills for high emotional intelligence, now branded as
      the key to effective management and leadership.2
      <br />
      <br />
      Showing empathy helps build trust and connection, which translate to
      higher commitment and engagement.
      <br />
      <br />
      <b>Encouragement</b>: Being recognized and encouraged on a daily basis was
      cited as a must-have with regards to a manager’s key qualities. Employees
      just want to know their boss cares, knows they exist and recognizes their
      work and input. A simple “Hello, how are you today?” in the morning was
      all it took for some employees to feel engaged, motivated, noticed and
      appreciated. This automatically transfers into productivity, teamwork and
      high output.
      <br />
      <br />
      <b>Active Listening</b>: Knowing that their superior was attuned to their
      needs and concerns was also very important for the wellbeing of employees.
      Knowing what others need or want will translate into better leadership
      skills, thus being able to persuade people to work toward a common goal.2
      <br />
      <br />
      These three key qualities will create a culture of respect and
      understanding and will nurture a healthy and safe work environment. These
      three qualities will also help managers be more aware of their staff,
      their behaviours and their performance.
      <br />
      <br />
      These qualities also align with recent findings on emotional intelligence
      and the benefits of “mindful management.”
      <br />
      <br />
      Going forward leadership can screen for these qualities when making
      management decisions since having the right managers in place is key to
      business success. */}
    </ArticleBody>
  </div>
);

BestQualitiesOfAManager.propTypes = {};

export default BestQualitiesOfAManager;
