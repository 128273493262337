import React from 'react';
import PropTypes from 'prop-types';

class SetComm100OnMount extends React.PureComponent {
  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    const user = this.props.user.data;
    // eslint-disable-next-line prefer-destructuring
    const company = this.props.user.data.company;

    // about user
    localStorage.setItem('user_id', user.id);
    localStorage.setItem('full_name', `${user.first_name} ${user.last_name}`);
    localStorage.setItem('language', user.locale);

    // about company
    localStorage.setItem('company_name', company.name || 'n/a');
    localStorage.setItem('company_plan_type', company.plan_type || 'n/a');
  }

  render() {
    return <noscript />;
  }
}

SetComm100OnMount.propTypes = {
  user: PropTypes.object.isRequired,
};

export default SetComm100OnMount;
