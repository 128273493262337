import styled from 'styled-components';
import { deepSeaBlue } from '../../global-styles';

const Navigation = styled.p`
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  letter-spacing: 1.1px;
  height: 50px;
  color: ${deepSeaBlue};
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 30px;
`;

export default Navigation;
