/*
 *
 * UserInfo
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { fetchUser } from 'containers/App/actions';
import { changeLocale } from 'containers/LanguageProvider/actions';
import { makeSelectApp, makeSelectLocale } from './selectors';

export class UserInfo extends React.PureComponent {
  componentDidMount() {
    this.props.fetchUser(user => {
      this.setLanguage(user.locale);
    });
  }

  setLanguage(userLocale) {
    const { currentLocale } = this.props;
    const newLocale = userLocale.substring(0, 2);

    if (
      (newLocale === 'en' || newLocale === 'fr') &&
      newLocale !== currentLocale
    ) {
      this.props.changeLocale(newLocale);
    }
  }

  render() {
    return <div />;
  }
}

UserInfo.propTypes = {
  fetchUser: PropTypes.func,
  currentLocale: PropTypes.string,
  changeLocale: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectApp(),
  currentLocale: makeSelectLocale(),
});

function mapDispatchToProps(dispatch) {
  return {
    fetchUser: callback => dispatch(fetchUser(callback)),
    changeLocale: languageLocale => dispatch(changeLocale(languageLocale)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(UserInfo));
