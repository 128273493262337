import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

import ArticleBody from '../ArticleBody';
import messages from '../../../messages';

const BustingMythsAroundMentalIllness = () => (
  <div>
    <ArticleBody>
      <FormattedHTMLMessage
        tagName="p"
        {...messages.bustingMythsAroundMentalIllnessBody}
      />
    </ArticleBody>
  </div>
);

BustingMythsAroundMentalIllness.propTypes = {};

export default BustingMythsAroundMentalIllness;
