import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import ArticleBody from '../ArticleBody';
import messages from '../../../messages';
const CovidTransformation = () => (
  <div>
    <ArticleBody>
      <FormattedHTMLMessage tagName="p" {...messages.covidTransformationBody} />
    </ArticleBody>
  </div>
);

CovidTransformation.propTypes = {};

export default CovidTransformation;
