/*
 *
 * Metrics
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { reduxForm, Field } from 'redux-form';
import _ from 'lodash';
import { Map } from 'immutable';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import PortalLayout from 'components/PortalLayout';
import Card from 'components/Card';
import P from 'components/P';
import DatePicker from 'components/DatePicker';
import SelectInput from 'components/SelectInput';
import { black, errorRed } from 'global-styles';
import { allowedToViewMetrics } from 'utils/auth';
import { makeSelectApp } from 'containers/App/selectors';
import SpinnerButton from 'components/SpinnerButton';

import { makeSelectMetrics, makeSelectCompanies } from './selectors';
import { fetchMetrics, fetchCompanies, clearCompanies } from './actions';
import reducer from './reducer';
import saga from './saga';

const FieldContainer = styled.div`
  position: relative;
`;

const ErrorText = styled(P)`
  color: ${errorRed};
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  top: 70px;
`;

/* eslint-disable react/prefer-stateless-function */
export class Metrics extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      company: null,
      startRange: null,
      endRange: null,
      errors: {},
    };
  }

  componentDidMount() {
    if (!allowedToViewMetrics()) {
      this.props.history.push('/dashboard');
    }
  }

  // Redux Form validation wasn't working so had to do error handling this way
  onSubmit = () => {
    const { company, startRange, endRange } = this.state;
    const errors = {};

    if (!company) {
      errors.company = 'Required';
    }
    if (!startRange) {
      errors.startRange = 'Required';
    }
    if (!endRange) {
      errors.endRange = 'Required';
    }
    if (startRange && endRange) {
      if (endRange.isBefore(startRange)) {
        errors.endRange = 'End range must be after start range';
      }
      // else if (endRange.diff(startRange, 'year') >= 1) {
      //   errors.endRange = 'Please select a range less than one year';
      // }
    }

    this.setState({ errors }, () => {
      if (_.isEmpty(this.state.errors)) {
        this.props.fetchMetrics(
          company,
          startRange.format('YYYY-MM-DD'),
          endRange.format('YYYY-MM-DD'),
        );
      }
    });
  };

  handleChange = (value, field) =>
    this.setState(prevState => ({
      [field]: value,
      errors: _.omit(prevState.errors, field),
    }));

  searchCompanies = value => {
    if (value !== '') {
      this.props.fetchCompanies(value);
    } else if (value === '') {
      this.props.clearCompanies();
    }
  };

  render() {
    const { handleSubmit, metrics, companies, user } = this.props;
    const { errors } = this.state;

    return (
      <div>
        <Helmet>
          <title>Metrics</title>
          <meta name="description" content="Metrics for People Corp" />
        </Helmet>
        <PortalLayout title="Metrics" activeTab="metrics">
          <Card>
            <form onSubmit={handleSubmit(this.onSubmit)}>
              <FieldContainer>
                <Field
                  label="Company"
                  component={SelectInput}
                  isSearchable
                  options={companies}
                  name="company"
                  placeholder="Search"
                  style={{ width: '180px', marginBottom: '40px' }}
                  onChange={(_c, value) => this.handleChange(value, 'company')}
                  onInputChange={_.debounce(
                    value => this.searchCompanies(value),
                    300,
                  )}
                />
                <ErrorText>{errors.company}</ErrorText>
              </FieldContainer>
              <FieldContainer>
                <Field
                  component={DatePicker}
                  label="Start Range"
                  name="start_range"
                  placeholder="DD/MM/YYYY"
                  style={{ width: '180px', marginBottom: '40px' }}
                  onChange={(_d, date) => this.handleChange(date, 'startRange')}
                />
                <ErrorText>{errors.startRange}</ErrorText>
              </FieldContainer>
              <FieldContainer>
                <Field
                  component={DatePicker}
                  label="End Range"
                  name="end_range"
                  placeholder="DD/MM/YYYY"
                  style={{ width: '180px', marginBottom: '20px' }}
                  onChange={(_d, date) => this.handleChange(date, 'endRange')}
                />
                <ErrorText>{errors.endRange}</ErrorText>
              </FieldContainer>
              <SpinnerButton
                label="SUBMIT"
                loading={metrics.get('loading')}
                people
                style={{ marginTop: '40px' }}
                type="submit"
              />
              <div style={{ marginTop: '20px' }}>
                {metrics.get('sent') && (
                  <P style={{ color: black }}>
                    The report is being generated and will be sent to your email
                    (<b>{user.data.email}</b>) when it is complete.
                  </P>
                )}
                {metrics.get('error') && (
                  <P style={{ color: errorRed }}>
                    There was an error generating the report. Please try again.
                  </P>
                )}
              </div>
            </form>
          </Card>
        </PortalLayout>
      </div>
    );
  }
}

Metrics.propTypes = {
  clearCompanies: PropTypes.func.isRequired,
  fetchMetrics: PropTypes.func.isRequired,
  fetchCompanies: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  metrics: PropTypes.instanceOf(Map),
  companies: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  user: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  metrics: makeSelectMetrics(),
  companies: makeSelectCompanies(),
  user: makeSelectApp(),
});

function mapDispatchToProps(dispatch) {
  return {
    fetchMetrics: (company, startRange, endRange, callback) =>
      dispatch(fetchMetrics(company, startRange, endRange, callback)),
    fetchCompanies: (query, callback) =>
      dispatch(fetchCompanies(query, callback)),
    clearCompanies: () => dispatch(clearCompanies()),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'metrics', reducer });
const withSaga = injectSaga({ key: 'metrics', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(reduxForm({ form: 'MetricsForm' })(Metrics));
