/*
 *
 * Assessment BackToScreen
 *
 */

/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import AssessmentLayout from 'components/AssessmentLayout';
import H1 from 'components/H1';
import P from 'components/P';
import MacScreen from 'images/mac-screen.png';
import messages from './messages';

const BackToWrapper = styled.div`
  .mac-screen {
    height: 200px;
    width: 200px;
    margin-bottom: 40px;
  }

  h1 {
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 70px;
    color: #0f2045;
  }
`;

const DashboardLinkWrapper = styled.div`
  padding-top: 20px;
`;

/* eslint-disable react/prefer-stateless-function */
const ErrorScreen = props => (
  <BackToWrapper className="back-to-screen">
    <AssessmentLayout openExitDialog={props.openExitDialog}>
      <img className="mac-screen" src={MacScreen} alt="mac" />
      <H1 fontSize="30px">Sorry, something went wrong...</H1>
      <P fontSize="20px">Please try again later</P>
      <DashboardLinkWrapper>
        <Link to="/dashboard">
          <P fontSize="14px">
            <span style={{ textDecoration: 'underline' }}>
              <FormattedMessage {...messages.introReturnToDashboard} />
            </span>
          </P>
        </Link>
      </DashboardLinkWrapper>
    </AssessmentLayout>
  </BackToWrapper>
);

export default ErrorScreen;
