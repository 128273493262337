import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the marketingPage state domain
 */

const selectOnboardingDomain = state => state.get('onboarding', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by MarketingPage
 */

const makeSelectOnboarding = () =>
  createSelector(selectOnboardingDomain, substate => substate.toJS());

const makeSelectStatuses = () =>
  createSelector(selectOnboardingDomain, substate => substate.get('statuses'));

export { selectOnboardingDomain, makeSelectOnboarding, makeSelectStatuses };
