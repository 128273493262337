/*
 *
 * MarketingPage actions
 *
 */

import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
} from './constants';

export function changePassword(user, callback) {
  return {
    type: CHANGE_PASSWORD,
    user,
    callback,
  };
}
export function changePasswordSuccess(user, callback) {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    user,
    callback,
  };
}
export function changePasswordError(error) {
  return {
    type: CHANGE_PASSWORD_ERROR,
    error,
  };
}
