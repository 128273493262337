/*
 * Booking Messages
 *
 * This contains all the text for the Booking container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.MarketingPage';

export default defineMessages({
  bossQuote: {
    id: `${scope}.bossQuote`,
    defaultMessage:
      'Today’s world and modern life are increasingly filled with uncertainty, complicated relationships, and frequent change. Employers need assistance creating mentally healthy workplaces and employees need easy access to help for mental concerns. Employees do not need to suffer in silence: feeling empty, withdrawn, and unsettled without sufficient support and help.',
  },
});
