import React from 'react';
import { deepSeaBlue } from '../../../global-styles';

const Input = props => {
  const {
    meta: { touched, error },
    label,
    name,
    placeholder,
    value,
    type,
    bError,
    id,
  } = props;
  return (
    <div className="input-container">
      <label style={{ fontFamily: '"Montserrat", sans-serif', color: deepSeaBlue, marginBottom: '1em' }} htmlFor={id}>
        {label}
      </label>
      <input
        type={type || 'text'}
        name={name}
        id={id}
        placeholder={placeholder}
        value={value}
        style={{ width: '100%', outline: 'none' }}
        {...props.input}
      />
      {touched && error && <p className="error-text">{error}</p>}
      {touched && bError && <p className="error-text">{bError}</p>}
    </div>
  );
};

export default Input;
