/*
 *
 * Metrics constants
 *
 */

export const FETCH_METRICS = 'app/Metrics/FETCH_METRICS';
export const FETCH_METRICS_SUCCESS = 'app/Metrics/FETCH_METRICS_SUCCESS';
export const FETCH_METRICS_ERROR = 'app/Metrics/FETCH_METRICS_ERROR';

export const FETCH_COMPANIES = 'app/Metrics/FETCH_COMPANIES';
export const FETCH_COMPANIES_SUCCESS = 'app/Metrics/FETCH_COMPANIES_SUCCESS';
export const FETCH_COMPANIES_ERROR = 'app/Metrics/FETCH_COMPANIES_ERROR';

export const CLEAR_COMPANIES = 'app/Metrics/CLEAR_COMPANIES';
