import React from 'react';
import { FormattedMessage } from 'react-intl';

import P from 'components/P';
import messages from './messages';

export const AuthorSectionHead = props => (
  <>
    {props.author.image ? (
      <>
        <img src={props.author.image} alt="profile" />
        <P>
          <FormattedMessage {...messages.author} />
          {props.author.name}
        </P>
      </>
    ) : (
      <>
        <div className="no-avatar" />
        <P>
          <FormattedMessage {...messages.author} />
          {props.author.name}
        </P>
      </>
    )}
  </>
);

export default AuthorSectionHead;
