/**
 *
 * SingleCheckBox
 *
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';

import P from 'components/P';

import { errorRed } from '../../global-styles';
import SelectedSvg from './selected.svg';
import UnselectedSvg from './unselected.svg';

const StyledContainer = styled.div`
  p {
    margin: 0;
  }
`;

const StyledCheckBox = styled.label`
  display: inline-flex;
  cursor: pointer;
  position: relative;
  margin-bottom: 13px;
  padding: 10px;
  left: -10px;
  input {
    position: absolute;
    opacity: 0;
  }

  .scb-icon {
    margin-left: -10px;
    margin-top: -6px;
    margin-right: 15px;
  }
  .scb-label {
    font-family: 'Lato', sans-serif;
    font-size: 12px;
    color: #1d212c;
    font-weight: 400;
  }
`;

const ErrorMessage = styled(P)`
  margin: -10px 0 30px 0 !important;
  font-size: 14px;
  color: ${errorRed};
`;

function SingleCheckBox({ input, meta, label }) {
  const { value, onChange } = input;
  const { touched, error } = meta;
  const ariaLabel = label.props.defaultMessage;
  return (
    <StyledContainer>
      <StyledCheckBox
        tabIndex={0}
        aria-label={value ? `Checked ${ariaLabel}` : `Uncheck ${ariaLabel}`}
        onKeyDown={({ key }) => {
          if (key === ' ' || key === 'Enter') onChange(!value);
        }}
        onClick={() => onChange(!value)}
      >
        <div className="scb-icon">
          <SVG src={value ? SelectedSvg : UnselectedSvg} alt="selected" />
        </div>
        <div className="scb-label">{label}</div>
      </StyledCheckBox>
      {touched && error && <ErrorMessage>{touched ? error : ''}</ErrorMessage>}
    </StyledContainer>
  );
}

SingleCheckBox.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default SingleCheckBox;
