import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { connect } from 'react-redux';
import { compose } from 'redux';

import PanelCard from 'components/PanelCard';
import Button from 'components/Button';
import H4 from 'components/H4';
import P from 'components/P';
import messages from './messages';

import { trackUserClick } from '../EmployeeResources/actions';

class ArticleCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleUserClick = () => {
    const { title, history, id } = this.props;
    const article_name = !title.props ? title : title.props.defaultMessage;
    this.props.trackUserClick(
      {
        article_name,
      },
      () => history.push(`/articles/${id}`),
    );
  };

  render() {
    const { bannerImage, title, description, type } = this.props;

    let buttonTitle = <FormattedMessage {...messages.readMore} />;
    if (type === 'video')
      buttonTitle = <FormattedMessage {...messages.playVideo} />;
    if (type === 'audio')
      buttonTitle = <FormattedMessage {...messages.playAudio} />;

    return (
      <PanelCard
        img={bannerImage}
        width="45%"
        imgHeight="38%"
        minHeight="320px"
      >
        <div>
          <H4 bold>{title}</H4>
          <P style={{ textOverflow: 'ellipsis' }}>{description}</P>
        </div>
        {type !== 'noButton' && (
          <Button onClick={this.handleUserClick}>{buttonTitle}</Button>
        )}
      </PanelCard>
    );
  }
}

ArticleCard.propTypes = {
  id: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  description: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  bannerImage: PropTypes.string,
  history: PropTypes.object,
  type: PropTypes.string,
  trackUserClick: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return {
    trackUserClick: (values, callback) =>
      dispatch(trackUserClick(values, callback)),
  };
}

const withConnect = connect(
  null,
  mapDispatchToProps,
);

export default withRouter(compose(withConnect)(ArticleCard));
