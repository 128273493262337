import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';

export const dassOptions = [
  { label: <FormattedMessage {...messages.dassNone} />, value: 0 },
  { label: <FormattedMessage {...messages.dassSome} />, value: 1 },
  { label: <FormattedMessage {...messages.dassGoodPart} />, value: 2 },
  { label: <FormattedMessage {...messages.dassMost} />, value: 3 },
];

export const wsOptions = [
  { label: <FormattedMessage {...messages.wsWork} />, value: 0 },
  { label: <FormattedMessage {...messages.wsSchool} />, value: 1 },
  { label: <FormattedMessage {...messages.wsEqual} />, value: 2 },
  { label: <FormattedMessage {...messages.wsNeither} />, value: 3 },
];

export const wsizeOptions = [
  { label: '0-9', value: 0 },
  { label: '10-49', value: 1 },
  { label: '50-249', value: 2 },
  { label: '250+', value: 3 },
];

export const schoolLevelOptions = [
  {
    label: <FormattedMessage {...messages.schoolLevelKindergarten} />,
    value: 0,
  },
  { label: <FormattedMessage {...messages.schoolLevelCollege} />, value: 1 },
  { label: <FormattedMessage {...messages.schoolLevelUniversity} />, value: 2 },
  { label: <FormattedMessage {...messages.schoolLevelOther} />, value: 3 },
];

export const neverAlwaysOptions = [
  {
    label: <FormattedMessage {...messages.neverAlwaysOptionsNever} />,
    value: 1,
  },
  {
    label: <FormattedMessage {...messages.neverAlwaysOptionsRarely} />,
    value: 2,
  },
  {
    label: <FormattedMessage {...messages.neverAlwaysOptionsSometimes} />,
    value: 3,
  },
  {
    label: <FormattedMessage {...messages.neverAlwaysOptionsOften} />,
    value: 4,
  },
  {
    label: <FormattedMessage {...messages.neverAlwaysOptionsAlways} />,
    value: 5,
  },
];

export const rarelyAllOptions = [
  {
    label: <FormattedMessage {...messages.rarelyAllOptionsRarely} />,
    value: 1,
  },
  {
    label: <FormattedMessage {...messages.rarelyAllOptionsOccasionally} />,
    value: 2,
  },
  { label: <FormattedMessage {...messages.rarelyAllOptionsOften} />, value: 3 },
  { label: <FormattedMessage {...messages.rarelyAllOptionsMost} />, value: 4 },
  { label: <FormattedMessage {...messages.rarelyAllOptionsAll} />, value: 5 },
];

export const notNearlyOptions = [
  { label: <FormattedMessage {...messages.notNearlyOptionsNot} />, value: 0 },
  { label: <FormattedMessage {...messages.notNearlyOptionsOne} />, value: 1 },
  {
    label: <FormattedMessage {...messages.notNearlyOptionsSeveral} />,
    value: 2,
  },
  { label: <FormattedMessage {...messages.notNearlyOptionsMore} />, value: 3 },
  {
    label: <FormattedMessage {...messages.notNearlyOptionsNearly} />,
    value: 4,
  },
];

export const notExtremelyOptions = [
  {
    label: <FormattedMessage {...messages.notExtremelyOptionsNot} />,
    value: 0,
  },
  {
    label: <FormattedMessage {...messages.notExtremelyOptionsLittle} />,
    value: 1,
  },
  {
    label: <FormattedMessage {...messages.notExtremelyOptionsModerately} />,
    value: 2,
  },
  {
    label: <FormattedMessage {...messages.notExtremelyOptionsQuite} />,
    value: 3,
  },
  {
    label: <FormattedMessage {...messages.notExtremelyOptionsExtremely} />,
    value: 4,
  },
];

export const ocdTimeOptions = [
  { label: <FormattedMessage {...messages.ocdTimeOptionsNone} />, value: 0 },
  { label: <FormattedMessage {...messages.ocdTimeOptionsLess} />, value: 1 },
  { label: <FormattedMessage {...messages.ocdTimeOptions13} />, value: 2 },
  { label: <FormattedMessage {...messages.ocdTimeOptions38} />, value: 3 },
  { label: <FormattedMessage {...messages.ocdTimeOptionsMore} />, value: 4 },
];

export const ocdDistressOptions = [
  {
    label: <FormattedMessage {...messages.ocdDistressOptionsNone} />,
    value: 0,
  },
  {
    label: <FormattedMessage {...messages.ocdDistressOptionsSlightly} />,
    value: 1,
  },
  {
    label: <FormattedMessage {...messages.ocdDistressOptionsManageable} />,
    value: 2,
  },
  {
    label: <FormattedMessage {...messages.ocdDistressOptionsVery} />,
    value: 3,
  },
  {
    label: <FormattedMessage {...messages.ocdDistressOptionsOverwhelming} />,
    value: 4,
  },
];

export const ocdControlOptions = [
  {
    label: <FormattedMessage {...messages.ocdControlOptionsComplete} />,
    value: 0,
  },
  {
    label: <FormattedMessage {...messages.ocdControlOptionsUsually} />,
    value: 1,
  },
  {
    label: <FormattedMessage {...messages.ocdControlOptionsSometimes} />,
    value: 2,
  },
  {
    label: <FormattedMessage {...messages.ocdControlOptionsInfrequently} />,
    value: 3,
  },
  {
    label: <FormattedMessage {...messages.ocdControlOptionsUnable} />,
    value: 4,
  },
];

export const ocdAvoidOptions = [
  { label: <FormattedMessage {...messages.ocdAvoidOptionsNo} />, value: 0 },
  {
    label: <FormattedMessage {...messages.ocdAvoidOptionsOccasional} />,
    value: 1,
  },
  {
    label: <FormattedMessage {...messages.ocdAvoidOptionsRegularly} />,
    value: 2,
  },
  {
    label: <FormattedMessage {...messages.ocdAvoidOptionsFrequently} />,
    value: 3,
  },
  { label: <FormattedMessage {...messages.ocdAvoidOptionsNearly} />, value: 4 },
];

export const ocdInterfereOptions = [
  {
    label: <FormattedMessage {...messages.ocdInterfereOptionsNone} />,
    value: 0,
  },
  {
    label: <FormattedMessage {...messages.ocdInterfereOptionsSlight} />,
    value: 1,
  },
  {
    label: <FormattedMessage {...messages.ocdInterfereOptionsManageable} />,
    value: 2,
  },
  {
    label: <FormattedMessage {...messages.ocdInterfereOptionsSubstantial} />,
    value: 3,
  },
  {
    label: <FormattedMessage {...messages.ocdInterfereOptionsNear} />,
    value: 4,
  },
];

export const phobiaItemOptions = [
  {
    label: <FormattedMessage {...messages.phobiaItemOptionsDriving} />,
    value: 1,
  },
  {
    label: <FormattedMessage {...messages.phobiaItemOptionsAnimals} />,
    value: 2,
  },
  {
    label: <FormattedMessage {...messages.phobiaItemOptionsHeights} />,
    value: 3,
  },
  {
    label: <FormattedMessage {...messages.phobiaItemOptionsBlood} />,
    value: 4,
  },
  {
    label: <FormattedMessage {...messages.phobiaItemOptionsChoking} />,
    value: 5,
  },
  { label: <FormattedMessage {...messages.phobiaItemOptionsNone} />, value: 0 },
];

export const neverAllOptions = [
  { label: <FormattedMessage {...messages.neverAllOptionsNever} />, value: 0 },
  {
    label: <FormattedMessage {...messages.neverAllOptionsOccasionally} />,
    value: 1,
  },
  { label: <FormattedMessage {...messages.neverAllOptionsHalf} />, value: 2 },
  { label: <FormattedMessage {...messages.neverAllOptionsMost} />, value: 3 },
  { label: <FormattedMessage {...messages.neverAllOptionsAll} />, value: 4 },
];

export const notMoreOptions = [
  { label: <FormattedMessage {...messages.notMoreOptionsNot} />, value: 0 },
  { label: <FormattedMessage {...messages.notMoreOptionsOnce} />, value: 1 },
  { label: <FormattedMessage {...messages.notMoreOptionsAlmost} />, value: 2 },
  { label: <FormattedMessage {...messages.notMoreOptionsAbout} />, value: 3 },
  { label: <FormattedMessage {...messages.notMoreOptionsMore} />, value: 4 },
];

export const veryVeryOptions = [
  {
    label: <FormattedMessage {...messages.veryVeryOptionsVeryFalse} />,
    value: 0,
  },
  {
    label: <FormattedMessage {...messages.veryVeryOptionsSometimesFalse} />,
    value: 1,
  },
  {
    label: <FormattedMessage {...messages.veryVeryOptionsSometimesTrue} />,
    value: 2,
  },
  {
    label: <FormattedMessage {...messages.veryVeryOptionsVeryTrue} />,
    value: 3,
  },
];

export const notBotheredOptions = [
  { label: <FormattedMessage {...messages.notBotheredOptionsNot} />, value: 0 },
  {
    label: <FormattedMessage {...messages.notBotheredOptionsLittle} />,
    value: 1,
  },
  { label: <FormattedMessage {...messages.notBotheredOptionsLot} />, value: 2 },
];
