/*
 *
 * Assessment
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Map } from 'immutable';
import { createStructuredSelector } from 'reselect';
import { fetchUser } from 'containers/App/actions';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import dashboardMessages from 'containers/Dashboard/messages';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import LoadingIndicator from 'components/LoadingIndicator';
import AssessmentLayout from 'components/AssessmentLayout';

import {
  fetchAssessment,
  uploadVideo,
  clearAssessment,
  fetchReport,
  emailReport,
  clearEmailReport,
} from './actions';
import messages from './messages';
import {
  makeSelectCurrentAssessment,
  makeSelectCurrentAssessmentLoaded,
  makeSelectReport,
} from './selectors';
import {
  getNextPageId,
  getProgressPercentage,
  getCurrentScreen,
} from './naviHelpers';

import {
  dassOptions,
  wsOptions,
  wsizeOptions,
  schoolLevelOptions,
  neverAlwaysOptions,
  rarelyAllOptions,
  notNearlyOptions,
  notExtremelyOptions,
  ocdTimeOptions,
  ocdDistressOptions,
  ocdControlOptions,
  ocdAvoidOptions,
  ocdInterfereOptions,
  phobiaItemOptions,
  neverAllOptions,
  notMoreOptions,
  veryVeryOptions,
  notBotheredOptions,
} from './SingleSelect/options';
import {
  genOptions,
  physOptions,
  dxOptions,
  stressOptions,
  workOptions,
  schoolOptions,
  crossP1S1Options,
  crossP1S2Options,
  crossP1S3Options,
  crossP2S1Options,
  crossP2S2Options,
  crossP2S3Options,
} from './MultiSelect/options';

import reducer from './reducer';
import saga from './saga';
import Introduction from './Introduction';
import ORS from './ORS';
import SingleSelect from './SingleSelect';
import MultiSelect from './MultiSelect';
import Video from './Video';
import InterimReport from './InterimReport';
import FinalReport from './FinalReport';
import BackToScreen from './BackToScreen';
import ExitDialog from './ExitDialog';
import ErrorScreen from './ErrorScreen';

/* eslint-disable react/prefer-stateless-function */
export class Assessment extends React.PureComponent {
  constructor(props) {
    super(props);

    this.pathValues = this.resetPaths();

    this.state = {
      exitDialogOpen: false,
      currentPageId: 'intro',
      phobiaTopic: '',
    };
  }

  componentDidMount() {
    this.props.fetchUser();
    this.props.fetchAssessment((assessment, error) => {
      if (!error && assessment && assessment.id) {
        if (!assessment.completed_yn && assessment.screens) {
          this.setState({ currentPageId: 'continue' });
        }
      } else {
        this.setState({ currentPageId: 'error-screen' });
      }
    });
    this.disableScreens();
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentPageId } = this.state;

    if (currentPageId !== prevState.currentPageId) {
      window.scrollTo(0, 0);
    }
  }

  disableScreens = () => {
    this.enableScreen('screen_cross_part1', false);
    this.enableScreen('screen_ors_report', false);
    this.enableScreen('screen_cross_part2', false);
    this.enableScreen('screen_depression', false);
    this.enableScreen('screen_anxiety', false);
    this.enableScreen('screen_bipolar', false);
    this.enableScreen('screen_substance', false);
    this.enableScreen('screen_ptsd', false);
    this.enableScreen('screen_interim_report', false);
    this.enableScreen('screen_cross_part2_md', false);
    this.enableScreen('screen_ocd', false);
    this.enableScreen('screen_phobia', false);
    this.enableScreen('screen_phobia_details', false);
    this.enableScreen('screen_dissociation', false);
    this.enableScreen('screen_personality', false);
    this.enableScreen('screen_somatic', false);
    this.enableScreen('screen_social', false);
    this.enableScreen('screen_genanx', false);
    this.enableScreen('screen_agoraphobia', false);
    this.enableScreen('screen_separation', false);
    this.enableScreen('closing', false);
  };

  resetPaths = () => ({
    opening: 0,
    ws_type: 0,
    screen_cross_part1: 0,
    screen_ors_report: 0,
    screen_cross_part2: 0,
    screen_depression: 0,
    screen_anxiety: 0,
    screen_bipolar: 0,
    screen_substance: 0,
    screen_ptsd: 0,
    screen_interim_report: 0,
    screen_cross_part2_md: 0,
    screen_ocd: 0,
    screen_phobia: 0,
    screen_phobia_details: 0,
    screen_dissociation: 0,
    screen_personality: 0,
    screen_somatic: 0,
    screen_social: 0,
    screen_genanx: 0,
    screen_agoraphobia: 0,
    screen_separation: 0,
    closing: 0,
  });

  setWsType = type => {
    this.pathValues.ws_type = type;
  };

  enableScreen = (screenName, flag) => {
    this.pathValues[screenName] = flag ? 1 : 0;
  };

  exitAssessment = () => {
    document.body.classList.remove('no-scroll');
    this.setState(
      {
        exitDialogOpen: false,
      },
      () => this.props.history.push('/dashboard'),
    );
  };

  closeExitDialog = () => {
    document.body.classList.remove('no-scroll');
    this.setState({
      exitDialogOpen: false,
    });
  };

  openExitDialog = () => {
    this.setState({
      exitDialogOpen: true,
    });
  };

  goToNextPage = () => {
    const nextPageId = getNextPageId(
      this.state.currentPageId,
      this.pathValues,
      true,
    );

    if (nextPageId) {
      this.setState({ currentPageId: nextPageId });
    }
  };

  goToPrevPage = () => {
    const prevPageId = getNextPageId(
      this.state.currentPageId,
      this.pathValues,
      false,
    );

    if (prevPageId) {
      this.setState({ currentPageId: prevPageId });
    }
  };

  goToSpecificPage = page => {
    this.enableScreen(page, true);
    this.goToNextPage();
  };

  goToErrorScreen = () => this.setState({ currentPageId: 'error-screen' });

  renderPageById = pageId => {
    const naviProps = {
      openExitDialog: this.openExitDialog,
      goToNextPage: this.goToNextPage,
      goToPrevPage: this.goToPrevPage,
      percent: getProgressPercentage(pageId, this.pathValues),
      currentAssessment: this.props.currentAssessment,
      paths: this.pathValues,
      goToErrorScreen: this.goToErrorScreen,
      currentScreen:
        pageId !== 'continue' && pageId !== 'error-screen'
          ? getCurrentScreen(pageId)
          : null,
    };

    if (!this.props.currentAssessmentLoaded) {
      const showSidebarPercent =
        pageId !== 'continue' &&
        pageId !== 'error-screen' &&
        pageId !== 'intro';

      return (
        <AssessmentLayout
          page={pageId}
          sidebar={showSidebarPercent}
          percent={showSidebarPercent ? naviProps.percent : null}
        >
          <LoadingIndicator />
        </AssessmentLayout>
      );
    }

    switch (pageId) {
      case 'continue':
        return (
          <BackToScreen
            openExitDialog={this.openExitDialog}
            goToCurrentPage={() => {
              this.pathValues = naviProps.currentAssessment
                .get('screens')
                .toJS();
              this.setState({
                currentPageId: naviProps.currentAssessment.get('current_page'),
              });
            }}
            startFresh={() => {
              this.props.clearAssessment(
                naviProps.currentAssessment.get('id'),
                () => {
                  this.pathValues = this.resetPaths();
                  this.disableScreens();
                  this.setState({ currentPageId: 'intro' });
                },
              );
            }}
          />
        );

      case 'error-screen':
        return <ErrorScreen />;
      case 'intro':
        return <Introduction {...naviProps} />;
      case 'ors1':
        return (
          <ORS
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            title={<FormattedMessage {...messages.evaluation} />}
            question={<FormattedMessage {...messages.orsQuestion} />}
            orsMainTitle={<FormattedMessage {...messages.orsIndividually} />}
            orsSubTitle={<FormattedMessage {...messages.orsPersonal} />}
          />
        );
      case 'ors2':
        return (
          <ORS
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            title={<FormattedMessage {...messages.evaluation} />}
            question={<FormattedMessage {...messages.orsQuestion} />}
            orsMainTitle={<FormattedMessage {...messages.orsInterpersonal} />}
            orsSubTitle={<FormattedMessage {...messages.orsFamily} />}
          />
        );
      case 'ors3':
        return (
          <ORS
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            title={<FormattedMessage {...messages.evaluation} />}
            question={<FormattedMessage {...messages.orsQuestion} />}
            orsMainTitle={<FormattedMessage {...messages.orsSocially} />}
            orsSubTitle={<FormattedMessage {...messages.orsWork} />}
          />
        );
      case 'ors4':
        return (
          <ORS
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            title={<FormattedMessage {...messages.evaluation} />}
            question={<FormattedMessage {...messages.orsQuestion} />}
            orsMainTitle={<FormattedMessage {...messages.orsOverall} />}
            orsSubTitle=""
          />
        );
      case 'dass1':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={dassOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description={<FormattedMessage {...messages.dassHowOften} />}
            question={<FormattedMessage {...messages.dass1} />}
            callback={resp => {
              this.enableScreen('screen_depression', resp.depression);

              const feelingWell = resp.ors_score >= 14;
              this.enableScreen('screen_cross_part1', !feelingWell);
              this.enableScreen('screen_ors_report', feelingWell);
            }}
          />
        );
      case 'dass5':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={dassOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description={<FormattedMessage {...messages.dassHowOften} />}
            question={<FormattedMessage {...messages.dass5} />}
            callback={resp =>
              this.enableScreen('screen_depression', resp.depression)
            }
          />
        );
      case 'dass2':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={dassOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description={<FormattedMessage {...messages.dassHowOften} />}
            question={<FormattedMessage {...messages.dass2} />}
            callback={resp => this.enableScreen('screen_anxiety', resp.anxiety)}
          />
        );
      case 'dass6':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={dassOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description={<FormattedMessage {...messages.dassHowOften} />}
            question={<FormattedMessage {...messages.dass6} />}
            callback={resp => this.enableScreen('screen_anxiety', resp.anxiety)}
          />
        );
      case 'dass3':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={dassOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description={<FormattedMessage {...messages.dassHowOften} />}
            question={<FormattedMessage {...messages.dass3} />}
          />
        );
      case 'dass7':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={dassOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description={<FormattedMessage {...messages.dassHowOften} />}
            question={<FormattedMessage {...messages.dass7} />}
          />
        );
      case 'dass4':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={dassOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description={<FormattedMessage {...messages.dassHowOften} />}
            question={<FormattedMessage {...messages.dass4} />}
            callback={resp =>
              this.enableScreen('screen_substance', resp.substance)
            }
          />
        );
      case 'gen':
        return (
          <MultiSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={genOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            question={<FormattedMessage {...messages.selectAny} />}
            description={<FormattedMessage {...messages.feelFreeSkip} />}
          />
        );
      case 'phys':
        return (
          <MultiSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={physOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            question={<FormattedMessage {...messages.selectAny} />}
            description={<FormattedMessage {...messages.feelFreeSkip} />}
          />
        );
      case 'dx':
        return (
          <MultiSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={dxOptions}
            small
            title={<FormattedMessage {...messages.dxTitle} />}
            question={<FormattedMessage {...messages.dxQuestion} />}
            description={<FormattedMessage {...messages.feelFreeSkip} />}
          />
        );
      case 'stress':
        return (
          <MultiSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={stressOptions}
            small
            title={<FormattedMessage {...messages.stressTitle} />}
            question={<FormattedMessage {...messages.stressQuestion} />}
            description={<FormattedMessage {...messages.feelFreeSkip} />}
          />
        );
      case 'ws':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={wsOptions}
            title={<FormattedMessage {...messages.wsTitle} />}
            description=""
            question={<FormattedMessage {...messages.wsQuestion} />}
            callback={(resp, type) => {
              this.setWsType(type);
            }}
          />
        );
      case 'work':
        return (
          <MultiSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={workOptions}
            small
            title={<FormattedMessage {...messages.workTitle} />}
            question={<FormattedMessage {...messages.workQuestion} />}
            description={<FormattedMessage {...messages.feelFreeSkip} />}
          />
        );
      case 'wsize':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={wsizeOptions}
            title={<FormattedMessage {...messages.wsizeTitle} />}
            description=""
            question={<FormattedMessage {...messages.wsizeQuestion} />}
          />
        );
      case 'school':
        return (
          <MultiSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={schoolOptions}
            small
            title={<FormattedMessage {...messages.schoolTitle} />}
            question={<FormattedMessage {...messages.schoolQuestion} />}
            description={<FormattedMessage {...messages.feelFreeSkip} />}
          />
        );
      case 'school_level':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={schoolLevelOptions}
            title={<FormattedMessage {...messages.schoolLevelTitle} />}
            description=""
            question={<FormattedMessage {...messages.schoolLevelQuestion} />}
          />
        );
      case 'cross-p1-s1':
        return (
          <MultiSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={crossP1S1Options}
            small
            title={<FormattedMessage {...messages.evaluation} />}
            question={<FormattedMessage {...messages.crossQuestion} />}
            description={<FormattedMessage {...messages.crossDescription} />}
            callback={resp => {
              this.enableScreen(
                'screen_cross_part2',
                this.pathValues.screen_cross_part1 === 1 &&
                  !resp.depression &&
                  !resp.bipolar &&
                  !resp.anxiety &&
                  !resp.substance &&
                  !resp.ptsd,
              );
              this.enableScreen('screen_depression', resp.depression);
              this.enableScreen('screen_anxiety', resp.anxiety);
              this.enableScreen('screen_bipolar', resp.bipolar);
              this.enableScreen('screen_substance', resp.substance);
              this.enableScreen('screen_ptsd', resp.ptsd);
            }}
          />
        );
      case 'cross-p1-s2':
        return (
          <MultiSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={crossP1S2Options}
            small
            title={<FormattedMessage {...messages.evaluation} />}
            question={<FormattedMessage {...messages.crossQuestion} />}
            description={<FormattedMessage {...messages.crossDescription} />}
            callback={resp => {
              this.enableScreen(
                'screen_cross_part2',
                this.pathValues.screen_cross_part1 === 1 &&
                  !resp.depression &&
                  !resp.bipolar &&
                  !resp.anxiety &&
                  !resp.substance &&
                  !resp.ptsd,
              );
              this.enableScreen('screen_depression', resp.depression);
              this.enableScreen('screen_anxiety', resp.anxiety);
              this.enableScreen('screen_bipolar', resp.bipolar);
              this.enableScreen('screen_substance', resp.substance);
              this.enableScreen('screen_ptsd', resp.ptsd);
            }}
          />
        );
      case 'cross-p1-s3':
        return (
          <MultiSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={crossP1S3Options}
            small
            title={<FormattedMessage {...messages.evaluation} />}
            question={<FormattedMessage {...messages.crossQuestion} />}
            description={<FormattedMessage {...messages.crossDescription} />}
            callback={resp => {
              this.enableScreen(
                'screen_cross_part2',
                this.pathValues.screen_cross_part1 === 1 &&
                  !resp.depression &&
                  !resp.bipolar &&
                  !resp.anxiety &&
                  !resp.substance &&
                  !resp.ptsd,
              );
              this.enableScreen('screen_depression', resp.depression);
              this.enableScreen('screen_anxiety', resp.anxiety);
              this.enableScreen('screen_bipolar', resp.bipolar);
              this.enableScreen('screen_substance', resp.substance);
              this.enableScreen('screen_ptsd', resp.ptsd);
            }}
          />
        );
      case 'cross-p2-s1':
        return (
          <MultiSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={crossP2S1Options}
            small
            title={<FormattedMessage {...messages.evaluation} />}
            question={<FormattedMessage {...messages.crossQuestion} />}
            description={<FormattedMessage {...messages.crossDescription} />}
            enablePrevious={false}
            callback={resp => {
              this.enableScreen('closing', true);
              this.enableScreen('screen_ocd', resp.ocd);
              this.enableScreen('screen_phobia', resp.phobia);
              this.enableScreen('screen_dissociation', resp.dissociation);
              this.enableScreen('screen_personality', resp.personality);
              this.enableScreen('screen_somatic', resp.somatic);
              this.enableScreen('screen_social', resp.social_anx);
              this.enableScreen('screen_genanx', resp.general_anx);
              this.enableScreen('screen_agoraphobia', resp.agoraphobia);
              this.enableScreen('screen_separation', resp.separation_anx);
            }}
          />
        );
      case 'cross-p2-s2':
        return (
          <MultiSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={crossP2S2Options}
            small
            title={<FormattedMessage {...messages.evaluation} />}
            question={<FormattedMessage {...messages.crossQuestion} />}
            description={<FormattedMessage {...messages.crossDescription} />}
            callback={resp => {
              this.enableScreen('closing', true);
              this.enableScreen('screen_ocd', resp.ocd);
              this.enableScreen('screen_phobia', resp.phobia);
              this.enableScreen('screen_dissociation', resp.dissociation);
              this.enableScreen('screen_personality', resp.personality);
              this.enableScreen('screen_somatic', resp.somatic);
              this.enableScreen('screen_social', resp.social_anx);
              this.enableScreen('screen_genanx', resp.general_anx);
              this.enableScreen('screen_agoraphobia', resp.agoraphobia);
              this.enableScreen('screen_separation', resp.separation_anx);
            }}
          />
        );
      case 'cross-p2-s3':
        return (
          <MultiSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={crossP2S3Options}
            small
            title={<FormattedMessage {...messages.evaluation} />}
            question={<FormattedMessage {...messages.crossQuestion} />}
            description={<FormattedMessage {...messages.crossDescription} />}
            callback={resp => {
              this.enableScreen('closing', true);
              this.enableScreen('screen_ocd', resp.ocd);
              this.enableScreen('screen_phobia', resp.phobia);
              this.enableScreen('screen_dissociation', resp.dissociation);
              this.enableScreen('screen_personality', resp.personality);
              this.enableScreen('screen_somatic', resp.somatic);
              this.enableScreen('screen_social', resp.social_anx);
              this.enableScreen('screen_genanx', resp.general_anx);
              this.enableScreen('screen_agoraphobia', resp.agoraphobia);
              this.enableScreen('screen_separation', resp.separation_anx);
            }}
          />
        );
      case 'depression-worthless':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAlwaysOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.depressionWorthless} />}
            callback={() => this.enableScreen('screen_interim_report', true)}
          />
        );
      case 'depression-nothing':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAlwaysOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.depressionNothing} />}
            callback={() => this.enableScreen('screen_interim_report', true)}
          />
        );
      case 'depression-helpless':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAlwaysOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.depressionHelpless} />}
            callback={() => this.enableScreen('screen_interim_report', true)}
          />
        );
      case 'depression-sad':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAlwaysOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.depressionSad} />}
            callback={() => this.enableScreen('screen_interim_report', true)}
          />
        );
      case 'depression-depressed':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAlwaysOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.depressionDepressed} />}
            callback={() => this.enableScreen('screen_interim_report', true)}
          />
        );
      case 'depression-unhappy':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAlwaysOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.depressionUnhappy} />}
            callback={() => this.enableScreen('screen_interim_report', true)}
          />
        );
      case 'depression-hopeless':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAlwaysOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.depressionHopeless} />}
            callback={() => this.enableScreen('screen_interim_report', true)}
          />
        );
      case 'anxiety-fearful':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAlwaysOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.anxietyFearful} />}
            callback={() => this.enableScreen('screen_interim_report', true)}
          />
        );
      case 'anxiety-anxious':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAlwaysOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.anxietyAnxious} />}
          />
        );
      case 'anxiety-worried':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAlwaysOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.anxietyWorried} />}
            callback={() => this.enableScreen('screen_interim_report', true)}
          />
        );
      case 'anxiety-focus':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAlwaysOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.anxietyFocus} />}
            callback={() => this.enableScreen('screen_interim_report', true)}
          />
        );
      case 'anxiety-nervous':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAlwaysOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.anxietyNervous} />}
            callback={() => this.enableScreen('screen_interim_report', true)}
          />
        );
      case 'anxiety-uneasy':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAlwaysOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.anxietyUneasy} />}
            callback={() => this.enableScreen('screen_interim_report', true)}
          />
        );
      case 'anxiety-tense':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAlwaysOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.anxietyTense} />}
            callback={() => this.enableScreen('screen_interim_report', true)}
          />
        );
      case 'bipolar-happier':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={rarelyAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.bipolarHappier} />}
            callback={() => this.enableScreen('screen_interim_report', true)}
          />
        );
      case 'bipolar-self':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={rarelyAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.bipolarSelf} />}
            callback={() => this.enableScreen('screen_interim_report', true)}
          />
        );
      case 'bipolar-sleep':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={rarelyAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.bipolarSleep} />}
            callback={() => this.enableScreen('screen_interim_report', true)}
          />
        );
      case 'bipolar-talk':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={rarelyAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.bipolarTalk} />}
            callback={() => this.enableScreen('screen_interim_report', true)}
          />
        );
      case 'bipolar-active':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={rarelyAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.bipolarActive} />}
            callback={() => this.enableScreen('screen_interim_report', true)}
          />
        );
      case 'severity-substance1':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={notNearlyOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.severitySubstance1} />}
            callback={() => this.enableScreen('screen_interim_report', true)}
          />
        );
      case 'severity-substance2':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={notNearlyOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.severitySubstance2} />}
            callback={() => this.enableScreen('screen_interim_report', true)}
          />
        );
      case 'severity-substance3':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={notNearlyOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.severitySubstance3} />}
            callback={() => this.enableScreen('screen_interim_report', true)}
          />
        );
      case 'severity-substance4':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={notNearlyOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.severitySubstance4} />}
            callback={() => this.enableScreen('screen_interim_report', true)}
          />
        );
      case 'severity-substance5':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={notNearlyOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.severitySubstance5} />}
            callback={() => this.enableScreen('screen_interim_report', true)}
          />
        );
      case 'severity-substance6':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={notNearlyOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.severitySubstance6} />}
            callback={() => this.enableScreen('screen_interim_report', true)}
          />
        );
      case 'severity-substance7':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={notNearlyOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.severitySubstance7} />}
            callback={() => this.enableScreen('screen_interim_report', true)}
          />
        );
      case 'severity-substance8':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={notNearlyOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.severitySubstance8} />}
            callback={() => this.enableScreen('screen_interim_report', true)}
          />
        );
      case 'severity-substance9':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={notNearlyOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.severitySubstance9} />}
            callback={() => this.enableScreen('screen_interim_report', true)}
          />
        );
      case 'severity-substance10':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={notNearlyOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.severitySubstance10} />}
            callback={() => this.enableScreen('screen_interim_report', true)}
          />
        );
      case 'ptsd-flashbacks':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={notExtremelyOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.ptsdFlashbacks} />}
            callback={() => this.enableScreen('screen_interim_report', true)}
          />
        );
      case 'ptsd-upset':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={notExtremelyOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.ptsdUpset} />}
            callback={() => this.enableScreen('screen_interim_report', true)}
          />
        );
      case 'ptsd-avoid':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={notExtremelyOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.ptsdAvoid} />}
            callback={() => this.enableScreen('screen_interim_report', true)}
          />
        );
      case 'ptsd-event':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={notExtremelyOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.ptsdEvent} />}
            callback={() => this.enableScreen('screen_interim_report', true)}
          />
        );
      case 'ptsd-negative':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={notExtremelyOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.ptsdNegative} />}
            callback={() => this.enableScreen('screen_interim_report', true)}
          />
        );
      case 'ptsd-interest':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={notExtremelyOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.ptsdInterest} />}
            callback={() => this.enableScreen('screen_interim_report', true)}
          />
        );
      case 'ptsd-alert':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={notExtremelyOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.ptsdAlert} />}
            callback={() => this.enableScreen('screen_interim_report', true)}
          />
        );
      case 'ptsd-noise':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={notExtremelyOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.ptsdNoise} />}
            callback={() => this.enableScreen('screen_interim_report', true)}
          />
        );
      case 'ptsd-angry':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={notExtremelyOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.ptsdAngry} />}
            callback={() => this.enableScreen('screen_interim_report', true)}
          />
        );
      case 'ocd-time':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={ocdTimeOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.ocdTime} />}
          />
        );
      case 'ocd-distress':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={ocdDistressOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.ocdDistress} />}
          />
        );
      case 'ocd-control':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={ocdControlOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.ocdControl} />}
          />
        );
      case 'ocd-avoid':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={ocdAvoidOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.ocdAvoid} />}
          />
        );
      case 'ocd-interfere':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={ocdInterfereOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.ocdInterfere} />}
          />
        );
      case 'phobia-item':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={phobiaItemOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.phobiaItem} />}
            callback={(resp, value) => {
              if (value === 0) {
                // none
                this.enableScreen('screen_phobia_details', false);
              } else {
                this.setState({
                  phobiaTopic: phobiaItemOptions.reduce(
                    (acc, cur) => (cur.value === value ? cur.label : acc),
                    '',
                  ),
                });
                this.enableScreen('screen_phobia_details', true);
              }
            }}
          />
        );
      case 'phobia-moment':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={
              <FormattedMessage
                {...messages.phobiaMoment}
                values={{ item: this.state.phobiaTopic }}
              />
            }
          />
        );
      case 'phobia-anxious':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={
              <FormattedMessage
                {...messages.phobiaAnxious}
                values={{ item: this.state.phobiaTopic }}
              />
            }
          />
        );
      case 'phobia-injured':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={
              <FormattedMessage
                {...messages.phobiaInjured}
                values={{ item: this.state.phobiaTopic }}
              />
            }
          />
        );
      case 'phobia-sweaty':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={
              <FormattedMessage
                {...messages.phobiaSweaty}
                values={{ item: this.state.phobiaTopic }}
              />
            }
          />
        );
      case 'phobia-tense':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={
              <FormattedMessage
                {...messages.phobiaTense}
                values={{ item: this.state.phobiaTopic }}
              />
            }
          />
        );
      case 'phobia-avoid':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={
              <FormattedMessage
                {...messages.phobiaAvoid}
                values={{ item: this.state.phobiaTopic }}
              />
            }
          />
        );
      case 'phobia-away':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={
              <FormattedMessage
                {...messages.phobiaAway}
                values={{ item: this.state.phobiaTopic }}
              />
            }
          />
        );
      case 'phobia-prepare':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={
              <FormattedMessage
                {...messages.phobiaPrepare}
                values={{ item: this.state.phobiaTopic }}
              />
            }
          />
        );
      case 'phobia-distract':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={
              <FormattedMessage
                {...messages.phobiaDistract}
                values={{ item: this.state.phobiaTopic }}
              />
            }
          />
        );
      case 'phobia-help':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={
              <FormattedMessage
                {...messages.phobiaHelp}
                values={{ item: this.state.phobiaTopic }}
              />
            }
          />
        );
      case 'dissociation-stare':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={notMoreOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.dissociationStare} />}
          />
        );
      case 'dissociation-strange':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={notMoreOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.dissociationStrange} />}
          />
        );
      case 'dissociation-remember':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={notMoreOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.dissociationRemember} />}
          />
        );
      case 'dissociation-alone':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={notMoreOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.dissociationAlone} />}
          />
        );
      case 'dissociation-fog':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={notMoreOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.dissociationFog} />}
          />
        );
      case 'dissociation-pain':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={notMoreOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.dissociationPain} />}
          />
        );
      case 'dissociation-act':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={notMoreOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.dissociationAct} />}
          />
        );
      case 'dissociation-do':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={notMoreOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.dissociationDo} />}
          />
        );
      case 'personality-disinhibition1':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={veryVeryOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={
              <FormattedMessage {...messages.personalityDisinhibition1} />
            }
          />
        );
      case 'personality-disinhibition2':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={veryVeryOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={
              <FormattedMessage {...messages.personalityDisinhibition2} />
            }
          />
        );
      case 'personality-disinhibition3':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={veryVeryOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={
              <FormattedMessage {...messages.personalityDisinhibition3} />
            }
          />
        );
      case 'personality-detachment1':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={veryVeryOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.personalityDetachment1} />}
          />
        );
      case 'personality-disinhibition4':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={veryVeryOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={
              <FormattedMessage {...messages.personalityDisinhibition4} />
            }
          />
        );
      case 'personality-disinhibition5':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={veryVeryOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={
              <FormattedMessage {...messages.personalityDisinhibition5} />
            }
          />
        );
      case 'personality-psychoticism1':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={veryVeryOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={
              <FormattedMessage {...messages.personalityPsychoticism1} />
            }
          />
        );
      case 'personality-negative1':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={veryVeryOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.personalityNegative1} />}
          />
        );
      case 'personality-negative2':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={veryVeryOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.personalityNegative2} />}
          />
        );
      case 'personality-negative3':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={veryVeryOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.personalityNegative3} />}
          />
        );
      case 'personality-negative4':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={veryVeryOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.personalityNegative4} />}
          />
        );
      case 'personality-psychoticism2':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={veryVeryOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={
              <FormattedMessage {...messages.personalityPsychoticism2} />
            }
          />
        );
      case 'personality-detachment2':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={veryVeryOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.personalityDetachment2} />}
          />
        );
      case 'personality-detachment3':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={veryVeryOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.personalityDetachment3} />}
          />
        );
      case 'personality-negative5':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={veryVeryOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.personalityNegative5} />}
          />
        );
      case 'personality-detachment4':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={veryVeryOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.personalityDetachment4} />}
          />
        );
      case 'personality-antagonism1':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={veryVeryOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.personalityAntagonism1} />}
          />
        );
      case 'personality-detachment5':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={veryVeryOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.personalityDetachment5} />}
          />
        );
      case 'personality-antagonism2':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={veryVeryOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.personalityAntagonism2} />}
          />
        );
      case 'personality-antagonism3':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={veryVeryOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.personalityAntagonism3} />}
          />
        );
      case 'personality-psychoticism3':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={veryVeryOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={
              <FormattedMessage {...messages.personalityPsychoticism3} />
            }
          />
        );
      case 'personality-antagonism4':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={veryVeryOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.personalityAntagonism4} />}
          />
        );
      case 'personality-psychoticism4':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={veryVeryOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={
              <FormattedMessage {...messages.personalityPsychoticism4} />
            }
          />
        );
      case 'personality-psychoticism5':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={veryVeryOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={
              <FormattedMessage {...messages.personalityPsychoticism5} />
            }
          />
        );
      case 'personality-antagonism5':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={veryVeryOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.personalityAntagonism5} />}
          />
        );
      case 'somatic-stomach':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={notBotheredOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.somaticStomach} />}
          />
        );
      case 'somatic-back':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={notBotheredOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.somaticBack} />}
          />
        );
      case 'somatic-arm':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={notBotheredOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.somaticArm} />}
          />
        );
      case 'somatic-headache':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={notBotheredOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.somaticHeadache} />}
          />
        );
      case 'somatic-chest':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={notBotheredOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.somaticChest} />}
          />
        );
      case 'somatic-dizziness':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={notBotheredOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.somaticDizziness} />}
          />
        );
      case 'somatic-fainting':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={notBotheredOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.somaticFainting} />}
          />
        );
      case 'somatic-heart':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={notBotheredOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.somaticHeart} />}
          />
        );
      case 'somatic-breath':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={notBotheredOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.somaticBreath} />}
          />
        );
      case 'somatic-sexual':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={notBotheredOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.somaticSexual} />}
          />
        );
      case 'somatic-constipation':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={notBotheredOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.somaticConstipation} />}
          />
        );
      case 'somatic-nausea':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={notBotheredOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.somaticNausea} />}
          />
        );
      case 'somatic-tired':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={notBotheredOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.somaticTired} />}
          />
        );
      case 'somatic-sleep':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={notBotheredOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.somaticSleep} />}
          />
        );
      case 'social-fear':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.socialFear} />}
          />
        );
      case 'social-anxious':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.socialAnxious} />}
          />
        );
      case 'social-reject':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.socialReject} />}
          />
        );
      case 'social-sweaty':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.socialSweaty} />}
          />
        );
      case 'social-tense':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.socialTense} />}
          />
        );
      case 'social-avoid':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.socialAvoid} />}
          />
        );
      case 'social-leave':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.socialLeave} />}
          />
        );
      case 'social-prepare':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.socialPrepare} />}
          />
        );
      case 'social-distract':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.socialDistract} />}
          />
        );
      case 'social-help':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.socialHelp} />}
          />
        );
      case 'genanx-fear':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.genanxFear} />}
          />
        );
      case 'genanx-anxious':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.genanxAnxious} />}
          />
        );
      case 'genanx-bad':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.genanxBad} />}
          />
        );
      case 'genanx-sweaty':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.genanxSweaty} />}
          />
        );
      case 'genanx-tense':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.genanxTense} />}
          />
        );
      case 'genanx-avoid':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.genanxAvoid} />}
          />
        );
      case 'genanx-leave':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.genanxLeave} />}
          />
        );
      case 'genanx-decision':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.genanxDecision} />}
          />
        );
      case 'genanx-reassurance':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.genanxReassurance} />}
          />
        );
      case 'genanx-help':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.genanxHelp} />}
          />
        );
      case 'agoraphobia-fear':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.agoraphobiaFear} />}
          />
        );
      case 'agoraphobia-anxious':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.agoraphobiaAnxious} />}
          />
        );
      case 'agoraphobia-panic':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.agoraphobiaPanic} />}
          />
        );
      case 'agoraphobia-sweaty':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.agoraphobiaSweaty} />}
          />
        );
      case 'agoraphobia-tense':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.agoraphobiaTense} />}
          />
        );
      case 'agoraphobia-avoid':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.agoraphobiaAvoid} />}
          />
        );
      case 'agoraphobia-away':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.agoraphobiaAway} />}
          />
        );
      case 'agoraphobia-prepare':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.agoraphobiaPrepare} />}
          />
        );
      case 'agoraphobia-distract':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.agoraphobiaDistract} />}
          />
        );
      case 'agoraphobia-help':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.agoraphobiaHelp} />}
          />
        );
      case 'separation-fear':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.separationFear} />}
          />
        );
      case 'separation-anxious':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.separationAnxious} />}
          />
        );
      case 'separation-bad':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.separationBad} />}
          />
        );
      case 'separation-sweaty':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.separationSweaty} />}
          />
        );
      case 'separation-tense':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.separationTense} />}
          />
        );
      case 'separation-avoid':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.separationAvoid} />}
          />
        );
      case 'separation-home':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.separationHome} />}
          />
        );
      case 'separation-prepare':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.separationPrepare} />}
          />
        );
      case 'separation-distract':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.separationDistract} />}
          />
        );
      case 'separation-help':
        return (
          <SingleSelect
            {...naviProps}
            key={pageId}
            fieldName={pageId}
            options={neverAllOptions}
            title={<FormattedMessage {...messages.evaluation} />}
            description=""
            question={<FormattedMessage {...messages.separationHelp} />}
          />
        );
      case 'video':
        return <Video {...naviProps} uploadVideo={this.props.uploadVideo} />;
      case 'interim-report':
        return (
          <InterimReport
            onDetailClick={this.goToSpecificPage}
            showDetail
            {...naviProps}
            report={this.props.report}
            showORS={false}
            fetchReport={this.props.fetchReport}
            emailReport={this.props.emailReport}
            clearEmailReport={this.props.clearEmailReport}
          />
        );
      case 'interim-ors-report':
        return (
          <InterimReport
            showORS
            showDetail={false}
            {...naviProps}
            report={this.props.report}
            fetchReport={this.props.fetchReport}
            emailReport={this.props.emailReport}
            clearEmailReport={this.props.clearEmailReport}
          />
        );

      case 'final-report':
        return (
          <FinalReport
            {...naviProps}
            report={this.props.report}
            fetchReport={this.props.fetchReport}
            emailReport={this.props.emailReport}
            clearEmailReport={this.props.clearEmailReport}
          />
        );
      default:
    }

    // eslint-disable-next-line no-unreachable
    return <h1 style={{ color: 'red' }}>Something wrong!</h1>;
  };

  render() {
    const { intl } = this.props;
    const { currentPageId, exitDialogOpen } = this.state;

    return (
      <div>
        <Helmet>
          <title>
            {intl.formatMessage(dashboardMessages.mentalHealthAssessment)}
          </title>
          <meta name="description" content="Description of Assessment" />
        </Helmet>
        <div>
          {exitDialogOpen && (
            <ExitDialog
              closeExitDialog={this.closeExitDialog}
              exitAssessment={this.exitAssessment}
              open={exitDialogOpen}
            />
          )}
        </div>
        {this.renderPageById(currentPageId)}
      </div>
    );
  }
}

Assessment.propTypes = {
  fetchUser: PropTypes.func,
  fetchAssessment: PropTypes.func,
  clearAssessment: PropTypes.func,
  currentAssessment: PropTypes.instanceOf(Map),
  currentAssessmentLoaded: PropTypes.bool,
  intl: intlShape,
  emailReport: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  currentAssessment: makeSelectCurrentAssessment(),
  currentAssessmentLoaded: makeSelectCurrentAssessmentLoaded(),
  report: makeSelectReport(),
});

function mapDispatchToProps(dispatch) {
  return {
    fetchUser: callback => dispatch(fetchUser(callback)),
    fetchAssessment: callback => dispatch(fetchAssessment(callback)),
    uploadVideo: (params, callback) => dispatch(uploadVideo(params, callback)),
    clearAssessment: (id, callback) => dispatch(clearAssessment(id, callback)),
    fetchReport: (id, lines, report, callback) =>
      dispatch(fetchReport(id, lines, report, callback)),
    emailReport: (id, lines, callback) =>
      dispatch(emailReport(id, lines, callback)),
    clearEmailReport: () => dispatch(clearEmailReport()),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'assessment', reducer });
const withSaga = injectSaga({ key: 'assessment', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(injectIntl(Assessment));
