import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Body from 'components/Body';

const Sources = ({ sources }) => (
  <ol style={{ fontSize: '10px', lineHeight: '1.8' }}>
    {_.map(sources, (source, i) => (
      <li key={i}>
        <Body style={{ fontSize: '10px' }}>{source}</Body>
      </li>
    ))}
  </ol>
);

Sources.propTypes = {
  sources: PropTypes.array,
};

export default Sources;
