import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Elements from './Elements';
import Manage from './Manage';
import PurpleBlob from './path-3@3x.png';
import { web, tablet, phone } from '../sizes';

const CoreElementsWrapper = styled.div`
  overflow-x: hidden;
  padding-top: 100px;
  padding-left: 200px;
  padding-right: 200px;
  padding-bottom: 200px;
  display: flex;
  background-image: url(${PurpleBlob});
  background-position: bottom;
  background-size: 2000px 450px;
  @media (max-width: ${web}) {
    padding-left: 70px;
    padding-right: 70px;
  }
  @media (max-width: ${tablet}) {
    padding-left: 40px;
    padding-right: 40px;
    background-size: 900px 450px;
  }
  @media (max-width: ${phone}) {
    padding-left: 20px;
    padding-right: 0px;
    flex-direction: column-reverse;
    background-size: ${phone} 180px;
    background-size: 600px 450px;
  }
`;

const index = props => (
  <CoreElementsWrapper id="features">
    <Elements eap={props.eap}/>
    <Manage acrc={props.acrc} eap={props.eap}/>
  </CoreElementsWrapper>
);

index.propTypes = {
  acrc: PropTypes.bool.isRequired,
};

export default index;
