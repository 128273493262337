import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import styled from 'styled-components';
import { phoneSize } from 'global-styles';
import MarketingStyles from '../MarketingStyles';
import Navigation from '../Navigation';
import Footer from '../Footer';
import messages from './messages';

const Wrapper = styled.div`
  padding-top: 20px;
  padding-left: 130px;
  padding-right: 130px;
  p {
    font-size: 14px;
    padding-bottom: 20px;
  }
  p.title {
    font-weight: 700;
  }
  ul {
    padding-bottom: 20px;
  }
  @media (max-width: ${phoneSize}) {
    padding-left: 40px;
    padding-right: 40px;
  }
`;

class MarketingPage extends React.PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <MarketingStyles>
        <Navigation />
        <Wrapper>
          <FormattedHTMLMessage tagName="p" {...messages.text} />
        </Wrapper>
        <Footer />
      </MarketingStyles>
    );
  }
}

MarketingPage.propTypes = {};

export default MarketingPage;
