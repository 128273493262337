import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import ArticleBody from '../ArticleBody';
import messages from '../../../messages';
const ReturnToWorkPrep = () => (
  <div>
    <ArticleBody>
      <FormattedHTMLMessage tagName="p" {...messages.returnToWorkPrepBody} />
    </ArticleBody>
  </div>
);

ReturnToWorkPrep.propTypes = {};

export default ReturnToWorkPrep;
