/*
 *
 * SettingsHistory
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import PortalLayout from 'components/PortalLayout';
import { compose } from 'redux';
import { FormattedMessage, intlShape, injectIntl } from 'react-intl';

import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';

import { makeSelectApp } from 'containers/App/selectors';
import { phoneSize } from 'global-styles';
import saga from './saga';
import SettingsDisplay from './SettingsDisplay';
import SettingsForm from './SettingsForm';
import AssessmentHistory from './AssessmentHistory';
import messages from './messages';
import reducer from './reducer';

const Panel = styled.div`
  width: 100%;
  height: 100%;
  display: block;
  box-shadow: 0 0 14px 0 rgba(11, 118, 188, 0.18);
  background-color: #ffffff;
  padding: 40px;
  position: relative;
  margin-bottom: 40px;

  .new-button {
    cursor: pointer;
    right: 40px;
    position: absolute;
    height: 40px;
    border-radius: 20px;
    background-image: linear-gradient(to left, #9b92c6, #c1bcf2);
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1.5px;
    text-align: center;
    color: #ffffff;
    padding-left: 40px;
    padding-right: 40px;
    text-transform: uppercase;
  }
  @media (max-width: ${phoneSize}) {
    .new-button {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
`;

/* eslint-disable react/prefer-stateless-function */
export class SettingsHistory extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      formOpen: false,
    };
  }

  componentDidMount() {}

  toggleForm = () => {
    this.setState(prevState => ({
      formOpen: !prevState.formOpen,
    }));
  };

  render() {
    const { intl } = this.props;
    return (
      <div>
        <Helmet>
          <title>{intl.formatMessage(messages.settingsAndHistory)}</title>
          <meta name="dashboard" content="Settings History of people corp" />
        </Helmet>
        <PortalLayout
          title={<FormattedMessage {...messages.settingsAndHistory} />}
          activeTab="settings"
        >
          <Panel>
            {this.state.formOpen ? (
              <SettingsForm
                user={this.props.user.data}
                toggleForm={this.toggleForm}
              />
            ) : (
              <SettingsDisplay
                user={this.props.user.data}
                toggleForm={this.toggleForm}
              />
            )}
          </Panel>
          <Panel>
            <AssessmentHistory />
          </Panel>
        </PortalLayout>
      </div>
    );
  }
}

SettingsHistory.propTypes = {
  intl: intlShape,
  user: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectApp(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}
const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'settings', reducer });
const withSaga = injectSaga({ key: 'settings', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(injectIntl(SettingsHistory));
