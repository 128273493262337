import React from 'react';
import { Player } from 'video-react';
import VideoBody from './VideoBody';
import StayingResilientVideo from './StayingResilient.mp4';

const StayingResilient = () => (
  <div>
    <VideoBody>
      <Player playsInline src={StayingResilientVideo} />
    </VideoBody>
  </div>
);
export default StayingResilient;
