/*
 *
 * EmailConfirmation
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setToken } from 'utils/auth';
import { confirmEmail } from 'containers/App/actions';

/* eslint-disable react/prefer-stateless-function */
class EmailConfirmation extends React.PureComponent {
  componentDidMount() {
    const { match, history } = this.props;
    const confirmationToken = match.params.confirmation_token;

    this.props.confirmEmail(
      { confirmation_token: confirmationToken },
      tokenInfo => {
        setToken(tokenInfo.access_token);
        history.push('/dashboard');
      },
    );
  }

  render() {
    return <div />;
  }
}

EmailConfirmation.propTypes = {
  confirmEmail: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    confirmEmail: (values, callback) =>
      dispatch(confirmEmail(values, callback)),
  };
}

export default connect(
  null,
  mapDispatchToProps,
)(EmailConfirmation);
