/*
 *
 * MarketingPage reducer
 *
 */

import { fromJS } from 'immutable';
import { SIGN_IN, SIGN_IN_ERROR, SIGN_IN_SUCCESS } from './constants';

export const initialState = fromJS({
  authenticated: false,
  error: {
    emailError: '',
    loginError: '',
  },
});

function signupReducer(state = initialState, action) {
  switch (action.type) {
    case SIGN_IN:
      return state.set('loading', true);
    case SIGN_IN_ERROR:
      return state
        .set('loading', false)
        .setIn(['error', 'loginError'], action.error.data.error);
    case SIGN_IN_SUCCESS:
      return state
        .set('loading', false)
        .set('authenticated', true)
        .setIn(['error', 'loginError'], '');
    default:
      return state;
  }
}

export default signupReducer;
