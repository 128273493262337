import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

class Authenticated extends PureComponent {
  componentDidMount() {
    if (localStorage.getItem('token')) {
      this.props.history.push('/dashboard');
    }
  }

  render() {
    return <div>{this.props.children}</div>;
  }
}

Authenticated.propTypes = {
  children: PropTypes.node,
  history: PropTypes.object,
};

export default withRouter(Authenticated);
