/*
 *
 * EmployeeResources actions
 *
 */

import {
  DEFAULT_ACTION,
  TRACK_USER_CLICK,
  TRACK_USER_CLICK_SUCCESS,
  TRACK_USER_CLICK_ERROR,
} from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function trackUserClick(article_name, callback) {
  return {
    type: TRACK_USER_CLICK,
    article_name,
    callback,
  };
}

export function trackUserClickSuccess(article_name, callback) {
  return {
    type: TRACK_USER_CLICK_SUCCESS,
    article_name,
    callback,
  };
}

export function trackUserClickError(error) {
  return {
    type: TRACK_USER_CLICK_ERROR,
    error,
  };
}
