/**
 *
 * MultiSelect
 *
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field } from 'redux-form/immutable';

import P from 'components/P';
import H4 from 'components/H4';
import { deepSeaBlue, errorRed } from '../../global-styles';

const StyledContainer = styled.div`
  max-width: ${props => (props.narrow ? '400px' : '600px')};
`;

const StyledP = styled(P)`
  margin: -10px 0 0 0;
`;

const StyledGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  .ms-checkbox {
    width: 180px;
    height: 120px;
    box-shadow: 0 0 10px 0 rgba(99, 140, 177, 0.1);
    border: solid 1px rgba(46, 95, 202, 0.1);
    background-color: #ffffff;

    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    padding: 10px 24px;
    cursor: pointer;

    h4 {
      text-align: center;
    }
  }

  .ms-checkbox-small {
    width: unset;
    height: unset;
    min-width: 80px;
    padding: 10px 20px;
  }

  .ms-checkbox-checked {
    box-shadow: 0 0 10px 0 rgba(99, 140, 177, 0.2);
    border: solid 2px ${deepSeaBlue};
    h4 {
      font-weight: 600;
      color: ${deepSeaBlue};
    }
  }

  .ms-checkbox-small.ms-checkbox-checked {
    padding: 9px 19px;
    h4 {
      font-weight: 400;
      text-shadow: 1px 0 0 ${deepSeaBlue};
    }
  }
`;

// eslint-disable-next-line react/prop-types
const CheckBox = ({ input, meta, label, small }) => {
  const { value, onChange } = input;
  const { touched, error } = meta;

  return (
    <div>
      <div
        className={`ms-checkbox ${small ? 'ms-checkbox-small' : ''} ${
          value ? 'ms-checkbox-checked' : ''
        }`}
        onClick={() => onChange(!value)}
        role="presentation"
      >
        <div className="msc-label">
          <H4>{label}</H4>
        </div>
      </div>
      {touched &&
        error && <StyledP color={errorRed}>{touched ? error : ''}</StyledP>}
    </div>
  );
};

class MultiSelect extends React.PureComponent {
  render() {
    const { options, small, narrow } = this.props;

    return (
      <StyledContainer narrow={narrow}>
        <StyledGroup>
          {options.map(option => (
            <Field
              component={CheckBox}
              name={option.fieldName}
              label={option.label}
              key={`key-${option.fieldName}`}
              small={small}
            />
          ))}
        </StyledGroup>
      </StyledContainer>
    );
  }
}

MultiSelect.propTypes = {
  options: PropTypes.array.isRequired,
  small: PropTypes.bool,
  narrow: PropTypes.bool,
};

MultiSelect.defaultProps = {
  small: false,
  narrow: false,
};
export default MultiSelect;
