/*
 *
 * EmployeeResources constants
 *
 */

export const DEFAULT_ACTION = 'app/EmployeeResources/DEFAULT_ACTION';
export const TRACK_USER_CLICK = 'app/EmployeeResources/TRACK_USER_CLICK';
export const TRACK_USER_CLICK_SUCCESS =
  'app/EmployeeResources/TRACK_USER_CLICK_SUCCESS';
export const TRACK_USER_CLICK_ERROR =
  'app/EmployeeResources/TRACK_USER_CLICK_ERROR';
