import React from 'react';
import { Player } from 'video-react';
import VideoBody from './VideoBody';
import MentalIllnessAndWorkVideo from './MentalIllnessAndWork.mp4';

const MentalIllnessAndWork = () => (
  <div>
    <VideoBody>
      <Player playsInline src={MentalIllnessAndWorkVideo} />
    </VideoBody>
  </div>
);
export default MentalIllnessAndWork;
