/**
 *
 * Button.js
 *
 * A common button, if you pass it a prop "route" it'll render a link to a react-router route
 * otherwise it'll render a link with an onclick
 */

import React, { Children } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import A from './A';
import StyledButton from './StyledButton';
import Wrapper from './Wrapper';

function Button(props) {
  // Render an anchor tag
  let button = (
    <A href={props.href} onClick={props.onClick}>
      {Children.toArray(props.children)}
    </A>
  );

  if (props.isButton) {
    button = (
      <StyledButton onClick={props.onClick}>
        {Children.toArray(props.children)}
      </StyledButton>
    );
  }
  // If the Button has a handleRoute prop, we want to render a button
  if (props.handleRoute) {
    button = (
      <Link to={props.handleRoute}>
        <StyledButton>{Children.toArray(props.children)}</StyledButton>
      </Link>
    );
  }

  if (props.externalRoute) {
    button = (
      <a href={props.externalRoute} target="_blank">
        <StyledButton>{Children.toArray(props.children)}</StyledButton>
      </a>
    );
  }

  return <Wrapper>{button}</Wrapper>;
}

Button.propTypes = {
  handleRoute: PropTypes.string,
  externalRoute: PropTypes.func,
  href: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  isButton: PropTypes.bool,
};

export default Button;
