/*
 *
 * TrainingResources
 *
 */

import React from 'react';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import PortalLayout from 'components/PortalLayout';
import styled from 'styled-components';
import messages from './messages';

const ArticleBodyWrapper = styled.div`
  padding-bottom: 60px;
  .article-body {
    font-family: 'Lato';
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #444e69;
    a {
      color: #005790;
    }
  }
`;

/* eslint-disable react/prefer-stateless-function */
export class TrainingResources extends React.PureComponent {
  render() {
    const { intl } = this.props;
    return (
      <div>
        <Helmet>
          <title>{intl.formatMessage(messages.trainingAndConsulting)}</title>
          <meta
            name="training and consulting"
            content="Training and consulting for managers"
          />
        </Helmet>
        <PortalLayout
          title={<FormattedMessage {...messages.trainingAndConsulting} />}
          activeTab="training"
        >
          <ArticleBodyWrapper>
            <div className="article-body">
              <FormattedMessage {...messages.importantAsset} />
              <br />
              <br />
              <b>
                <FormattedMessage {...messages.mentalHealthEmployees} />
              </b>
              <br />
              <br />
              <FormattedMessage 
                {...messages.mentalHealthEmployeesBlurb}
                values={{
                  lineBreak: <br/>,
                  bullet: '•  '
                }}
              />
              <br />
              <br />
              <b>
                <FormattedMessage {...messages.mentalHealthManagers} />
              </b>
              <br />
              <br />
              <FormattedMessage
                {...messages.mentalHealthManagersBlurb}
                values={{ lineBreak: <br /> }}
              />
              <br />
              <br />
              <b>
                <FormattedMessage {...messages.strategicConsulting} />
              </b>
              <br />
              <br />
              <FormattedMessage {...messages.strategicConsultingBlurb} />
              <br />
              <br />
              <b>
                <FormattedMessage
                  {...messages.executiveSponsorshipDiscussions}
                />
              </b>
              <br />
              <br />
              <FormattedMessage
                {...messages.executiveSponsorshipDiscussionsBlurb}
              />
              <br />
              <br />
              <b>
                <FormattedMessage {...messages.peerProgramDevelopment} />
              </b>
              <br />
              <br />
              <FormattedMessage {...messages.peerProgramDevelopmentBlurb} />
              <br />
              <br />
              <b>
                <FormattedMessage {...messages.compliance} />
              </b>
              <br />
              <br />
              <FormattedMessage {...messages.complianceBlurb} />
              <br />
              <br />
              <br />
              <b>
                <FormattedMessage {...messages.contact} />{' '}
                <a href="mailto:peopleconnect@peoplecorporation.com">
                  peopleconnect@peoplecorporation.com
                </a>
              </b>
            </div>
          </ArticleBodyWrapper>
        </PortalLayout>
      </div>
    );
  }
}

TrainingResources.propTypes = {
  intl: intlShape,
};

export default injectIntl(TrainingResources);
