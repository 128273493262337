/**
 *
 * Ors
 *
 */

import React from 'react';
import { Slider } from 'rsuite';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FormattedMessage } from 'react-intl';
import messages from './messages';

const StyledOrsSlider = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  @media screen and (max-width: 330px) {
    width: 240px;
  }
`;

const LegendContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: -25px;
`;

const StyledLegend = styled.div`
  width: 16.5%;
  height: 71px;

  .osl-label {
    height: 30px;
    margin-top: 15px;
    p {
      text-align: center;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 14px;
      font-weight: 600;
      color: rgba(15, 32, 69, 0.6);
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.1px;
      margin: 0;
      padding: 0 0.5px;
    }
  }
`;

const StyledSlider = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
/* eslint-disable react/prefer-stateless-function */
class Ors extends React.PureComponent {
  renderSlider() {
    const {
      input: { value, onChange },
    } = this.props;

    return (
      <StyledSlider>
        <React.Fragment>
          <Slider
            min={0}
            max={10}
            graduated
            style={{ width: 294 }}
            className="ors-custom-slider"
            onChange={value => {
              onChange(value);
            }}
            value={value}
            renderMark={mark => mark}
          />
        </React.Fragment>
      </StyledSlider>
    );
  }

  render() {
    const {
      leftLegendLabel,
      rightLegendLabel,
    } = this.props;
    return (
      <React.Fragment>
        <StyledOrsSlider>{this.renderSlider()}</StyledOrsSlider>
        <LegendContainer>
          <StyledLegend>
            <div className="osl-label">
              <p>
                {leftLegendLabel || (
                  <FormattedMessage {...messages.veryPoorly} />
                )}
              </p>
            </div>
          </StyledLegend>
          <StyledLegend>
            <div className="osl-label">
              <p>
                {rightLegendLabel || (
                  <FormattedMessage {...messages.veryWell} />
                )}
              </p>
            </div>
          </StyledLegend>
        </LegendContainer>
      </React.Fragment>
    );
  }
}

Ors.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  leftLegendLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  rightLegendLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default Ors;
