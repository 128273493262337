/*
 * Assessment Messages
 *
 * This contains all the text for the Assessment container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Assessment';

export default defineMessages({
  evaluation: {
    id: `${scope}.evaluation`,
    defaultMessage: 'Evaluation',
  },
  next: {
    id: `${scope}.next`,
    defaultMessage: 'Next',
  },
  previous: {
    id: `${scope}.previous`,
    defaultMessage: 'Previous',
  },
  selectAny: {
    id: `${scope}.selectAny`,
    defaultMessage: 'Select any statements that apply to you:',
  },
  feelFreeSkip: {
    id: `${scope}.feelFreeSkip`,
    defaultMessage:
      'Feel free to skip this section if none apply. You can choose multiple options.',
  },
  introPeopleCare: {
    id: `${scope}.introPeopleCare`,
    defaultMessage: 'People Connect Assess',
  },
  introWelcome: {
    id: `${scope}.introWelcome`,
    defaultMessage:
      'Welcome! Mental health check ups are just as important as physical health check ups. This tool helps you understand your symptoms and identify mood and behaviour patterns.',
    tagName: 'p',
  },
  introThisTool: {
    id: `${scope}.introThisTool`,
    defaultMessage:
      'This tool helps to assess to see if you are having any mental health problems and suggests ways to feel better. No automated tool can currently replace the opinion of a medical professional.',
  },
  introYourResponses: {
    id: `${scope}.introYourResponses`,
    defaultMessage:
      'Your responses will be held confidential and secure. Please make sure you have read and agreed with our privacy agreement.',
  },
  introAutoSave: {
    id: `${scope}.introAutoSave`,
    defaultMessage:
      'Your responses are automatically saved. You can return at any time to finish the assessment.',
  },
  introEmergency: {
    id: `${scope}.introEmergency`,
    defaultMessage:
      'If you are not feeling safe, or may harm others, please call 911 or seek out emergency services.',
  },
  introGetStarted: {
    id: `${scope}.introGetStarted`,
    defaultMessage: 'Get started',
  },
  introReturnToDashboard: {
    id: `${scope}.introReturnToDashboard`,
    defaultMessage: 'Return to dashboard',
  },
  areYouSure: {
    id: `${scope}.areYouSure`,
    defaultMessage: 'Are you sure you want to exit?',
  },
  youCanReturn: {
    id: `${scope}.youCanReturn`,
    defaultMessage: 'You can return to the assessment at any time.',
  },
  continueAssessment: {
    id: `${scope}.continueAssessment`,
    defaultMessage: 'NO, CONTINUE ASSESSMENT',
  },
  exit: {
    id: `${scope}.exit`,
    defaultMessage: 'Exit',
  },
  orsQuestion: {
    id: `${scope}.orsQuestion`,
    defaultMessage: 'In the past two weeks, how have you been doing:',
  },
  orsIndividually: {
    id: `${scope}.orsIndividually`,
    defaultMessage: 'Individually',
  },
  orsPersonal: {
    id: `${scope}.orsPersonal`,
    defaultMessage: 'Personal well-being',
  },
  orsInterpersonal: {
    id: `${scope}.orsInterpersonal`,
    defaultMessage: 'Interpersonal',
  },
  orsFamily: {
    id: `${scope}.orsFamily`,
    defaultMessage: 'Family, close relationships',
  },
  orsSocially: {
    id: `${scope}.orsSocially`,
    defaultMessage: 'Socially',
  },
  orsWork: {
    id: `${scope}.orsWork`,
    defaultMessage: 'Work, school',
  },
  orsOverall: {
    id: `${scope}.orsOverall`,
    defaultMessage: 'Overall',
  },
  dassHowOften: {
    id: `${scope}.dassHowOften`,
    defaultMessage: 'In the last two weeks how often did you:',
  },
  dassNone: {
    id: `${scope}.dassNone`,
    defaultMessage: 'None',
  },
  dassSome: {
    id: `${scope}.dassSome`,
    defaultMessage: 'Some',
  },
  dassGoodPart: {
    id: `${scope}.dassGoodPart`,
    defaultMessage: 'Good part',
  },
  dassMost: {
    id: `${scope}.dassMost`,
    defaultMessage: 'Most',
  },
  dass1: {
    id: `${scope}.dass1`,
    defaultMessage: 'Feel you had nothing to look forward to?',
  },
  dass2: {
    id: `${scope}.dass2`,
    defaultMessage: 'Feel scared without any good reason?',
  },
  dass3: {
    id: `${scope}.dass3`,
    defaultMessage: 'Find it hard to wind down?',
  },
  dass4: {
    id: `${scope}.dass4`,
    defaultMessage: 'Feel guilty about substance use (alcohol, drugs, etc)?',
  },
  dass5: {
    id: `${scope}.dass5`,
    defaultMessage: 'Feel that life was meaningless?',
  },
  dass6: {
    id: `${scope}.dass6`,
    defaultMessage: 'Feel you were close to panic?',
  },
  dass7: {
    id: `${scope}.dass7`,
    defaultMessage:
      'Feel intolerant of anything that kept you from what you were doing?',
  },
  gen1: {
    id: `${scope}.gen1`,
    defaultMessage: 'I am feeling stuck',
  },
  gen2: {
    id: `${scope}.gen2`,
    defaultMessage: 'I am feeling stuck',
  },
  gen3: {
    id: `${scope}.gen3`,
    defaultMessage: 'I am feeling stuck',
  },
  gen4: {
    id: `${scope}.gen4`,
    defaultMessage: 'I am feeling stuck',
  },
  gen5: {
    id: `${scope}.gen5`,
    defaultMessage: 'I am feeling stuck',
  },
  phys1: {
    id: `${scope}.phys1`,
    defaultMessage: 'I am sleeping poorly',
  },
  phys2: {
    id: `${scope}.phys2`,
    defaultMessage: 'I have longstanding physical pain',
  },
  phys3: {
    id: `${scope}.phys3`,
    defaultMessage: 'I have difficulty remembering things',
  },
  phys4: {
    id: `${scope}.phys4`,
    defaultMessage: 'I am overweight or underweight',
  },
  phys5: {
    id: `${scope}.phys5`,
    defaultMessage: 'I have a prescribed psychiatric medication',
  },
  dxTitle: {
    id: `${scope}.dxTitle`,
    defaultMessage: 'Specific Diagnosis',
  },
  dxQuestion: {
    id: `${scope}.dxQuestion`,
    defaultMessage:
      'Have you been formally diagnosed by a healthcare provider with any of the following?',
  },
  dx1: {
    id: `${scope}.dx1`,
    defaultMessage: 'Depression',
  },
  dx2: {
    id: `${scope}.dx2`,
    defaultMessage: 'Anxiety',
  },
  dx3: {
    id: `${scope}.dx3`,
    defaultMessage: 'Substance use disorder',
  },
  dx4: {
    id: `${scope}.dx4`,
    defaultMessage: 'Postpartum depression',
  },
  dx5: {
    id: `${scope}.dx5`,
    defaultMessage: 'Eating disorder',
  },
  dx6: {
    id: `${scope}.dx6`,
    defaultMessage: 'ADHD',
  },
  dx7: {
    id: `${scope}.dx7`,
    defaultMessage: 'Obsessive Compulsive Disorder',
  },
  dx8: {
    id: `${scope}.dx8`,
    defaultMessage: 'Bipolar Disorder',
  },
  dx9: {
    id: `${scope}.dx9`,
    defaultMessage: 'Borderline Personality Disorder',
  },
  dx10: {
    id: `${scope}.dx10`,
    defaultMessage: 'Post Traumatic Stress Disorder',
  },
  dx11: {
    id: `${scope}.dx11`,
    defaultMessage: 'Learning disability',
  },
  dx12: {
    id: `${scope}.dx12`,
    defaultMessage: 'Autism',
  },
  dx13: {
    id: `${scope}.dx13`,
    defaultMessage: 'Psychosis/Schizophrenia',
  },
  stressTitle: {
    id: `${scope}.stressTitle`,
    defaultMessage: 'Specific Diagnosis',
  },
  stressQuestion: {
    id: `${scope}.stressQuestion`,
    defaultMessage: 'Are any of these factors causing distress?',
  },
  stress1: {
    id: `${scope}.stress1`,
    defaultMessage: 'Loneliness',
  },
  stress2: {
    id: `${scope}.stress2`,
    defaultMessage: 'Marital/relationships',
  },
  stress3: {
    id: `${scope}.stress3`,
    defaultMessage: 'Family dynamics',
  },
  stress4: {
    id: `${scope}.stress4`,
    defaultMessage: 'Grief & loss',
  },
  stress5: {
    id: `${scope}.stress5`,
    defaultMessage: 'Caregiver burnout',
  },
  stress6: {
    id: `${scope}.stress6`,
    defaultMessage: 'Injury or illness',
  },
  stress7: {
    id: `${scope}.stress7`,
    defaultMessage: 'Gender identity',
  },
  stress8: {
    id: `${scope}.stress8`,
    defaultMessage: 'Sexual orientation',
  },
  stress9: {
    id: `${scope}.stress9`,
    defaultMessage: 'Being abused or neglected',
  },
  stress10: {
    id: `${scope}.stress10`,
    defaultMessage: 'Financial',
  },
  stress11: {
    id: `${scope}.stress11`,
    defaultMessage: 'Legal',
  },
  stress12: {
    id: `${scope}.stress12`,
    defaultMessage: 'Children',
  },
  stress13: {
    id: `${scope}.stress13`,
    defaultMessage: 'Unemployment',
  },
  stress14: {
    id: `${scope}.stress14`,
    defaultMessage: 'Retirement',
  },
  stress15: {
    id: `${scope}.stress15`,
    defaultMessage: 'Procrastination',
  },
  stress16: {
    id: `${scope}.stress16`,
    defaultMessage: 'Perfectionism',
  },
  stress17: {
    id: `${scope}.stress17`,
    defaultMessage: 'Sexual difficulties',
  },
  wsTitle: {
    id: `${scope}.wsTitle`,
    defaultMessage: 'Specific Stressors',
  },
  wsQuestion: {
    id: `${scope}.wsQuestion`,
    defaultMessage:
      'Do you spend most of your time at work, school, or neither?',
  },
  wsWork: {
    id: `${scope}.wsWork`,
    defaultMessage: 'Work',
  },
  wsSchool: {
    id: `${scope}.wsSchool`,
    defaultMessage: 'School',
  },
  wsEqual: {
    id: `${scope}.wsEqual`,
    defaultMessage: 'Equally work and school',
  },
  wsNeither: {
    id: `${scope}.wsNeither`,
    defaultMessage: 'Neither',
  },
  workTitle: {
    id: `${scope}.workTitle`,
    defaultMessage: 'Specific Stressors',
  },
  workQuestion: {
    id: `${scope}.workQuestion`,
    defaultMessage:
      'Do any of these cause you distress in your main employment?',
  },
  work1: {
    id: `${scope}.work1`,
    defaultMessage: 'High workload',
  },
  work2: {
    id: `${scope}.work2`,
    defaultMessage: 'Lack of control',
  },
  work3: {
    id: `${scope}.work3`,
    defaultMessage: 'Not meaningful',
  },
  work4: {
    id: `${scope}.work4`,
    defaultMessage: 'Not appreciated',
  },
  work5: {
    id: `${scope}.work5`,
    defaultMessage: 'Not treated fairly',
  },
  work6: {
    id: `${scope}.work6`,
    defaultMessage: 'Poor management',
  },
  work7: {
    id: `${scope}.work7`,
    defaultMessage: 'High conflict',
  },
  work8: {
    id: `${scope}.work8`,
    defaultMessage: 'Job uncertainty',
  },
  work9: {
    id: `${scope}.work9`,
    defaultMessage: 'Poor work-life balance',
  },
  work10: {
    id: `${scope}.work10`,
    defaultMessage: 'Sexual harassment',
  },
  work11: {
    id: `${scope}.work11`,
    defaultMessage: 'Bullying',
  },
  work12: {
    id: `${scope}.work12`,
    defaultMessage: 'Racial discrimination',
  },
  work13: {
    id: `${scope}.work13`,
    defaultMessage: 'Gender discrimination',
  },
  wsizeTitle: {
    id: `${scope}.wsizeTitle`,
    defaultMessage: 'Specific Stressors',
  },
  wsizeQuestion: {
    id: `${scope}.wsizeQuestion`,
    defaultMessage: 'How large is the company (# of employees):',
  },
  schoolTitle: {
    id: `${scope}.schoolTitle`,
    defaultMessage: 'Specific Stressors',
  },
  schoolQuestion: {
    id: `${scope}.schoolQuestion`,
    defaultMessage: 'Do any of these cause you distress at your school?',
  },
  school1: {
    id: `${scope}.school1`,
    defaultMessage: 'High workload',
  },
  school2: {
    id: `${scope}.school2`,
    defaultMessage: 'Difficulty adjusting',
  },
  school3: {
    id: `${scope}.school3`,
    defaultMessage: 'Difficulty socializing',
  },
  school4: {
    id: `${scope}.school4`,
    defaultMessage: 'High competition',
  },
  school5: {
    id: `${scope}.school5`,
    defaultMessage: 'Expenses',
  },
  school6: {
    id: `${scope}.school6`,
    defaultMessage: 'Future employment',
  },
  school7: {
    id: `${scope}.school7`,
    defaultMessage: 'Not treated fairly',
  },
  school8: {
    id: `${scope}.school8`,
    defaultMessage: 'Poor school-life balance',
  },
  school9: {
    id: `${scope}.school9`,
    defaultMessage: 'Sexual harassment',
  },
  school10: {
    id: `${scope}.school10`,
    defaultMessage: 'Bullying',
  },
  school11: {
    id: `${scope}.school11`,
    defaultMessage: 'Racial discrimination',
  },
  school12: {
    id: `${scope}.school12`,
    defaultMessage: 'Gender discrimination',
  },
  schoolLevelTitle: {
    id: `${scope}.schoolLevelTitle`,
    defaultMessage: 'Specific Stressors',
  },
  schoolLevelQuestion: {
    id: `${scope}.schoolLevelQuestion`,
    defaultMessage: 'What is your current school level:',
  },
  schoolLevelKindergarten: {
    id: `${scope}.schoolLevelKindergarten`,
    defaultMessage: 'Kindergarten-Grade 12',
  },
  schoolLevelCollege: {
    id: `${scope}.schoolLevelCollege`,
    defaultMessage: 'College',
  },
  schoolLevelUniversity: {
    id: `${scope}.schoolLevelUniversity`,
    defaultMessage: 'University',
  },
  schoolLevelOther: {
    id: `${scope}.schoolLevelOther`,
    defaultMessage: 'Other',
  },
  crossQuestion: {
    id: `${scope}.crossQuestion`,
    defaultMessage:
      'Which of the following have you experienced for at least a couple days in the last two weeks?',
  },
  crossDescription: {
    id: `${scope}.crossDescription`,
    defaultMessage: 'You can skip or choose multiple options.',
  },
  crossDepression1: {
    id: `${scope}.crossDepression1`,
    defaultMessage: 'Little interest or pleasure in doing things',
  },
  crossBipolar1: {
    id: `${scope}.crossBipolar1`,
    defaultMessage: 'Sleep less than usual but still have lots of energy',
  },
  crossDepression2: {
    id: `${scope}.crossDepression2`,
    defaultMessage: 'Feel down, depressed or hopeless',
  },
  crossAnxiety1: {
    id: `${scope}.crossAnxiety1`,
    defaultMessage: 'Feel nervous, anxious, worried or on edge',
  },
  crossAnxiety2: {
    id: `${scope}.crossAnxiety2`,
    defaultMessage: 'Feel panic or frightened',
  },
  crossBipolar2: {
    id: `${scope}.crossBipolar2`,
    defaultMessage: 'Start lots more projects than usual',
  },
  crossAnxiety3: {
    id: `${scope}.crossAnxiety3`,
    defaultMessage: 'Avoid situations that make you anxious',
  },
  crossSubstance1: {
    id: `${scope}.crossSubstance1`,
    defaultMessage: 'Drink at least 4 drinks of any kind of alcohol in a day',
  },
  crossBipolar3: {
    id: `${scope}.crossBipolar3`,
    defaultMessage: 'Do more risky things than usual',
  },
  crossSubstance2: {
    id: `${scope}.crossSubstance2`,
    defaultMessage:
      'Use drugs either without a doctor’s prescription or more than prescribed',
  },
  crossPtsd1: {
    id: `${scope}.crossPtsd1`,
    defaultMessage: 'Have been bothered by unwanted memories or nightmares',
  },
  crossOcd1: {
    id: `${scope}.crossOcd1`,
    defaultMessage:
      'Unpleasant thoughts, or images that repeatedly enter your mind',
  },
  crossPhobia1: {
    id: `${scope}.crossPhobia1`,
    defaultMessage:
      'Have severe fear about a specific object or situation (e.g. flying, animals, heights)',
  },
  crossPsychosis1: {
    id: `${scope}.crossPsychosis1`,
    defaultMessage: 'Hear things such as voices other people couldn’t hear',
  },
  crossOcd2: {
    id: `${scope}.crossOcd2`,
    defaultMessage:
      'Feel driven to do certain behaviours or acts over & over again',
  },
  crossGenanx1: {
    id: `${scope}.crossGenanx1`,
    defaultMessage:
      'Feel severe anxiety about a number of events or activities',
  },
  crossPsychosis3: {
    id: `${scope}.crossPsychosis3`,
    defaultMessage: 'Feel you could hear another person’s thoughts',
  },
  crossDissociation1: {
    id: `${scope}.crossDissociation1`,
    defaultMessage: 'Feel detached or distant from yourself or your body',
  },
  crossPsychosis2: {
    id: `${scope}.crossPsychosis2`,
    defaultMessage: 'Feel someone could hear your thoughts',
  },
  crossPersonality1: {
    id: `${scope}.crossPersonality1`,
    defaultMessage: 'Not know who you really are or want from life',
  },
  crossAgoraphobia1: {
    id: `${scope}.crossAgoraphobia1`,
    defaultMessage: 'Have severe fear of being in open or enclosed spaces',
  },
  crossPersonality2: {
    id: `${scope}.crossPersonality2`,
    defaultMessage:
      'Not feel close to other people or enjoy your relationships',
  },
  crossSomatic1: {
    id: `${scope}.crossSomatic1`,
    defaultMessage: 'Unexplained aches and pains',
  },
  crossSomatic2: {
    id: `${scope}.crossSomatic2`,
    defaultMessage:
      'Feeling that your illnesses are not being taken seriously enough',
  },
  crossSocial1: {
    id: `${scope}.crossSocial1`,
    defaultMessage: 'Have severe anxiety about one or more social situations',
  },
  crossSeparation: {
    id: `${scope}.crossSeparation`,
    defaultMessage:
      'Feel excessive fear or anxiety separating from home or someone',
  },
  neverAlwaysOptionsNever: {
    id: `${scope}.neverAlwaysOptionsNever`,
    defaultMessage: 'Never',
  },
  neverAlwaysOptionsRarely: {
    id: `${scope}.neverAlwaysOptionsRarely`,
    defaultMessage: 'Rarely',
  },
  neverAlwaysOptionsSometimes: {
    id: `${scope}.neverAlwaysOptionsSometimes`,
    defaultMessage: 'Sometimes',
  },
  neverAlwaysOptionsOften: {
    id: `${scope}.neverAlwaysOptionsOften`,
    defaultMessage: 'Often',
  },
  neverAlwaysOptionsAlways: {
    id: `${scope}.neverAlwaysOptionsAlways`,
    defaultMessage: 'Always',
  },
  rarelyAllOptionsRarely: {
    id: `${scope}.rarelyAllOptionsRarely`,
    defaultMessage: 'Rarely',
  },
  rarelyAllOptionsOccasionally: {
    id: `${scope}.rarelyAllOptionsOccasionally`,
    defaultMessage: 'Occasionally',
  },
  rarelyAllOptionsOften: {
    id: `${scope}.rarelyAllOptionsOften`,
    defaultMessage: 'Often',
  },
  rarelyAllOptionsMost: {
    id: `${scope}.rarelyAllOptionsMost`,
    defaultMessage: 'Most of the time',
  },
  rarelyAllOptionsAll: {
    id: `${scope}.rarelyAllOptionsAll`,
    defaultMessage: 'All of the time',
  },
  notNearlyOptionsNot: {
    id: `${scope}.notNearlyOptionsNot`,
    defaultMessage: 'Not at all',
  },
  notNearlyOptionsOne: {
    id: `${scope}.notNearlyOptionsOne`,
    defaultMessage: 'One or two days',
  },
  notNearlyOptionsSeveral: {
    id: `${scope}.notNearlyOptionsSeveral`,
    defaultMessage: 'Several days',
  },
  notNearlyOptionsMore: {
    id: `${scope}.notNearlyOptionsMore`,
    defaultMessage: 'More than half the days',
  },
  notNearlyOptionsNearly: {
    id: `${scope}.notNearlyOptionsNearly`,
    defaultMessage: 'Nearly every day',
  },
  notExtremelyOptionsNot: {
    id: `${scope}.notExtremelyOptionsNot`,
    defaultMessage: 'Not at all',
  },
  notExtremelyOptionsLittle: {
    id: `${scope}.notExtremelyOptionsLittle`,
    defaultMessage: 'A little bit',
  },
  notExtremelyOptionsModerately: {
    id: `${scope}.notExtremelyOptionsModerately`,
    defaultMessage: 'Moderately',
  },
  notExtremelyOptionsQuite: {
    id: `${scope}.notExtremelyOptionsQuite`,
    defaultMessage: 'Quite a bit',
  },
  notExtremelyOptionsExtremely: {
    id: `${scope}.notExtremelyOptionsExtremely`,
    defaultMessage: 'Extremely',
  },
  ocdTimeOptionsNone: {
    id: `${scope}.ocdTimeOptionsNone`,
    defaultMessage: 'None',
  },
  ocdTimeOptionsLess: {
    id: `${scope}.ocdTimeOptionsLess`,
    defaultMessage: 'Less than an hour a day',
  },
  ocdTimeOptions13: {
    id: `${scope}.ocdTimeOptions13`,
    defaultMessage: '1-3 hours a day',
  },
  ocdTimeOptions38: {
    id: `${scope}.ocdTimeOptions38`,
    defaultMessage: '3-8 hours a day',
  },
  ocdTimeOptionsMore: {
    id: `${scope}.ocdTimeOptionsMore`,
    defaultMessage: 'More than 8 hours a day',
  },
  ocdDistressOptionsNone: {
    id: `${scope}.ocdDistressOptionsNone`,
    defaultMessage: 'None',
  },
  ocdDistressOptionsSlightly: {
    id: `${scope}.ocdDistressOptionsSlightly`,
    defaultMessage: 'Slightly disturbing',
  },
  ocdDistressOptionsManageable: {
    id: `${scope}.ocdDistressOptionsManageable`,
    defaultMessage: 'Disturbing but still manageable',
  },
  ocdDistressOptionsVery: {
    id: `${scope}.ocdDistressOptionsVery`,
    defaultMessage: 'Very disturbing',
  },
  ocdDistressOptionsOverwhelming: {
    id: `${scope}.ocdDistressOptionsOverwhelming`,
    defaultMessage: 'Overwhelming distress',
  },
  ocdControlOptionsComplete: {
    id: `${scope}.ocdControlOptionsComplete`,
    defaultMessage: 'Complete control',
  },
  ocdControlOptionsUsually: {
    id: `${scope}.ocdControlOptionsUsually`,
    defaultMessage: 'Usually able to control thoughts or behaviours',
  },
  ocdControlOptionsSometimes: {
    id: `${scope}.ocdControlOptionsSometimes`,
    defaultMessage: 'Sometimes able to control thoughts or behaviours',
  },
  ocdControlOptionsInfrequently: {
    id: `${scope}.ocdControlOptionsInfrequently`,
    defaultMessage: 'Infrequently able to control thoughts or behaviours',
  },
  ocdControlOptionsUnable: {
    id: `${scope}.ocdControlOptionsUnable`,
    defaultMessage: 'Unable to control thoughts or behaviours',
  },
  ocdAvoidOptionsNo: {
    id: `${scope}.ocdAvoidOptionsNo`,
    defaultMessage: 'No avoidance',
  },
  ocdAvoidOptionsOccasional: {
    id: `${scope}.ocdAvoidOptionsOccasional`,
    defaultMessage: 'Occasional avoidance',
  },
  ocdAvoidOptionsRegularly: {
    id: `${scope}.ocdAvoidOptionsRegularly`,
    defaultMessage: 'Regularly avoid doing these things',
  },
  ocdAvoidOptionsFrequently: {
    id: `${scope}.ocdAvoidOptionsFrequently`,
    defaultMessage: 'Frequently and extensive avoidance',
  },
  ocdAvoidOptionsNearly: {
    id: `${scope}.ocdAvoidOptionsNearly`,
    defaultMessage: 'Nearly complete avoidance & house-bound',
  },
  ocdInterfereOptionsNone: {
    id: `${scope}.ocdInterfereOptionsNone`,
    defaultMessage: 'None',
  },
  ocdInterfereOptionsSlight: {
    id: `${scope}.ocdInterfereOptionsSlight`,
    defaultMessage: 'Slight interference',
  },
  ocdInterfereOptionsManageable: {
    id: `${scope}.ocdInterfereOptionsManageable`,
    defaultMessage: 'Definite interference but still manageable',
  },
  ocdInterfereOptionsSubstantial: {
    id: `${scope}.ocdInterfereOptionsSubstantial`,
    defaultMessage: 'Substantial interference',
  },
  ocdInterfereOptionsNear: {
    id: `${scope}.ocdInterfereOptionsNear`,
    defaultMessage: 'Near-total interference & incapacitated',
  },
  phobiaItemOptionsDriving: {
    id: `${scope}.phobiaItemOptionsDriving`,
    defaultMessage: 'Driving, flying, tunnels, bridges, or enclosed spaces',
  },
  phobiaItemOptionsAnimals: {
    id: `${scope}.phobiaItemOptionsAnimals`,
    defaultMessage: 'Animals or insects',
  },
  phobiaItemOptionsHeights: {
    id: `${scope}.phobiaItemOptionsHeights`,
    defaultMessage: 'Heights, storms, or water',
  },
  phobiaItemOptionsBlood: {
    id: `${scope}.phobiaItemOptionsBlood`,
    defaultMessage: 'Blood, needles, or injections',
  },
  phobiaItemOptionsChoking: {
    id: `${scope}.phobiaItemOptionsChoking`,
    defaultMessage: 'Choking or vomiting',
  },
  phobiaItemOptionsNone: {
    id: `${scope}.phobiaItemOptionsNone`,
    defaultMessage: 'None of the above',
  },
  neverAllOptionsNever: {
    id: `${scope}.neverAllOptionsNever`,
    defaultMessage: 'Never',
  },
  neverAllOptionsOccasionally: {
    id: `${scope}.neverAllOptionsOccasionally`,
    defaultMessage: 'Occasionally',
  },
  neverAllOptionsHalf: {
    id: `${scope}.neverAllOptionsHalf`,
    defaultMessage: 'Half of the time',
  },
  neverAllOptionsMost: {
    id: `${scope}.neverAllOptionsMost`,
    defaultMessage: 'Most of the time',
  },
  neverAllOptionsAll: {
    id: `${scope}.neverAllOptionsAll`,
    defaultMessage: 'All of the time',
  },
  notMoreOptionsNot: {
    id: `${scope}.notMoreOptionsNot`,
    defaultMessage: 'Not at all',
  },
  notMoreOptionsOnce: {
    id: `${scope}.notMoreOptionsOnce`,
    defaultMessage: 'Once or twice',
  },
  notMoreOptionsAlmost: {
    id: `${scope}.notMoreOptionsAlmost`,
    defaultMessage: 'Almost every day',
  },
  notMoreOptionsAbout: {
    id: `${scope}.notMoreOptionsAbout`,
    defaultMessage: 'About once a day',
  },
  notMoreOptionsMore: {
    id: `${scope}.notMoreOptionsMore`,
    defaultMessage: 'More than once a day',
  },
  veryVeryOptionsVeryFalse: {
    id: `${scope}.veryVeryOptionsVeryFalse`,
    defaultMessage: 'Very false or often false',
  },
  veryVeryOptionsSometimesFalse: {
    id: `${scope}.veryVeryOptionsSometimesFalse`,
    defaultMessage: 'Sometimes or somewhat false',
  },
  veryVeryOptionsSometimesTrue: {
    id: `${scope}.veryVeryOptionsSometimesTrue`,
    defaultMessage: 'Sometimes or somewhat true',
  },
  veryVeryOptionsVeryTrue: {
    id: `${scope}.veryVeryOptionsVeryTrue`,
    defaultMessage: 'Very true or often true',
  },
  notBotheredOptionsNot: {
    id: `${scope}.notBotheredOptionsNot`,
    defaultMessage: 'Not bothered at all',
  },
  notBotheredOptionsLittle: {
    id: `${scope}.notBotheredOptionsLittle`,
    defaultMessage: 'Bothered a little',
  },
  notBotheredOptionsLot: {
    id: `${scope}.notBotheredOptionsLot`,
    defaultMessage: 'Bothered a lot',
  },
  depressionWorthless: {
    id: `${scope}.depressionWorthless`,
    defaultMessage:
      'During the past seven days, how often have you felt worthless?',
  },
  depressionNothing: {
    id: `${scope}.depressionNothing`,
    defaultMessage:
      'During the past seven days, how often have you felt that you had nothing to look forward to?',
  },
  depressionHelpless: {
    id: `${scope}.depressionHelpless`,
    defaultMessage:
      'During the past seven days, how often have you felt helpless?',
  },
  depressionSad: {
    id: `${scope}.depressionSad`,
    defaultMessage: 'During the past seven days, how often have you felt sad?',
  },
  depressionDepressed: {
    id: `${scope}.depressionDepressed`,
    defaultMessage:
      'During the past seven days, how often have you felt depressed?',
  },
  depressionUnhappy: {
    id: `${scope}.depressionUnhappy`,
    defaultMessage:
      'During the past seven days, how often have you felt unhappy?',
  },
  depressionHopeless: {
    id: `${scope}.depressionHopeless`,
    defaultMessage:
      'During the past seven days, how often have you felt hopeless?',
  },
  anxietyFearful: {
    id: `${scope}.anxietyFearful`,
    defaultMessage:
      'During the past seven days, how often have you felt fearful?',
  },
  anxietyAnxious: {
    id: `${scope}.anxietyAnxious`,
    defaultMessage:
      'During the past seven days, how often have you felt anxious?',
  },
  anxietyWorried: {
    id: `${scope}.anxietyWorried`,
    defaultMessage:
      'During the past seven days, how often have you felt worried?',
  },
  anxietyFocus: {
    id: `${scope}.anxietyFocus`,
    defaultMessage:
      'During the past seven days, how often have you found it hard to focus on anything other than your anxiety?',
  },
  anxietyNervous: {
    id: `${scope}.anxietyNervous`,
    defaultMessage:
      'During the past seven days, how often have you felt nervous?',
  },
  anxietyUneasy: {
    id: `${scope}.anxietyUneasy`,
    defaultMessage:
      'During the past seven days, how often have you felt uneasy?',
  },
  anxietyTense: {
    id: `${scope}.anxietyTense`,
    defaultMessage:
      'During the past seven days, how often have you felt tense?',
  },
  bipolarHappier: {
    id: `${scope}.bipolarHappier`,
    defaultMessage:
      'How often do you feel happier or more cheerful than usual?',
  },
  bipolarSelf: {
    id: `${scope}.bipolarSelf`,
    defaultMessage: 'How often do you feel self-confident?',
  },
  bipolarSleep: {
    id: `${scope}.bipolarSleep`,
    defaultMessage:
      'How often do you feel that you need less sleep than usual?',
  },
  bipolarTalk: {
    id: `${scope}.bipolarTalk`,
    defaultMessage: 'How often do you talk more than usual?',
  },
  bipolarActive: {
    id: `${scope}.bipolarActive`,
    defaultMessage:
      'How often are you more active (either socially, sexually, at work, home or school) than usual?',
  },
  severitySubstance1: {
    id: `${scope}.severitySubstance1`,
    defaultMessage:
      'During the past 2 weeks, how often did you use painkillers (e.g. Percocet) without a doctor’s prescription, in greater amounts or for longer than prescribed?',
  },
  severitySubstance2: {
    id: `${scope}.severitySubstance2`,
    defaultMessage:
      'During the past 2 weeks, how often did you use stimulants (e.g. Adderall) without a doctor’s prescription, in greater amounts or for longer than prescribed?',
  },
  severitySubstance3: {
    id: `${scope}.severitySubstance3`,
    defaultMessage:
      'During the past 2 weeks, how often did you use sedatives (e.g. sleeping pills) without a doctor’s prescription, in greater amounts or for longer than prescribed?',
  },
  severitySubstance4: {
    id: `${scope}.severitySubstance4`,
    defaultMessage: 'During the past 2 weeks, how often did you use marijuana?',
  },
  severitySubstance5: {
    id: `${scope}.severitySubstance5`,
    defaultMessage:
      'During the past 2 weeks, how often did you use cocaine or crack?',
  },
  severitySubstance6: {
    id: `${scope}.severitySubstance6`,
    defaultMessage:
      'During the past 2 weeks, how often did you use club drugs (e.g. ecstasy)?',
  },
  severitySubstance7: {
    id: `${scope}.severitySubstance7`,
    defaultMessage:
      'During the past 2 weeks, how often did you use hallucinogens (e.g. LSD)?',
  },
  severitySubstance8: {
    id: `${scope}.severitySubstance8`,
    defaultMessage: 'During the past 2 weeks, how often did you use heroin?',
  },
  severitySubstance9: {
    id: `${scope}.severitySubstance9`,
    defaultMessage:
      'During the past 2 weeks, how often did you use inhalants or solvents (e.g. glue)?',
  },
  severitySubstance10: {
    id: `${scope}.severitySubstance10`,
    defaultMessage:
      'During the past 2 weeks, how often did you use methamphetamines (e.g. speed)?',
  },
  ptsdFlashbacks: {
    id: `${scope}.ptsdFlashbacks`,
    defaultMessage:
      'How much have you been bothered during the past 7 days by having “flashbacks,” that is, you suddenly acted or felt as if a stressful experience from the past was happening all over again (for example, you re-experienced part of it by seeing, hearing, smelling or feeling parts of the experience)?',
  },
  ptsdUpset: {
    id: `${scope}.ptsdUpset`,
    defaultMessage:
      'How much have you been bothered during the past 7 days by feeling very emotionally upset when something reminded you of a stressful experience?',
  },
  ptsdAvoid: {
    id: `${scope}.ptsdAvoid`,
    defaultMessage:
      'How much have you been bothered during the past 7 days by trying to avoid thoughts, feelings, or physical sensations that reminded you of a stressful experience?',
  },
  ptsdEvent: {
    id: `${scope}.ptsdEvent`,
    defaultMessage:
      'How much have you been bothered during the past 7 days by thinking that a stressful event happened because you or someone else (who didn’t directly harm you) did something wrong or didn’t do everything possible to prevent it?',
  },
  ptsdNegative: {
    id: `${scope}.ptsdNegative`,
    defaultMessage:
      'How much have you been bothered during the past 7 days by having a very negative emotional state (e.g. experiencing lots of fear, anger, guilt, shame or horror) after a stressful experience?',
  },
  ptsdInterest: {
    id: `${scope}.ptsdInterest`,
    defaultMessage:
      'How much have you been bothered during the past 7 days by losing interest in activities you used to enjoy before having a stressful experience?',
  },
  ptsdAlert: {
    id: `${scope}.ptsdAlert`,
    defaultMessage:
      'How much have you been bothered during the past 7 days by being “super alert,” or on guard, or constantly on the lookout for danger?',
  },
  ptsdNoise: {
    id: `${scope}.ptsdNoise`,
    defaultMessage:
      'How much have you been bothered during the past 7 days by feeling jumpy or easily startled when you hear an unexpected noise?',
  },
  ptsdAngry: {
    id: `${scope}.ptsdAngry`,
    defaultMessage:
      'How much have you been bothered during the past 7 days by being extremely irritable or angry to the point where you yelled at other people, got into fights, or destroyed things?',
  },
  ocdTime: {
    id: `${scope}.ocdTime`,
    defaultMessage:
      'With regards to the ‘unwanted repeated thoughts, images or urges’ and/or ‘being driven to perform certain behaviours or mental acts over and over,’ during the past seven days, on average, how much time is occupied by these thoughts or behaviours daily?',
  },
  ocdDistress: {
    id: `${scope}.ocdDistress`,
    defaultMessage:
      'With regards to the ‘unwanted repeated thoughts, images or urges’ and/or ‘being driven to perform certain behaviours or mental acts over and over,’ during the past seven days, on average, how much distress do these thoughts or behaviours cause you?',
  },
  ocdControl: {
    id: `${scope}.ocdControl`,
    defaultMessage:
      'With regards to the ‘unwanted repeated thoughts, images or urges’ and/or ‘being driven to perform certain behaviours or mental acts over and over,’ during the past seven days, on average, how hard is it for you to control these thoughts or behaviours?',
  },
  ocdAvoid: {
    id: `${scope}.ocdAvoid`,
    defaultMessage:
      'With regards to the ‘unwanted repeated thoughts, images or urges’ and/or ‘being driven to perform certain behaviours or mental acts over and over,’ during the past seven days, on average, how much do these thoughts or behaviours cause you to avoid doing anything, going anyplace or being with anyone?',
  },
  ocdInterfere: {
    id: `${scope}.ocdInterfere`,
    defaultMessage:
      'With regards to the ‘unwanted repeated thoughts, images or urges’ and/or ‘being driven to perform certain behaviours or mental acts over and over,’ during the past seven days, on average, how much do these thoughts or behaviours interfere with school, work, or your social/family life?',
  },
  phobiaItem: {
    id: `${scope}.phobiaItem`,
    defaultMessage: 'Choose the item that makes you most anxious:',
  },
  phobiaMoment: {
    id: `${scope}.phobiaMoment`,
    defaultMessage:
      'With regards to ‘{item}’, in the past 7 days, how often have you felt moments of sudden terror, fear, or fright?',
  },
  phobiaAnxious: {
    id: `${scope}.phobiaAnxious`,
    defaultMessage:
      'With regards to ‘{item}’, in the past 7 days, how often have you felt anxious, worried, or nervous about these situations?',
  },
  phobiaInjured: {
    id: `${scope}.phobiaInjured`,
    defaultMessage:
      'With regards to ‘{item}’, in the past 7 days, how often have you had thoughts of being injured, overcome with fear, or other bad things happening?',
  },
  phobiaSweaty: {
    id: `${scope}.phobiaSweaty`,
    defaultMessage:
      'With regards to ‘{item}’, in the past 7 days, how often have you  felt a racing heart, sweaty, trouble breathing, faint, or shaky?',
  },
  phobiaTense: {
    id: `${scope}.phobiaTense`,
    defaultMessage:
      'With regards to ‘{item}’, in the past 7 days, how often have you felt tense muscles, felt on edge or restless, or had trouble relaxing?',
  },
  phobiaAvoid: {
    id: `${scope}.phobiaAvoid`,
    defaultMessage:
      'With regards to ‘{item}’, in the past 7 days, how often have you avoided, or did not approach or enter?',
  },
  phobiaAway: {
    id: `${scope}.phobiaAway`,
    defaultMessage:
      'With regards to ‘{item}’, in the past 7 days, how often have you moved away from these situations or left them early?',
  },
  phobiaPrepare: {
    id: `${scope}.phobiaPrepare`,
    defaultMessage:
      'With regards to ‘{item}’, in the past 7 days, how often have you spent a lot of time preparing for, or procrastinating about these situations?',
  },
  phobiaDistract: {
    id: `${scope}.phobiaDistract`,
    defaultMessage:
      'With regards to ‘{item}’, in the past 7 days, how often have you distracted yourself to avoid thinking about them?',
  },
  phobiaHelp: {
    id: `${scope}.phobiaHelp`,
    defaultMessage:
      'With regards to ‘{item}’, in the past 7 days, how often have you needed help to cope with these situations (e.g. alcohol or medications, superstitious objects, other people)?',
  },
  dissociationStare: {
    id: `${scope}.dissociationStare`,
    defaultMessage:
      'In the past seven days, how often did you stare into space and thinking about nothing?',
  },
  dissociationStrange: {
    id: `${scope}.dissociationStrange`,
    defaultMessage:
      'In the past seven days, how often did people, objects, or the world around you seem strange or unreal?',
  },
  dissociationRemember: {
    id: `${scope}.dissociationRemember`,
    defaultMessage:
      'In the past seven days, how often did you do things that you do not remember doing?',
  },
  dissociationAlone: {
    id: `${scope}.dissociationAlone`,
    defaultMessage:
      'In the past seven days, how often when alone, did you talk out loud to yourself?',
  },
  dissociationFog: {
    id: `${scope}.dissociationFog`,
    defaultMessage:
      'In the past seven days, how often did you feel as though you were looking at the world through a fog so that people and things seemed far away or unclear?',
  },
  dissociationPain: {
    id: `${scope}.dissociationPain`,
    defaultMessage:
      'In the past seven days, how often were you able to ignore pain?',
  },
  dissociationAct: {
    id: `${scope}.dissociationAct`,
    defaultMessage:
      'In the past seven days, how often did you act so differently from one situation to another that it was almost as if you were two different people?',
  },
  dissociationDo: {
    id: `${scope}.dissociationDo`,
    defaultMessage:
      'In the past seven days, how often did you do things very easily that would typically be hard for you?',
  },
  personalityDisinhibition1: {
    id: `${scope}.personalityDisinhibition1`,
    defaultMessage:
      'How true is it that people would describe you as reckless?',
  },
  personalityDisinhibition2: {
    id: `${scope}.personalityDisinhibition2`,
    defaultMessage: 'How true is that you act totally on impulse?',
  },
  personalityDisinhibition3: {
    id: `${scope}.personalityDisinhibition3`,
    defaultMessage:
      'How true is it that even though you know better, you can’t stop making rash decisions?',
  },
  personalityDetachment1: {
    id: `${scope}.personalityDetachment1`,
    defaultMessage:
      'How true is it that you often feel like nothing you do really matters?',
  },
  personalityDisinhibition4: {
    id: `${scope}.personalityDisinhibition4`,
    defaultMessage: 'How true is it that others see you as irresponsible?',
  },
  personalityDisinhibition5: {
    id: `${scope}.personalityDisinhibition5`,
    defaultMessage: 'How true is it that you are not good at planning ahead?',
  },
  personalityPsychoticism1: {
    id: `${scope}.personalityPsychoticism1`,
    defaultMessage:
      'How true is it that your thoughts often don’t make sense to others?',
  },
  personalityNegative1: {
    id: `${scope}.personalityNegative1`,
    defaultMessage: 'How true is it that you worry about almost everything?',
  },
  personalityNegative2: {
    id: `${scope}.personalityNegative2`,
    defaultMessage:
      'How true is it that you get emotional easily, often for very little reason?',
  },
  personalityNegative3: {
    id: `${scope}.personalityNegative3`,
    defaultMessage:
      'How true is it that you fear being alone in life more than anything else?',
  },
  personalityNegative4: {
    id: `${scope}.personalityNegative4`,
    defaultMessage:
      'How true is it that you get stuck on one way of doing things, even when it’s clear it won’t work?',
  },
  personalityPsychoticism2: {
    id: `${scope}.personalityPsychoticism2`,
    defaultMessage:
      'How true is it that you have seen things that weren’t really there?',
  },
  personalityDetachment2: {
    id: `${scope}.personalityDetachment2`,
    defaultMessage:
      'How true is it that you steer clear of romantic relationships?',
  },
  personalityDetachment3: {
    id: `${scope}.personalityDetachment3`,
    defaultMessage:
      'How true is it that you are not interested in making friends?',
  },
  personalityNegative5: {
    id: `${scope}.personalityNegative5`,
    defaultMessage:
      'How true is it that you get irritated easily by all sorts of things?',
  },
  personalityDetachment4: {
    id: `${scope}.personalityDetachment4`,
    defaultMessage:
      'How true is it that you don’t like to get too close to people?',
  },
  personalityAntagonism1: {
    id: `${scope}.personalityAntagonism1`,
    defaultMessage:
      'How true is it that It’s no big deal if you hurt other peoples’ feelings?',
  },
  personalityDetachment5: {
    id: `${scope}.personalityDetachment5`,
    defaultMessage:
      'How true is it that you rarely get enthusiastic about anything?',
  },
  personalityAntagonism2: {
    id: `${scope}.personalityAntagonism2`,
    defaultMessage: 'How true is it that you crave attention?',
  },
  personalityAntagonism3: {
    id: `${scope}.personalityAntagonism3`,
    defaultMessage:
      'How true is it that you often have to deal with people who are less important than you?',
  },
  personalityPsychoticism3: {
    id: `${scope}.personalityPsychoticism3`,
    defaultMessage:
      'How true is it that you often have thoughts that make sense to you but that other people say are strange?',
  },
  personalityAntagonism4: {
    id: `${scope}.personalityAntagonism4`,
    defaultMessage: 'How true is it that you use people to get what you want?',
  },
  personalityPsychoticism4: {
    id: `${scope}.personalityPsychoticism4`,
    defaultMessage:
      'How true is it that you often ‘zone out’ and then suddenly come to and realize that a lot of time has passed?',
  },
  personalityPsychoticism5: {
    id: `${scope}.personalityPsychoticism5`,
    defaultMessage:
      'How true is it that things around you often feel unreal, or more real than usual?',
  },
  personalityAntagonism5: {
    id: `${scope}.personalityAntagonism5`,
    defaultMessage:
      'How true is it that it is easy for you to take advantage of others?',
  },
  somaticStomach: {
    id: `${scope}.somaticStomach`,
    defaultMessage:
      'During the past 7 days, how much have you been bothered by stomach pain?',
  },
  somaticBack: {
    id: `${scope}.somaticBack`,
    defaultMessage:
      'During the past 7 days, how much have you been bothered by back pain?',
  },
  somaticArm: {
    id: `${scope}.somaticArm`,
    defaultMessage:
      'During the past 7 days, how much have you been bothered by pain in your arms, legs or joints (knees, hips, etc.)?',
  },
  somaticHeadache: {
    id: `${scope}.somaticHeadache`,
    defaultMessage:
      'During the past 7 days, how much have you been bothered by headaches?',
  },
  somaticChest: {
    id: `${scope}.somaticChest`,
    defaultMessage:
      'During the past 7 days, how much have you been bothered by chest pain?',
  },
  somaticDizziness: {
    id: `${scope}.somaticDizziness`,
    defaultMessage:
      'During the past 7 days, how much have you been bothered by dizziness?',
  },
  somaticFainting: {
    id: `${scope}.somaticFainting`,
    defaultMessage:
      'During the past 7 days, how much have you been bothered by fainting spells?',
  },
  somaticHeart: {
    id: `${scope}.somaticHeart`,
    defaultMessage:
      'During the past 7 days, how much have you been bothered by feeling your heart pound or race?',
  },
  somaticBreath: {
    id: `${scope}.somaticBreath`,
    defaultMessage:
      'During the past 7 days, how much have you been bothered by shortness of breath?',
  },
  somaticSexual: {
    id: `${scope}.somaticSexual`,
    defaultMessage:
      'During the past 7 days, how much have you been bothered by pain or problems during sexual intercourse?',
  },
  somaticConstipation: {
    id: `${scope}.somaticConstipation`,
    defaultMessage:
      'During the past 7 days, how much have you been bothered by constipation, loose bowels, or diarrhea?',
  },
  somaticNausea: {
    id: `${scope}.somaticNausea`,
    defaultMessage:
      'During the past 7 days, how much have you been bothered by nausea, gas, or indigestion?',
  },
  somaticTired: {
    id: `${scope}.somaticTired`,
    defaultMessage:
      'During the past 7 days, how much have you been bothered by feeling tired or having low energy?',
  },
  somaticSleep: {
    id: `${scope}.somaticSleep`,
    defaultMessage:
      'During the past 7 days, how much have you been bothered by trouble sleeping?',
  },
  socialFear: {
    id: `${scope}.socialFear`,
    defaultMessage:
      'During the past 7 days, how often have you felt moments of sudden terror, fear or fright in social situations?',
  },
  socialAnxious: {
    id: `${scope}.socialAnxious`,
    defaultMessage:
      'During the past 7 days, how often have you felt anxious, worried, or nervous about social situations?',
  },
  socialReject: {
    id: `${scope}.socialReject`,
    defaultMessage:
      'During the past 7 days, how often have you had thoughts of being rejected, humiliated, embarrassed, ridiculed, or offending others?',
  },
  socialSweaty: {
    id: `${scope}.socialSweaty`,
    defaultMessage:
      'During the past 7 days, how often have you felt a racing heart, sweaty, trouble breathing, faint, or shaky in social situations?',
  },
  socialTense: {
    id: `${scope}.socialTense`,
    defaultMessage:
      'During the past 7 days, how often have you felt tense muscles, felt on edge or restless, or had trouble relaxing in social situations?',
  },
  socialAvoid: {
    id: `${scope}.socialAvoid`,
    defaultMessage:
      'During the past 7 days, how often have you avoided, or did not approach or enter, social situations?',
  },
  socialLeave: {
    id: `${scope}.socialLeave`,
    defaultMessage:
      'During the past 7 days, how often have you left social situations early or participated only minimally (e.g. said little, avoided eye contact)?',
  },
  socialPrepare: {
    id: `${scope}.socialPrepare`,
    defaultMessage:
      'During the past 7 days, how often have you spent a lot of time preparing what to say or how to act in social situations?',
  },
  socialDistract: {
    id: `${scope}.socialDistract`,
    defaultMessage:
      'During the past 7 days, how often have you distracted yourself to avoid thinking about social situations?',
  },
  socialHelp: {
    id: `${scope}.socialHelp`,
    defaultMessage:
      'During the past 7 days, how often have you needed help to cope with social situations (e.g. alcohol or medications, superstitious objects)?',
  },
  genanxFear: {
    id: `${scope}.genanxFear`,
    defaultMessage:
      'During the past 7 days, how often have you felt moments of sudden terror, fear or fright related to concerns about family, health, finances, school and work?',
  },
  genanxAnxious: {
    id: `${scope}.genanxAnxious`,
    defaultMessage:
      'During the past 7 days, how often have you felt anxious, worried, or nervous related to concerns about family, health, finances, school and work?',
  },
  genanxBad: {
    id: `${scope}.genanxBad`,
    defaultMessage:
      'During the past 7 days, how often have you had thoughts of bad things happening, such as family tragedy, ill health, loss of a job, or accidents?',
  },
  genanxSweaty: {
    id: `${scope}.genanxSweaty`,
    defaultMessage:
      'During the past 7 days, how often have you felt a racing heart, sweaty, trouble breathing, faint, or shaky related to concerns about family, health, finances, school and work?',
  },
  genanxTense: {
    id: `${scope}.genanxTense`,
    defaultMessage:
      'During the past 7 days, how often have you felt tense muscles, felt on edge or restless, or had trouble sleeping related to concerns about family, health, finances, school and work?',
  },
  genanxAvoid: {
    id: `${scope}.genanxAvoid`,
    defaultMessage:
      'During the past 7 days, how often have you avoided, or did not approach or enter, situations related to concerns about family, health, finances, school and work?',
  },
  genanxLeave: {
    id: `${scope}.genanxLeave`,
    defaultMessage:
      'During the past 7 days, how often have you left situations early or participated only minimally due to worries related to family, health, finances, school and work?',
  },
  genanxDecision: {
    id: `${scope}.genanxDecision`,
    defaultMessage:
      'During the past 7 days, how often have you spent a lot of time making decisions, putting off making decisions, or preparing related to concerns about family, health, finances, school and work?',
  },
  genanxReassurance: {
    id: `${scope}.genanxReassurance`,
    defaultMessage:
      'During the past 7 days, how often have you sought reassurance from others due to worries related to family, health, finances, school and work?',
  },
  genanxHelp: {
    id: `${scope}.genanxHelp`,
    defaultMessage:
      'During the past 7 days, how often have you needed help to cope with anxiety (e.g. alcohol or medication, other people)?',
  },
  agoraphobiaFear: {
    id: `${scope}.agoraphobiaFear`,
    defaultMessage:
      'During the past 7 days, how often have you felt moments of sudden terror, fear or fright when in crowds, public places or using transportation?',
  },
  agoraphobiaAnxious: {
    id: `${scope}.agoraphobiaAnxious`,
    defaultMessage:
      'During the past 7 days, how often have you felt anxious, worried, or nervous about being in crowds, public places, or using transportation?',
  },
  agoraphobiaPanic: {
    id: `${scope}.agoraphobiaPanic`,
    defaultMessage:
      'During the past 7 days, how often have you had thoughts about panic attacks, uncomfortable physical sensations, getting lost or being overcome with fear in crowds, public places, or when using transportation?',
  },
  agoraphobiaSweaty: {
    id: `${scope}.agoraphobiaSweaty`,
    defaultMessage:
      'During the past 7 days, how often have you felt a racing heart, sweaty, trouble breathing, faint, or shaky in crowds, public places or when using transportation?',
  },
  agoraphobiaTense: {
    id: `${scope}.agoraphobiaTense`,
    defaultMessage:
      'During the past 7 days, how often have you felt tense muscles, felt on edge or restless, or had trouble relaxing in crowds, public places or when using transportation?',
  },
  agoraphobiaAvoid: {
    id: `${scope}.agoraphobiaAvoid`,
    defaultMessage:
      'During the past 7 days, how often have you avoided, or did not approach or enter crowds, public places or when using transportation?',
  },
  agoraphobiaAway: {
    id: `${scope}.agoraphobiaAway`,
    defaultMessage:
      'During the past 7 days, how often have you moved away from, left early, or remained close to the exits when in crowds, public places or when using transportation?',
  },
  agoraphobiaPrepare: {
    id: `${scope}.agoraphobiaPrepare`,
    defaultMessage:
      'During the past 7 days, how often have you spent a lot of time preparing for, or procrastinating about being in crowds, public places or when using transportation?',
  },
  agoraphobiaDistract: {
    id: `${scope}.agoraphobiaDistract`,
    defaultMessage:
      'During the past 7 days, how often have you distracted yourself to avoid thinking about crowds, public places or when using transportation?',
  },
  agoraphobiaHelp: {
    id: `${scope}.agoraphobiaHelp`,
    defaultMessage:
      'During the past 7 days, how often have you needed help to cope with crowds, public places or when using transportation (e.g. alcohol or medication, other people)?',
  },
  separationFear: {
    id: `${scope}.separationFear`,
    defaultMessage:
      'During the past 7 days, how often have you felt moments of sudden terror, fear, or fright about being separated from home or people important to you?',
  },
  separationAnxious: {
    id: `${scope}.separationAnxious`,
    defaultMessage:
      'In the past 7 days, how often have you felt anxious, worried, or nervous about being separated from home or people important to you?',
  },
  separationBad: {
    id: `${scope}.separationBad`,
    defaultMessage:
      'In the past 7 days, how often have you had thoughts of bad things happening to people important to you or bad things happening to you when separated from them (e.g. getting lost, accidents)?',
  },
  separationSweaty: {
    id: `${scope}.separationSweaty`,
    defaultMessage:
      'In the past 7 days, how often have you felt a racing heart, sweaty, trouble breathing, faint, or shaky when separated from home or people important to you?',
  },
  separationTense: {
    id: `${scope}.separationTense`,
    defaultMessage:
      'In the past 7 days, how often have you felt tense muscles, felt on edge of restless, or had trouble relaxing or trouble sleeping when separated from home or people important to you?',
  },
  separationAvoid: {
    id: `${scope}.separationAvoid`,
    defaultMessage:
      'In the past 7 days, how often have you avoided going places where you would be separated from home or people important to you?',
  },
  separationHome: {
    id: `${scope}.separationHome`,
    defaultMessage:
      'In the past 7 days, how often have you when separated, left places early to go home?',
  },
  separationPrepare: {
    id: `${scope}.separationPrepare`,
    defaultMessage:
      'In the past 7 days, how often have you spent a lot of time preparing for how to deal with separation?',
  },
  separationDistract: {
    id: `${scope}.separationDistract`,
    defaultMessage:
      'In the past 7 days, how often have you distracted yourself to avoid thinking about being separated from home or people important to you?',
  },
  separationHelp: {
    id: `${scope}.separationHelp`,
    defaultMessage:
      'In the past 7 days, how often have you needed help to cope with separation (e.g. alcohol, medications, superstitious objects)?',
  },
  backYouAre: {
    id: `${scope}.backYouAre`,
    defaultMessage: "You're Back",
  },
  backWeNoticed: {
    id: `${scope}.backWeNoticed`,
    defaultMessage:
      'We noticed that you partially completed the assessment process.',
  },
  backContinue: {
    id: `${scope}.backContinue`,
    defaultMessage: 'Continue from where I left off',
  },
  backStartFresh: {
    id: `${scope}.backStartFresh`,
    defaultMessage: 'Start Fresh',
  },
  videoOptionalVideoClip: {
    id: `${scope}.videoOptionalVideoClip`,
    defaultMessage: 'Optional Video Clip',
  },
  videoWeUse: {
    id: `${scope}.videoWeUse`,
    defaultMessage:
      'We use a short video clip to help improve our assessment tool. The video clip will be stored with the highest level of security for a short period of time and will then be destroyed.',
  },
  videoThisIs: {
    id: `${scope}.videoThisIs`,
    defaultMessage: 'This is an optional step.',
  },
  videoInGeneral: {
    id: `${scope}.videoInGeneral`,
    defaultMessage: 'In general, how are you feeling about work & life?',
  },
  videoAllowRecording: {
    id: `${scope}.videoAllowRecording`,
    defaultMessage: 'Allow Recording?',
  },
  videoEnableCamera: {
    id: `${scope}.videoEnableCamera`,
    defaultMessage: 'Please enable your camera.',
  },
  videoRecording: {
    id: `${scope}.videoRecording`,
    defaultMessage: 'Recording...',
  },
  videoUploadingVideo: {
    id: `${scope}.videoUploadingVideo`,
    defaultMessage: 'Uploading Video...',
  },
  reportFinalReport: {
    id: `${scope}.reportFinalReport`,
    defaultMessage: 'Final Report ^^^',
  },
  reportInterimReport: {
    id: `${scope}.reportInterimReport`,
    defaultMessage: 'Interim Report ^^^',
  },
  reportSummaryReport: {
    id: `${scope}.reportInterimReport`,
    defaultMessage: 'Summary Report ^^^',
  },
  reportResultSummary: {
    id: `${scope}.reportResultSummary`,
    defaultMessage: 'Result summary: ^^^',
  },
  reportMoreDetailed: {
    id: `${scope}.reportMoreDetailed`,
    defaultMessage: 'More detailed evaluation ^^^',
  },
  reportDownloadReport: {
    id: `${scope}.reportDownloadReport`,
    defaultMessage: 'Download report ^^^',
  },
  reportStartVirtual: {
    id: `${scope}.reportStartVirtual`,
    defaultMessage: 'Start Virtual Counselling ^^^',
  },
  reportReturnToDashboard: {
    id: `${scope}.reportReturnToDashboard`,
    defaultMessage: 'Return to Dashboard ^^^',
  },
  privacyPolicy: {
    id: `${scope}.privacyPolicy`,
    defaultMessage: 'Privacy Policy',
  },
  emailReport: {
    id: `${scope}.emailReport`,
    defaultMessage: 'Email Report',
  },
  emailSent: {
    id: `${scope}.emailSent`,
    defaultMessage: 'Email Sent',
  },
  emailError: {
    id: `${scope}.emailError`,
    defaultMessage: 'Error',
  },
});
