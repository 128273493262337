/**
 *
 * SignupForm
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import {
  FormattedMessage,
  FormattedHTMLMessage,
  injectIntl,
  intlShape,
} from 'react-intl';
import _ from 'lodash';
import { withRouter, Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  reduxForm,
  Field,
  formValueSelector,
  getFormSyncErrors,
} from 'redux-form/immutable';
import queryString from 'query-string';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';

import { US_URL, API_URL, BBDBAP, BBDEAP } from 'utils/environment';
import Body from 'components/Body';
import { setToken } from 'utils/auth';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { logEvent } from 'utils/amplitude';
import RadioButton from 'components/RadioButton';
import SingleCheckBox from 'components/SingleCheckBox';
import SpinnerButton from 'components/SpinnerButton';
import PasswordInput from 'components/PasswordInput';
import P from 'components/P';

import TextField from './TextField';
import SelectInput from './SelectInput';
import QuestionMark from '../../../images/auth/QuestionMark.png';
import {
  errorRed,
  tabletSize,
  phoneSize,
  deepSeaBlue,
} from '../../../global-styles';
import { signUp, searchCompanies, clearCompanies } from '../actions';
import messages from '../messages';
import {
  validateEmail,
  validateRequired,
  validatePasswordLength,
  validatePasswordsMatch,
  normalizeDate,
  validateBirthday,
} from './validations';
import { makeSelectCompanies, makeSelectEmailError } from '../selectors';

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;

const RadioButtonRow = styled.div`
  display: block;
  justify-content: space-between;
  width: 100%;

  @media (max-width: ${phoneSize}) {
    max-width: 70%;
    align-content: center;
    align-self: center;
  }
`;

const QuestionMarkWrapper = styled.img`
  width: 13px;
  height: 13px;
  margin-left: 4px;
`;

const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  .input-container {
    width: 100%;
    margin-bottom: 20px;
    p {
      text-transform: uppercase;
      font-size: 14px;
      &.error-text {
        font-family: 'Lato', sans-serif;
        text-transform: none;
        color: ${errorRed};
        margin-top: 5px;
      }
    }
    input {
      width: 90%;
      margin: 10px 0 0px 0;
      height: 40px;
      padding-left: 10px;
      background-color: rgba(192, 208, 223, 0.26);
      ::placeholder {
        font-family: 'Montserrat', sans-serif;
        color: #8e8e8e;
      }
    }
  }

  #custom {
    width: 95%;

    @media (max-width: ${phoneSize}) {
      width: 100%;
    }
  }

  @media (max-width: ${phoneSize}) {
    flex-wrap: wrap;

    .input-container {
      width: 70%;
      margin-left: auto;
      margin-right: auto;

      input {
        width: 100%;
      }
    }
  }
`;

const Tooltip = styled.div`
  position: relative;
  display: inline-block;
`;

const TooltipText = styled.span`
  visibility: hidden;
  background-color: #ffffff;
  color: ${deepSeaBlue};
  text-align: center;
  font-size: 10px;
  border: 1px solid ${deepSeaBlue};
  white-space: normal;
  max-width: 250px;
  padding: 2px 5;
  width: 350px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
  ${Tooltip}:hover & {
    visibility: visible;
  }
`;

const Label = styled.p`
  font-family: 'Montserrat', sans-serif;
  color: rgb(0, 86, 142);
  white-space: nowrap;
  text-transform: uppercase;
  font-size: 14px;
  display: inline-block;
`;

const Center = styled.div`
  @media (max-width: ${tabletSize}) {
    text-align: center;
  }
`;

const CheckboxWrapper = styled.div`
  margin-top: 23px;
`;

class SignupForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      gender: null,
      acrc: false,
      loading: false,
      errorMessage: '',
      formErrorMessage: '',
      successMessage: '',
    };
  }

  componentDidMount() {
    this.setState({
      acrc: queryString.parse(window.location.search).acrc,
    });
  }

  onSubmit = values => {
    const { locale, country, isAbbreviatedSignup } = this.props;
    let valuesToSubmit = _.omit(values, ['custom']);
    valuesToSubmit = valuesToSubmit.toJS();
    valuesToSubmit.locale = locale;

    if (isAbbreviatedSignup) {
      valuesToSubmit = _.omit(valuesToSubmit, [
        'first_name',
        'last_name',
        'email',
        'gender',
      ]);
      valuesToSubmit.unlink_token = this.extractUnlinkToken();
    }
    if (valuesToSubmit.gender === 'custom') {
      valuesToSubmit.gender = this.state.custom_gender;
    }
    if (valuesToSubmit.newsletter_yn === undefined) {
      valuesToSubmit.newsletter_yn = false;
    }
    if (this.state.acrc) {
      valuesToSubmit.company_name =
        'Atlantic Canada Regional Council Health and Wellness Trust Fund';
    }
    if (this.belongsToUS()) {
      valuesToSubmit.company_name = this.belongsToUS().name;
    }

    this.setState({ loading: true, errorMessage: '' });
    this.props.signUp(
      valuesToSubmit,
      country === 'USA' ? US_URL : API_URL,
      token => {
        logEvent('complete signup', { domain: window.location.hostname });
        this.setState({
          successMessage: 'Successfully signup and redirecting to dashboard',
        });
        setToken(token);
        if (country && country === 'USA') {
          window.location.href = `https://us.pcpeopleconnect.com/redirect/${token}`;
        } else {
          window.setTimeout(() => this.props.history.push('/dashboard'), 0);
        }
        this.setState({ loading: false });
      },
      error => {
        const errorMessage = _.get(error, 'data.error', '');
        this.setState({ loading: false, errorMessage });
      },
    );
  };

  extractUnlinkToken() {
    const path = window.location.pathname;
    const match = path.match(/\/unlink\/([^/]+)/);
    if (match) {
      return match[1];
    }
    return null;
  }

  getPlanType() {
    if (BBDBAP) {
      return 'bbd_bap';
    }
    if (BBDEAP) {
      return 'bbd_eap';
    }
    return '';
  }

  belongsToUS = () =>
    _.find(this.props.usCompanies, ({ id }) => id === this.props.company);

  searchCompanies = newQuery => {
    const planType = this.getPlanType();
    if (newQuery === '') {
      this.props.clearCompanies();
    } else {
      this.props.searchCompanies(newQuery, planType);
    }
  };

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: name === 'dob' ? this.normalizeDate(value) : value,
    });
  };

  customFilter = (option, searchText) =>
    (option.data.alias &&
      option.data.alias.toLowerCase().includes(searchText.toLowerCase())) ||
    option.data.label.toLowerCase().includes(searchText.toLowerCase());

  collectFormError() {
    const { signupFormErrors } = this.props;

    // no error
    if (signupFormErrors.size === 0) return;

    const errorKeys = Object.keys(signupFormErrors)
      .join(', ')
      .replace('_', ' ')
      .replace(
        'TOS_agree_yn',
        'Terms and Conditions and Privacy Policy Agreement',
      );
    this.setState({ formErrorMessage: `Please complete ${errorKeys}` });
  }

  render() {
    const {
      handleSubmit,
      emailError,
      companies,
      statuses,
      intl,
      isAbbreviatedSignup,
    } = this.props;
    const {
      gender,
      acrc,
      loading,
      errorMessage,
      formErrorMessage,
      successMessage,
    } = this.state;

    const parseError = () => {
      if(errorMessage.toLowerCase() === 'email-exists') {
        return <FormattedHTMLMessage tagName="span" {...messages.emailError}/>
      } else if (errorMessage.toLowerCase() === 'azure-profile-id' && BBDBAP) {
        return <FormattedHTMLMessage tagName="span" {...messages.azureProfileIdExists}/>
      } else if (errorMessage.toLowerCase() === 'azure-profile-id' && BBDEAP) {
        return <FormattedHTMLMessage tagName="span" {...messages.azureProfileIdExistsEap}/>
      } else if (errorMessage.toLocaleLowerCase() === 'azure-profile-id') {
        return <FormattedHTMLMessage tagName="span" {...messages.emailError}/>;
      } else {
        return errorMessage;
      }
    };

    return (
      <div>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <FormWrapper id="signupForm">
            {!isAbbreviatedSignup && (
              <InputRow>
                <Field
                  label={<FormattedMessage {...messages.firstName} />}
                  placeholder="John"
                  component={TextField}
                  validate={validateRequired}
                  name="first_name"
                />
                <Field
                  label={<FormattedMessage {...messages.lastName} />}
                  placeholder="Doe"
                  component={TextField}
                  validate={validateRequired}
                  name="last_name"
                />
              </InputRow>
            )}
            {!isAbbreviatedSignup && (
              <RadioButtonRow>
                <Label>{<FormattedMessage {...messages.gender} />}</Label>
                <Tooltip>
                  <QuestionMarkWrapper src={QuestionMark} alt="More info" />
                  <TooltipText>
                    {<FormattedMessage {...messages.genderTooltip} />}
                  </TooltipText>
                </Tooltip>
                <Field
                  component={RadioButton}
                  name="gender"
                  onChange={this.handleChange}
                  options={[
                    {
                      label: <FormattedMessage {...messages.male} />,
                      value: 'male',
                    },
                    {
                      label: <FormattedMessage {...messages.female} />,
                      value: 'female',
                    },
                    {
                      label: <FormattedMessage {...messages.custom} />,
                      value: 'custom',
                    },
                    {
                      label: <FormattedMessage {...messages.unspecified} />,
                      value: 'unspecified',
                    },
                  ]}
                  validate={[validateRequired]}
                />
              </RadioButtonRow>
            )}
            {gender === 'custom' ? (
              <InputRow>
                <Field
                  label={<FormattedMessage {...messages.custom} />}
                  component={TextField}
                  validate={[validateRequired]}
                  bError={emailError}
                  name="custom"
                  id="custom"
                />
              </InputRow>
            ) : null}
            {!isAbbreviatedSignup && (
              <InputRow>
                <Field
                  label={<FormattedMessage {...messages.email} />}
                  component={TextField}
                  validate={[validateRequired, validateEmail]}
                  bError={emailError}
                  name="email"
                />
                <Field
                  label={<FormattedMessage {...messages.dob} />}
                  component={TextField}
                  validate={[validateRequired, validateBirthday]}
                  normalize={normalizeDate}
                  placeholder={intl.formatMessage(messages.dobPlaceholder)}
                  name="dob"
                />
              </InputRow>
            )}
            <InputRow>
              {!acrc && (
                <Field
                  label={<FormattedMessage {...messages.company} />}
                  component={SelectInput}
                  isSearchable
                  options={companies.toJS()}
                  name="company"
                  placeholder="Search"
                  onInputChange={newQuery => this.searchCompanies(newQuery)}
                  filterOption={this.customFilter}
                  validate={!acrc && [validateRequired]}
                />
              )}
              <Field
                label={<FormattedMessage {...messages.status} />}
                component={SelectInput}
                options={statuses}
                name="status"
                validate={[validateRequired]}
              />
            </InputRow>
            {this.belongsToUS() && (
              <InputRow>
                <Field
                  label="Country"
                  component={SelectInput}
                  options={[
                    {
                      label: 'Canada',
                      value: 'CAN',
                    },
                    {
                      label: 'United States',
                      value: 'USA',
                    },
                  ]}
                  validate={[validateRequired]}
                  name="country"
                />
              </InputRow>
            )}
            <InputRow>
              <Field
                label={<FormattedMessage {...messages.password} />}
                component={PasswordInput}
                validate={[validateRequired, validatePasswordLength]}
                name="password"
              />
              <Field
                label={<FormattedMessage {...messages.confirmPassword} />}
                component={PasswordInput}
                validate={[validateRequired, validatePasswordsMatch]}
                name="confirm_password"
              />
            </InputRow>
            <CheckboxWrapper>
              <Field
                component={SingleCheckBox}
                name="TOS_agree_yn"
                label={
                  <FormattedMessage
                    {...messages.TOSAgree}
                    values={{
                      conditions: (
                        <a href="/conditions" target="_blank">
                          Terms and Conditions{' '}
                        </a>
                      ),
                      privacy: (
                        <a href="/privacy" target="_blank">
                          Privacy Policy
                        </a>
                      ),
                    }}
                  />
                }
                validate={[validateRequired]}
              />
              <Field
                component={SingleCheckBox}
                name="newsletter_yn"
                label={<FormattedMessage {...messages.newsletterAgree} />}
              />
            </CheckboxWrapper>
          </FormWrapper>
          <Center>
            <SpinnerButton
              type="submit"
              label={<FormattedMessage {...messages.register} />}
              people
              style={{ marginBottom: 30 }}
              loading={loading}
              onClick={this.collectFormError()}
              aria-label={successMessage || formErrorMessage}
            />
            <P color={errorRed}>{parseError()}</P>
            <Body style={{ fontSize: '18px' }}>
              <FormattedMessage
                {...messages.alreadyHaveAccount}
                values={{
                  item: (
                    <Link to="/signin">
                      <FormattedMessage {...messages.signIn} />
                    </Link>
                  ),
                }}
              />
            </Body>
          </Center>
        </form>
      </div>
    );
  }
}

SignupForm.propTypes = {
  statuses: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  usCompanies: PropTypes.array.isRequired,
  intl: intlShape,
  isAbbreviatedSignup: PropTypes.bool,
};

function mapStateToProps(state) {
  const selector = formValueSelector('SignupForm');
  const sagaMapStateToProps = createStructuredSelector({
    companies: makeSelectCompanies(),
    emailError: makeSelectEmailError(),
    locale: makeSelectLocale(),
  })(state);
  return {
    company: selector(state, 'company'),
    country: selector(state, 'country'),
    signupFormErrors: getFormSyncErrors('SignupForm')(state),
    ...sagaMapStateToProps,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    signUp: (values, url, callback, errCallback) =>
      dispatch(signUp(values, url, callback, errCallback)),
    searchCompanies: (query, planType) =>
      dispatch(searchCompanies(query, planType)),
    clearCompanies: () => dispatch(clearCompanies()),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect)(
  withRouter(
    reduxForm({
      form: 'SignupForm',
      enableReinitialize: true,
    })(injectIntl(SignupForm)),
  ),
);
