import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { InkblotTheme, Button } from 'inkblot-ui';
import styled from 'styled-components';
import { reduxForm, Field } from 'redux-form/immutable';
import { FormattedMessage } from 'react-intl';

import AssessmentLayout from 'components/AssessmentLayout';
import Ors from 'components/Ors';
import Anchor from 'components/Button';
import H1 from 'components/H1';
import H3 from 'components/H3';
import P from 'components/P';

import { singleSelectAnswer } from '../naviHelpers';
import { updateAssessment } from '../actions';
import messages from '../messages';

const Description = styled.div`
  margin: 50px 0;
  display: flex;
  flex-direction: column;
  h1 {
    margin-bottom: 5px;
  }
`;

class ORS extends React.PureComponent {
  onSubmit = values => {
    const { fieldName, currentAssessment, callback } = this.props;
    const value = values.get(fieldName);

    if (!currentAssessment.get('id')) {
      this.props.goToErrorScreen();
    }

    if (value !== undefined) {
      const answer = Object.values(values.toJS());
      this.props.updateAssessment(
        currentAssessment.get('id'),
        fieldName,
        answer[0],
        this.props.paths,
        this.props.currentScreen,
        (assessment, error) => {
          if (!error && assessment && assessment.id) {
            if (callback) callback(assessment);
            this.props.goToNextPage();
          } else {
            this.props.goToErrorScreen();
          }
        },
      );
    }
  };

  onPrevious = () => {
    this.props.goToPrevPage();
  };

  render() {
    const {
      openExitDialog,
      handleSubmit,
      percent,
      fieldName,
      title,
      question,
      orsMainTitle,
      orsSubTitle,
    } = this.props;
    return (
      <AssessmentLayout
        page={fieldName}
        sidebar
        percent={percent}
        openExitDialog={openExitDialog}
      >
        <H1 fontSize="38px">{title}</H1>
        <P fontSize="18px">{question}</P>
        <Description>
          <H3>{orsMainTitle}</H3>
          <P fontSize="18px">{orsSubTitle}</P>
        </Description>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <Field component={Ors} name={fieldName} />
          <InkblotTheme>
            <Button
              people
              label={<FormattedMessage {...messages.next} />}
              style={{ textTransform: 'uppercase', margin: '100px 0 20px 0' }}
              type="submit"
            />
          </InkblotTheme>
          <Anchor onClick={this.onPrevious}>
            <FormattedMessage {...messages.previous} />
          </Anchor>
        </form>
      </AssessmentLayout>
    );
  }
}

ORS.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  percent: PropTypes.number.isRequired,
  openExitDialog: PropTypes.func.isRequired,
  goToNextPage: PropTypes.func.isRequired,
  goToPrevPage: PropTypes.func.isRequired,
  goToErrorScreen: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  question: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  orsMainTitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
    .isRequired,
  orsSubTitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
    .isRequired,
  currentAssessment: PropTypes.object,
  updateAssessment: PropTypes.func,
  callback: PropTypes.func,
  paths: PropTypes.object,
  currentScreen: PropTypes.string,
};

function validate(values, ownProps) {
  const errors = {};
  const val = Number.parseInt(values.get(ownProps.fieldName), 10);
  if (!Number.isInteger(val)) {
    errors[ownProps.fieldName] = 'Please complete';
  }
  return errors;
}

function mapStateToProps(state, ownProps) {
  const answer = singleSelectAnswer(
    ownProps.currentAssessment,
    ownProps.fieldName,
  );
  return {
    form: `${ownProps.fieldName}Form`,
    initialValues: {
      [ownProps.fieldName]: typeof answer === 'number' ? answer : 0,
    },
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateAssessment: (
      id,
      questionCode,
      answers,
      currentScreen,
      paths,
      callback,
    ) =>
      dispatch(
        updateAssessment(
          id,
          questionCode,
          answers,
          currentScreen,
          paths,
          callback,
        ),
      ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm({ enableReinitialize: true, validate })(ORS));
