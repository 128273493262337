import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { reduxForm, Field } from 'redux-form/immutable';
import { connect } from 'react-redux';
import { InkblotTheme, Button } from 'inkblot-ui';
import { FormattedMessage } from 'react-intl';

import H1 from 'components/H1';
import H3 from 'components/H3';
import P from 'components/P';
import Anchor from 'components/Button';
import MultiSelectComponent from 'components/MultiSelect';
import AssessmentLayout from 'components/AssessmentLayout';

import messages from '../messages';
import { updateAssessment } from '../actions';
import { multiSelectAnswers } from '../naviHelpers';
import { tabletSize } from '../../../global-styles';

const Description = styled.div`
  margin: 50px 0;
  display: flex;
  flex-direction: column;
  h3 {
    margin-bottom: 10px;
  }
  @media (max-width: ${tabletSize}) {
    margin: 40px 0;
  }
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 70px;
`;

class MultiSelect extends React.PureComponent {
  onSubmit = values => {
    const { callback } = this.props;
    const jsValues = values.toJS();
    const answers = Object.keys(jsValues).filter(jvalue => jsValues[jvalue]);

    if (!this.props.currentAssessment.get('id')) {
      this.props.goToErrorScreen();
    }

    this.props.updateAssessment(
      this.props.currentAssessment.get('id'),
      this.props.fieldName,
      answers,
      this.props.paths,
      this.props.currentScreen,
      (assessment, error) => {
        if (!error && assessment && assessment.id) {
          if (callback) callback(assessment);
          this.props.goToNextPage();
        } else {
          this.props.goToErrorScreen();
        }
      },
    );
  };

  onPrevious = () => {
    this.props.goToPrevPage();
  };

  render() {
    const {
      openExitDialog,
      handleSubmit,
      percent,
      fieldName,
      options,
      small,
      narrow,
      title,
      description,
      question,
      enablePrevious,
    } = this.props;

    return (
      <AssessmentLayout
        page={fieldName}
        sidebar
        percent={percent}
        openExitDialog={openExitDialog}
      >
        <H1 fontSize="38px">{title}</H1>
        <Description>
          <H3>{question}</H3>
          <P fontSize="18px">{description}</P>
        </Description>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <Center>
            <Field
              component={MultiSelectComponent}
              name={fieldName}
              small={small}
              narrow={narrow}
              options={options}
            />
          </Center>
          <InkblotTheme>
            <Button
              people
              label={<FormattedMessage {...messages.next} />}
              style={{
                textTransform: 'uppercase',
              }}
              type="submit"
            />
          </InkblotTheme>
          {enablePrevious && (
            <Anchor onClick={this.onPrevious}>
              <FormattedMessage {...messages.previous} />
            </Anchor>
          )}
        </form>
      </AssessmentLayout>
    );
  }
}

MultiSelect.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  percent: PropTypes.number.isRequired,
  openExitDialog: PropTypes.func.isRequired,
  goToNextPage: PropTypes.func.isRequired,
  goToPrevPage: PropTypes.func.isRequired,
  goToErrorScreen: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  small: PropTypes.bool,
  narrow: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  question: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  description: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
    .isRequired,
  updateAssessment: PropTypes.func,
  currentAssessment: PropTypes.object,
  callback: PropTypes.func,
  paths: PropTypes.object,
  currentScreen: PropTypes.string,
  enablePrevious: PropTypes.bool,
};

MultiSelect.defaultProps = {
  enablePrevious: true,
};

function mapStateToProps(state, ownProps) {
  const answers = multiSelectAnswers(
    ownProps.currentAssessment,
    ownProps.fieldName,
  );

  return {
    form: `${ownProps.fieldName}Form`,
    initialValues: {
      ...answers,
    },
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateAssessment: (
      id,
      questionCode,
      answers,
      currentScreen,
      paths,
      callback,
    ) =>
      dispatch(
        updateAssessment(
          id,
          questionCode,
          answers,
          currentScreen,
          paths,
          callback,
        ),
      ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm({ enableReinitialize: true })(MultiSelect));
