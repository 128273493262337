/*
 * CriticalIncidentServices Messages
 *
 * This contains all the text for the CriticalIncidentServices container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.CriticalIncidentServices';

export default defineMessages({
  critServTitle: {
    id: `${scope}.critServTitle`,
    defaultMessage: 'Critical incident support services ',
  },
  critServSubtitle: {
    id: `${scope}.critServSubtitle`,
    defaultMessage:
      'Supporting employee health in times of workplace disruption',
  },
  announcement: {
    id: `${scope}.announcement`,
    defaultMessage:
      'We are pleased to announce that for the first time in the Canadian market, People Connect EAP will now include behavioral health support from R3 Continuum, the global leader in the management of disruptive events and critical incidents around the world.',
  },
  criticalIncidentSupport: {
    id: `${scope}.criticalIncidentSupport`,
    defaultMessage:
      'Critical Incident Support Services can help prevent long-term psychological issues like anxiety, stress, and depression, while improving employees’ ability to return to optimal levels of psychological wellness and productivity in their work and personal lives, and providing them with tools and skills to cope if another critical incident occurs.',
  },
  traumaInformedDebriefings: {
    id: `${scope}.traumaInformedDebriefings`,
    defaultMessage:
      'With our enhanced trauma-informed critical incident debriefings, employees will receive holistic, specialized services designed to not only address the immediate impacts of a critical incident, but also the aftereffects and potential need for ongoing psychological intervention.',
  },
  traumaInformedFollowUp: {
    id: `${scope}.traumaInformedFollowUp`,
    defaultMessage:
      'A unique feature of our approach is trauma-informed follow-up for each participant, and employees can receive ongoing trauma-informed support beyond the initial intervention as required.',
  },
  workplaceDisruptionsTypes: {
    id: `${scope}.workplaceDisruptionsTypes`,
    defaultMessage: '3 types of workplace disruptions you may experience:',
  },
  acuteDisruptions: {
    id: `${scope}.acuteDisruptions`,
    defaultMessage: 'Acute Disruptions',
  },
  whetherTheDisruption: {
    id: `${scope}.whetherTheDisruption`,
    defaultMessage:
      'Whether the disruption is expected or unexpected, acute disruptions are singular events that can have a serious impact on your employees’ morale, wellbeing, and performance—as well as the organization’s ability to recover from the impact.',
  },
  deathOfACoworker: {
    id: `${scope}.deathOfACoworker`,
    defaultMessage:
      'Examples: Death of a co-worker/family member, layoffs/reductions in workforce, traumatic injury, workplace violence, robbery.',
  },
  criticalIncidents: {
    id: `${scope}.criticalIncidents`,
    defaultMessage: 'Critical Incidents',
  },
  criticalIncidentsTend: {
    id: `${scope}.criticalIncidentsTend`,
    defaultMessage:
      'Critical incidents tend to be larger-scale and are often featured on headline news. Whether your workplace is directly or indirectly affected, these incidents can present a complex and multi-level impact to your employees and entire organization. Obtaining group, individual, and leadership support from experts who understand the intricacies of navigating and responding to high-profile disruptions is key to ensuring your employees and organization recover effectively.',
  },
  naturalDisasters: {
    id: `${scope}.naturalDisasters`,
    defaultMessage:
      'Examples: Natural disasters, terrorist attacks, violence, civil/political unrest, pandemic',
  },
  systemicDisruptions: {
    id: `${scope}.systemicDisruptions`,
    defaultMessage: 'Systemic Disruptions',
  },
  systemicDisruptionsInvolve: {
    id: `${scope}.systemicDisruptionsInvolve`,
    defaultMessage:
      'Systemic disruptions involve employees’ exposure to ongoing workplace disruption and intensive stress. Systemic disruption can result in burnout, depression, anxiety, and more. We offer proven solutions to mitigate the human and organizational impact of systemic disruption so people and organizations can thrive.',
  },
  systemicStress: {
    id: `${scope}.systemicStress`,
    defaultMessage:
      'Examples: Systemic stress/burnout, layoffs, re-structures, re-organizations, multiple disruptions over a short period of time.',
  },
  traumaInformedFollowUpHeading: {
    id: `${scope}.traumaInformedFollowUpHeading`,
    defaultMessage:
      'Looking beyond the initial intervention: Trauma-informed Critical Incident Follow-up',
  },
  traumaInformedFollowUpDetails: {
    id: `${scope}.traumaInformedFollowUpDetails`,
    defaultMessage:
      'Roughly 1/3 of employees who have experienced a trauma will need support days or weeks following the debriefing. They may not have had enough time to process by the time of the debriefing, and won’t realize they are struggling until things have had time to “set in."',
  },
  traumaInformedFollowUpDetails2: {
    id: `${scope}.traumaInformedFollowUpDetails2`,
    defaultMessage:
      'As soon as possible following the initial outreach for support, a highly trained and specialized critical incident counsellor will meet with impacted employees. Within 30 days of the debriefing, employees who have given consent will receive two 15-minute follow-up outreaches.',
  },
  traumaInformedFollowUpDetails3: {
    id: `${scope}.traumaInformedFollowUpDetails3`,
    defaultMessage:
      'Clinical impact will be evaluated using evidence-based and validated outcome measurement tools. This unique program, providing timely, trauma-informed, high quality clinical debriefing support followed by clinical follow-ups, will reach more employees in need and improve workplace resiliency and psychological wellness.',
  },
  traumaInformedFollowUpDetails4: {
    id: `${scope}.traumaInformedFollowUpDetails4`,
    defaultMessage:
      'Employees will be asked to give their consent to receiving a future follow-up outreach with the same counsellor who is providing the debriefing. Included in the price of the intervention, this is a key differentiator for this product as it carries the employees well past the initial debriefing and ensures continuity of care for any emerging or ongoing issues related to the critical incident. If it is discovered that the employee is still or newly suffering because of the trauma, triaging to specialized, ongoing support will be provided, with prior approval.',
  },
  clinicalImpactAssessment: {
    id: `${scope}.clinicalImpactAssessment`,
    defaultMessage: 'Unique Feature: Clinical Impact Assessment',
  },
  clinicalImpactAssessmentDetails: {
    id: `${scope}.clinicalImpactAssessmentDetails`,
    defaultMessage:
      'This program will evaluate clinical impact using evidence-based and validated outcome measurement tools. At the beginning of the debriefing, employees will be asked to answer a confidential check-in on how they’re doing. The same check-in will be conducted again at the end of the session. The answers to these screening questions will provide insight into the employee’s current psychological state and help to direct the counsellor’s approach during and following the intervention.',
  },
  feesHeading: {
    id: `${scope}.feesHeading`,
    defaultMessage: 'Fees',
  },
  disruptionFees: {
    id: `${scope}.disruptionFees`,
    defaultMessage:
      'Acute Disruptions, Critical Incidents, and Systemic Disruptions, including Trauma-informed Follow-up',
  },
  disruptionFeesDetails: {
    id: `${scope}.disruptionFeesDetails`,
    defaultMessage:
      '$295 CDN per hour, per debriefer; 4-hour minimum including follow-up calls.',
  },
  travelFeesDetails: {
    id: `${scope}.travelFeesDetails`,
    defaultMessage:
      'Travel is charged at cost. Travel time is $100 per hour after the initial hour. After the first 60 km, $0.68/KM is charged. Hotels, airfare, other expenses will be charged subject to approval.',
  },
  traumaSupportFees: {
    id: `${scope}.traumaSupportFees`,
    defaultMessage: 'Ongoing Trauma-informed Support (Fee-for-service)',
  },
  traumaSupportFeesDetails: {
    id: `${scope}.traumaSupportFeesDetails`,
    defaultMessage:
      '$250 per hour for ongoing trauma support. Subject to pre-approval. If needed and identified through validated measures during follow-up we can offer ongoing trauma-informed specialized therapy. Services are provided by a unique and senior network of specialists who have completed Trauma Informed training and who understand the specific needs of those who have experienced a disruptive event and who may need immediate and specialized care. ',
  },
  traumaSupportFeesDetails2: {
    id: `${scope}.traumaSupportFeesDetails2`,
    defaultMessage:
      'This highly specialized counselling is separate from the therapy included in the People Connect EAP network, and may be eligible for reimbursement under paramedical benefits coverage.',
  },
  contactDetails: {
    id: `${scope}.contactDetails`,
    defaultMessage: 'Helping workplaces recover by putting people first.',
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: "Email: <a href='mailto:info@R3c.com'>info@R3c.com</a>",
  },
  telephone: {
    id: `${scope}.telephone`,
    defaultMessage: 'Telephone: 1-855-588-7590',
  },
  helpingWorkplaces: {
    id: `${scope}.helpingWorkplaces`,
    defaultMessage: ' Helping workplaces recover by putting people first.',
  },
  theseAreServices: {
    id: `${scope}.theseAreServices`,
    defaultMessage:
      'These are services available to groups of impacted employees at the workplace. If your workplace has experienced an incident a people leader or HR Representative may explore these services as a response.',
  },
  titleBap: {
    id: `${scope}.titleBap`,
    defaultMessage: 'Critical incident support services',
  },
  subtitleBap: {
    id: `${scope}.subtitleBap`,
    defaultMessage:
      '<i>Supporting employee health in times of workplace disruption</i>',
  },
  body1: {
    id: `${scope}.body1`,
    defaultMessage:
      'We are pleased to announce that for the first time in the Canadian market, People Connect BAP will now include behavioral health support from R3 Continuum, the global leader in the management of disruptive events and critical incidents around the world.',
  },
  body2: {
    id: `${scope}.body2`,
    defaultMessage:
      'Critical incident support services can help prevent long-term psychological issues, like anxiety, stress, and depression, and improve employees’ ability to return to optimal levels of psychological wellness and productivity in their work and personal lives, and provide them with tools and skills to cope if another critical incident occurs.',
  },
  body3: {
    id: `${scope}.body3`,
    defaultMessage:
      'With our enhanced trauma-informed critical incident debriefings, employees will receive holistic, specialized services designed to not only address the immediate impacts of a critical incident, but also the aftereffects and potential need for ongoing psychological intervention.',
  },
  body4: {
    id: `${scope}.body4`,
    defaultMessage:
      'A unique feature of our approach is trauma-informed follow-up for each participant.  Employees can receive ongoing support beyond the initial intervention as required.',
  },
  body5: {
    id: `${scope}.body5`,
    defaultMessage: 'Three types of workplace disruptions you may experience:',
  },
  body6: {
    id: `${scope}.body6`,
    defaultMessage: '<b>Acute disruptions</b>',
  },
  body7: {
    id: `${scope}.body7`,
    defaultMessage:
      'Whether the disruption is expected or unexpected, acute disruptions are singular events that can have a serious impact on your employees’ morale, wellbeing, and performance—as well as the organization’s ability to recover from the impact. <br/>Examples: Death of a co-worker/family member, layoffs/reductions in workforce, traumatic injury, workplace violence, robbery',
  },
  body8: {
    id: `${scope}.body8`,
    defaultMessage: '<b>Critical incidents</b>',
  },
  body9: {
    id: `${scope}.body9`,
    defaultMessage:
      'Critical incidents tend to be larger-scale and are often featured on headline news. Whether your workplace is directly or indirectly affected, these incidents can present a complex and multi-level impact to your employees and entire organization. Obtaining group, individual, and leadership support from experts who understand the intricacies of navigating and responding to high-profile disruptions is key to ensuring your employees and organization recover effectively. <br/><br/>Examples: Natural disasters, terrorist attacks, violence, civil/political unrest, pandemic ',
  },
  body10: {
    id: `${scope}.body10`,
    defaultMessage: '<b>Systemic disruptions</b>',
  },
  body11: {
    id: `${scope}.body11`,
    defaultMessage:
      'Systemic disruptions involve employees’ exposure to ongoing workplace disruption and intensive stress. Systemic disruption can result in burnout, depression, anxiety, and more. We offer proven solutions to mitigate the human and organizational impact of systemic disruption so people and organizations can thrive. <br/><br/>Examples: Systemic stress/burnout, layoffs, re-structures, re-organizations, multiple disruptions over a short period of time',
  },
  body12: {
    id: `${scope}.body12`,
    defaultMessage: '<i>Looking beyond the initial intervention:</i>',
  },
  body13: {
    id: `${scope}.body13`,
    defaultMessage: '<b>Trauma-informed Critical Incident Follow-up</b>',
  },
  body14: {
    id: `${scope}.body14`,
    defaultMessage:
      'Roughly one-third of employees who have experienced a trauma will need support days or weeks following the debriefing. They may not have had enough time to process by the time of the debriefing, and won’t realize they are struggling until things have had time to “set in."',
  },
  body15: {
    id: `${scope}.body15`,
    defaultMessage:
      'As soon as possible following the initial outreach for support, a highly trained and specialized critical incident counsellor will meet with impacted employees. Within 30 days of the debriefing, employees who have given consent will receive two 15-minute follow-up outreaches.',
  },
  body16: {
    id: `${scope}.body16`,
    defaultMessage:
      'Clinical impact will be evaluated using evidence-based and validated outcome measurement tools. This unique program, providing timely, trauma-informed, high quality clinical debriefing support followed by clinical follow-ups, will reach more employees in need and improve workplace resiliency and psychological wellness.',
  },
  body17: {
    id: `${scope}.body17`,
    defaultMessage:
      'Employees will be asked to give their consent to receiving a future follow-up outreach with the same counsellor who is providing the debriefing. Included in the price of the intervention, this is a key differentiator for this product as it carries the employees well past the initial debriefing and ensures continuity of care for any emerging or ongoing issues related to the critical incident. If it is discovered that the employee is still or newly suffering because of the trauma, triaging to specialized, ongoing support will be provided, with prior approval.',
  },
  body18: {
    id: `${scope}.body18`,
    defaultMessage: '<b>Unique feature: Clinical impact assessment</b>',
  },
  body19: {
    id: `${scope}.body19`,
    defaultMessage:
      'This program will evaluate clinical impact using evidence-based and validated outcome measurement tools. At the beginning of the debriefing, employees will be asked to answer a confidential check-in on how they’re doing. The same check-in will be conducted again at the end of the session. The answers to these screening questions will provide insight into the employees’ current psychological state and help to direct the counsellor’s approach during and following the intervention.',
  },
  body20: {
    id: `${scope}.body20`,
    defaultMessage:
      '<b>Helping workplaces recover by putting people first.</b>',
  },
  body21: {
    id: `${scope}.body21`,
    defaultMessage:
      'These are services available to groups of impacted employees at the workplace. If your workplace has experienced an incident, a people leader or HR Representative may explore these services as a response.',
  },
  body22: {
    id: `${scope}.body22`,
    defaultMessage: '<b>Fees:</b>',
  },
  body23: {
    id: `${scope}.body23`,
    defaultMessage:
      '<b>Acute disruptions, critical incidents, and systemic disruptions, including trauma-informed follow-up</b>',
  },
  body24: {
    id: `${scope}.body24`,
    defaultMessage:
      '$295 per hour, per debriefer; 4-hour minimum including follow-up calls.',
  },
  body25: {
    id: `${scope}.body25`,
    defaultMessage:
      'Travel is charged at cost. Travel time is $100 per hour after the initial hour. After the first 60 km, $0.68/km is charged. Hotels, airfare, other expenses will be charged subject to approval.',
  },
  body26: {
    id: `${scope}.body26`,
    defaultMessage: '<b>Ongoing trauma-informed support (fee-for-service)</b>',
  },
  body27: {
    id: `${scope}.body27`,
    defaultMessage:
      '$250 per hour for ongoing trauma support. Subject to pre-approval. If needed and identified through validated measures during follow-up we can offer ongoing trauma-informed specialized therapy. Services are provided by a unique and senior network of specialists who have completed trauma informed training and who understand the specific needs of those who have experienced a disruptive event and who may need immediate and specialized care.',
  },
  body28: {
    id: `${scope}.body28`,
    defaultMessage:
      'This highly specialized counselling is separate from the therapy included in the People Connect network and may be eligible for reimbursement under paramedical benefits coverage.',
  },
  body29: {
    id: `${scope}.body29`,
    defaultMessage:
      '<b>Helping workplaces recover by putting people first.</b>',
  },
  body30: {
    id: `${scope}.body30`,
    defaultMessage: 'Email: <a href="mailto:info@R3c.com">info@R3c.com</a>',
  },
  body31: {
    id: `${scope}.body31`,
    defaultMessage: 'Telephone: 1-855-588-7590 (24/7 live call answer)',
  },
});
