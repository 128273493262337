import styled from 'styled-components';
import { phoneSize, tabletSize } from 'global-styles';

const Card = styled.div`
  margin-top: 32px;
  background-color: #ffffff;
  width: 100%;
  box-shadow: 0 0 14px 0 rgba(11, 118, 188, 0.18);
  padding: 32px 30px;
  margin-bottom: 40px;
  color: #444e69;

  .headerImg {
    width: 100%;
  }

  .logo {
    width: 200px;
    margin-bottom: 40px;
  }

  h1 {
    font-size: 28px;
    padding: 12px 0px;
    color: #1f275b;
    margin-bottom: 0px;
  }

  h2 {
    font-size: 18px;
    font-weight: bold;
  }

  h3 {
    font-size: 18px;
    color: #1f275b;
    font-weight: bold;
  }

  h4 {
    font-size: 18px;
    font-weight: bold;
  }

  p {
    font-size: 18px;
  }

  li {
    font-size: 18px;
  }

  @media (max-width: ${tabletSize}) {
    margin-bottom: 175px;
  }

  @media (max-width: ${phoneSize}) {
    margin-bottom: 100px;
  }
`;

export default Card;
