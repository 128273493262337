/**
 *
 * PanelCard
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import Wrapper from './Wrapper';
import Image from './Image';
import Content from './Content';

function PanelCard(props) {
  return (
    <Wrapper
      width={props.width}
      id={props.id}
      style={props.style}
      minHeight={props.minHeight}
      className={props.className}
      locale={props.locale}
    >
      {props.img ? (
        <Image imgHeight={props.imgHeight}>
          <img src={props.img} alt={props.alt} />
        </Image>
      ) : (
        <div
          style={{
            height: props.imgHeight,
            width: '100%',
            backgroundColor: '#2256aa',
          }}
        />
      )}

      <Content height={contentHeight(props.imgHeight)}>
        {props.children}
      </Content>
    </Wrapper>
  );
}

const contentHeight = imgHeight => {
  if (imgHeight !== undefined) {
    return `${100 - parseFloat(imgHeight)}%`;
  }
  return '66%';
};

PanelCard.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
  id: PropTypes.string,
  img: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
  imgHeight: PropTypes.string,
  width: PropTypes.string,
  minHeight: PropTypes.string,
  locale: PropTypes.string,
};

export default PanelCard;
