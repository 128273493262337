/*
 *
 * UpdateProvider reducer
 *
 */

import { fromJS } from 'immutable';
import {
  FETCH_USER_SETTINGS,
  FETCH_USER_SETTINGS_SUCCESS,
  FETCH_USER_SETTINGS_ERROR,
  UPDATE_USER_SETTINGS,
  UPDATE_USER_SETTINGS_SUCCESS,
  UPDATE_USER_SETTINGS_ERROR,
} from './constants';

export const initialState = fromJS({
  settings: {},
  loading: false,
});

function settingsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_USER_SETTINGS:
    case UPDATE_USER_SETTINGS:
      return state.set('loading', true);
    case FETCH_USER_SETTINGS_ERROR:
    case UPDATE_USER_SETTINGS_ERROR:
      return state.set('loading', false).set('error', action.error);
    case FETCH_USER_SETTINGS_SUCCESS:
    case UPDATE_USER_SETTINGS_SUCCESS:
      return state
        .set('loading', false)
        .mergeDeep({ settings: action.settings });
    default:
      return state;
  }
}

export default settingsReducer;
