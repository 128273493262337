import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import ArticleBody from '../ArticleBody';
import messages from '../../../messages';
const RoleDietPlays = () => (
  <div>
    <ArticleBody>
      <FormattedHTMLMessage tagName="p" {...messages.roleDietPlaysBody} />
    </ArticleBody>
  </div>
);

RoleDietPlays.propTypes = {};

export default RoleDietPlays;
