import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the assessment state domain
 */

const selectAssessmentDomain = state => state.get('assessment', initialState);

const makeSelectCurrentAssessment = () =>
  createSelector(selectAssessmentDomain, substate =>
    substate.get('currentAssessment'),
  );

const makeSelectCurrentAssessmentLoaded = () =>
  createSelector(selectAssessmentDomain, substate =>
    substate.get('currentAssessmentLoaded'),
  );

const makeSelectReport = () =>
  createSelector(selectAssessmentDomain, substate => substate.get('report'));
/**
 * Other specific selectors
 */

const makeSelectEmailReport = () =>
  createSelector(selectAssessmentDomain, substate =>
    substate.get('emailReport').toJS(),
  );

/**
 * Default selector used by Assessment
 */

const makeSelectAssessment = () =>
  createSelector(selectAssessmentDomain, substate => substate.toJS());

export default makeSelectAssessment;
export {
  selectAssessmentDomain,
  makeSelectCurrentAssessment,
  makeSelectReport,
  makeSelectCurrentAssessmentLoaded,
  makeSelectEmailReport,
};
