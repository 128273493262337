import styled from 'styled-components';

const Card = styled.div`
  background-color: #ffffff;
  width: 100%;
  box-shadow: 0 0 14px 0 rgba(11, 118, 188, 0.18);
  padding: 40px;
  margin-bottom: 40px;
  word-break: break-word;
  h3.title {
    font-family: 'Montserrat';
    margin-bottom: 40px;
  }
  .email {
    margin-top: 20px;

    display: flex;
    align-items: center;
    img {
      height: 20px;
      width: 25px;
      margin-right: 20px;
    }
    a {
      font-size: 18px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.2px;
      color: #3c9dd7;
      margin: 0px;
    }
  }
`;

export default Card;
