import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import ArticleBody from '../ArticleBody';
import messages from '../../../messages';

const EchoPandemic = () => (
  <div>
    <ArticleBody>
      <FormattedHTMLMessage tagName="p" {...messages.echoPandemicBody} />
    </ArticleBody>
  </div>
);

EchoPandemic.propTypes = {};

export default EchoPandemic;
