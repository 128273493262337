/*
 * Dashboard Messages
 *
 * This contains all the text for the Dashboard container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Dashboard';

export default defineMessages({
  workHealthLife: {
    id: `${scope}.workHealthLife`,
    defaultMessage: 'EAP: Work, health and life services',
  },
  workHealthLifeBody: {
    id: `${scope}.workHealthLifeBody`,
    defaultMessage:
      'Match and video chat with a certified care provider who fits your needs',
  },
  dashboard: {
    id: `${scope}.dashboard`,
    defaultMessage: 'Dashboard',
  },
  mentalHealthAssessment: {
    id: `${scope}.mentalHealthAssessment`,
    defaultMessage: 'Mental health assessment',
  },
  answerAVariety: {
    id: `${scope}.answerAVariety`,
    defaultMessage:
      'Answer a variety of questions from which we can gather a snapshot of your mental health.',
  },
  startAssessment: {
    id: `${scope}.startAssessment`,
    defaultMessage: 'Start assessment',
  },
  virtualTherapy: {
    id: `${scope}.virtualTherapy`,
    defaultMessage: 'Virtual counselling',
  },
  matchAndVideo: {
    id: `${scope}.matchAndVideo`,
    defaultMessage:
      'Match and video chat with a certified care provider who fits you needs.',
  },
  moreInformation: {
    id: `${scope}.moreInformation`,
    defaultMessage: 'More information',
  },
  employeeResources: {
    id: `${scope}.employeeResources`,
    defaultMessage: 'Employee resources',
  },
  articlesAndPodcasts: {
    id: `${scope}.articlesAndPodcasts`,
    defaultMessage: 'Articles and podcasts, videos on mental health topics',
  },
  seeMore: {
    id: `${scope}.seeMore`,
    defaultMessage: 'See more',
  },
  trainingAndConsulting: {
    id: `${scope}.trainingAndConsulting`,
    defaultMessage: 'Training & consulting',
  },
  learnAbout: {
    id: `${scope}.learnAbout`,
    defaultMessage:
      'Learn about the kind of training and consulting we can offer your organization',
  },
  learnPlanSponsor: {
    id: `${scope}.learnPlanSponsor`,
    defaultMessage: 'Learn more about what you can do as a Plan Sponsor.',
  },
  peopleLeaderResources: {
    id: `${scope}.peopleLeaderResources`,
    defaultMessage: 'People leader resources',
  },
  planSponsorResources: {
    id: `${scope}.planSponsorResources`,
    defaultMessage: 'Plan sponsor resources',
  },
  videosOnManagement: {
    id: `${scope}.videosOnManagement`,
    defaultMessage: 'Article and podcasts, videos on management topics',
  },
  getStarted: {
    id: `${scope}.getStarted`,
    defaultMessage: 'Need help getting started?',
  },
  stepByStep: {
    id: `${scope}.stepByStep`,
    defaultMessage: 'Watch our Step by Step video guide.',
  },
  stepByStepEAP: {
    id: `${scope}.stepByStepEAP`,
    defaultMessage: 'Watch our Step by Step video guide.',
  },
  icbt: {
    id: `${scope}.icbt`,
    defaultMessage: 'iCBT Self-Help Learning',
  },
  icbtBody: {
    id: `${scope}.icbtBody`,
    defaultMessage:
      'Make lifestyle goals and behaviour change a reality with state-of-the-art CBT based courses.',
  },
  criticalIncidentTitle: {
    id: `${scope}.criticalIncidentTitle`,
    defaultMessage: 'Critical incident support services',
  },
  criticalIncidentBody: {
    id: `${scope}.criticalIncidentBody`,
    defaultMessage:
      'Supporting employee health in times of workplace disruption and critical incidents. (People leaders and plan sponsors only - fee for service) ',
  },
  criticalIncidentBodyBap: {
    id: `${scope}.criticalIncidentBodyBap`,
    defaultMessage:
      'Supporting employee health in times of workplace disruption and critical incidents (i.e. employee death, workplace accident or violence, fire, robbery, bankruptcy, etc). <br/><br/>Fee for service (initial consultation included)',
  },
  specServicesTitle: {
    id: `${scope}.specServicesTitle`,
    defaultMessage: 'Specialized services',
  },
  specServicesBody: {
    id: `${scope}.specServicesBody`,
    defaultMessage:
      'Provide your workforce with more support at any moment. Supplementary specialized care services for employee needs that expand beyond traditional counselling and EAP offerings are available upon request for the fees indicated below.',
  },
  managementConsultingTitle: {
    id: `${scope}.managementConsultingTitle`,
    defaultMessage: 'Management consultation',
  },
  managementConsultingTitleBap: {
    id: `${scope}.managementConsultingTitleBap`,
    defaultMessage: 'HR and management consultations',
  },
  managementConsultingBody: {
    id: `${scope}.managementConsultingBody`,
    defaultMessage:
      'Specialized support for communication and management of behavioral concerns of direct reports.',
  },
  managementConsultingBodyBap: {
    id: `${scope}.managementConsultingBodyBap`,
    defaultMessage:
      'Telephonic consultations for a wide range of people management issues. For business owners and those with direct reports.',
  },
  getStartedIcbt: {
    id: `${scope}.getStartedIcbt`,
    defaultMessage: 'Get started',
  },
  financialLegal: {
    id: `${scope}.financialLegal`,
    defaultMessage: 'Financial and legal advisory services',
  },
  financialLegalBody: {
    id: `${scope}.financialLegalBody`,
    defaultMessage:
      'As a business owner, obtain answers and recommendations to solve common legal and financial concerns from highly qualified professionals.',
  },
  businessAssistance: {
    id: `${scope}.businessAssistance`,
    defaultMessage: 'Business assistance program (BAP)',
  },
  businessAssistanceBody: {
    id: `${scope}.businessAssistanceBody`,
    defaultMessage:
      'Are you a business owner? Gain access to business support for common legal, financial and people management concerns.<br/><br/><b>To arrange a consultation, contact <a href="mailto:consultation@inkblottherapy.com">consultation@inkblottherapy.com</a> or call 1-855-933-0103.</b>',
  },
  carepath: {
    id: `${scope}.carepath`,
    defaultMessage: 'Carepath',
  },
  carepathBody: {
    id: `${scope}.carepathBody`,
    defaultMessage:
      'You may have access to Carepath which includes second opinion services, health navigation, and cancer assistance. <br/><br/><b><a href="https://peopleconnectcarepath.ca/#form">Access health care now</a><br/><br/>Or call 1-866-883-5956 to find out</b>',
  },
  secondOpinionServices: {
    id: `${scope}.secondOpinionServices`,
    defaultMessage: 'Second opinion services',
  },
  secondOpinionServicesBody: {
    id: `${scope}.secondOpinionServicesBody`,
    defaultMessage:
      'An expert review by a specialist to ensure that your recommended treatment plan is consistent with best clinical practice guidelines.',
  },
  cancerAssistance: {
    id: `${scope}.cancerAssistance`,
    defaultMessage: 'Cancer assistance',
  },
  cancerAssistanceBody: {
    id: `${scope}.cancerAssistanceBody`,
    defaultMessage:
      'Receive specialized support to ease the physical and emotional impact of cancer diagnosis and treatment.',
  },
  healthNavigation: {
    id: `${scope}.healthNavigation`,
    defaultMessage: 'Health navigation',
  },
  healthNavigationBody: {
    id: `${scope}.healthNavigationBody`,
    defaultMessage:
      'Receive support navigating the health care system, improving the quality of your care with emotional support along the way.',
  },
  mentalHealthSupport: {
    id: `${scope}.mentalHealthSupport`,
    defaultMessage: 'Mental health support',
  },
  mentalHealthSupportBody: {
    id: `${scope}.mentalHealthSupportBody`,
    defaultMessage:
      'A virtual mental health resource centre providing tools and services designed to support you and your family. Match and video chat with a certified care provider who fits your needs and preferences.',
  },
  getSupport: {
    id: `${scope}.getSupport`,
    defaultMessage: 'Get support',
  },
  getSupportBody: {
    id: `${scope}.getSupportBody`,
    defaultMessage:
      '<ul><li>Video chat with a certified care provider who fits your needs and preferences for mental health counselling (available to you and your family members).</li> <li>Speak with a financial and/or legal advisor on a variety of topics (available to you and your family members)</li></ul>',
  },
});
