import { setUserId, setUserProperties } from 'utils/amplitude';

import {
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_ERROR,
  UPDATE_USER,
  UPDATE_USER_ERROR,
  UPDATE_USER_SUCCESS,
  CONFIRM_EMAIL,
  CONFIRM_EMAIL_ERROR,
  CONFIRM_EMAIL_SUCCESS,
} from './constants';

export function fetchUser(callback) {
  return {
    type: FETCH_USER,
    callback,
  };
}

export function fetchUserSuccess(user) {
  setUserId(user.id);
  setUserProperties(user.user_properties);

  return {
    type: FETCH_USER_SUCCESS,
    user,
  };
}

export function fetchUserError(error) {
  return {
    type: FETCH_USER_ERROR,
    error,
  };
}

export function updateUser(user, callback) {
  return {
    type: UPDATE_USER,
    user,
    callback,
  };
}

export function updateUserSuccess(user) {
  return {
    type: UPDATE_USER_SUCCESS,
    user,
  };
}

export function updateUserError(error) {
  return {
    type: UPDATE_USER_ERROR,
    error,
  };
}

export function confirmEmail(data, callback) {
  return {
    type: CONFIRM_EMAIL,
    data,
    callback,
  };
}
export function confirmEmailSuccess(tokenInfo) {
  return {
    type: CONFIRM_EMAIL_SUCCESS,
    tokenInfo,
  };
}

export function confirmEmailError(error) {
  return {
    type: CONFIRM_EMAIL_ERROR,
    error,
  };
}
