import React from 'react';
import styled from 'styled-components';
import { FormattedHTMLMessage } from 'react-intl';
import TQuote from './top-quote.png';
import BQuote from './bottom-quote.png';
import BookGirl from './book-girl.png';
import messages from './messages';
import { web, tablet, phone } from '../sizes';

const ArashQuoteWrapper = styled.div`
  padding-left: 200px;
  padding-right: 200px;
  padding-bottom: 100px;
  display: flex;
  align-items: center;
  background-color: #f6f8fa;
  .quote {
    padding-top: 100px;
    width: 500px;
    position: relative;
    .top-quote,
    .bottom-quote {
      width: 70px;
      height: 50px;
      position: absolute;
    }
    .top-quote {
      top: 50px;
      left: -70px;
    }
    .bottom-quote {
      bottom: 40px;
      right: 0;
    }
    p {
      font-size: 20px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.35;
      letter-spacing: 0.1px;
      color: #1f275b;
      margin-bottom: 40px;
    }
    span {
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.7px;
      color: #3c9dd7;
      text-transform: uppercase;
      margin-top: 20px;
    }
  }
  .book-girl {
    margin-left: 200px;
    margin-top: -100px;
    margin-bottom: -200px;
    img {
      width: 438px;
      height: 671px;
      object-fit: contain;
    }
  }
  @media (max-width: ${web}) {
    padding-left: 70px;
    padding-right: 70px;
    p {
      font-size: 18px;
    }
    .book-girl {
      margin-left: 50px;
      margin-top: 50px;
      margin-bottom: 0px;
      img {
        width: 377px;
        height: 577px;
      }
    }
  }
  @media (max-width: ${tablet}) {
    .quote {
      p {
        font-size: 16px;
      }
      .bottom-quote,
      .top-quote {
        width: 36.7px;
        height: 26px;
      }
    }
    .book-girl {
      margin-bottom: 0px;
      margin-top: 0px;
      margin-left: 50px;
      img {
        width: 263px;
        height: 403px;
      }
    }
  }
  @media (max-width: ${phone}) {
    padding-left: 40px;
    padding-right: 40px;
    .quote {
      .top-quote {
        left: -30px;
      }
    }
    .book-girl {
      display: none;
    }
  }
`;

const index = () => (
  <ArashQuoteWrapper>
    <div className="quote">
      <img className="top-quote" src={TQuote} alt="q" />
      <img className="bottom-quote" src={BQuote} alt="q" />
      <FormattedHTMLMessage tagName="p" {...messages.bossQuote} />
      <br />
      <span>- Arash Zohoor MD ccfp</span>
    </div>
    <div className="book-girl">
      <img src={BookGirl} alt="bg" />
    </div>
  </ArashQuoteWrapper>
);

export default index;
