import { takeLatest, put, call } from 'redux-saga/effects';
import { API_URL } from 'utils/environment';
import { post } from 'utils/request';
import { resetPasswordSuccess, resetPasswordError } from './actions';
import { RESET_PASSWORD } from './constants';

function* resetPassword(values) {
  const requestURL = `${API_URL}/people/forgot_passwords`;

  try {
    const response = yield call(post, requestURL, values.user);
    yield put(resetPasswordSuccess(response));
    if (response.access_token) {
      values.callback(response.access_token);
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

export default function* signupSaga() {
  yield takeLatest(RESET_PASSWORD, resetPassword);
}
