import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import ArticleBody from '../ArticleBody';
import messages from '../../../messages';

const GroundhogDay = () => (
  <div>
    <ArticleBody>
      <FormattedHTMLMessage tagName="p" {...messages.groundhogDayBody} />
    </ArticleBody>
  </div>
);

GroundhogDay.propTypes = {};

export default GroundhogDay;
