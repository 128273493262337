import React from 'react';
import H3 from 'components/H3';
import styled from 'styled-components';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { ENVIRONMENT } from 'utils/environment';
import Card from './Card';
import messages from './messages';

const FaqWrapper = styled.div`
  .header {
    padding: 0px;
    font-family: 'Montserrat';
    font-size: 22px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: #1f275b;
    margin-bottom: 20px;
  }
  .body {
    font-family: 'Lato';
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: #0f2045;
    margin-bottom: 80px;
  }
  .note {
    font-family: 'Lato';
    font-size: 12px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: #0f2045;
    margin-bottom: 80px;
  }
`;

const FaqEmployee = () => (
  <Card>
    <H3 className="title">
      <FormattedMessage {...messages.faq} />
    </H3>
    <FaqWrapper>
      <h1 className="header">
        <FormattedMessage {...messages.whatPeopleCare} />
      </h1>
      <p className="body">
        <FormattedMessage {...messages.whatPeopleCareAnswer} />
      </p>
      <h1 className="header">
        <FormattedMessage {...messages.confidentiality} />
      </h1>
      <p className="body">
        <FormattedMessage
          {...messages.confidentialityAnswer}
          values={{
            canadian: ENVIRONMENT === 'US_PRODUCTION' ? '' : 'Canadian ',
          }}
        />
      </p>
      <h1 className="header">
        <FormattedMessage {...messages.familyMembers} />
      </h1>
      <p className="body">
        <FormattedMessage {...messages.familyMembersAnswer} />
      </p>
      <h1 className="header">
        <FormattedMessage {...messages.accessPC} />
      </h1>
      <p className="body">
        {ENVIRONMENT === 'US_PRODUCTION' ? (
          <FormattedHTMLMessage
            id="app.containers.SupportFaq.accessPCAnswerUS"
            tagName="p"
          />
        ) : (
          <FormattedHTMLMessage {...messages.accessPCAnswer} />
        )}
      </p>
      <h1 className="header">
        <FormattedMessage {...messages.compareEAP} />
      </h1>
      <p className="body">
        <FormattedMessage
          {...messages.compareEAPAnswer}
          values={{ lineBreak: <br /> }}
        />
        <br />
        <br />
        <span className="note">
          <FormattedMessage {...messages.planDesign} />
        </span>
      </p>
      <h1 className="header">
        <FormattedMessage {...messages.meetCounsellor} />
      </h1>
      <p className="body">
        <FormattedMessage {...messages.meetCounsellorAnswer} />
      </p>
      <h1 className="header">
        <FormattedMessage {...messages.whoPays} />
      </h1>
      <p className="body">
        <FormattedMessage
          {...messages.whoPaysAnswer}
          values={{
            pricePer30Min: ENVIRONMENT === 'US_PRODUCTION' ? '55' : '45',
            pricePerHour: ENVIRONMENT === 'US_PRODUCTION' ? '110' : '90',
          }}
        />
      </p>
      <h1 className="header">
        <FormattedMessage {...messages.cancellationPolicy} />
      </h1>
      <p className="body">
        <FormattedMessage {...messages.cancellationPolicyAnswer} />
      </p>
      <h1 className="header">
        <FormattedMessage {...messages.howMany} />
      </h1>
      <p className="body">
        <FormattedMessage {...messages.howManyAnswer} />
      </p>
      <h1 className="header">
        <FormattedMessage {...messages.differentTherapists} />
      </h1>
      <p className="body">
        {ENVIRONMENT === 'US_PRODUCTION' ? (
          <FormattedHTMLMessage
            id="app.containers.SupportFaq.differentTherapistsAnswerUS"
            tagName="p"
          />
        ) : (
          <FormattedHTMLMessage {...messages.differentTherapistsAnswer} />
        )}
      </p>
      {ENVIRONMENT !== 'US_PRODUCTION' && (
        <>
          <h1 className="header">
            <FormattedMessage {...messages.accessPsychiatrist} />
          </h1>
          <p className="body">
            <FormattedMessage {...messages.accessPsychiatristAnswer} />
          </p>
        </>
      )}
      <h1 className="header">
        <FormattedMessage {...messages.paramedical} />
      </h1>
      <p className="body">
        <FormattedMessage {...messages.paramedicalAnswer} />
      </p>
      <h1 className="header">
        <FormattedMessage {...messages.contactWith} />
      </h1>
      <p className="body">
        <FormattedHTMLMessage {...messages.contactWithAnswer} />
      </p>
    </FaqWrapper>
  </Card>
);

FaqEmployee.propTypes = {};

export default FaqEmployee;
