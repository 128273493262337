/**
 *
 * ManagementConsulting
 *
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { injectIntl, intlShape, FormattedHTMLMessage } from 'react-intl';
import { compose } from 'redux';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';

import PortalLayout from 'components/PortalLayout';
import { BBDBAP } from 'utils/environment';
import bbdLogoFr from 'images/pcCarePathBapFr.png';
import bbdLogo from 'images/pcCarePathBap.png';
import { fetchUser } from '../App/actions';
import { trackUserClick } from '../EmployeeResources/actions';
import Card from './Card';
import messages from './messages';
import reducer from '../EmployeeResources/reducer';
import saga from '../EmployeeResources/saga';

// eslint-disable-next-line react/prefer-stateless-function
class ManagementConsulting extends Component {
  render() {
    const { intl } = this.props;

    const defaultBody = () => (
      <>
        <FormattedHTMLMessage tagName="h1" {...messages.managementTitle} />
        <FormattedHTMLMessage tagName="h3" {...messages.summaryOfService} />
        <FormattedHTMLMessage tagName="p" {...messages.asPartOf} />
        <FormattedHTMLMessage tagName="p" {...messages.toRequest} />
      </>
    );

    const bapBody = () => (
      <>
        <FormattedHTMLMessage tagName="h3" {...messages.body1} />
        <FormattedHTMLMessage tagName="p" {...messages.body2} />
        <FormattedHTMLMessage tagName="p" {...messages.body3} />
        <b>
          <FormattedHTMLMessage tagName="p" {...messages.body4} />
        </b>
      </>
    );

    return (
      <div>
        <Helmet>
          <title>{intl.formatMessage(messages.managementTitle)}</title>
          <meta
            name="description"
            content="Description of Management Consulting"
          />
        </Helmet>
        <PortalLayout activeTab="management_consulting">
          <Card>
            {BBDBAP ? bapBody() : defaultBody()}
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              {BBDBAP && (
                <img
                  src={intl.locale === 'en' ? bbdLogo : bbdLogoFr}
                  alt="bbd bap logo"
                  style={{ width: '250px' }}
                />
              )}
            </div>
          </Card>
        </PortalLayout>
      </div>
    );
  }
}

ManagementConsulting.propTypes = {
  intl: intlShape,
  trackUserClick: PropTypes.func.isRequired,
  fetchUser: PropTypes.func,
  copmany: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    trackUserClick: values => dispatch(trackUserClick(values)),
    fetchUser: callback => dispatch(fetchUser(callback)),
  };
}

function mapStateToProps(state) {
  // eslint-disable-next-line prefer-destructuring
  const company = state.toJS().app.user.data.company;
  const status = state.toJS().app.user.data.people_care;
  return { company, status };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'employee_resources', reducer });
const withSaga = injectSaga({ key: 'employee_resources', saga });

export default compose(
  withConnect,
  withReducer,
  withSaga,
)(injectIntl(ManagementConsulting));
