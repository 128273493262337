/**
 *
 * PanelCard
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';

import { signOut } from 'utils/auth';
import Hamburger from 'images/hamburger.png';
import { tabletSize } from 'global-styles';
import LanguageSelect from 'containers/LanguageSelect';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import messages from 'components/PortalLayout/messages';
import { makeSelectApp } from '../../containers/App/selectors';
import Wrapper from './Wrapper';
import Settings from './Settings';
import MobileNavigationOverlay from './MobileNavigationOverlay';

const ResponsiveWrapper = styled.div`
  .mobile-navigation-right {
    display: none;
  }
  @media (max-width: ${tabletSize}) {
    .desktop-navigation-right {
      display: none;
    }
    .mobile-navigation-right {
      display: inline;
      img {
        height: 20px;
        width: 25px;
      }
    }
  }
`;

class Options extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { mobileNavOpen: false };
  }

  toggleNavigation = () => {
    this.setState(prevState => ({
      mobileNavOpen: !prevState.mobileNavOpen,
    }));
  };

  render() {
    const status = this.props.user.data.people_care || '';
    const { company } = this.props.user.data;
    const { locale } = this.props;
    return (
      <ResponsiveWrapper>
        <Wrapper
          className="desktop-navigation-right"
          id={this.props.id}
          style={this.props.style}
        >
          <LanguageSelect {...locale} />
          <Settings onClick={() => signOut(company.plan_type, locale)}>
            <FormattedMessage {...messages.signOut} />
          </Settings>
        </Wrapper>
        <div className="mobile-navigation-right">
          <img
            role="presentation"
            src={Hamburger}
            alt="menu"
            onClick={this.toggleNavigation}
          />
        </div>
        <MobileNavigationOverlay
          open={this.state.mobileNavOpen}
          toggleNavigation={this.toggleNavigation}
          status={status}
        />
      </ResponsiveWrapper>
    );
  }
}

Options.propTypes = {
  style: PropTypes.object,
  id: PropTypes.string,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectApp(),
  locale: makeSelectLocale(),
});

export default connect(mapStateToProps)(Options);
