/**
 *
 * PanelItem
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import Item from './Item';

function PanelItem(props) {
  return (
    <Item id={props.id} style={props.style}>
      {props.children}
    </Item>
  );
}

PanelItem.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
  id: PropTypes.string,
};

export default PanelItem;
