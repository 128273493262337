/*
 * ForgotPassword Messages
 *
 * This contains all the text for the ForgotPassword container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.ForgotPassword';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Forgot Password',
  },
  alreadyHavePassword: {
    id: `${scope}.alreadyHavePassword`,
    defaultMessage: 'Already have your password?',
  },
  signIn: {
    id: `${scope}.signIn`,
    defaultMessage: 'Sign In',
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: 'EMAIL',
  },
  submit: {
    id: `${scope}.submit`,
    defaultMessage: 'SUBMIT',
  },
});
