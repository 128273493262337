import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectAssessmentDomain = state => state.get('settings', initialState);

const makeSelectAssessments = () =>
  createSelector(
    selectAssessmentDomain,
    substate => substate.get('assessments'),
  );

const makeSelectSettings = () =>
  createSelector(
    selectAssessmentDomain,
    substate => substate.toJS(),
  );

export default makeSelectSettings;
export { selectAssessmentDomain, makeSelectAssessments };
