import React from 'react';
import { FormattedMessage } from 'react-intl';

import PandemicMentalHealthImg from 'images/articles/banner/pandemic-mental-health.png';
import DeepBreathingImg from 'images/articles/banner/deep-breathing.jpg';
import PandemicMentalHealth from './PandemicMentalHealth';
import IntroDeepBreathing from './IntroDeepBreathing';
import Authors from '../Articles/Authors';
import messages from '../messages';

export const PandemicMentalHealthAudio = {
  deep_breathing_intro: {
    id: 'deep_breathing_intro',
    title: <FormattedMessage {...messages.introDeepBreathingTitle} />,
    description: '',
    ArticleBody: IntroDeepBreathing,
    bannerImage: DeepBreathingImg,
    type: 'audio',
  },
  pandemic_mental_health: {
    id: 'pandemic_mental_health',
    title: <FormattedMessage {...messages.pandemicAndMentalHealth} />,
    description: '',
    author: Authors.judyPlotkin,
    ArticleBody: PandemicMentalHealth,
    bannerImage: PandemicMentalHealthImg,
    type: 'audio',
  },
};
