import React from 'react';
import P from 'components/P';
import { black } from '../../../global-styles';

const isFrench = locale => locale === 'fr';

export const gatherThankYou = (lines, locale) => {
  if (isFrench(locale)) {
    lines.push('Merci pour vos réponses!');
  } else {
    lines.push('Thank you for your responses!');
  }
};

export const renderThankYou = locale =>
  isFrench(locale) ? (
    <div>
      <P color={black} fontSize="16px">
        Merci pour vos réponses!
      </P>
    </div>
  ) : (
    <div>
      <P color={black} fontSize="16px">
        Thank you for your responses!
      </P>
    </div>
  );

export const gatherOCD = (lines, description, locale) => {
  if (isFrench(locale)) {
    lines.push(
      `Certaines de vos réponses laissent croire à une possibilité de trouble obsessionnel compulsif (TOC). La probabilité a été évaluée à : <b>${description}</b>. Sachez qu’il est très difficile de diagnostiquer le TOC. Ce n’est pas un diagnostic qui peut être établi au moyen d’un outil automatisé.`,
    );
    lines.push(
      'Le TOC est un trouble anxieux causant des pensées récurrentes et involontaires, ainsi que des idées ou des sensations (obsessions) qui poussent à faire quelque chose de façon répétitive (compulsions). Les comportements répétitifs, comme se laver les mains, vérifier des choses ou nettoyer peut perturber de façon significative les activités quotidiennes et les interactions sociales d’une personne.',
    );
    lines.push(
      'De nombreuses personnes ont des pensées concentrées ou des comportements répétitifs. Mais ceux-ci ne perturbent pas la vie quotidienne et peuvent y ajouter de la structure ou rendre les tâches plus faciles. Pour les personnes souffrant du TOC, les pensées sont persistantes et les routines ou comportements involontaires sont rigides et le fait de s’en détourner cause chez elles une grande détresse. De nombreuses personnes souffrant du TOC savent ou soupçonnent que leurs obsessions ne sont pas fondées; d’autres croient qu’elles sont fondées. Bien qu’elles sachent que leurs obsessions ne sont pas fondées, les personnes souffrant du TOC ont beaucoup de difficulté à se concentrer sur autre chose que leurs obsessions ou à cesser leurs comportements compulsifs.',
    );
  } else {
    lines.push(
      `Some of your responses suggest a possibility of obsessive-compulsive disorder (OCD). The likelihood was assessed to be: <b>${description}</b>. Please note: it is very difficult to diagnose OCD. It is not a diagnosis that can be made using an automated tool.`,
    );
    lines.push(
      'OCD is an anxiety disorder in which people have recurring, unwanted thoughts, ideas or sensations (obsessions) that make them feel driven to do something repetitively (compulsions). The repetitive behaviours, such as hand washing, checking on things, or cleaning, can significantly interfere with a person’s daily activities and social interactions.',
    );
    lines.push(
      'Many people have focused thoughts or repeated behaviours. But these do not disrupt daily life and may add structure or make tasks easier. For people with OCD, thoughts are persistent and unwanted routines and behaviours are rigid and not doing them causes great distress. Many people with OCD know or suspect their obsessions are not true; others may think they could be true. Even if they know their obsessions are not true, people with OCD have a hard time keeping their focus off the obsessions or stopping the compulsive actions. A diagnosis of OCD requires the presence of obsessions and/or compulsions that are time-consuming (more than one hour a day), cause major distress, and impair work, social or other important function. OCD often begins in childhood, adolescence or early adulthood; the average age symptoms appear is 19 years old.',
    );
  }

  return lines;
};

export const renderOCD = (description, locale) =>
  isFrench(locale) ? (
    <div>
      <P color={black} fontSize="16px">
        Certaines de vos réponses laissent croire à une possibilité de trouble
        obsessionnel compulsif (TOC). La probabilité a été évaluée à :{' '}
        <span>{description}</span>. Sachez qu’il est très difficile de
        diagnostiquer le TOC. Ce n’est pas un diagnostic qui peut être établi au
        moyen d’un outil automatisé.
      </P>
      <P color={black} fontSize="16px">
        Le TOC est un trouble anxieux causant des pensées récurrentes et
        involontaires, ainsi que des idées ou des sensations (obsessions) qui
        poussent à faire quelque chose de façon répétitive (compulsions). Les
        comportements répétitifs, comme se laver les mains, vérifier des choses
        ou nettoyer peut perturber de façon significative les activités
        quotidiennes et les interactions sociales d’une personne.
      </P>
      <P color={black} fontSize="16px">
        De nombreuses personnes ont des pensées concentrées ou des comportements
        répétitifs. Mais ceux-ci ne perturbent pas la vie quotidienne et peuvent
        y ajouter de la structure ou rendre les tâches plus faciles. Pour les
        personnes souffrant du TOC, les pensées sont persistantes et les
        routines ou comportements involontaires sont rigides et le fait de s’en
        détourner cause chez elles une grande détresse. De nombreuses personnes
        souffrant du TOC savent ou soupçonnent que leurs obsessions ne sont pas
        fondées; d’autres croient qu’elles sont fondées. Bien qu’elles sachent
        que leurs obsessions ne sont pas fondées, les personnes souffrant du TOC
        ont beaucoup de difficulté à se concentrer sur autre chose que leurs
        obsessions ou à cesser leurs comportements compulsifs.
      </P>
    </div>
  ) : (
    <div>
      <P color={black} fontSize="16px">
        Some of your responses suggest a possibility of obsessive-compulsive
        disorder (OCD). The likelihood was assessed to be:{' '}
        <span>{description}</span>. Please note: it is very difficult to
        diagnose OCD. It is not a diagnosis that can be made using an automated
        tool.
      </P>
      <P color={black} fontSize="16px">
        OCD is an anxiety disorder in which people have recurring, unwanted
        thoughts, ideas or sensations (obsessions) that make them feel driven to
        do something repetitively (compulsions). The repetitive behaviours, such
        as hand washing, checking on things, or cleaning, can significantly
        interfere with a person’s daily activities and social interactions.
      </P>
      <P color={black} fontSize="16px">
        Many people have focused thoughts or repeated behaviours. But these do
        not disrupt daily life and may add structure or make tasks easier. For
        people with OCD, thoughts are persistent and unwanted routines and
        behaviours are rigid and not doing them causes great distress. Many
        people with OCD know or suspect their obsessions are not true; others
        may think they could be true. Even if they know their obsessions are not
        true, people with OCD have a hard time keeping their focus off the
        obsessions or stopping the compulsive actions. A diagnosis of OCD
        requires the presence of obsessions and/or compulsions that are
        time-consuming (more than one hour a day), cause major distress, and
        impair work, social or other important function. OCD often begins in
        childhood, adolescence or early adulthood; the average age symptoms
        appear is 19 years old.
      </P>
    </div>
  );

export const gatherPhobia = (lines, description, locale) => {
  if (isFrench(locale)) {
    lines.push(
      'Certaines de vos réponses laissent croire à la présence possible d’une phobie. Une phobie spécifique est un type de trouble anxieux défini comme la peur ou l’aversion extrême et irrationnelle à l’égard de quelque chose.',
    );
    lines.push(
      `Vos réponses laissent croire que la probabilité d’une phobie est : <b>${description}</b>.`,
    );
    lines.push(
      'Bien que tout le monde éprouve de la peur, une phobie spécifique est une sensation extrême accompagnée d’angoisse ou de panique. Les phobies spécifiques sont déclenchées par des situations ou des objets très spécifiques. Ces déclencheurs et l’anticipation de ces déclencheurs causent une anxiété irrationnelle et invalidante, ainsi que l’évitement.',
    );
    lines.push('Il existe des catégories spécifiques de phobies :');
    lines.push(
      '<space>• Phobies des animaux (p. ex., chiens, serpents, araignées)<br><space>• Phobies des environnements naturels (p. ex., hauteurs, orages, milieux aquatiques)<br><space>• Phobies de type sang-injection-accident (p. ex., peur de voir du sang, des prises de sang ou des injections, d’une intervention médicale représentée dans une émission télévisée)<br><space>• Phobies de situation (p. ex., avions, ascenseurs, conduite d’un véhicule, endroits fermés)<br><space>• Autres phobies (p. ex., évasion obsessive de situations pouvant entraîner l’étouffement, le vomissement, la contraction d’une maladie.)',
    );
    lines.push(
      'Au contraire d’une anxiété mineure, les phobies spécifiques accaparent les individus à tel point que leur vie quotidienne en est gravement affectée. Les phobies ont tendance à se développer entre les âges de sept et onze ans et sont attribuables à une variété de facteurs. L’origine exacte d’une phobie donnée est souvent difficile à cerner.',
    );
    lines.push(
      'Bien que les causes d’une phobie spécifique soient inconnues, elles sont souvent traitables.',
    );
  } else {
    lines.push(
      'Some of your responses suggest the possibility that you have a phobia. A specific phobia is a type of anxiety condition that is defined as an extreme, irrational fear of or aversion to something.',
    );
    lines.push(
      `Your responses suggest the likelihood of a phobia to be <b>${description}</b>.`,
    );
    lines.push(
      'Although everyone experiences fear, a specific phobia is extreme with sensations of dread or panic. Specific phobias as triggered by very specific situations or objects. These triggers and the anticipation of these triggers causes irrational debilitating anxiety and avoidance.',
    );
    lines.push('There are specific categories of phobias:');
    lines.push(
      '<space>• Animal phobias (e.g. dogs, snakes, spiders)<br><space>• Natural environment phobias (e.g. heights, storms, water)<br><space>• Blood-injection-injury phobias (e.g. fear of seeing blood, receiving a blood test or shot, watching television shows that display medical procedures)<br><space>• Situational phobias (e.g. airplanes, elevators, driving, enclosed places)<br><space>• Other phobias (e.g. phobic avoidance of situations that may lead to choking, vomiting, contracting an illness.)',
    );
    lines.push(
      'Unlike minor anxieties, specific phobias impair individuals that it is disabling to their daily life. Phobias tend to develop between the age of 7-11 and due to various factors. Often, the exact reason why a phobia developed is not known.',
    );
    lines.push(
      'While the cause of a specific phobia may be unknown, they are often treatable.',
    );
  }
  return lines;
};

export const renderPhobia = (description, locale) =>
  isFrench(locale) ? (
    <div>
      <P color={black} fontSize="16px">
        Certaines de vos réponses laissent croire à la présence possible d’une
        phobie. Une phobie spécifique est un type de trouble anxieux défini
        comme la peur ou l’aversion extrême et irrationnelle à l’égard de
        quelque chose.
      </P>
      <P color={black} fontSize="16px">
        Vos réponses laissent croire que la probabilité d’une phobie est :{' '}
        <span>{description}</span>.
      </P>
      <P color={black} fontSize="16px">
        Bien que tout le monde éprouve de la peur, une phobie spécifique est une
        sensation extrême accompagnée d’angoisse ou de panique. Les phobies
        spécifiques sont déclenchées par des situations ou des objets très
        spécifiques. Ces déclencheurs et l’anticipation de ces déclencheurs
        causent une anxiété irrationnelle et invalidante, ainsi que l’évitement.
      </P>
      <P color={black} fontSize="16px">
        Il existe des catégories spécifiques de phobies :
        <ul>
          <li>Phobies des animaux (p. ex., chiens, serpents, araignées)</li>
          <li>
            Phobies des environnements naturels (p. ex., hauteurs, orages,
            milieux aquatiques)
          </li>
          <li>
            Phobies de type sang-injection-accident (p. ex., peur de voir du
            sang, des prises de sang ou des injections, d’une intervention
            médicale représentée dans une émission télévisée)
          </li>
          <li>
            Phobies de situation (p. ex., avions, ascenseurs, conduite d’un
            véhicule, endroits fermés)
          </li>
          <li>
            Autres phobies (p. ex., évasion obsessive de situations pouvant
            entraîner l’étouffement, le vomissement, la contraction d’une
            maladie.)
          </li>
        </ul>
      </P>
      <P color={black} fontSize="16px">
        Au contraire d’une anxiété mineure, les phobies spécifiques accaparent
        les individus à tel point que leur vie quotidienne en est gravement
        affectée. Les phobies ont tendance à se développer entre les âges de
        sept et onze ans et sont attribuables à une variété de facteurs.
        L’origine exacte d’une phobie donnée est souvent difficile à cerner.
      </P>
      <P color={black} fontSize="16px">
        Bien que les causes d’une phobie spécifique soient inconnues, elles sont
        souvent traitables.
      </P>
    </div>
  ) : (
    <div>
      <P color={black} fontSize="16px">
        Some of your responses suggest the possibility that you have a phobia. A
        specific phobia is a type of anxiety condition that is defined as an
        extreme, irrational fear of or aversion to something.
      </P>
      <P color={black} fontSize="16px">
        Your responses suggest the likelihood of a phobia to be{' '}
        <span>{description}</span>.
      </P>
      <P color={black} fontSize="16px">
        Although everyone experiences fear, a specific phobia is extreme with
        sensations of dread or panic. Specific phobias as triggered by very
        specific situations or objects. These triggers and the anticipation of
        these triggers causes irrational debilitating anxiety and avoidance.
      </P>
      <P color={black} fontSize="16px">
        There are specific categories of phobias:
        <ul>
          <li>Animal phobias (e.g. dogs, snakes, spiders)</li>
          <li>Natural environment phobias (e.g. heights, storms, water)</li>
          <li>
            Blood-injection-injury phobias (e.g. fear of seeing blood, receiving
            a blood test or shot, watching television shows that display medical
            procedures)
          </li>
          <li>
            Situational phobias (e.g. airplanes, elevators, driving, enclosed
            places)
          </li>
          <li>
            Other phobias (e.g. phobic avoidance of situations that may lead to
            choking, vomiting, contracting an illness.)
          </li>
        </ul>
      </P>
      <P color={black} fontSize="16px">
        Unlike minor anxieties, specific phobias impair individuals that it is
        disabling to their daily life. Phobias tend to develop between the age
        of 7-11 and due to various factors. Often, the exact reason why a phobia
        developed is not known.
      </P>
      <P color={black} fontSize="16px">
        While the cause of a specific phobia may be unknown, they are often
        treatable.
      </P>
    </div>
  );

export const gatherDissociation = (lines, description, locale) => {
  if (isFrench(locale)) {
    lines.push(
      `Certaines de vos réponses laissent croire à la présence d’un trouble dissociatif.  La gravité est évaluée à : <b>${description}</b>. Sachez qu’il est très difficile de diagnostiquer un trouble dissociatif. Ce n’est pas un diagnostic qui peut être établi au moyen d’un outil automatisé.`,
    );
    lines.push(
      'Les troubles dissociatifs sont accompagnés de déséquilibres au niveau de la mémoire, de l’identité, des émotions, de la perception, du comportement et de l’image de soi. Les symptômes dissociatifs peuvent perturber tous les domaines du fonctionnement mental.',
    );
    lines.push(
      'Tout le monde connaît la dissociation. Être dans la lune, avoir de l’hypnose de la route ou « se perdre » dans un livre ou un film sont tous des exemples courants et sans gravité de dissociation. Tous signifient en quelque sorte une « perte de repères » vis-à-vis de l’environnement immédiat. En revanche, les troubles dissociatifs sont bien plus graves et sont souvent associés à une expérience traumatisante antérieure.',
    );
    lines.push(
      'Si une expérience traumatisante a lieu (un accident, une catastrophe, une agression criminelle), la dissociation peut aider la personne à tolérer ce qui autrement serait insupportable. Dans de telles situations, la personne pourrait se dissocier du souvenir de l’endroit, des circonstances ou des émotions entourant l’incident, pour ainsi s’échapper mentalement de la terreur, de la douleur et de l’effroi. Cela peut rendre flou le souvenir des détails de l’expérience plus tard, tel que le rapportent de nombreux survivants de catastrophes et d’accidents.',
    );
    lines.push(
      'Si vous avez forte raison de soupçonner que vous souffrez d’un trouble dissociatif, pensez à consulter un professionnel pour une évaluation formelle.',
    );
  } else {
    lines.push(
      `Some of your responses suggest the possibility that you have a dissociative disorder. The severity was assessed to be <b>${description}</b>. Please note: it is very difficult to diagnose dissociative disorders. It is not a diagnosis that can be made using an automated tool.`,
    );
    lines.push(
      'Dissociative disorders involve problems with memory, identity, emotion, perception, behaviour and sense of self. Dissociative symptoms can potentially disrupt every area of mental functioning.',
    );
    lines.push(
      'Everyone has experienced dissociation. Examples of mild, common dissociation include daydreaming, highway hypnosis or “getting lost” in a book or movie, all of which involve “losing touch” with awareness of one’s immediate surroundings. However, dissociative disorders are far more severe and frequently associated with previous experience of trauma.',
    );
    lines.push(
      'During a traumatic experience such as an accident, disaster or crime victimization, dissociation can help a person tolerate what might otherwise be too difficult to bear. In situations like these, a person may dissociate the memory of the place, circumstances or feelings about of the overwhelming event, mentally escaping from the fear, pain and horror. This may make it difficult to later remember the details of the experience, as reported by many disaster and accident survivors.',
    );
    lines.push(
      'If you strongly suspect you have a dissociative disorder, consider seeing a professional for a formal evaluation.',
    );
  }
  return lines;
};

export const renderDissociation = (description, locale) =>
  isFrench(locale) ? (
    <div>
      <P color={black} fontSize="16px">
        Certaines de vos réponses laissent croire à la présence d’un trouble
        dissociatif. La gravité est évaluée à : <span>{description}</span>.
        Sachez qu’il est très difficile de diagnostiquer un trouble dissociatif.
        Ce n’est pas un diagnostic qui peut être établi au moyen d’un outil
        automatisé.
      </P>
      <P color={black} fontSize="16px">
        Les troubles dissociatifs sont accompagnés de déséquilibres au niveau de
        la mémoire, de l’identité, des émotions, de la perception, du
        comportement et de l’image de soi. Les symptômes dissociatifs peuvent
        perturber tous les domaines du fonctionnement mental.
      </P>
      <P color={black} fontSize="16px">
        Tout le monde connaît la dissociation. Être dans la lune, avoir de
        l’hypnose de la route ou « se perdre » dans un livre ou un film sont
        tous des exemples courants et sans gravité de dissociation. Tous
        signifient en quelque sorte une « perte de repères » vis-à-vis de
        l’environnement immédiat. En revanche, les troubles dissociatifs sont
        bien plus graves et sont souvent associés à une expérience traumatisante
        antérieure.
      </P>
      <P color={black} fontSize="16px">
        Si une expérience traumatisante a lieu (un accident, une catastrophe,
        une agression criminelle), la dissociation peut aider la personne à
        tolérer ce qui autrement serait insupportable. Dans de telles
        situations, la personne pourrait se dissocier du souvenir de l’endroit,
        des circonstances ou des émotions entourant l’incident, pour ainsi
        s’échapper mentalement de la terreur, de la douleur et de l’effroi. Cela
        peut rendre flou le souvenir des détails de l’expérience plus tard, tel
        que le rapportent de nombreux survivants de catastrophes et d’accidents.
      </P>
      <P color={black} fontSize="16px">
        Si vous avez forte raison de soupçonner que vous souffrez d’un trouble
        dissociatif, pensez à consulter un professionnel pour une évaluation
        formelle.
      </P>
    </div>
  ) : (
    <div>
      <P color={black} fontSize="16px">
        Some of your responses suggest the possibility that you have a
        dissociative disorder. The severity was assessed to be{' '}
        <span>{description}</span>. Please note: it is very difficult to
        diagnose dissociative disorders. It is not a diagnosis that can be made
        using an automated tool.
      </P>
      <P color={black} fontSize="16px">
        Dissociative disorders involve problems with memory, identity, emotion,
        perception, behaviour and sense of self. Dissociative symptoms can
        potentially disrupt every area of mental functioning.
      </P>
      <P color={black} fontSize="16px">
        Everyone has experienced dissociation. Examples of mild, common
        dissociation include daydreaming, highway hypnosis or “getting lost” in
        a book or movie, all of which involve “losing touch” with awareness of
        one’s immediate surroundings. However, dissociative disorders are far
        more severe and frequently associated with previous experience of
        trauma.
      </P>
      <P color={black} fontSize="16px">
        During a traumatic experience such as an accident, disaster or crime
        victimization, dissociation can help a person tolerate what might
        otherwise be too difficult to bear. In situations like these, a person
        may dissociate the memory of the place, circumstances or feelings about
        of the overwhelming event, mentally escaping from the fear, pain and
        horror. This may make it difficult to later remember the details of the
        experience, as reported by many disaster and accident survivors.
      </P>
      <P color={black} fontSize="16px">
        If you strongly suspect you have a dissociative disorder, consider
        seeing a professional for a formal evaluation.
      </P>
    </div>
  );

export const gatherPersonality = (lines, description, locale) => {
  if (isFrench(locale)) {
    lines.push(
      `L’analyse de vos réponses laisse croire à la possibilité d’un trouble de la personnalité. La gravité est évaluée à : <b>${description}</b>.`,
    );
    lines.push(
      'La personnalité est la façon dont les pensées, les émotions et le comportement d’une personne diffèrent de ceux des autres. La personnalité d’un individu variera selon ses expériences, son environnement (entourage, situations de vie) et ses traits hérités. Habituellement, la personnalité d’une personne restera la même au fil du temps.',
    );
    lines.push(
      'Un trouble de la personnalité est une manière de penser, d’éprouver des émotions et de se comporter qui diffère des attentes de la culture environnante, engendre la détresse ou perturbe le fonctionnement de la personne, et qui dure dans le temps.',
    );
    lines.push(
      'Les troubles de la personnalité sont des tendances à long terme de comportements et d’expériences intérieures qui diffèrent de manière importante de ce qui est attendu. Ces tendances de l’expérience et du comportement commencent vers la fin de l’adolescence ou au début de l’âge adulte et engendrent de la détresse ou des problèmes au niveau du fonctionnement. Les troubles de la personnalité ont une incidence sur au moins deux des domaines suivants :',
    );
    lines.push(
      '<space>• Manière de penser à soi-même et aux autres<br><space>• Réactions émotives<br><space>• Relations avec les autres<br><space>• Maîtrise de soi<br>',
    );
    lines.push('Il existe de nombreux types de troubles de la personnalité.');
    lines.push(
      'Pour diagnostiquer un trouble de la personnalité, un professionnel de la santé mentale doit évaluer les tendances du comportement et des symptômes à long terme. Le diagnostic est donné habituellement aux individus de 18 ans ou plus. Les personnes de moins de 18 ans ne reçoivent pas normalement un diagnostic de trouble de personnalité, car leur personnalité se développe encore.',
    );
  } else {
    lines.push(
      `The analysis of your responses suggests the possibility that you may have a personality disorder. The severity was evaluated to be <b>${description}</b>.`,
    );
    lines.push(
      'Personality is the way of thinking, feeling and behaving that makes a person different from other people. An individual’s personality is influenced by experiences, environment (surroundings, life situations) and inherited characteristics. A person’s personality typically stays the same over time.',
    );
    lines.push(
      'A personality disorder is a way of thinking, feeling and behaving that deviates from the expectations of the culture, causes distress or problems functioning, and lasts over time.',
    );
    lines.push(
      'Personality disorders are long-term patterns of behaviour and inner experiences that differs significantly from what is expected. The pattern of experience and behaviour begins by late adolescence or early adulthood and causes distress or problems in functioning. Personality disorders affect at least two of these areas:',
    );
    lines.push(
      '<space>• Way of thinking about oneself and others<br><space>• Way of responding emotionally<br><space>• Way of relating to other people<br><space>• Way of controlling one’s behaviour<br>',
    );
    lines.push('There are many different types of personality disorders.');
    lines.push(
      'Diagnosis of a personality disorder requires a mental health professional looking at long-term patterns of functioning and symptoms. Diagnosis is typically made in individuals 18 or older. People under 18 are typically not diagnosed with personality disorders because their personalities are still developing.',
    );
  }
  return lines;
};
export const renderPersonality = (description, locale) =>
  isFrench(locale) ? (
    <div>
      <P color={black} fontSize="16px">
        L’analyse de vos réponses laisse croire à la possibilité d’un trouble de
        la personnalité. La gravité est évaluée à : <span>{description}</span>.
      </P>
      <P color={black} fontSize="16px">
        La personnalité est la façon dont les pensées, les émotions et le
        comportement d’une personne diffèrent de ceux des autres. La
        personnalité d’un individu variera selon ses expériences, son
        environnement (entourage, situations de vie) et ses traits hérités.
        Habituellement, la personnalité d’une personne restera la même au fil du
        temps.
      </P>
      <P color={black} fontSize="16px">
        Un trouble de la personnalité est une manière de penser, d’éprouver des
        émotions et de se comporter qui diffère des attentes de la culture
        environnante, engendre la détresse ou perturbe le fonctionnement de la
        personne, et qui dure dans le temps.
      </P>
      <P color={black} fontSize="16px">
        Les troubles de la personnalité sont des tendances à long terme de
        comportements et d’expériences intérieures qui diffèrent de manière
        importante de ce qui est attendu. Ces tendances de l’expérience et du
        comportement commencent vers la fin de l’adolescence ou au début de
        l’âge adulte et engendrent de la détresse ou des problèmes au niveau du
        fonctionnement. Les troubles de la personnalité ont une incidence sur au
        moins deux des domaines suivants :
      </P>
      <P color={black} fontSize="16px">
        <ul>
          <li>Manière de penser à soi-même et aux autres</li>
          <li>Réactions émotives</li>
          <li>Relations avec les autres</li>
          <li>Maîtrise de soi</li>
        </ul>
      </P>
      <P color={black} fontSize="16px">
        Il existe de nombreux types de troubles de la personnalité.
      </P>
      <P color={black} fontSize="16px">
        Pour diagnostiquer un trouble de la personnalité, un professionnel de la
        santé mentale doit évaluer les tendances du comportement et des
        symptômes à long terme. Le diagnostic est donné habituellement aux
        individus de 18 ans ou plus. Les personnes de moins de 18 ans ne
        reçoivent pas normalement un diagnostic de trouble de personnalité, car
        leur personnalité se développe encore.
      </P>
    </div>
  ) : (
    <div>
      <P color={black} fontSize="16px">
        The analysis of your responses suggests the possibility that you may
        have a personality disorder. The severity was evaluated to be{' '}
        <span>{description}</span>.
      </P>
      <P color={black} fontSize="16px">
        Personality is the way of thinking, feeling and behaving that makes a
        person different from other people. An individual’s personality is
        influenced by experiences, environment (surroundings, life situations)
        and inherited characteristics. A person’s personality typically stays
        the same over time.
      </P>
      <P color={black} fontSize="16px">
        A personality disorder is a way of thinking, feeling and behaving that
        deviates from the expectations of the culture, causes distress or
        problems functioning, and lasts over time.
      </P>
      <P color={black} fontSize="16px">
        Personality disorders are long-term patterns of behaviour and inner
        experiences that differs significantly from what is expected. The
        pattern of experience and behaviour begins by late adolescence or early
        adulthood and causes distress or problems in functioning. Personality
        disorders affect at least two of these areas:
      </P>
      <P color={black} fontSize="16px">
        <ul>
          <li>Way of thinking about oneself and others</li>
          <li>Way of responding emotionally</li>
          <li>Way of relating to other people</li>
          <li>Way of controlling one’s behaviour</li>
        </ul>
      </P>
      <P color={black} fontSize="16px">
        There are many different types of personality disorders.
      </P>
      <P color={black} fontSize="16px">
        Diagnosis of a personality disorder requires a mental health
        professional looking at long-term patterns of functioning and symptoms.
        Diagnosis is typically made in individuals 18 or older. People under 18
        are typically not diagnosed with personality disorders because their
        personalities are still developing.
      </P>
    </div>
  );

export const gatherPersonalityDisinhibition = (lines, description, locale) => {
  if (isFrench(locale)) {
    lines.push(
      `Votre évaluation laisse croire que votre personnalité comporte des traits de désinhibition. La gravité est évaluée à : <b>${description}</b>.`,
    );
    lines.push(
      'Parmi les symptômes d’une personnalité désinhibée, on peut compter l’irresponsabilité, l’impulsivité et la distractibilité. Plusieurs troubles de santé mentale manifesteront de la désinhibition en même temps. Il est important de consulter un professionnel pour une évaluation précise.',
    );
  } else {
    lines.push(
      `Your assessment suggests that your personality has features of disinhibition. The severity was found to be <b>${description}</b>.`,
    );
    lines.push(
      'Disinhibited personality symptoms include irresponsibility, impulsivity, and distractibility. There is an overlap of various mental health conditions that exhibit disinhibition. It’s important to talk to a professional for an accurate assessment.',
    );
  }
};
export const renderPersonalityDisinhibition = (description, locale) =>
  isFrench(locale) ? (
    <div>
      <P color={black} fontSize="16px">
        Votre évaluation laisse croire que votre personnalité comporte des
        traits de désinhibition. La gravité est évaluée à :{' '}
        <span>{description}</span>.
      </P>
      <P color={black} fontSize="16px">
        Parmi les symptômes d’une personnalité désinhibée, on peut compter
        l’irresponsabilité, l’impulsivité et la distractibilité. Plusieurs
        troubles de santé mentale manifesteront de la désinhibition en même
        temps. Il est important de consulter un professionnel pour une
        évaluation précise.
      </P>
    </div>
  ) : (
    <div>
      <P color={black} fontSize="16px">
        Your assessment suggests that your personality has features of
        disinhibition. The severity was found to be <span>{description}</span>.
      </P>
      <P color={black} fontSize="16px">
        Disinhibited personality symptoms include irresponsibility, impulsivity,
        and distractibility. There is an overlap of various mental health
        conditions that exhibit disinhibition. It’s important to talk to a
        professional for an accurate assessment.
      </P>
    </div>
  );

export const gatherPersonalityNegative = (lines, description, locale) => {
  if (isFrench(locale)) {
    lines.push(
      `Votre évaluation laisse croire que votre personnalité comporte des traits de négativité. La gravité est évaluée à : <b>${description}</b>.`,
    );
    lines.push(
      'Parmi les symptômes de l’affectivité négative, on peut nommer la labilité émotionnelle, l’anxiété et l’insécurité liée à la séparation. Plusieurs troubles de santé mentale manifesteront de la négativité en même temps. Il est important de consulter un professionnel pour une évaluation précise.',
    );
  } else {
    lines.push(
      `Your assessment suggests that your personality has features of negativity. The severity was found to be <b>${description}</b>.`,
    );
    lines.push(
      'Negative personality symptoms include emotional lability, anxiousness and separation insecurity. There is an overlap of various mental health conditions that exhibit negativity. It’s important to talk to a professional for an accurate assessment.',
    );
  }
  return lines;
};
export const renderPersonalityNegative = (description, locale) =>
  isFrench(locale) ? (
    <div>
      <P color={black} fontSize="16px">
        Votre évaluation laisse croire que votre personnalité comporte des
        traits de négativité. La gravité est évaluée à :{' '}
        <span>{description}</span>.
      </P>
      <P color={black} fontSize="16px">
        Parmi les symptômes de l’affectivité négative, on peut nommer la
        labilité émotionnelle, l’anxiété et l’insécurité liée à la séparation.
        Plusieurs troubles de santé mentale manifesteront de la négativité en
        même temps. Il est important de consulter un professionnel pour une
        évaluation précise.
      </P>
    </div>
  ) : (
    <div>
      <P color={black} fontSize="16px">
        Your assessment suggests that your personality has features of
        negativity. The severity was found to be <span>{description}</span>.
      </P>
      <P color={black} fontSize="16px">
        Negative personality symptoms include emotional lability, anxiousness
        and separation insecurity. There is an overlap of various mental health
        conditions that exhibit negativity. It’s important to talk to a
        professional for an accurate assessment.
      </P>
    </div>
  );

export const gatherPersonalityPyscho = (lines, description, locale) => {
  if (isFrench(locale)) {
    lines.push(
      `Votre évaluation laisse croire que votre personnalité comporte des traits psychotiques. La gravité est évaluée à : <b>${description}</b>.`,
    );
    lines.push(
      'Parmi les symptômes du psychoticisme, on peut nommer l’excentricité et le dérèglement de la perception. Plusieurs troubles de santé mentale manifesteront des aspects du psychoticisme en même temps. Il est important de consulter un professionnel pour une évaluation précise.',
    );
  } else {
    lines.push(
      `Your assessment suggests that your personality has psychotic features. The severity was found to be <b>${description}</b>.`,
    );
    lines.push(
      'Psychotic personality symptoms include eccentricity and perceptual dysregulation. There is an overlap of various mental health conditions that exhibit psychoticism. It’s important to talk to a professional for an accurate assessment.',
    );
  }
};

export const renderPersonalityPyscho = (description, locale) =>
  isFrench(locale) ? (
    <div>
      <P color={black} fontSize="16px">
        Votre évaluation laisse croire que votre personnalité comporte des
        traits psychotiques. La gravité est évaluée à :{' '}
        <span>{description}</span>.
      </P>
      <P color={black} fontSize="16px">
        Parmi les symptômes du psychoticisme, on peut nommer l’excentricité et
        le dérèglement de la perception. Plusieurs troubles de santé mentale
        manifesteront des aspects du psychoticisme en même temps. Il est
        important de consulter un professionnel pour une évaluation précise.
      </P>
    </div>
  ) : (
    <div>
      <P color={black} fontSize="16px">
        Your assessment suggests that your personality has psychotic features.
        The severity was found to be <span>{description}</span>.
      </P>
      <P color={black} fontSize="16px">
        Psychotic personality symptoms include eccentricity and perceptual
        dysregulation. There is an overlap of various mental health conditions
        that exhibit psychoticism. It’s important to talk to a professional for
        an accurate assessment.
      </P>
    </div>
  );

export const gatherPersonalityDetach = (lines, description, locale) => {
  if (isFrench(locale)) {
    lines.push(
      `Votre évaluation laisse croire que votre personnalité comporte des traits de détachement. La gravité est évaluée à : <b>${description}</b>.`,
    );
    lines.push(
      'Parmi les symptômes du détachement, on peut nommer le repli sur soi, la perte du plaisir et de la jouissance des choses et des activités et l’évasion de l’intimité. Plusieurs troubles de santé mentale manifesteront le détachement en même temps. Il est important de consulter un professionnel pour une évaluation précise.',
    );
  } else {
    lines.push(
      `Your assessment suggests that your personality has features of detachment. The severity was found to be <b>${description}</b>.`,
    );
    lines.push(
      'Detached personality symptoms include withdrawal, loss of pleasure and enjoyment in things & activities, and avoidance of intimacy. There is an overlap of various mental health conditions that exhibit detachment. It’s important to talk to a professional for an accurate assessment.',
    );
  }
};
export const renderPersonalityDetach = (description, locale) =>
  isFrench(locale) ? (
    <div>
      <P color={black} fontSize="16px">
        Votre évaluation laisse croire que votre personnalité comporte des
        traits de détachement. La gravité est évaluée à :{' '}
        <span>{description}</span>.
      </P>
      <P color={black} fontSize="16px">
        Parmi les symptômes du détachement, on peut nommer le repli sur soi, la
        perte du plaisir et de la jouissance des choses et des activités et
        l’évasion de l’intimité. Plusieurs troubles de santé mentale
        manifesteront le détachement en même temps. Il est important de
        consulter un professionnel pour une évaluation précise.
      </P>
    </div>
  ) : (
    <div>
      <P color={black} fontSize="16px">
        Your assessment suggests that your personality has features of
        detachment. The severity was found to be <span>{description}</span>.
      </P>
      <P color={black} fontSize="16px">
        Detached personality symptoms include withdrawal, loss of pleasure and
        enjoyment in things & activities, and avoidance of intimacy. There is an
        overlap of various mental health conditions that exhibit detachment.
        It’s important to talk to a professional for an accurate assessment.
      </P>
    </div>
  );

export const gatherPersonalityAntagonism = (lines, description, locale) => {
  if (isFrench(locale)) {
    lines.push(
      `Votre évaluation laisse croire que votre personnalité comporte des traits d’antagonisme. La gravité est évaluée à : <b>${description}</b>.`,
    );
    lines.push(
      'Parmi les symptômes de l’antagonisme, on peut compter les tendances manipulatoires, la malhonnêteté et la grandiosité. Plusieurs troubles de santé mentale manifesteront des aspects de l’antagonisme en même temps. Il est important de consulter un professionnel pour une évaluation précise.',
    );
  } else {
    lines.push(
      `Your assessment suggests that your personality has features of antagonism. The severity was found to be <b>${description}</b>.`,
    );
    lines.push(
      'Antagonistic personality symptoms include manipulativeness, deceitfulness and grandiosity. There is an overlap of various mental health conditions that exhibit antagonism. It’s important to talk to a professional for an accurate assessment.',
    );
  }
};
export const renderPersonalityAntagonism = (description, locale) =>
  isFrench(locale) ? (
    <div>
      <P color={black} fontSize="16px">
        Votre évaluation laisse croire que votre personnalité comporte des
        traits d’antagonisme. La gravité est évaluée à :{' '}
        <span>{description}</span>.
      </P>
      <P color={black} fontSize="16px">
        Parmi les symptômes de l’antagonisme, on peut compter les tendances
        manipulatoires, la malhonnêteté et la grandiosité. Plusieurs troubles de
        santé mentale manifesteront des aspects de l’antagonisme en même temps.
        Il est important de consulter un professionnel pour une évaluation
        précise.
      </P>
    </div>
  ) : (
    <div>
      <P color={black} fontSize="16px">
        Your assessment suggests that your personality has features of
        antagonism. The severity was found to be <span>{description}</span>.
      </P>
      <P color={black} fontSize="16px">
        Antagonistic personality symptoms include manipulativeness,
        deceitfulness and grandiosity. There is an overlap of various mental
        health conditions that exhibit antagonism. It’s important to talk to a
        professional for an accurate assessment.
      </P>
    </div>
  );

export const gatherSomatic = (lines, description, locale) => {
  if (isFrench(locale)) {
    lines.push(
      `Votre évaluation laisse croire à la possibilité d’un trouble à symptomatologie somatique. La gravité est évaluée à : <b>${description}</b>.`,
    );
    lines.push(
      'Un trouble à symptomatologie somatique fait que la personne se concentre intensément sur ses symptômes physiques, tels que la douleur, l’affaiblissement ou l’essoufflement, ce qui donne lieu à une grande détresse ou à des problèmes au niveau du fonctionnement. L’individu a des pensées, émotions et comportements excessifs vis-à-vis des symptômes physiques. Les symptômes physiques peuvent ou peuvent ne pas être associés à une affection médicale confirmée par diagnostic, mais la personne vit ses symptômes de sorte qu’elle croit réellement être malade (c’est-à-dire, elle ne feint pas la maladie).',
    );
    lines.push(
      '<space>• Un symptôme physique ou plus qui cause de la détresse ou des perturbations dans la vie quotidienne',
    );
    lines.push(
      '<space>• Pensées, émotions ou comportements excessifs liés aux symptômes physiques ou aux questions de santé, accompagnés d’au moins un des éléments suivants :',
    );
    lines.push(
      '<doublespace>• Pensées persistantes et mal proportionnées à la gravité réelle des symptômes<br>• Niveau élevé d’anxiété persistante sur sa santé ou ses symptômes<br>• Trop de temps et d’énergie consacrés aux symptômes ou aux questions de santé',
    );
    lines.push(
      '<space>• At least one symptom is constantly present, although there may be different symptoms and symptoms may come and go',
    );
    lines.push(
      'Le diagnostic d’un trouble à symptomatologie somatique est difficile à établir et ne peut se faire au moyen d’un outil automatisé. Pensez à consulter un professionnel pour obtenir une évaluation précise et détaillée.',
    );
  } else {
    lines.push(
      `Your assessment suggests the possibility of a somatic symptom disorder. The severity was assessed to be <b>${description}</b>.`,
    );
    lines.push(
      'Somatic symptom disorder involves a person having a significant focus on physical symptoms, such as pain, weakness or shortness of breath, that results in major distress and/or problems functioning. The individual has excessive thoughts, feelings and behaviours relating to the physical symptoms. The physical symptoms may or may not be associated with a diagnosed medical condition, but the person is experiencing symptoms and believes they are sick (that is, not faking the illness).',
    );
    lines.push(
      'A person is not diagnosed with somatic symptom disorder solely because a medical cause can’t be identified for a physical symptom. The emphasis is on the extent to which the thoughts, feelings and behaviours related to the illness are excessive or out of proportion.',
    );
    lines.push(
      '<space>• One or more physical symptoms that are distressing or cause disruption in daily life',
    );
    lines.push(
      '<space>• Excessive thoughts, feelings or behaviours related to the physical symptoms or health concerns with at least one of the following:',
    );
    lines.push(
      '<doublespace>• Ongoing thoughts that are out of proportion with the seriousness of symptoms<br>• Ongoing high level of anxiety about health or symptoms<br>• Excessive time and energy spent on the symptoms or health concerns',
    );
    lines.push(
      '<space>• At least one symptom is constantly present, although there may be different symptoms and symptoms may come and go',
    );
    lines.push(
      'The diagnosis of somatic symptom disorder is difficult to make and cannot be done by an automated tool. Please consider speaking to a professional for a detailed accurate assessment.',
    );
  }
};
export const renderSomatic = (description, locale) =>
  isFrench(locale) ? (
    <div>
      <P color={black} fontSize="16px">
        Votre évaluation laisse croire à la possibilité d’un trouble à
        symptomatologie somatique. La gravité est évaluée à :{' '}
        <span>{description}</span>.
      </P>
      <P color={black} fontSize="16px">
        Un trouble à symptomatologie somatique fait que la personne se concentre
        intensément sur ses symptômes physiques, tels que la douleur,
        l’affaiblissement ou l’essoufflement, ce qui donne lieu à une grande
        détresse ou à des problèmes au niveau du fonctionnement. L’individu a
        des pensées, émotions et comportements excessifs vis-à-vis des symptômes
        physiques. Les symptômes physiques peuvent ou peuvent ne pas être
        associés à une affection médicale confirmée par diagnostic, mais la
        personne vit ses symptômes de sorte qu’elle croit réellement être malade
        (c’est-à-dire, elle ne feint pas la maladie).
      </P>
      <P color={black} fontSize="16px">
        On n’établira pas un diagnostic de trouble à symptomatologie somatique
        simplement parce qu’on n’arrive pas à déterminer la cause médicale d’un
        symptôme physique. L’accent est surtout mis sur le degré auquel les
        pensées, les émotions et les comportements liés à la maladie sont
        excessifs ou disproportionnés.
      </P>
      <P color={black} fontSize="16px">
        <ul>
          <li>
            Un symptôme physique ou plus qui cause de la détresse ou des
            perturbations dans la vie quotidienne
          </li>
          <li>
            Pensées, émotions ou comportements excessifs liés aux symptômes
            physiques ou aux questions de santé, accompagnés d’au moins un des
            éléments suivants :
          </li>
          <ul>
            <li>
              Pensées persistantes et mal proportionnées à la gravité réelle des
              symptômes
            </li>
            <li>
              Niveau élevé d’anxiété persistante sur sa santé ou ses symptômes
            </li>
            <li>
              Trop de temps et d’énergie consacrés aux symptômes ou aux
              questions de santé
            </li>
          </ul>
          <li>
            Au moins un symptôme est constamment présent, quoique d’autres
            symptômes se présentent et certains, de façon intermittente
          </li>
        </ul>
      </P>
      <P color={black} fontSize="16px">
        Le diagnostic d’un trouble à symptomatologie somatique est difficile à
        établir et ne peut se faire au moyen d’un outil automatisé. Pensez à
        consulter un professionnel pour obtenir une évaluation précise et
        détaillée.
      </P>
    </div>
  ) : (
    <div>
      <P color={black} fontSize="16px">
        Your assessment suggests the possibility of a somatic symptom disorder.
        The severity was assessed to be <span>{description}</span>.
      </P>
      <P color={black} fontSize="16px">
        Somatic symptom disorder involves a person having a significant focus on
        physical symptoms, such as pain, weakness or shortness of breath, that
        results in major distress and/or problems functioning. The individual
        has excessive thoughts, feelings and behaviours relating to the physical
        symptoms. The physical symptoms may or may not be associated with a
        diagnosed medical condition, but the person is experiencing symptoms and
        believes they are sick (that is, not faking the illness).
      </P>
      <P color={black} fontSize="16px">
        A person is not diagnosed with somatic symptom disorder solely because a
        medical cause can’t be identified for a physical symptom. The emphasis
        is on the extent to which the thoughts, feelings and behaviours related
        to the illness are excessive or out of proportion.
      </P>
      <P color={black} fontSize="16px">
        <ul>
          <li>
            One or more physical symptoms that are distressing or cause
            disruption in daily life
          </li>
          <li>
            Excessive thoughts, feelings or behaviours related to the physical
            symptoms or health concerns with at least one of the following:
          </li>
          <ul>
            <li>
              Ongoing thoughts that are out of proportion with the seriousness
              of symptoms
            </li>
            <li>Ongoing high level of anxiety about health or symptoms</li>
            <li>
              Excessive time and energy spent on the symptoms or health concerns
            </li>
          </ul>
          <li>
            At least one symptom is constantly present, although there may be
            different symptoms and symptoms may come and go
          </li>
        </ul>
      </P>
      <P color={black} fontSize="16px">
        The diagnosis of somatic symptom disorder is difficult to make and
        cannot be done by an automated tool. Please consider speaking to a
        professional for a detailed accurate assessment.
      </P>
    </div>
  );

export const gatherSocial = (lines, description, locale) => {
  if (isFrench(locale)) {
    lines.push(
      `Certaines de vos réponses laissent supposer une anxiété sociale avec une gravité de :<b>${description}</b>.`,
    );
    lines.push(
      'La personne atteinte d’un trouble d’anxiété sociale sera très anxieuse et mal à l’aise avec l’idée d’être embarrassée, humiliée, rejetée ou méprisée dans ses interactions sociales.  Les personnes souffrant de ce trouble essaieront d’éviter de telles situations ou ne feront que les supporter anxieusement.',
    );
    lines.push(
      'Des exemples courants sont une peur extrême de parler devant un public, faire de nouvelles connaissances ou manger ou boire en public.',
    );
  } else {
    lines.push(
      `Some of your responses suggest social anxiety with a severity of <b>${description}</b>.`,
    );
    lines.push(
      'A person with social anxiety disorder has significant anxiety and discomfort about being embarrassed, humiliated, rejected or looked down on in social interactions. People with this disorder will try to avoid the situation or endure it with great anxiety.',
    );
    lines.push(
      'Common examples are extreme fear of public speaking, meeting new people or eating/drinking in public.',
    );
  }
};
export const renderSocial = (description, locale) =>
  isFrench(locale) ? (
    <div>
      <P color={black} fontSize="16px">
        Certaines de vos réponses laissent supposer une anxiété sociale avec une
        gravité de : <span>{description}</span>.
      </P>
      <P color={black} fontSize="16px">
        La personne atteinte d’un trouble d’anxiété sociale sera très anxieuse
        et mal à l’aise avec l’idée d’être embarrassée, humiliée, rejetée ou
        méprisée dans ses interactions sociales. Les personnes souffrant de ce
        trouble essaieront d’éviter de telles situations ou ne feront que les
        supporter anxieusement.
      </P>
      <P color={black} fontSize="16px">
        Des exemples courants sont une peur extrême de parler devant un public,
        faire de nouvelles connaissances ou manger ou boire en public.
      </P>
    </div>
  ) : (
    <div>
      <P color={black} fontSize="16px">
        Some of your responses suggest social anxiety with a severity of{' '}
        <span>{description}</span>.
      </P>
      <P color={black} fontSize="16px">
        A person with social anxiety disorder has significant anxiety and
        discomfort about being embarrassed, humiliated, rejected or looked down
        on in social interactions. People with this disorder will try to avoid
        the situation or endure it with great anxiety.
      </P>
      <P color={black} fontSize="16px">
        Common examples are extreme fear of public speaking, meeting new people
        or eating/drinking in public.
      </P>
    </div>
  );

export const gatherGeneralAnxiety = (lines, description, locale) => {
  if (isFrench(locale)) {
    lines.push(
      `Certaines de vos réponses laissent supposer une anxiété généralisée avec une gravité de <b>${description}</b>.`,
    );
    lines.push(
      'Le trouble d’anxiété généralisée signifie une inquiétude persistante et excessive qui empêche le déroulement normal des activités quotidiennes. L’inquiétude et la tension continuelles peuvent être accompagnées de symptômes physiques, tels que l’instabilité psychomotrice, un sens d’avoir les nerfs toujours à vif ou de se fatiguer facilement, avoir du mal à se concentrer, la tension musculaire ou des problèmes de sommeil.',
    );
    lines.push(
      'Souvent les inquiétudes se fondent sur les éléments de la vie quotidienne tels que les responsabilités professionnelles, la santé de la famille ou des questions d’importance mineure comme les tâches ménagères, l’entretien d’un véhicule ou un rendez-vous.',
    );
  } else {
    lines.push(
      `Some of your responses suggest generalized anxiety with a severity of <b>${description}</b>.`,
    );
    lines.push(
      'Generalized anxiety disorder involves persistent and excessive worry that interferes with daily activities. This ongoing worry and tension may be accompanied by physical symptoms, such as restlessness, feeling on edge oreasily fatigued, difficulty concentrating, muscle tension or problems sleeping.',
    );
    lines.push(
      'Often the worries focus on everyday things such as job responsibilities, family health or minor matters such as chores, car repairs, or appointments.',
    );
  }
};

export const renderGeneralAnxiety = (description, locale) =>
  isFrench(locale) ? (
    <div>
      <P color={black} fontSize="16px">
        Certaines de vos réponses laissent supposer une anxiété généralisée avec
        une gravité de <span>{description}</span>.
      </P>
      <P color={black} fontSize="16px">
        Le trouble d’anxiété généralisée signifie une inquiétude persistante et
        excessive qui empêche le déroulement normal des activités quotidiennes.
        L’inquiétude et la tension continuelles peuvent être accompagnées de
        symptômes physiques, tels que l’instabilité psychomotrice, un sens
        d’avoir les nerfs toujours à vif ou de se fatiguer facilement, avoir du
        mal à se concentrer, la tension musculaire ou des problèmes de sommeil.
      </P>
      <P color={black} fontSize="16px">
        Souvent les inquiétudes se fondent sur les éléments de la vie
        quotidienne tels que les responsabilités professionnelles, la santé de
        la famille ou des questions d’importance mineure comme les tâches
        ménagères, l’entretien d’un véhicule ou un rendez-vous.
      </P>
    </div>
  ) : (
    <div>
      <P color={black} fontSize="16px">
        Some of your responses suggest generalized anxiety with a severity of{' '}
        <span>{description}</span>.
      </P>
      <P color={black} fontSize="16px">
        Generalized anxiety disorder involves persistent and excessive worry
        that interferes with daily activities. This ongoing worry and tension
        may be accompanied by physical symptoms, such as restlessness, feeling
        on edge or easily fatigued, difficulty concentrating, muscle tension or
        problems sleeping.
      </P>
      <P color={black} fontSize="16px">
        Often the worries focus on everyday things such as job responsibilities,
        family health or minor matters such as chores, car repairs, or
        appointments.
      </P>
    </div>
  );

export const gatherAgoraphobia = (lines, description, locale) => {
  if (isFrench(locale)) {
    lines.push(
      `Certaines de vos réponses laissent supposer une agoraphobie avec une gravité de : <b>${description}</b>.`,
    );
    lines.push(
      'L’agoraphobie est la peur des situations où s’en échapper peut s’avérer difficile, voire embarrassant, ou où l’aide ne serait pas disponible si des symptômes de panique se présentaient. La crainte n’est pas proportionnée à la situation réell',
    );
    lines.push(
      'La personne atteinte d’agoraphobie vivra cette crainte dans au moins deux des situations suivantes :',
    );
    lines.push(
      '<space>• Utilisation des transports en commun<br><space>• Être dans des espaces libres<br><space>• Être dans des endroits fermés<br><space>• Se trouver dans une foule ou une file d’attente<br><space>• Se trouver seul en dehors de chez soi<br>',
    );
    lines.push(
      'L’individu tentera d’éviter la situation, aura besoin de la présence d’un compagnon ou supportera la situation avec une grande crainte ou anxiété. Une agoraphobie non traitée peut s’aggraver à tel point que la personne est incapable de quitter son domicile. Un diagnostic d’agoraphobie est possible seulement si la crainte est bouleversante ou si elle entrave gravement le déroulement normal des activités quotidiennes.',
    );
  } else {
    lines.push(
      `Some of your responses suggest agoraphobia with a severity of <b>${description}</b>.`,
    );
    lines.push(
      'Agoraphobia is the fear of being in situations where escape may be difficult or embarrassing, or help might not be available in the event of panic symptoms. The fear is out of proportion to the actual situation.',
    );
    lines.push(
      'A person with agoraphobia experiences this fear in two or more of the following situations:',
    );
    lines.push(
      '<space>• Using public transportation<br><space>• Being in open spaces<br><space>• Being in enclosed places<br><space>• Standing in line or being in a crowd<br><space>• Being outside the home alone<br>',
    );
    lines.push(
      'The individual actively avoids the situation, requires a companion or endures with intense fear or anxiety. Untreated agoraphobia can become so serious that a person may be unable to leave the house. A person can only be diagnosed with agoraphobia if the fear is intensely upsetting, or if it significantly interferes with normal daily activities.',
    );
  }
};
export const renderAgoraphobia = (description, locale) =>
  isFrench(locale) ? (
    <div>
      <P color={black} fontSize="16px">
        Certaines de vos réponses laissent supposer une agoraphobie avec une
        gravité de : <span>{description}</span>.
      </P>
      <P color={black} fontSize="16px">
        L’agoraphobie est la peur des situations où s’en échapper peut s’avérer
        difficile, voire embarrassant, ou où l’aide ne serait pas disponible si
        des symptômes de panique se présentaient. La crainte n’est pas
        proportionnée à la situation réell
      </P>
      <P color={black} fontSize="16px">
        La personne atteinte d’agoraphobie vivra cette crainte dans au moins
        deux des situations suivantes :
        <ul>
          <li>Utilisation des transports en commun</li>
          <li>Être dans des espaces libres</li>
          <li>Être dans des endroits fermés</li>
          <li>Se trouver dans une foule ou une file d’attente</li>
          <li>Se trouver seul en dehors de chez soi</li>
        </ul>
      </P>
      <P color={black} fontSize="16px">
        L’individu tentera d’éviter la situation, aura besoin de la présence
        d’un compagnon ou supportera la situation avec une grande crainte ou
        anxiété. Une agoraphobie non traitée peut s’aggraver à tel point que la
        personne est incapable de quitter son domicile. Un diagnostic
        d’agoraphobie est possible seulement si la crainte est bouleversante ou
        si elle entrave gravement le déroulement normal des activités
        quotidiennes.
      </P>
    </div>
  ) : (
    <div>
      <P color={black} fontSize="16px">
        Some of your responses suggest agoraphobia with a severity of{' '}
        <span>{description}</span>.
      </P>
      <P color={black} fontSize="16px">
        Agoraphobia is the fear of being in situations where escape may be
        difficult or embarrassing, or help might not be available in the event
        of panic symptoms. The fear is out of proportion to the actual
        situation.
      </P>
      <P color={black} fontSize="16px">
        A person with agoraphobia experiences this fear in two or more of the
        following situations:
        <ul>
          <li>Using public transportation</li>
          <li>Being in open spaces</li>
          <li>Being in enclosed places</li>
          <li>Standing in line or being in a crowd</li>
          <li>Being outside the home alone</li>
        </ul>
      </P>
      <P color={black} fontSize="16px">
        The individual actively avoids the situation, requires a companion or
        endures with intense fear or anxiety. Untreated agoraphobia can become
        so serious that a person may be unable to leave the house. A person can
        only be diagnosed with agoraphobia if the fear is intensely upsetting,
        or if it significantly interferes with normal daily activities.
      </P>
    </div>
  );

export const gatherSeparationAnxiety = (lines, description, locale) => {
  if (isFrench(locale)) {
    lines.push(
      `Certaines de vos réponses laissent supposer une anxiété de séparation avec une gravité de :  <b>${description}</b>.`,
    );
    lines.push(
      'Une personne atteinte d’un trouble d’anxiété de séparation éprouvera une crainte ou une anxiété excessives de la séparation de ceux à qui elle est attachée. Le sentiment dépasse ce qui est approprié compte tenu de l’âge de la personne, persiste (au moins quatre semaines chez les enfants et six mois chez les adultes) et entrave le fonctionnement de la personne.',
    );
    lines.push(
      'Une personne atteinte d’un trouble d’anxiété de séparation peut s’inquiéter de manière persistante de la perte de l’être cher, peut hésiter ou refuser de sortir ou dormir ailleurs que chez soi ou sans la personne ou peut avoir des cauchemars sur la séparation. Des symptômes physiques de détresse vont souvent se développer durant l’enfance, mais les symptômes peuvent continuer à l’âge adulte.',
    );
  } else {
    lines.push(
      `Some of your responses suggest separation anxiety with a severity of <b>${description}</b>.`,
    );
    lines.push(
      'A person with separation anxiety disorder is excessively fearful or anxious about separation from those with whom he or she is attached. The feeling is beyond what is appropriate for the person’s age, persists (at least four weeks in children and six months in adults) and causes problems functioning.',
    );
    lines.push(
      'A person with separation anxiety disorder may be persistently worried about losing the person closest to him or her, may be reluctant or refuse to go out or sleep away from home or without that person, or may experience nightmares about separation. Physical symptoms of distress often develop in childhood, but symptoms can carry through adulthood.',
    );
  }
};

export const renderSeparationAnxiety = (description, locale) =>
  isFrench(locale) ? (
    <div>
      <P color={black} fontSize="16px">
        Certaines de vos réponses laissent supposer une anxiété de séparation
        avec une gravité de : <span>{description}</span>.
      </P>
      <P color={black} fontSize="16px">
        Une personne atteinte d’un trouble d’anxiété de séparation éprouvera une
        crainte ou une anxiété excessives de la séparation de ceux à qui elle
        est attachée. Le sentiment dépasse ce qui est approprié compte tenu de
        l’âge de la personne, persiste (au moins quatre semaines chez les
        enfants et six mois chez les adultes) et entrave le fonctionnement de la
        personne.
      </P>
      <P color={black} fontSize="16px">
        Une personne atteinte d’un trouble d’anxiété de séparation peut
        s’inquiéter de manière persistante de la perte de l’être cher, peut
        hésiter ou refuser de sortir ou dormir ailleurs que chez soi ou sans la
        personne ou peut avoir des cauchemars sur la séparation. Des symptômes
        physiques de détresse vont souvent se développer durant l’enfance, mais
        les symptômes peuvent continuer à l’âge adulte.
      </P>
    </div>
  ) : (
    <div>
      <P color={black} fontSize="16px">
        Some of your responses suggest separation anxiety with a severity of{' '}
        <span>{description}</span>.
      </P>
      <P color={black} fontSize="16px">
        A person with separation anxiety disorder is excessively fearful or
        anxious about separation from those with whom he or she is attached. The
        feeling is beyond what is appropriate for the person’s age, persists (at
        least four weeks in children and six months in adults) and causes
        problems functioning.
      </P>
      <P color={black} fontSize="16px">
        A person with separation anxiety disorder may be persistently worried
        about losing the person closest to him or her, may be reluctant or
        refuse to go out or sleep away from home or without that person, or may
        experience nightmares about separation. Physical symptoms of distress
        often develop in childhood, but symptoms can carry through adulthood.
      </P>
    </div>
  );

export const gatherEmpty = (lines, locale) => {
  if (isFrench(locale)) {
    lines.push(
      'Vos réponses ne laissent pas supposer des symptômes graves sur les troubles de santé évalués. Nous mettons régulièrement à jour cet outil avec des troubles de santé additionnels.',
    );
  } else {
    lines.push(
      'Your responses did not suggest severe symptoms on the conditions that were assessed. We are regularly updating this tool with additional conditions.',
    );
  }
};
export const renderEmpty = locale =>
  isFrench(locale) ? (
    <div>
      <P color={black} fontSize="16px">
        Vos réponses ne laissent pas supposer des symptômes graves sur les
        troubles de santé évalués. Nous mettons régulièrement à jour cet outil
        avec des troubles de santé additionnels.
      </P>
    </div>
  ) : (
    <div>
      <P color={black} fontSize="16px">
        Your responses did not suggest severe symptoms on the conditions that
        were assessed. We are regularly updating this tool with additional
        conditions.
      </P>
    </div>
  );

export const gatherFooter = (lines, locale) => {
  if (isFrench(locale)) {
    lines.push(
      'En général, augmenter l’activité physique, améliorer le sommeil et passer plus de temps avec sa famille/ses amis est bénéfique.',
    );
    lines.push(
      'Vous devriez envisager de parler avec un professionnel en utilisant la thérapie virtuelle. La première session est gratuite. Vous pouvez décider de déclarer les coûts liés à cette thérapie auprès du régime d’avantages sociaux fourni par votre employeur.  Assurez-vous de vérifier les détails de votre couverture dans la brochure des avantages sociaux ou en contactant l’administrateur de votre régime.',
    );
    lines.push(
      'Veuillez noter que ces résultats ne constituent pas un diagnostic et qu’ils ne devraient pas être considérés comme une évaluation médicale.',
    );
    lines.push(
      'Si vous ne vous sentez pas en sécurité ou que vous pourriez blesser d’autres personnes, veuillez appeler le 9-1-1 ou chercher des services d’urgence.',
    );
    lines.push('Merci!');
  } else {
    lines.push(
      'In general, increasing physical activity, improving sleep, and spending more time with family/friends is beneficial.',
    );
    lines.push(
      'You may want to consider speaking to a professional using virtual counselling. The first session is complimentary. You may choose to submit the costs for this therapy through the employee benefits provided by your employer. Please ensure you check your coverage details by checking your benefits booklet or contacting your plan administrator.',
    );
    lines.push(
      'Please note that our results are not diagnostic and should not be considered a medical assessment.',
    );
    lines.push(
      'If you are not feeling safe or may harm others, please call 911 or seek out emergency services.',
    );
    lines.push('Thank you!');
  }
};
export const renderFooter = locale =>
  isFrench(locale) ? (
    <div>
      <P color={black} fontSize="16px">
        En général, augmenter l’activité physique, améliorer le sommeil et
        passer plus de temps avec sa famille/ses amis est bénéfique.
      </P>
      <P color={black} fontSize="16px">
        Vous devriez envisager de parler avec un professionnel en utilisant la
        thérapie virtuelle. La première session est gratuite. Vous pouvez
        décider de déclarer les coûts liés à cette thérapie auprès du régime
        d’avantages sociaux fourni par votre employeur. Assurez-vous de vérifier
        les détails de votre couverture dans la brochure des avantages sociaux
        ou en contactant l’administrateur de votre régime.
      </P>
      <P color={black} fontSize="16px">
        Veuillez noter que ces résultats ne constituent pas un diagnostic et
        qu’ils ne devraient pas être considérés comme une évaluation médicale.
      </P>
      <P color={black} fontSize="16px">
        Si vous ne vous sentez pas en sécurité ou que vous pourriez blesser
        d’autres personnes, veuillez appeler le 9-1-1 ou chercher des services
        d’urgence.
      </P>
      <P color={black} fontSize="16px">
        Merci!
      </P>
    </div>
  ) : (
    <div>
      <P color={black} fontSize="16px">
        In general, increasing physical activity, improving sleep, and spending
        more time with family/friends is beneficial.
      </P>
      <P color={black} fontSize="16px">
        You may want to consider speaking to a professional using virtual
        counselling. The first session is complimentary. You may choose to
        submit the costs for this therapy through the employee benefits provided
        by your employer. Please ensure you check your coverage details by
        checking your benefits booklet or contacting your plan administrator.
      </P>
      <P color={black} fontSize="16px">
        Please note that our results are not diagnostic and should not be
        considered a medical assessment.
      </P>
      <P color={black} fontSize="16px">
        If you are not feeling safe or may harm others, please call 911 or seek
        out emergency services.
      </P>
      <P color={black} fontSize="16px">
        Thank you!
      </P>
    </div>
  );
