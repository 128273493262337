import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import H3 from 'components/H3';
import messages from './messages';

const AuthorWrapper = styled.div`
  display: flex;
  padding-left: 20px;
  padding-bottom: 60px;
  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    object-position: top;
  }
  .no-avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #e8eff5;
  }
  .right {
    margin-left: 20px;
    h3 {
      margin-bottom: 20px;
    }
    .author-description {
      font-family: 'Lato';
      font-size: 12px;
      font-weight: normal;
      font-style: italic;
      font-stretch: normal;
      letter-spacing: normal;
      color: #444e69;
    }
  }
`;
const Divider = styled.div`
  width: 200px;
  height: 1px;
  border: solid 1px #e8eff5;
  margin-bottom: 20px;
  margin-left: 20px;
`;

const AuthorSection = ({ description, image }) => (
  <div>
    <AuthorWrapper>
      <div className="left">
        {image ? (
          <img src={image} alt="profile" />
        ) : (
          <div className="no-avatar" />
        )}
      </div>
      <div className="right">
        <H3>
          <FormattedMessage {...messages.aboutTheAuthor} />
        </H3>
        {description}
      </div>
    </AuthorWrapper>
    <Divider />
  </div>
);

AuthorSection.propTypes = {
  description: PropTypes.node,
  image: PropTypes.string,
};

export default AuthorSection;
