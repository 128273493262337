/*
 *
 * Article
 *
 */

import React from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
// import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import PortalLayout from 'components/PortalLayout';
// import PanelCard from 'components/PanelCard';
import makeSelectArticle from './selectors';
import DisplayArticle from './Article';
// import messages from './messages';
import {
  EmployeeArticles as EArticles,
  ManagerArticles as MArticles,
  PlanSponsorArticles as PSArticles,
} from './Articles';
import { Videos } from './Videos';
import { PandemicMentalHealthAudio } from './Audios';

/* eslint-disable react/prefer-stateless-function */
export class Article extends React.PureComponent {
  componentDidMount() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  render() {
    const articles = {
      ...PandemicMentalHealthAudio,
      ...EArticles,
      ...MArticles,
      ...PSArticles,
      ...Videos,
    };
    const articleId = this.props.match.params.id;
    return (
      <div>
        <Helmet>
          <title>Article</title>
          <meta name="description" content="Description of Article" />
        </Helmet>
        <PortalLayout customChildren>
          <DisplayArticle
            {...articles[articleId]}
            goBack={this.props.history.goBack}
          />
        </PortalLayout>
      </div>
    );
  }
}

Article.propTypes = {
  // dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  article: makeSelectArticle(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Article);
