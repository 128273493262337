import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import messages from '../../messages';
import danaHurstImage from './dana_hurst.jpg';
import melissaFallisImage from './melissa_fallis.jpg';
import scottWallaceImage from './scott_wallace.jpg';
import isabelleLiparImage from './isabelle_lipari.jpg';
import judyPlotkinImage from './judy_plotkin.png';
import andreaBassettImage from './andrea_bassett.jpg';
import peopleCorpImage from './people_corp.png';

const Authors = {
  peopleCorporation: {
    name: <FormattedMessage {...messages.peopleCorporation} />,
    description: null,
    image: peopleCorpImage,
  },
  dannaHurst: {
    name: 'Dana Hurst',
    description: (
      <div className="author-description">
        <FormattedHTMLMessage tagName="p" {...messages.danaHurst} />
      </div>
    ),
    image: danaHurstImage,
  },
  andreaBassett: {
    name: 'Andrea Bassett',
    description: (
      <div className="author-description">
        <FormattedHTMLMessage tagName="p" {...messages.andreaBassett} />
      </div>
    ),
    image: andreaBassettImage,
  },
  melissaFallis: {
    name: 'Melissa Fallis',
    description: (
      <div className="author-description">
        <FormattedHTMLMessage tagName="p" {...messages.melissaFallis} />
      </div>
    ),
    image: melissaFallisImage,
  },
  judyPlotkin: {
    name: 'Judy Plotkin',
    description: (
      <div className="author-description">
        <FormattedHTMLMessage tagName="p" {...messages.judyPlotkin} />
      </div>
    ),
    image: judyPlotkinImage,
  },
  scottWallace: {
    name: 'Scott Wallace, PhD',
    description: (
      <p className="author-description">
        Scott Wallace is a behavioural scientist and PhD in Clinical Psychology
        with a 30-year history in occupational and workplace mental health. He
        is an authority on psychological workplace mental health, having sat on
        several editorial boards for mental health promotion. Currently he is
        the Chief Science Officer for a mobile health venture, “Avail” where he
        is responsible for creating mental health screening, psychoeducational
        materials, and contributing to user-centric platform design as well as
        marketing strategy.
      </p>
    ),
    image: scottWallaceImage,
  },
  isabelleLipari: {
    name: 'Isabelle Lipari',
    description: (
      <div className="author-description">
        <FormattedHTMLMessage tagName="p" {...messages.isabelleLipari} />
      </div>
    ),
    image: isabelleLiparImage,
  },
  maryAnnBaynton: {
    name: 'Mary Ann Baynton',
    description: <p className="author-description" />,
  },
  judyPlotkinTwo: {
    name: 'Judy Plotkin',
    description: (
      <div className="author-description">
        <FormattedHTMLMessage tagName="p" {...messages.judyPlotkinTwo} />
      </div>
    ),
    image: judyPlotkinImage,
  },
  andreaBassettTwo: {
    name: ' Andrea Bassett',
    description: (
      <div className="author-description">
        <FormattedHTMLMessage tagName="p" {...messages.andreaBassettTwo} />
      </div>
    ),
    image: andreaBassettImage,
  },
};

export default Authors;
