import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the userInformation state domain
 */

const selectUpdatePopoverDomain = state =>
  state.get('user_updates', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by UserInformation
 */

const makeSelectUserSettings = () =>
  createSelector(selectUpdatePopoverDomain, substate =>
    substate.get('settings'),
  );

export default makeSelectUserSettings;
export { selectUpdatePopoverDomain };
