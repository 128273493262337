import React from 'react';
import H3 from 'components/H3';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { ENVIRONMENT } from 'utils/environment';
import Card from './Card';
import messages from './messages';

const FaqWrapper = styled.div`
  .header {
    padding: 0px;
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    font-size: 22px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: #1f275b;
    margin-bottom: 20px;
  }
  .body {
    font-family: 'Lato', Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: #0f2045;
    margin-bottom: 80px;
  }
`;

const FaqPeopleLeader = () => (
  <Card>
    <H3 className="title">
      <FormattedMessage {...messages.faq} />
    </H3>
    <FaqWrapper>
      <h1 className="header">
        <FormattedMessage {...messages.whatPeopleCare} />
      </h1>
      <p className="body">
        <FormattedMessage
          {...messages.whatPeopleCareAnswerForLeader}
          values={{ lineBreak: <br /> }}
        />
      </p>
      <h1 className="header">
        <FormattedMessage {...messages.legislativeImplication} />
      </h1>
      <p className="body">
        <FormattedMessage
          {...messages.humanRights}
          values={{
            bold: (
              <span style={{ fontWeight: '600' }}>
                <FormattedMessage {...messages.humanRightsTitle} />
              </span>
            ),
          }}
        />
        <br />
        <br />
        <FormattedMessage
          {...messages.workersComp}
          values={{
            bold: (
              <span style={{ fontWeight: '600' }}>
                <FormattedMessage {...messages.workersCompTitle} />
              </span>
            ),
          }}
        />
        <br />
        <br />
        <FormattedMessage
          {...messages.ohs}
          values={{
            bold: (
              <span style={{ fontWeight: '600' }}>
                <FormattedMessage {...messages.ohsTitle} />
              </span>
            ),
          }}
        />
      </p>
      <h1 className="header">
        <FormattedMessage {...messages.compareEAPForLeader} />
      </h1>
      <p className="body">
        <FormattedMessage
          {...messages.compareEAPAnswerForLeader}
          values={{ lineBreak: <br /> }}
        />
      </p>
      <h1 className="header">
        <FormattedMessage {...messages.whoUses} />
      </h1>
      <p className="body">
        <FormattedMessage {...messages.whoUsesAnswer} />
      </p>
      <h1 className="header">
        <FormattedMessage {...messages.accessTraining} />
      </h1>
      <p className="body">
        <FormattedHTMLMessage {...messages.accessTrainingAnswer} />
      </p>
      <h1 className="header">
        <FormattedMessage {...messages.accessPCLeader} />
      </h1>
      <p className="body">
        <FormattedMessage
          {...messages.accessPCAnswerLeader}
          values={{
            pcLink:
              ENVIRONMENT === 'US_PRODUCTION' ? (
                <span>
                  <a target="_blank" href="https://us.pcpeopleconnect.com">
                    us.pcpeopleconnect.com
                  </a>
                </span>
              ) : (
                <span>
                  <a target="_blank" href="https://pcpeopleconnect.com">
                    pcpeopleconnect.com
                  </a>
                </span>
              ),
            lineBreak: <br />,
          }}
        />
      </p>
      <h1 className="header">
        <FormattedMessage {...messages.accessPsychiatry} />
      </h1>
      <p className="body">
        <FormattedMessage {...messages.accessPsychiatryAnswer} />
      </p>
      <h1 className="header">
        <FormattedMessage {...messages.assessmentClaim} />
      </h1>
      <p className="body">
        <FormattedMessage {...messages.assessmentClaimAnswer} />
      </p>
      <h1 className="header">
        <FormattedMessage {...messages.whoPaysLeader} />
      </h1>
      <p className="body">
        <FormattedMessage {...messages.whoPaysAnswerLeader} />
      </p>
      <h1 className="header">
        <FormattedMessage {...messages.howMany} />
      </h1>
      <p className="body">
        <FormattedMessage {...messages.howManyAnswer} />
      </p>
      <h1 className="header">
        <FormattedMessage {...messages.differentTherapists} />
      </h1>
      <p className="body">
        <FormattedMessage
          {...messages.differentTherapistsAnswerForLeader}
          values={{
            copy:
              ENVIRONMENT === 'US_PRODUCTION'
                ? 'All practitioners are members of a college or association in good standing.'
                : 'All practitioners are Canadian residents and are members of a college or association in good standing.',
          }}
        />
      </p>
      <h1 className="header">
        <FormattedMessage {...messages.contactWith} />
      </h1>
      <p className="body">
        <FormattedHTMLMessage {...messages.contactWithAnswerLeader} />
      </p>
    </FaqWrapper>
  </Card>
);

FaqPeopleLeader.propTypes = {};

export default FaqPeopleLeader;
