import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import messages from '../../../messages';
import ArticleBody from '../ArticleBody';

const BeAChampionForChange = () => (
  <ArticleBody>
    <FormattedHTMLMessage tagName="p" {...messages.aChampionForChangeBody} />
  </ArticleBody>
);

BeAChampionForChange.propTypes = {};

export default BeAChampionForChange;
