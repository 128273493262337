/*
 * VirtualCounselling Messages
 *
 * This contains all the text for the VirtualCounselling container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.SpecializedServices';

export default defineMessages({
  specServMeta: {
    id: `${scope}.specServMeta`,
    defaultMessage: 'Specialized services',
  },
  specServTitle: {
    id: `${scope}.specServTitle`,
    defaultMessage: 'Specialized services<br/> (people leaders and plan sponsors only – fee for service)',
  },
  specServSummary: {
    id: `${scope}.specServSummary`,
    defaultMessage: 'Please note that trauma services and specialized services are available at an additional cost. Service requests must be authorized by people leaders or plan sponsors. By submitting a request for these services, you agree to the additional charges that apply',
  },
  provideYourWorkforce: {
    id: `${scope}.provideYourWorkforce`,
    defaultMessage: 'Provide your workforce with more support at any moment. Supplementary specialized care services for employee needs that expand beyond traditional counselling and EAP offerings are available upon request for the fees indicated below.',
  },
  WorkHealthTitle: {
    id: `${scope}.WorkHealthTitle`,
    defaultMessage: 'WorkHealth Psychological Assessment and Treatment Services',
  },
  helpEmployees: {
    id: `${scope}.helpEmployees`,
    defaultMessage: 'Help employees currently off work or at risk due to mental health concerns get the best treatment recommendations based on our expedited psychological assessment and treatment services. Recommendations provide fitness to work evaluation and recommended Gradual Return to Work plans where appropriate.',
  },
  AWorkHealth: {
    id: `${scope}.AWorkHealth`,
    defaultMessage: 'A WorkHealth Psychological Assessment provides:',
  },
  comprehensive: {
    id: `${scope}.comprehensive`,
    defaultMessage: 'Comprehensive information regarding the employee’s current global level of functioning',
  },
  identification: {
    id: `${scope}.identification`,
    defaultMessage: 'Identification of mental health symptoms and challenges that are or may be impairing the employee’s ability to conduct their work-related tasks productively and effectively.',
  },
  diagnosis: {
    id: `${scope}.diagnosis`,
    defaultMessage: 'A diagnosis for mental disorders based on the DSM-5 (when diagnostic criteria are met)',
  },
  understanding: {
    id: `${scope}.understanding`,
    defaultMessage: 'An understanding and identification of the barriers to remaining at work or returning to work    ',
  },
  identificationOf: {
    id: `${scope}.identificationOf`,
    defaultMessage: 'Identification of employee’s functional limitations and restrictions in order to determine potentially appropriate workplace accommodations based on employee’s position and work-related tasks and responsibilities',
  },
  mentalHealthTreatment: {
    id: `${scope}.mentalHealthTreatment`,
    defaultMessage: 'Mental health treatment and, where appropriate, psychopharmacological recommendations or medication review and follow-ups.',
  },
  psychiatricConsultationTitle: {
    id: `${scope}.psychiatricConsultationTitle`,
    defaultMessage: 'Psychiatric Consultations and Assessments/Collaborative Care Services',
  },
  accessTo: {
    id: `${scope}.accessTo`,
    defaultMessage: 'Access to an extended health team, including psychiatrists, psychologists, and nurse practitioners with special expertise in mental health and addictions who collaborate with our therapists to provide cohesive and collaborative medical care. The diagnostic process allows clients and therapists to develop interventions, recommendations, referrals and/or treatment plans.',
  },
  substanceUseTitle: {
    id: `${scope}.substanceUseTitle`,
    defaultMessage: 'Substance Use Assessment and Treatment (SUAT) Services',
  },
  ensureTheSafety: {
    id: `${scope}.ensureTheSafety`,
    defaultMessage: 'Ensure the safety and well-being of employees struggling with substance use issues with assessments conducted by a registered psychologist specializing in substance use disorders. Inkblot’s clinical team facilitates access, oversees employee treatment, and offers internal substance use counselling and relapse prevention support options. A report provides the employer with clinical conclusions and recommendations around diagnosis, treatment, fitness to work and disability status, and monitoring needs through unannounced substance screens.',
  },
  specializedComplexTitle: {
    id: `${scope}.specializedComplexTitle`,
    defaultMessage: 'Specialized Complex Mental Health Programs',
  },
  helpEmployeesExperiencing: {
    id: `${scope}.helpEmployeesExperiencing`,
    defaultMessage: 'Help employees experiencing complex mental health conditions such as PTSD, major depressive episodes, anxiety disorders and more return to well-being through work-focused CBT treatment sessions delivered by mental health experts.',
  },
  mentalHealthPeerTitle: {
    id: `${scope}.mentalHealthPeerTitle`,
    defaultMessage: 'Mental Health Peer Support Program',
  },
  peerSupport: {
    id: `${scope}.peerSupport`,
    defaultMessage: 'Peer support is a cost-effective way to reduce mental health stigma and promote early intervention by training employees to support each other in the workplace. We build, execute and oversee peer support programs with our technological expertise, including consultation and support for the roles and responsibilities of key stakeholders, recruitment strategies, screening and selection process and tools, and much more.',
  },
  peer: {
    id: `${scope}.peer`,
    defaultMessage: 'Peer Support program policy and procedures including roles and responsibilities of key stakeholders (e.g., peers, principal administrator/owner, advisory group, senior management)',
  },
  recruitment: {
    id: `${scope}.recruitment`,
    defaultMessage: 'Recruitment strategy (e.g., prerequisites, communication)',
  },
  screening: {
    id: `${scope}.screening`,
    defaultMessage: 'Peer screening and selection process (e.g., screening tool, interviews, agreement)',
  },
  initial: {
    id: `${scope}.initial`,
    defaultMessage: 'Initial and ongoing training and support',
  },
  communication: {
    id: `${scope}.communication`,
    defaultMessage: 'Communication/education and launch',
  },
  quality: {
    id: `${scope}.quality`,
    defaultMessage: 'Quality management',
  },
  program: {
    id: `${scope}.program`,
    defaultMessage: 'Program evaluation/outcome measurement',
  },
  thisServiceProvides: {
    id: `${scope}.thisServiceProvides`,
    defaultMessage: 'This service provides:',
  },
  peerTraining: {
    id: `${scope}.peerTraining`,
    defaultMessage: 'Peer training which utilizes a reverse classroom approach with eight (8) online training modules that peers complete over a period of three (3) weeks; combined with weekly small group discussion on our video platform which supports entrenchment of concepts and skills learned through case examples and peer dialogue.  Along with standardized training modules (e.g., Role and Responsibilities of Peers, Privacy and Confidentiality Principles, Navigating High-Risk Situations), Inkblot can customize, in collaboration with each employer, up to three (3) topics specifically for their unique peer program.  Content is integrated specific to being a peer in their organization with aligned language, culture and values as well as customized to integrate with their mental health strategy.',
  },
  during: {
    id: `${scope}.during`,
    defaultMessage: 'During the training period:  weekly small group discussion on our video platform to support learning through case studies and peer dialogue',
  },
  ongoing: {
    id: `${scope}.ongoing`,
    defaultMessage: 'Ongoing: Quarterly larger group video sessions to review fundamental peer support principles and to sustain peer growth',
  },
  confidential: {
    id: `${scope}.confidential`,
    defaultMessage: 'Confidential consultation for peers with regards to their experience and navigating particular situations',
  },
  one: {
    id: `${scope}.one`,
    defaultMessage: 'One-on-one peer support through Inkblot counselling services',
  },
  chronicDiseaseTitle: {
    id: `${scope}.chronicDiseaseTitle`,
    defaultMessage: 'Chronic Disease Management',
  },
  supportsTheIndividual: {
    id: `${scope}.supportsTheIndividual`,
    defaultMessage: 'Supports the individuals living with chronic diseases in all aspects of whole health, including medication management, diet, physical activity, health teaching and mental health. Employees engaged in this program have regular access to a circle of practitioners specializing in their condition to allow ongoing monitoring, modification and improved health outcomes.',
  },
  healthPlus: {
    id: `${scope}.healthPlus`,
    defaultMessage: 'Health +',
  },
  providesEmployees: {
    id: `${scope}.providesEmployees`,
    defaultMessage: 'Provides employees with an opportunity to maximize the benefit of biometric screening by providing customized health coaching. Employees receive a comprehensive consultation/assessment by a nurse addressing current health status, social functioning, medical history, medication review and risk assessment to create a plan that best supports their needs.',
  },
  forMoreInfo: {
    id: `${scope}.forMoreInfo`,
    defaultMessage: "For more information on service details and fees, please contact <br/> <a href='mailto:peopleconnecteap@inkblottherapy.com'>peopleconnecteap@inkblottherapy.com</a>.",
  },
});
