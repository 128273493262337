/*
 * Carepath Messages
 *
 * This contains all the text for the Carepath container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Carepath';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Carepath',
  },
  body1: {
    id: `${scope}.body1`,
    defaultMessage: '<b>Health navigation</b>',
  },
  body2: {
    id: `${scope}.body2`,
    defaultMessage:
      'A nurse case manager acts as a single point of contact, ensuring you receive continuity of care through health navigation.  Support services can include treatment review, health coaching and education and community resource support.',
  },
  body3: {
    id: `${scope}.body3`,
    defaultMessage: '<b>Cancer assistance</b>',
  },
  body3_2: {
    id: `${scope}.body3_2`,
    defaultMessage:
      'Oncology nurse specialists provide ongoing support, information, guidance, and expertise for all cancer diagnoses.',
  },
  body4: {
    id: `${scope}.body4`,
    defaultMessage: '<b>Second opinion services</b>',
  },
  body5: {
    id: `${scope}.body5`,
    defaultMessage:
      'Providing medical second opinions by a specialist to ensure that your recommended treatment plan is consistent with best practice clinical guidelines. A full assessment and written report is provided. Powered by Cleveland Clinic.',
  },
  body6: {
    id: `${scope}.body6`,
    defaultMessage: '<b>How it works:</b>',
  },
  body7: {
    id: `${scope}.body7`,
    defaultMessage:
      'All services are delivered virtually via telephone or secure video conferencing, which allows for timely, safe, and efficient support. Your nurse case manager is here to assist you through diagnosis, treatment and plan of care. Our program works in parallel to the public health care system and does not replace your current health care team.',
  },
  body8: {
    id: `${scope}.body8`,
    defaultMessage:
      'When you access support, you will be connected with our intake team who will ask you some general questions and you will receive consent forms to complete.',
  },
  body9: {
    id: `${scope}.body9`,
    defaultMessage:
      'A nurse case manager will then contact you within 1-2 business days to conduct a thorough review of your medical history, current diagnosis, associated symptoms, and to discuss any other questions you may have about your condition.',
  },
  body10: {
    id: `${scope}.body10`,
    defaultMessage:
      '<b>For more information about what is included in Carepath services, <a href="https://peopleconnectcarepath.ca/">click here.</a></b>',
  },
  button: {
    id: `${scope}.button`,
    defaultMessage: 'Access health care now',
  },
  body11: {
    id: `${scope}.body11`,
    defaultMessage: 'Or call 1-866-883-5956',
  },
});
