import styled from 'styled-components';
import { phoneSize, darkSlateBlue, tabletSize } from 'global-styles';

const Card = styled.div`
  background-color: #ffffff;
  width: 100%;
  box-shadow: 0 0 14px 0 rgba(11, 118, 188, 0.18);
  text-align: center;
  padding-top: 60px;
  margin-bottom: 40px;

  .laptop {
    display: block;
    margin: 0 auto;
    width: 350px;
    margin-bottom: 40px;
  }

  .logo {
    width: 200px;
    margin-bottom: 40px;
  }

  h4 {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: ${darkSlateBlue};
    width: 70%;
    margin: 0 auto;
  }

  @media (max-width: ${tabletSize}) {
    margin-bottom: 175px;
  }

  @media (max-width: ${phoneSize}) {
    margin-bottom: 100px;

    .laptop {
      width: 250px;
    }
  }
`;

export default Card;
