import styled from 'styled-components';

import { phoneSize } from '../../global-styles';

const Item = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: ${phoneSize}) {
    margin: 0px;
  }
`;

export default Item;
