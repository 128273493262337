import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import ArticleBody from '../ArticleBody';
import messages from '../../../messages';

const BenefitsOfSelfCare = () => (
  <div>
    <ArticleBody>
      <FormattedHTMLMessage tagName="p" {...messages.benefitsSelfCareBody} />
    </ArticleBody>
  </div>
);

BenefitsOfSelfCare.propTypes = {};

export default BenefitsOfSelfCare;
