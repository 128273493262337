/*
 *
 * MarketingPage actions
 *
 */

import { API_URL } from 'utils/environment';
import {
  SIGN_UP,
  SIGN_UP_SUCCESS,
  SIGN_UP_ERROR,
  SIGN_IN,
  SIGN_IN_SUCCESS,
  SIGN_IN_ERROR,
  SEARCH_COMPANIES,
  SEARCH_COMPANIES_SUCCESS,
  SEARCH_COMPANIES_ERROR,
  FETCH_STATUSES,
  FETCH_STATUSES_SUCCESS,
  FETCH_STATUSES_ERROR,
  CLEAR_COMPANIES,
  FETCH_US_COMPANIES,
  FETCH_US_COMPANIES_ERROR,
  FETCH_US_COMPANIES_SUCCESS,
} from './constants';

export function signUp(user, url = API_URL, callback, errCallback) {
  return {
    type: SIGN_UP,
    user,
    url,
    callback,
    errCallback,
  };
}
export function signUpSuccess(user, callback) {
  return {
    type: SIGN_UP_SUCCESS,
    user,
    callback,
  };
}
export function signUpError(error) {
  return {
    type: SIGN_UP_ERROR,
    error,
  };
}

export function signIn(user, callback) {
  return {
    type: SIGN_IN,
    user,
    callback,
  };
}
export function signInSuccess(user, callback) {
  return {
    type: SIGN_IN_SUCCESS,
    user,
    callback,
  };
}
export function signInError(error) {
  return {
    type: SIGN_IN_ERROR,
    error,
  };
}

export function searchCompanies(query, planType) {
  return {
    type: SEARCH_COMPANIES,
    query,
    planType,
  };
}

export function searchCompaniesSuccess(companies) {
  return {
    type: SEARCH_COMPANIES_SUCCESS,
    companies,
  };
}

export function searchCompaniesError(error) {
  return {
    type: SEARCH_COMPANIES_ERROR,
    error,
  };
}

export function fetchStatuses() {
  return {
    type: FETCH_STATUSES,
  };
}

export function fetchStatusesSuccess(statuses) {
  return {
    type: FETCH_STATUSES_SUCCESS,
    statuses,
  };
}

export function fetchStatusesError(error) {
  return {
    type: FETCH_STATUSES_ERROR,
    error,
  };
}

export function fetchUSCompanies() {
  return {
    type: FETCH_US_COMPANIES,
  };
}

export function fetchUSCompaniesSuccess(companies) {
  return {
    type: FETCH_US_COMPANIES_SUCCESS,
    companies,
  };
}

export function fetchUSCompaniesError(error) {
  return {
    type: FETCH_US_COMPANIES_ERROR,
    error,
  };
}

export function clearCompanies() {
  return {
    type: CLEAR_COMPANIES,
  };
}
