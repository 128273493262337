import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

import ArticleBody from '../ArticleBody';
import messages from '../../../messages';

const HelpEmployeesThroughChange = () => (
  <div>
    <ArticleBody>
      <FormattedHTMLMessage tagName="p" {...messages.sixWaysManagersBody} />
    </ArticleBody>
  </div>
);

HelpEmployeesThroughChange.propTypes = {};

export default HelpEmployeesThroughChange;
