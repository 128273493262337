import styled from 'styled-components';
import { darkSlateBlue } from '../../global-styles';

const H1 = styled.h1`
  font-family: 'Montserrat', sans-serif;
  color: ${darkSlateBlue};
  font-size: ${props => (props.fontSize ? props.fontSize : '22px')};
  font-weight: 600;
  letter-spacing: 0.5px;
`;

export default H1;
