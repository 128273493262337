import React from 'react';
import PanelCardDashboard from 'components/PanelCardDashboard';
import Button from 'components/Button';
import H4 from 'components/H4';
import P from 'components/P';
import PropTypes from 'prop-types';

const Panel = ({
  img,
  header,
  body,
  route,
  buttonBody,
  externalRoute,
  locale,
}) => (
  <PanelCardDashboard img={img} locale={locale}>
    <div>
      <H4 bold>{header}</H4>
      <P>{body}</P>
    </div>
    {route && <Button handleRoute={route}>{buttonBody}</Button>}
    {externalRoute && (
      <Button externalRoute={externalRoute} isButton>
        {buttonBody}
      </Button>
    )}
  </PanelCardDashboard>
);

export default Panel;

Panel.propTypes = {
  img: PropTypes.any,
  header: PropTypes.object,
  body: PropTypes.object,
  route: PropTypes.string,
  externalRoute: PropTypes.string,
  buttonBody: PropTypes.object,
  locale: PropTypes.string,
};
