/*
 *
 * Metrics reducer
 *
 */

import { fromJS } from 'immutable';
import {
  FETCH_METRICS,
  FETCH_METRICS_ERROR,
  FETCH_METRICS_SUCCESS,
  FETCH_COMPANIES,
  FETCH_COMPANIES_ERROR,
  FETCH_COMPANIES_SUCCESS,
  CLEAR_COMPANIES,
} from './constants';

export const initialState = fromJS({
  metrics: {
    loading: false,
    error: false,
    sent: false,
  },
  companies: {
    loading: false,
    error: false,
    data: [],
  },
});

function metricsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_METRICS:
      return state
        .setIn(['metrics', 'sent'], false)
        .setIn(['metrics', 'error'], false)
        .setIn(['metrics', 'loading'], true);
    case FETCH_METRICS_ERROR:
      return state
        .setIn(['metrics', 'loading'], false)
        .setIn(['metrics', 'error'], action.error);
    case FETCH_METRICS_SUCCESS:
      return state
        .setIn(['metrics', 'loading'], false)
        .setIn(['metrics', 'sent'], true);
    case FETCH_COMPANIES:
      return state.setIn(['companies', 'loading'], true);
    case FETCH_COMPANIES_ERROR:
      return state
        .setIn(['companies', 'loading'], false)
        .setIn(['companies', 'error'], action.error);
    case FETCH_COMPANIES_SUCCESS:
      return state
        .setIn(['companies', 'loading'], false)
        .setIn(['companies', 'data'], action.companies);
    case CLEAR_COMPANIES:
      return state.setIn(['companies', 'data'], []);
    default:
      return state;
  }
}

export default metricsReducer;
