import React from 'react';
import H3 from 'components/H3';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Card from './Card';
import messages from './messages';

const FaqWrapper = styled.div`
  .header {
    padding: 0px;
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    font-size: 22px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: #1f275b;
    margin-bottom: 20px;
  }
  .body {
    font-family: 'Lato', Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: #0f2045;
    margin-bottom: 80px;
  }
`;

const FaqSection = ({ question, answer }) => (
  <>
    <h1 className="header">
      <FormattedMessage {...question} />
    </h1>
    <div className="body">
      <FormattedHTMLMessage tagName="p" {...answer} />
    </div>
  </>
);

const FaqPeopleLeaderBap = ({ locale }) => (
  <Card>
    {locale === 'fr' && (
      <H3 className="title">
        <FormattedMessage {...messages.titleThatOnlyExistsInFrench} />
      </H3>
    )}
    <H3 className="title">
      <FormattedMessage {...messages.bapFaqHeader3} />
    </H3>
    <H3 className="title">
      <FormattedMessage {...messages.bapFaqHeader2} />
    </H3>
    <FaqWrapper>
      <FaqSection
        question={messages.faqBapTitle}
        answer={messages.faqBapSubtitle}
      />
      <FaqSection
        question={messages.dependantsUsage}
        answer={messages.dependantsUsageBody}
      />
      <FaqSection
        question={messages.communicationProvided}
        answer={messages.communicationProvidedBody}
      />
      <FaqSection
        question={messages.usageReports}
        answer={messages.usageReportsBody}
      />
      <FaqSection
        question={messages.cancerAssistance2}
        answer={messages.cancerAssistanceBody2}
      />
      <FaqSection
        question={messages.annualLimit}
        answer={messages.annualLimitBody}
      />
      <FaqSection
        question={messages.travelRequirement}
        answer={messages.travelRequirementBody}
      />
      <FaqSection
        question={messages.secondOpinionSpeed}
        answer={messages.secondOpinionSpeedBody}
      />
      <FaqSection
        question={messages.accessProcedure}
        answer={messages.accessProcedureBody}
      />
      <h2 className="header">
        <FormattedMessage {...messages.businessAssistanceProgram} />
      </h2>
      <div className="body">
        <FormattedHTMLMessage
          tagName="div"
          className="body"
          {...messages.businessAssistanceProgramSub}
        />
        <FormattedMessage {...messages.businessAssistanceProgramBody} />
      </div>
      <FaqSection
        question={messages.businessAssistanceAccess}
        answer={messages.businessAssistanceAccessBody}
      />
      <FaqSection
        question={messages.appointmentBooking}
        answer={messages.appointmentBookingBody}
      />
      <FaqSection
        question={messages.supportAvailability}
        answer={messages.supportAvailabilityBody}
      />
      <FaqSection
        question={messages.consultationLimit}
        answer={messages.consultationLimitBody}
      />
      <FaqSection
        question={messages.incidentSupportFees}
        answer={messages.incidentSupportFeesBody}
      />
      <FaqSection
        question={messages.ongoingTrauma}
        answer={messages.ongoingTraumaBody}
      />
    </FaqWrapper>
  </Card>
);

export default FaqPeopleLeaderBap;
