import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import ArticleBody from '../ArticleBody';
import messages from '../../../messages';
const UnderstandingThreeStandards = () => (
  <div>
    <ArticleBody>
      <FormattedHTMLMessage
        tagName="p"
        {...messages.understandingThreeStandardsBody}
      />
    </ArticleBody>
  </div>
);

UnderstandingThreeStandards.propTypes = {};

export default UnderstandingThreeStandards;
