/*
 * Article Messages
 *
 * This contains all the text for the Article container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Privacy';

export default defineMessages({
  text: {
    id: `${scope}.text`,
    defaultMessage: 'text:',
  },
});
