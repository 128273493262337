/**
 *
 * PanelCard Wrapper
 *
 */

import styled from 'styled-components';
import { phoneSize } from 'global-styles';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  width: ${props => (props.width ? props.width : '30%')};
  box-shadow: 0 0 14px 0 rgba(11, 118, 188, 0.18);
  background-color: #fff;
  min-height: ${props => (props.minHeight ? props.minHeight : '300px')};
  margin: 0 20px 40px 0;
  width: 250px;
  height: ${props => (props.locale === 'en' ? '550px' : '700px')};
  @media (max-width: ${phoneSize}) {
    width: 100%;
    margin-right: 0px;
  }
`;

export default Wrapper;
