import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import ArticleBody from '../ArticleBody';
import messages from '../../../messages';
const HowWeEatHealthy = () => (
  <div>
    <ArticleBody>
      <FormattedHTMLMessage tagName="p" {...messages.howWeEatHealthyBody} />
    </ArticleBody>
  </div>
);

HowWeEatHealthy.propTypes = {};

export default HowWeEatHealthy;
