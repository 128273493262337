/*
 *
 * MarketingPage actions
 *
 */

import {
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
} from './constants';

export function resetPassword(user, callback) {
  return {
    type: RESET_PASSWORD,
    user,
    callback,
  };
}
export function resetPasswordSuccess(user, callback) {
  return {
    type: RESET_PASSWORD_SUCCESS,
    user,
    callback,
  };
}
export function resetPasswordError(error) {
  return {
    type: RESET_PASSWORD_ERROR,
    error,
  };
}
