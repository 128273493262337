import amplitude from 'amplitude-js/amplitude';

import { AMPLITUDE_API_KEY } from './environment';

export const initAmplitude = () =>
  amplitude.getInstance().init(AMPLITUDE_API_KEY);

export const setUserId = userId => amplitude.getInstance().setUserId(userId);

export const setUserProperties = userProperties =>
  amplitude.getInstance().setUserProperties(userProperties);

export const logEvent = (eventType, eventProperties) =>
  amplitude.getInstance().logEvent(eventType, eventProperties);

export const setUserProperty = (property, value) => {
  const identify = new amplitude.Identify().set(property, value);
  amplitude.getInstance().identify(identify);
};
