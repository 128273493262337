import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import messages from '../../../messages';
import ArticleBody from '../ArticleBody';
const Gratitude = () => (
  <ArticleBody>
    <ArticleBody>
      <FormattedHTMLMessage tagName="p" {...messages.gratitudeBody} />
    </ArticleBody>
  </ArticleBody>
);

Gratitude.propTypes = {};

export default Gratitude;
