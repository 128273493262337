/**
 *
 * SpecializedServices
 *
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { injectIntl, intlShape, FormattedHTMLMessage } from 'react-intl';
import { compose } from 'redux';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';

import PortalLayout from 'components/PortalLayout';
import { fetchUser } from '../App/actions';
import { trackUserClick } from '../EmployeeResources/actions';
import Card from './Card';
import messages from './messages';

import reducer from '../EmployeeResources/reducer';
import saga from '../EmployeeResources/saga';
import SpecializedServicesImg from '../../images/dashboard/spec-services.jpg';

// eslint-disable-next-line react/prefer-stateless-function
class SpecializedServices extends Component {
  render() {
    const { intl } = this.props;

    return (
      <div>
        <Helmet>
          <title>{intl.formatMessage(messages.specServMeta)}</title>
          <meta
            name="description"
            content="Description of Specialized Services"
          />
        </Helmet>
        <PortalLayout activeTab="specialized_services">
          <Card>
            <img
              src={SpecializedServicesImg}
              className="headerImg"
              alt="a woman sitting on a couch with an earpiece."
            />
            <FormattedHTMLMessage tagName="h1" {...messages.specServTitle} />
            <FormattedHTMLMessage tagName="p" {...messages.specServSummary} />
            <FormattedHTMLMessage
              tagName="p"
              {...messages.provideYourWorkforce}
            />
            <FormattedHTMLMessage tagName="h3" {...messages.WorkHealthTitle} />
            <FormattedHTMLMessage tagName="p" {...messages.helpEmployees} />
            <FormattedHTMLMessage tagName="p" {...messages.AWorkHealth} />
            <ul>
              <FormattedHTMLMessage tagName="li" {...messages.comprehensive} />
              <FormattedHTMLMessage tagName="li" {...messages.identification} />
              <FormattedHTMLMessage tagName="li" {...messages.diagnosis} />
              <FormattedHTMLMessage tagName="li" {...messages.understanding} />
              <FormattedHTMLMessage
                tagName="li"
                {...messages.identificationOf}
              />
              <FormattedHTMLMessage
                tagName="li"
                {...messages.mentalHealthTreatment}
              />
            </ul>
            <FormattedHTMLMessage
              tagName="h3"
              {...messages.psychiatricConsultationTitle}
            />
            <FormattedHTMLMessage tagName="p" {...messages.accessTo} />
            <FormattedHTMLMessage
              tagName="h3"
              {...messages.substanceUseTitle}
            />
            <FormattedHTMLMessage tagName="p" {...messages.ensureTheSafety} />
            <FormattedHTMLMessage
              tagName="h3"
              {...messages.specializedComplexTitle}
            />
            <FormattedHTMLMessage
              tagName="p"
              {...messages.helpEmployeesExperiencing}
            />
            <FormattedHTMLMessage
              tagName="h3"
              {...messages.mentalHealthPeerTitle}
            />
            <FormattedHTMLMessage tagName="p" {...messages.peerSupport} />
            <ul>
              <FormattedHTMLMessage tagName="li" {...messages.peer} />
              <FormattedHTMLMessage tagName="li" {...messages.recruitment} />
              <FormattedHTMLMessage tagName="li" {...messages.screening} />
              <FormattedHTMLMessage tagName="li" {...messages.initial} />
              <FormattedHTMLMessage tagName="li" {...messages.communication} />
              <FormattedHTMLMessage tagName="li" {...messages.quality} />
              <FormattedHTMLMessage tagName="li" {...messages.program} />
            </ul>
            <FormattedHTMLMessage
              tagName="p"
              {...messages.thisServiceProvides}
            />
            <FormattedHTMLMessage tagName="p" {...messages.peerTraining} />
            <ul>
              <FormattedHTMLMessage tagName="li" {...messages.during} />
              <FormattedHTMLMessage tagName="li" {...messages.ongoing} />
              <FormattedHTMLMessage tagName="li" {...messages.confidential} />
              <FormattedHTMLMessage tagName="li" {...messages.one} />
            </ul>
            <FormattedHTMLMessage
              tagName="h3"
              {...messages.chronicDiseaseTitle}
            />
            <FormattedHTMLMessage
              tagName="p"
              {...messages.supportsTheIndividual}
            />
            <FormattedHTMLMessage tagName="h3" {...messages.healthPlus} />
            <FormattedHTMLMessage tagName="p" {...messages.providesEmployees} />
            <FormattedHTMLMessage tagName="p" {...messages.forMoreInfo} />
          </Card>
        </PortalLayout>
      </div>
    );
  }
}

SpecializedServices.propTypes = {
  intl: intlShape,
  trackUserClick: PropTypes.func.isRequired,
  fetchUser: PropTypes.func,
  copmany: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    trackUserClick: values => dispatch(trackUserClick(values)),
    fetchUser: callback => dispatch(fetchUser(callback)),
  };
}

function mapStateToProps(state) {
  // eslint-disable-next-line prefer-destructuring
  const company = state.toJS().app.user.data.company;
  return { company };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'employee_resources', reducer });
const withSaga = injectSaga({ key: 'employee_resources', saga });

export default compose(
  withConnect,
  withReducer,
  withSaga,
)(injectIntl(SpecializedServices));
