import React, { Component } from 'react';
import MarketingStyles from './MarketingStyles';
import Navigation from './Navigation';
import EveryWeek from './EveryWeek';
import TackleWorkplace from './TackleWorkplace';
import CoreElements from './CoreElements';
import ArashQuote from './ArashQuote';
import Footer from './Footer';
import Authenticated from './Authenticated';

class MarketingPage extends Component {
  constructor() {
    super();
    this.state = {
      acrc: false,
      eap: false,
    };
  }

  componentDidMount() {
    this.setState({
      acrc: window.location.pathname.toLowerCase() === '/acrc',
      eap: this.isEap(),
    });
  }

  isEap = () => {
    const pathname = window.location.pathname.toLocaleLowerCase();
    if (pathname === '/eap' || pathname === '/pae') {
      return true;
    }
    return false;
  };

  render() {
    const { acrc, eap } = this.state;

    return (
      <Authenticated>
        <MarketingStyles>
          <Navigation acrc={acrc} eap={eap} />
          <EveryWeek eap={eap} />
          <TackleWorkplace acrc={acrc} eap={eap} />
          <CoreElements acrc={acrc} eap={eap} />
          <ArashQuote />
          <Footer acrc={acrc} eap={eap} />
        </MarketingStyles>
      </Authenticated>
    );
  }
}

export default MarketingPage;
