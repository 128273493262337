/*
 *
 * Dashboard
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import {
  FormattedMessage,
  injectIntl,
  intlShape,
  FormattedHTMLMessage,
} from 'react-intl';
import { createStructuredSelector } from 'reselect';

import PortalLayout from 'components/PortalLayout';
import H4 from 'components/H4';
import P from 'components/P';
import SetComm100OnMount from 'components/SetComm100OnMount';

import { makeSelectApp } from 'containers/App/selectors';
import styled from 'styled-components';
import makeSelectDashboard from './selectors';
import messages from './messages';
import DashboardPanels from './components/DashboardPanels';

const StyledP = styled(P)`
  a:focus {
    border: 2px solid #015fcc;
  }
`;
const GetStarted = ({ planType }) => {
  if (planType === 'eap') return <></>;
  return (
    <div>
      <H4 style={{ fontWeight: '600' }}>
        <FormattedMessage {...messages.getStarted} />
      </H4>
      <ul style={{ paddingLeft: '20px' }}>
        <li>
          <StyledP>
            {planType === 'eap' ? (
              <FormattedHTMLMessage tagName="P" {...messages.stepByStepEAP} />
            ) : (
              <FormattedHTMLMessage tagName="P" {...messages.stepByStep} />
            )}
          </StyledP>
        </li>
      </ul>
    </div>
  );
};

/* eslint-disable react/prefer-stateless-function */
export class Dashboard extends React.PureComponent {
  render() {
    const { intl } = this.props;
    const { company } = this.props.user.data;

    return (
      <div>
        <Helmet>
          <title>{intl.formatMessage(messages.dashboard)}</title>
          <meta name="dashboard" content="Dashboard of people corp" />
        </Helmet>
        <PortalLayout
          title={<FormattedMessage {...messages.dashboard} />}
          activeTab="dashboard"
          customDashText={
            <GetStarted planType={company && company.plan_type} />
          }
        >
          <DashboardPanels locale={intl.locale} />
        </PortalLayout>
        {this.props.user.data && <SetComm100OnMount user={this.props.user} />}
      </div>
    );
  }
}

Dashboard.propTypes = {
  intl: intlShape,
  user: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  dashboard: makeSelectDashboard(),
  user: makeSelectApp(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Dashboard),
);
