import styled from 'styled-components';

const AudioBody = styled.div`
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #444e69;
  display: flex;
  justify-content: center;
  audio {
    margin: 60px 20px;
    width: 600px;
    min-width: 80%;
  }
`;

export default AudioBody;
