/**
 *
 * PasswordInput
 *
 */

import React, { Component } from 'react';
import styled from 'styled-components';
import { deepSeaBlue } from '../../global-styles';
import EyeOpenImg from './eye-open.svg';
import EyeHideImg from './eye-hide.svg';

const InputWrapper = styled.div`
  position: relative;

  .text-input {
    width: 100%;
    display: inline-block;
  }

  .eye-icon {
    position: absolute;
    right: 30px;
    top: 17px;
    cursor: pointer;
    img {
      width: 24px;
    }
  }

  .eye-icon-wide {
    left: 165px;
    right: none;
  }
`;

class PasswordInput extends Component {
  constructor() {
    super();

    this.state = { visible: false };
  }

  render() {
    const {
      meta: { dirty, error },
      label,
      name,
      placeholder,
      value,
      bError,
      inputStyle,
      id,
      wide,
    } = this.props;
    const { visible } = this.state;

    return (
      <div className="input-container">
        <label
          style={{
            fontFamily: '"Montserrat", sans-serif',
            color: deepSeaBlue,
            whiteSpace: 'nowrap',
            marginBottom: '1em',
          }}
          htmlFor={id}
        >
          {label}
        </label>
        <InputWrapper>
          <div className="text-input">
            <input
              type={visible ? 'text' : 'password'}
              name={name}
              placeholder={placeholder}
              value={value}
              style={{ outline: 'none', ...inputStyle }}
              id={id}
              {...this.props.input}
            />
          </div>
          <div className={`eye-icon ${wide && 'eye-icon-wide'}`}>
            <img
              src={visible ? EyeHideImg : EyeOpenImg}
              role="button"
              onClick={() => this.setState({ visible: !visible })}
              alt="Show Password"
              tabIndex="0"
            />
          </div>
        </InputWrapper>
        {dirty && error && <p className="error-text">{error}</p>}
        {dirty && bError && <p className="error-text">{bError}</p>}
      </div>
    );
  }
}

export default PasswordInput;
