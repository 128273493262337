import React from 'react';
import { deepSeaBlue } from '../../../global-styles';

const Input = props => {
  const {
    meta: { touched, error },
    label,
    name,
    placeholder,
    value,
    type,
    bError,
    inputStyle,
    id,
  } = props;
  return (
    <div className="input-container">
      <label
        style={{
          fontFamily: '"Montserrat", sans-serif',
          color: deepSeaBlue,
          whiteSpace: 'nowrap',
          marginBottom: '1em',
          display: 'inline-block',
        }}
        htmlFor={id}
      >
        {label}
      </label>
      <input
        type={type || 'text'}
        name={name}
        placeholder={placeholder}
        value={value}
        style={{ outline: 'none', ...inputStyle }}
        id={id}
        {...props.input}
      />
      {touched && error && <p className="error-text">{error}</p>}
      {touched && bError && <p className="error-text">{bError}</p>}
    </div>
  );
};

export default Input;
