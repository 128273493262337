/* eslint-disable indent */
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Scroll from 'react-scroll-to-element';
import { darkSlateBlue } from 'global-styles';
import Close from './close.png';
import messages from './messages';

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  display: ${props => (props.open ? 'block' : 'none')};
  opacity: ${props => (props.open ? 1 : 0)};
  transition: opacity 0.5s;
`;

const NavigationContent = styled.div`
  height: 100%;
  top: 0;
  z-index: 200;
  background-color: #ffffff;
  box-sizing: border-box;
  position: fixed;
  width: 260px;
  right: ${props => (props.open ? '0px' : '-260px')};
  transition: 0.5s;
  padding: 20px;
  .close {
    height: 12px;
    width: 12px;
    display: block;
    margin-left: auto;
    margin-bottom: 30px;
  }
  a {
    display: block;
    font-family: 'Montserrat', sans-serif;
    color: ${darkSlateBlue};
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.7px;
    margin-bottom: 30px;
    text-decoration: none;
  }
`;

const LanguageWrapper = styled.div`
  display: flex;
  a,
  span {
    color: #1f275b;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.7px;
    margin-right: 5px !important;
    margin-left: 5px !important;
  }
  a.active {
    font-weight: bold;
  }
`;
const MobileNavigationOverlay = props => (
  <div>
    <Overlay {...props} onClick={props.toggleNavigation} />
    {props.open && (
      <NavigationContent {...props}>
        <button
          type="button"
          className="close"
          onClick={props.toggleNavigation}
        >
          <img src={Close} alt="Close Menu" />
        </button>
        {props.showNavItems && (
          <Scroll type="id" element="features">
            <Link to="/">
              <FormattedMessage {...messages.features} />
            </Link>
          </Scroll>
        )}
        <Link to="/signin" type="id" element="signIn">
          <FormattedMessage {...messages.signIn} />
        </Link>
        <Link to="/signup">
          <button type="button" className="get-started">
            <FormattedMessage {...messages.getStarted} />
          </button>
        </Link>
        <LanguageWrapper>
          <a
            className={`${props.locale === 'en' ? 'active' : ''}`}
            href="https://pcpeopleconnect.com"
          >
            English
          </a>
          <span>|</span>
          <a
            className={`${props.locale === 'fr' ? 'active' : ''}`}
            href="https://peoplevousconnecte.com"
          >
            Français
          </a>
        </LanguageWrapper>
      </NavigationContent>
    )}
  </div>
);

MobileNavigationOverlay.propTypes = {};

export default MobileNavigationOverlay;
