import styled from 'styled-components';
import 'video-react/dist/video-react.css';

const ArticleBody = styled.div`
  font-family: 'Lato';
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #444e69;
  a {
    color: #005790;
  }
  ol,
  ul {
    margin: 0;
  }
`;

export default ArticleBody;
