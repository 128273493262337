import React from 'react';
import VideoBody from './VideoBody';

const RespondingWithEmpathy = () => {
  const locale = localStorage.getItem('initLocale');
  return (
    <div>
      <VideoBody>
        <iframe
          src={
            locale === 'en'
              ? 'https://player.vimeo.com/video/745091739?h=82ee63aab6&amp;app_id=122963'
              : 'https://player.vimeo.com/video/745106556?h=ff0f0c9815&amp;app_id=122963'
          }
          width="884"
          height="497"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullscreen
          title={
            locale === 'en'
              ? 'Responding with Empathy'
              : 'Répondre avec empathie'
          }
        />
      </VideoBody>
    </div>
  );
};
export default RespondingWithEmpathy;
