import { takeLatest, put as reduxPut, call } from 'redux-saga/effects';
import { get, put } from 'utils/request';
import { API_URL } from 'utils/environment';
import {
  fetchStatusesSuccess,
  fetchStatusesError,
  changePasswordSuccess,
  changePasswordError,
  updateUserSuccess,
  updateUserError,
  fetchAssessmentsSuccess,
  fetchAssessmentsError,
} from './actions';
import {
  FETCH_STATUSES,
  CHANGE_PASSWORD,
  UPDATE_USER,
  FETCH_ASSESSMENTS,
} from './constants';

function* fetchStatuses() {
  const requestURL = `${API_URL}/people/people_care_status`;
  try {
    const companies = yield call(get, requestURL);
    yield reduxPut(fetchStatusesSuccess(companies));
  } catch (error) {
    yield reduxPut(fetchStatusesError(error));
  }
}

function* changePassword({ callback, password }) {
  const requestURL = `${API_URL}/passwords/me`;
  try {
    yield call(put, requestURL, { ...password });
    yield reduxPut(changePasswordSuccess());
    if (callback) yield call(callback);
  } catch (error) {
    yield reduxPut(changePasswordError(error));
  }
}

function* fetchAssessments({ callback }) {
  const requestURL = `${API_URL}/people/assessments`;
  try {
    const assessments = yield call(get, requestURL);
    yield reduxPut(fetchAssessmentsSuccess(assessments));
    if (callback) yield call(callback);
  } catch (error) {
    yield reduxPut(fetchAssessmentsError(error));
  }
}

export default function* settingsSaga() {
  yield takeLatest(FETCH_STATUSES, fetchStatuses);
  yield takeLatest(CHANGE_PASSWORD, changePassword);
  yield takeLatest(FETCH_ASSESSMENTS, fetchAssessments);
}
