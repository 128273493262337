import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

import ArticleBody from '../ArticleBody';
import messages from '../../../messages';

const HomeAndWorkBalance = () => (
  <div>
    <ArticleBody>
      <FormattedHTMLMessage tagName="p" {...messages.homeAndWorkBalanceBody} />
    </ArticleBody>
  </div>
);

HomeAndWorkBalance.propTypes = {};

export default HomeAndWorkBalance;
