/**
 *
 * PanelCard Image
 *
 */

import styled from 'styled-components';

const Image = styled.div`
  width: 100%;
  height: ${props => (props.imgHeight ? props.imgHeight : '33%')};
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export default Image;
