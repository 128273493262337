/*
 * Booking Messages
 *
 * This contains all the text for the Booking container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.MarketingPage';

export default defineMessages({
  coreElements: {
    id: `${scope}.coreElements`,
    defaultMessage: 'Our 4 core elements',
  },
  eapCoreElements: {
    id: `${scope}.eapCoreElements`,
    defaultMessage: 'Our 7 core elements',
  },
  manageTheChallenge: {
    id: `${scope}.manageTheChallenge`,
    defaultMessage: 'Manage the Challenge of Mental Health.',
  },
  eapManageTheChallenge: {
    id: `${scope}.eapManageTheChallenge`,
    defaultMessage: 'Manage everyday work, health and life challenges',
  },
  offerLeaderResources: {
    id: `${scope}.offerLeaderResources`,
    defaultMessage:
      'People Connect offers leaders self-serve resources and information to help them create a psychologically safe workplace. The service also provides employees educational tools, online assessments, and access to immediate care through virtual counselling.',
  },
  eapOfferLeaderResources: {
    id: `${scope}.eapOfferLeaderResources`,
    defaultMessage:
      'People Connect EAP offers personalized, virtual well-being support to you and your family members.  The service also provides a variety of care options, easy booking and a counsellor matching service to make sure your preferences are prioritized.',
  },
  getStarted: {
    id: `${scope}.getStarted`,
    defaultMessage: 'Get Started',
  },
  resourceBlog: {
    id: `${scope}.resourceBlog`,
    defaultMessage: 'Resource Blog',
  },
  resourceBlogText: {
    id: `${scope}.resourceBlogText`,
    defaultMessage:
      'A mental health knowledge forum and full library, including videos and audio files.',
  },
  assessmentTool: {
    id: `${scope}.assessmentTool`,
    defaultMessage: 'Self-Assessment Tool',
  },
  assessmentToolText: {
    id: `${scope}.assessmentToolText`,
    defaultMessage:
      'Proprietary Diagnostic and Statistical Manual (DSM) based mental health self-assessment tool.',
  },
  videoTherapy: {
    id: `${scope}.videoTherapy`,
    defaultMessage: 'Video Therapy',
  },
  eapVideoTherapy: {
    id: `${scope}.eapVideoTherapy`,
    defaultMessage: 'People Connect Continued Therapy',
  },
  noCreditCard: {
    id: `${scope}.noCreditCard`,
    defaultMessage: ', No Credit Card Required',
  },
  videoTherapyText: {
    id: `${scope}.videoTherapyText`,
    defaultMessage:
      'Video therapy with Inkblot; affordable access to mental health care and treatment, booked in real time with a therapist of choice.',
  },
  eapVideoTherapyText: {
    id: `${scope}.eapVideoTherapyText`,
    defaultMessage:
      'Seamlessly transition to longer term care with your therapist. Continue using your benefits plans if you need to extend therapy.',
  },
  virtualCollaboration: {
    id: `${scope}.virtualCollaboration`,
    defaultMessage: 'Psychiatric Collaboration',
  },
  virtualCollaborationText: {
    id: `${scope}.virtualCollaborationText`,
    defaultMessage:
      'Virtual psychiatric collaboration for cases requiring additional support.',
  },
  mentalHealthSupport: {
    id: `${scope}.mentalHealthSupport`,
    defaultMessage: 'Mental Health Support',
  },
  mentalHealthBody: {
    id: `${scope}.mentalHealthBody`,
    defaultMessage:
      'Easy to use, virtual or in your local community, we have registered counsellors and therapists that support short- and long-term needs.',
  },
  workHealthLife: {
    id: `${scope}.workHealthLife`,
    defaultMessage: 'Work, Health and Life Services',
  },
  workHealthLifeBody: {
    id: `${scope}.workHealthLifeBody`,
    defaultMessage:
      'Personalized, short-term support to assist with day-to-day work-life challenges such as health coaching, financial services, mental health support, and more.',
  },
  selfHelpCbt: {
    id: `${scope}.selfHelpCbt`,
    defaultMessage: 'iCBT Self-Help Learning',
  },
  selfHelpCbtBody: {
    id: `${scope}.selfHelpCbtBody`,
    defaultMessage:
      'Online multi-week learning modules, based on Cognitive Behavioural Therapy to help us drive our own behaviour change. Work independently and improve your wellbeing. ',
  },
});
