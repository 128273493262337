/*
 * Booking Messages
 *
 * This contains all the text for the Booking container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.MarketingPage';

export default defineMessages({
  features: {
    id: `${scope}.features`,
    defaultMessage: 'Features',
  },
  getStarted: {
    id: `${scope}.getStarted`,
    defaultMessage: 'Get Started',
  },
  signIn: {
    id: `${scope}.signIn`,
    defaultMessage: 'Sign In',
  },
  meditation: {
    id: `${scope}.meditation`,
    defaultMessage: 'Meditation',
  },
});
