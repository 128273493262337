import styled from 'styled-components';
import { darkSlateBlue } from '../../global-styles';

const H4 = styled.h4`
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: ${props => (props.bold ? 600 : 400)};
  color: ${props => (props.color ? props.color : darkSlateBlue)};
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
`;

export default H4;
