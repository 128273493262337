/*
 *
 * PlanSponsor
 *
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import PortalLayout from 'components/PortalLayout';
import messages from 'containers/Dashboard/messages';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import ArticleCard from './ArticleCard';
import { PlanSponsorArticles as articles } from '../Articles/Articles';
import { PlanSponsorsVideos as videos } from '../Articles/Videos';
import reducer from '../EmployeeResources/reducer';
import saga from '../EmployeeResources/saga';

/* eslint-disable react/prefer-stateless-function */
export class PlanSponsor extends React.PureComponent {
  render() {
    const { intl, locale } = this.props;
    return (
      <div>
        <Helmet>
          <title>{intl.formatMessage(messages.planSponsorResources)}</title>
          <meta
            name="employee resources"
            content="Resources for people corp employees"
          />
        </Helmet>
        <PortalLayout
          title={<FormattedMessage {...messages.planSponsorResources} />}
          activeTab="plan_sponsors"
        >
          {_.map({ ...articles, ...videos }, (article, i) => {
            if (locale === 'en') {
              return <ArticleCard key={i} {...article} />;
            }
            if (locale === 'fr' && article.supportsFrench) {
              return <ArticleCard key={i} {...article} />;
            }
            return <noscript />;
          })}
        </PortalLayout>
      </div>
    );
  }
}

PlanSponsor.propTypes = {
  intl: intlShape,
  locale: PropTypes.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  locale: makeSelectLocale(),
});

const withConnect = connect(mapStateToProps);

const withReducer = injectReducer({ key: 'employee_resources', reducer });
const withSaga = injectSaga({ key: 'employee_resources', saga });

export default compose(
  withConnect,
  withReducer,
  withSaga,
)(injectIntl(PlanSponsor));
