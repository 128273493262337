/**
 *
 * Signin
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import _ from 'lodash';
import { Helmet } from 'react-helmet';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import P from 'components/P';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import H2 from 'components/H2';
import PasswordInput from 'components/PasswordInput';
import { tabletSize, phoneSize, errorRed } from 'global-styles';
import { Link } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form/immutable';
import { InkblotTheme, Button } from 'inkblot-ui';
import makeSelectSignupPage from './selectors';
import reducer from './reducer';
import saga from './saga';
import { changePassword } from './actions';
import {
  validateRequired,
  validatePasswordLength,
  validatePasswordsMatch,
} from './helpers';

const SignupWrapper = styled.div`
  display: flex;
  max-width: 375px;
  min-height: 300px;
  background-color: #ffffff;
  z-index: 1;
  position: relative;
  margin: 150px auto;
  box-shadow: 0 0 50px 0 rgba(40, 71, 140, 0.2);
`;

const InfoContainer = styled.div`
  padding: 40px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  width: 75%;
  .input-container {
    width: 100%;
    margin-bottom: 20px;
    p {
      text-transform: uppercase;
      font-size: 12px;
      &.error-text {
        text-transform: none;
        color: ${errorRed};
        margin-top: 5px;
      }
    }
    input {
      width: 90%;
      margin: 10px 0 0px 0;
      height: 40px;
      padding-left: 10px;
      background-color: rgba(192, 208, 223, 0.26);
      ::placeholder {
        font-family: 'Montserrat', sans-serif;
        color: #8e8e8e;
      }
    }
  }

  @media (max-width: ${tabletSize}) {
    padding-bottom: 200px;
    margin: 0 auto;
    width: 60%;

    h2 {
      text-align: center;
    }
  }

  @media (max-width: ${phoneSize}) {
    padding-bottom: 150px;
    width: 100%;
  }
`;

class ResetPassword extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onSubmit = values =>
    this.props.changePassword(
      {
        password: values.get('password'),
        password_confirmation: values.get('password_confirmation'),
        reset_password_token: this.props.match.params.token,
      },
      () => {},
    );

  render() {
    const { handleSubmit, password } = this.props;
    const error = _.get(password, 'error.loginError');
    return (
      <div>
        <Helmet>
          <title>Forgot Password</title>
          <meta name="description" content="People Corp Signin" />
        </Helmet>
        <SignupWrapper id="changePassword">
          <InfoContainer>
            <H2>Reset Password</H2>
            <P>Please enter your new password</P>
            <form onSubmit={handleSubmit(this.onSubmit)}>
              <Field
                name="password"
                component={PasswordInput}
                label="Password"
                validate={[validatePasswordLength, validateRequired]}
                type="password"
              />
              <Field
                name="password_confirmation"
                component={PasswordInput}
                label="Confirm Password"
                validate={[validateRequired, validatePasswordsMatch]}
                type="password"
              />
              <InkblotTheme>
                <Button people label="SUBMIT" type="submit" />
              </InkblotTheme>
              {password.message && (
                <P color="black" style={{ fontSize: '18px' }}>
                  {password.message} <Link to="/signin">here</Link>
                </P>
              )}
              {error && (
                <P color="red" style={{ fontSize: '18px' }}>
                  {error}
                </P>
              )}
            </form>
          </InfoContainer>
        </SignupWrapper>
      </div>
    );
  }
}

ResetPassword.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  password: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  changePassword: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  password: makeSelectSignupPage(),
});

function mapDispatchToProps(dispatch) {
  return {
    changePassword: (value, callback) =>
      dispatch(changePassword(value, callback)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'changePassword', reducer });
const withSaga = injectSaga({ key: 'changePassword', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(
  reduxForm({
    form: 'changePasswordForm',
  })(ResetPassword),
);
