/*
 * TrainingResources Messages
 *
 * This contains all the text for the TrainingResources container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.TrainingResources';

export default defineMessages({
  trainingAndConsulting: {
    id: `${scope}.trainingAndConsulting`,
    defaultMessage: 'Training & consulting',
  },
  importantAsset: {
    id: `${scope}.importantAsset`,
    defaultMessage:
      'People Corporation provides training and expertise to help you safeguard your most important asset – your people.',
  },
  mentalHealthEmployees: {
    id: `${scope}.mentalHealthEmployees`,
    defaultMessage: 'Mental Health Training for Employees',
  },
  mentalHealthEmployeesBlurb: {
    id: `${scope}.mentalHealthEmployeesBlurb`,
    defaultMessage:
      "Help create a mentally healthy workplace with these sessions featuring a focus on mental health awareness, stigma, stress, staying resilient and how to help yourself and others. The following topics available. • Stress Happens • Difficult Conversations • What's on Your Mind • The Balancing Act • Mindfullness and Gratitude • Mental Health and the Holidays",
  },
  mentalHealthManagers: {
    id: `${scope}.mentalHealthManagers`,
    defaultMessage: 'Mental Health Training for Manager/Leaders',
  },
  mentalHealthManagersBlurb: {
    id: `${scope}.mentalHealthManagersBlurb`,
    defaultMessage:
      'These sessions help leaders understand mental health and workplace implications focusing on recognizing signs in their employees, psychological safety, the national standard and healthy workplace factors. The Mindful Manager. Making Accommodations at Work. Empathetic Leadership',
  },
  mentalHealthFirstAid: {
    id: `${scope}.mentalHealthFirstAid`,
    defaultMessage: 'Mental Health First Aid',
  },
  mentalHealthFirstAidBlurb: {
    id: `${scope}.mentalHealthFirstAidBlurb`,
    defaultMessage:
      'A two day course where employees and leaders will learn how to recognize a change in behaviour, engage in a confident conversation, guide one to appropriate resources, reduce the stigma around mental health and increase empathy and understanding in the workplace.',
  },
  strategicConsulting: {
    id: `${scope}.strategicConsulting`,
    defaultMessage: 'Strategic Consulting',
  },
  strategicConsultingBlurb: {
    id: `${scope}.strategicConsultingBlurb`,
    defaultMessage:
      'Mental health is a leading driver of workplace disability costs and risks. People Corporation offers professional mental health consulting to help your organization develop your mental health strategy. Mental Health Survey Conducting a survey is an important first step toward having a successful mental health strategy. Ensuring employee mental health concerns are identified and targeted is crucial to mental health planning.',
  },
  executiveSponsorshipDiscussions: {
    id: `${scope}.executiveSponsorshipDiscussions`,
    defaultMessage: 'Executive Sponsorship Discussions',
  },
  executiveSponsorshipDiscussionsBlurb: {
    id: `${scope}.executiveSponsorshipDiscussionsBlurb`,
    defaultMessage:
      'Geared specifically towards executives and senior leadership with the goal of creating awareness and a mental health champion in the C-suite. What impact does mental health have on your organization? Are you modeling best practices and behaviours? How does your influence impact your team? What actions can you start implementing within your teams? How can you best support the future leaders?',
  },
  peerProgramDevelopment: {
    id: `${scope}.peerProgramDevelopment`,
    defaultMessage: 'Peer Program Development',
  },
  peerProgramDevelopmentBlurb: {
    id: `${scope}.peerProgramDevelopmentBlurb`,
    defaultMessage:
      'Developing a Peer program is an important grassroots initiative for any workplace. Peer programs foster supportive relationships between those who have a lived experience in common. Let us help guide your organization in setting up a Peer Support Program, including the training of Peer Support workers.',
  },
  compliance: {
    id: `${scope}.compliance`,
    defaultMessage:
      'Compliance with the Canadian Standard for Psychological Health and Safety in the Workplace',
  },
  complianceBlurb: {
    id: `${scope}.complianceBlurb`,
    defaultMessage:
      'Learn what data sources you should use to assess your work environment, what actions will help improve employee psychological health and safety and how to align your initiatives with the Standard.',
  },
  contact: {
    id: `${scope}.contact`,
    defaultMessage:
      'To order training, arrange a consultation or talk to one of our health and wellness professionals contact us at: ',
  },
});
