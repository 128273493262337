/*
 *
 * UpdatePopover constants
 *
 */

export const UPDATE_USER_SETTINGS = 'app/UpdatePopover/UPDATE_USER_SETTING';
export const UPDATE_USER_SETTINGS_SUCCESS =
  'app/UpdatePopover/UPDATE_USER_SETTING_SUCCESS';
export const UPDATE_USER_SETTINGS_ERROR =
  'app/UpdatePopover/UPDATE_USER_SETTING_ERROR';
export const FETCH_USER_SETTINGS = 'app/UpdatePopover/FETCH_USER_SETTING';
export const FETCH_USER_SETTINGS_SUCCESS =
  'app/UpdatePopover/FETCH_USER_SETTINGS_SUCCESS';
export const FETCH_USER_SETTINGS_ERROR =
  'app/UpdatePopover/FETCH_USER_SETTING_ERROR';
