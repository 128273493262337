/*
 *
 * MarketingPage actions
 *
 */

import {
  FETCH_STATUSES,
  FETCH_STATUSES_SUCCESS,
  FETCH_STATUSES_ERROR,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  FETCH_ASSESSMENTS,
  FETCH_ASSESSMENTS_SUCCESS,
  FETCH_ASSESSMENTS_ERROR,
} from './constants';

export function fetchStatuses() {
  return {
    type: FETCH_STATUSES,
  };
}

export function fetchStatusesSuccess(statuses) {
  return {
    type: FETCH_STATUSES_SUCCESS,
    statuses,
  };
}

export function fetchStatusesError(error) {
  return {
    type: FETCH_STATUSES_ERROR,
    error,
  };
}

export function changePassword(password, callback) {
  return {
    type: CHANGE_PASSWORD,
    password,
    callback,
  };
}

export function changePasswordSuccess() {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
  };
}

export function changePasswordError(error) {
  return {
    type: CHANGE_PASSWORD_ERROR,
    error,
  };
}

export function fetchAssessments() {
  return {
    type: FETCH_ASSESSMENTS,
  };
}

export function fetchAssessmentsSuccess(assessments) {
  return {
    type: FETCH_ASSESSMENTS_SUCCESS,
    assessments,
  };
}

export function fetchAssessmentsError(error) {
  return {
    type: FETCH_ASSESSMENTS_ERROR,
    error,
  };
}
