import styled from 'styled-components';
import { darkSlateBlue } from '../../global-styles';

const H2 = styled.h2`
  font-family: 'Montserrat', sans-serif;
  color: ${darkSlateBlue};
  font-size: 21px;
  font-weight: 300;
  letter-spacing: 0.2px;
  > span {
    font-weight: 600;
  }
`;

export default H2;
