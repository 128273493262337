import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import H2 from 'components/H2';
import styled from 'styled-components';
import messages from './messages';

const SettingsDisplayWrapper = styled.div`
  p.sub-header {
    text-transform: uppercase;
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #00568e;
    margin-bottom: 20px;
  }
  p.information {
    font-family: 'Lato';
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1d212c;
    margin-bottom: 40px;
  }
`;

const SettingsDisplay = props => (
  <SettingsDisplayWrapper>
    <H2 style={{ display: 'inline', fontWeight: 500, fontSize: '18px' }}>
      <FormattedMessage {...messages.settings} />
    </H2>
    <button type="button" className="new-button" onClick={props.toggleForm}>
      <FormattedMessage {...messages.modify} />
    </button>
    <div style={{ marginTop: '40px' }}>
      <p className="sub-header">
        <FormattedMessage {...messages.email} />
      </p>
      <p className="information">{props.user && props.user.email}</p>
      <p className="sub-header">
        <FormattedMessage {...messages.password} />
      </p>
      <p className="information">••••••••</p>
      <p className="sub-header">
        <FormattedMessage {...messages.userType} />
      </p>
      <p className="information">{props.user && props.user.people_care}</p>
    </div>
  </SettingsDisplayWrapper>
);

SettingsDisplay.propTypes = {};

export default SettingsDisplay;
