/**
 *
 * RadioButton
 *
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';

import P from 'components/P';
import { deepSeaBlue, errorRed } from '../../global-styles';

const Group = styled.div`
  p {
    margin-top: 10px;
  }
`;

const Container = styled.label`
  display: inline-flex;
  position: relative;
  padding-left: 30px;
  width: 160px;
  height: 34px;
  cursor: pointer;
  align-items: center;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  input:checked ~ span {
    box-shadow: 0 0 10px 0 rgba(99, 140, 177, 0.2);
    border: solid 2px ${deepSeaBlue};
    background-color: white;
  }

  input:checked ~ span:after {
    display: block;
  }

  span:after {
    top: 5px;
    left: 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: ${deepSeaBlue};
  }
`;

const ErrorMessage = styled(P)`
  margin-top: 0 !important;
  font-size: 14px;
  color: ${errorRed};
`;

const Circle = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(99, 140, 177, 0.1);
  border: solid 1px rgba(46, 95, 202, 0.1);
  border-radius: 50%;

  &:after {
    content: '';
    position: absolute;
    display: none;
  }
`;

function RadioButton({ input, meta, options }) {
  const { name, value, onChange } = input;
  const { touched, error } = meta;

  return (
    <Group>
      {_.map(options, option => (
        <Container key={option.value}>
          <P color="#1d212c" fontSize="17px">
            {option.label}
          </P>
          <input
            type="radio"
            name={name}
            value={option.value}
            checked={value.toString() === option.value.toString()}
            onChange={onChange}
          />
          <Circle />
        </Container>
      ))}
      <ErrorMessage>{touched ? error : ''}</ErrorMessage>
    </Group>
  );
}

RadioButton.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  options: PropTypes.array,
};

export default RadioButton;
