import { BBDEAP } from 'utils/environment';

export const isEap = company =>
  (company && company.plan_type === 'eap') || BBDEAP;

export const isEapAndPeopleLeader = (company, status) =>
  company &&
  (company.plan_type === 'eap' || BBDEAP) &&
  status === 'People Leader';

export const isPeopleLeader = status => status === 'People Leader';
