import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import ArticleBody from '../ArticleBody';
import messages from '../../../messages';

const GlobalConflictArticle = () => (
  <div>
    <ArticleBody>
      <FormattedHTMLMessage
        tagName="p"
        {...messages.globalConflictReactionsBody}
      />
    </ArticleBody>
  </div>
);

GlobalConflictArticle.propTypes = {};

export default GlobalConflictArticle;
