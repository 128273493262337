/*
 * OrsSlider Messages
 *
 * This contains all the text for the OrsSlider component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.Ors';

export default defineMessages({
  veryPoorly: {
    id: `${scope}.veryPoorly`,
    defaultMessage: 'Very Poorly',
  },
  veryWell: {
    id: `${scope}.veryWell`,
    defaultMessage: 'Very Well',
  },
});
