import { takeLatest, put as reduxPut, call } from 'redux-saga/effects';
import { API_URL } from 'utils/environment';
import { get, put, post, destroy } from 'utils/request';
import {
  fetchAssessmentSuccess,
  fetchAssessmentError,
  updateAssessmentSuccess,
  updateAssessmentError,
  fetchReportSuccess,
  fetchReportError,
  uploadVideoError,
  uploadVideoSuccess,
  downloadReportSuccess,
  downloadReportError,
  clearAssessmentSuccess,
  clearAssessmentError,
  emailReportSuccess,
  emailReportError,
} from './actions';
import {
  FETCH_ASSESSMENT,
  UPDATE_ASSESSMENT,
  FETCH_REPORT,
  UPLOAD_VIDEO,
  DOWNLOAD_REPORT,
  CLEAR_ASSESSMENT,
  EMAIL_REPORT,
} from './constants';

function* fetchAssessment({ callback }) {
  const requestURL = `${API_URL}/people/assessments/current`;

  try {
    const response = yield call(get, requestURL);
    yield reduxPut(fetchAssessmentSuccess(response));
    if (callback) yield call(callback, response, null);
  } catch (error) {
    yield reduxPut(fetchAssessmentError(error));
    if (callback) yield call(callback, null, error);
  }
}

function* clearAssessment({ id, callback }) {
  const requestURL = `${API_URL}/people/assessments/${id}`;
  try {
    const response = yield call(destroy, requestURL);
    yield reduxPut(clearAssessmentSuccess(response));
    if (callback) yield call(callback, response);
  } catch (error) {
    yield reduxPut(clearAssessmentError(error));
  }
}

function* fetchReport(values) {
  const params = {
    id: values.id,
    lines: values.lines ? JSON.stringify(values.lines) : null,
    report: values.report,
  };
  const requestUrl = `${API_URL}/people/assessments/report`;
  try {
    const response = yield call(post, requestUrl, params);
    yield reduxPut(fetchReportSuccess(response));
    if (values.callback) yield call(values.callback, response);
  } catch (error) {
    yield reduxPut(fetchReportError(error));
  }
}

function* downloadReport(values) {
  const requestURL = `${API_URL}/people/assessments/download_report`;
  const params = {
    id: values.id,
    lines: JSON.stringify(values.lines),
  };

  try {
    const assessment = yield call(put, requestURL, params);
    yield reduxPut(downloadReportSuccess(assessment));
    if (values.callback) yield call(values.callback, assessment);
  } catch (error) {
    yield reduxPut(downloadReportError(error));
  }
}

function* updateAssessment(values) {
  const params = {
    answers: values.answers,
    current_page: values.questionCode,
    current_path: values.currentScreen,
    screens: JSON.stringify(values.paths),
    completed_yn: false,
  };
  const requestURL = `${API_URL}/people/assessments/${values.id}`;

  try {
    const evaluation = yield call(put, requestURL, params);
    yield reduxPut(updateAssessmentSuccess(evaluation));
    if (values.callback) yield call(values.callback, evaluation, null);
  } catch (error) {
    yield reduxPut(updateAssessmentError(error));
    if (values.callback) yield call(values.callback, null, error);
  }
}

function* uploadVideo({ params, callback }) {
  const requestURL = `${API_URL}/people/assessments/upload_video`;

  try {
    const assessment = yield call(put, requestURL, params);
    yield reduxPut(uploadVideoSuccess(assessment));
    if (callback) yield call(callback);
  } catch (error) {
    yield reduxPut(uploadVideoError(error));
  }
}

function* emailReport({ id, lines, callback }) {
  const requestURL = `${API_URL}/people/assessments/email_report`;
  const params = {
    id,
    lines: JSON.stringify(lines),
  };

  try {
    const assessment = yield call(put, requestURL, params);
    yield reduxPut(emailReportSuccess(assessment));
    if (callback) callback(assessment);
  } catch (error) {
    yield reduxPut(emailReportError(error));
  }
}

export default function* assessmentSaga() {
  yield takeLatest(FETCH_ASSESSMENT, fetchAssessment);
  yield takeLatest(UPDATE_ASSESSMENT, updateAssessment);
  yield takeLatest(FETCH_REPORT, fetchReport);
  yield takeLatest(UPLOAD_VIDEO, uploadVideo);
  yield takeLatest(DOWNLOAD_REPORT, downloadReport);
  yield takeLatest(CLEAR_ASSESSMENT, clearAssessment);
  yield takeLatest(EMAIL_REPORT, emailReport);
}
