import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Button, InkblotTheme } from 'inkblot-ui';

import Anchor from 'components/Button';
import H4 from 'components/H4';
import H2 from 'components/H2';
import { darkSlateBlue } from 'global-styles';
import Dialog from 'components/Dialog';
import messages from './messages';

const Container = styled.div`
  text-align: center;

  h2,
  h4 {
    width: 90%;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  h2 {
    margin-bottom: 30px;
  }
`;

function ExitDialog({ closeExitDialog, exitAssessment, open }) {
  return (
    <Dialog onRequestClose={closeExitDialog} open={open}>
      <Container>
        <H2 color={darkSlateBlue}>
          <FormattedMessage {...messages.areYouSure} />
        </H2>
        <H4>
          <FormattedMessage {...messages.youCanReturn} />
        </H4>
        <InkblotTheme>
          <Button
            people
            label={<FormattedMessage {...messages.continueAssessment} />}
            onClick={closeExitDialog}
            singleClick={false}
            style={{ marginTop: '40px' }}
          />
        </InkblotTheme>
        <div style={{ marginTop: '20px' }}>
          <Anchor onClick={exitAssessment}>
            <FormattedMessage {...messages.exit} />
          </Anchor>
        </div>
      </Container>
    </Dialog>
  );
}

ExitDialog.propTypes = {
  closeExitDialog: PropTypes.func,
  exitAssessment: PropTypes.func,
  open: PropTypes.bool,
};

export default ExitDialog;
