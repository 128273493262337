/*
 *
 * Assessment Result
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { InkblotTheme, Button } from 'inkblot-ui';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import AssessmentLayout from 'components/AssessmentLayout';
import { FormattedMessage } from 'react-intl';
import H1 from 'components/H1';
import H3 from 'components/H3';
import { Link } from 'react-router-dom';
import Anchor from 'components/Button';
import { fromJS } from 'immutable';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import LogEventOnMount from 'components/LogEventOnMount';
import LoadingIndicator from 'components/LoadingIndicator';
import SpinnerButton from 'components/SpinnerButton';
import { deepSeaBlue } from '../../../global-styles';
import { trackUserClick } from '../../EmployeeResources/actions';
import reducer from '../../EmployeeResources/reducer';
import saga from '../../EmployeeResources/saga';
import { makeSelectEmailReport, makeSelectReport } from '../selectors';
import {
  renderOCD,
  gatherOCD,
  renderPhobia,
  gatherPhobia,
  renderDissociation,
  gatherDissociation,
  renderPersonality,
  gatherPersonality,
  renderPersonalityDisinhibition,
  gatherPersonalityDisinhibition,
  renderPersonalityNegative,
  gatherPersonalityNegative,
  renderPersonalityPyscho,
  gatherPersonalityPyscho,
  renderPersonalityDetach,
  gatherPersonalityDetach,
  renderPersonalityAntagonism,
  gatherPersonalityAntagonism,
  renderSomatic,
  gatherSomatic,
  renderSocial,
  gatherSocial,
  renderGeneralAnxiety,
  gatherGeneralAnxiety,
  renderAgoraphobia,
  gatherAgoraphobia,
  renderSeparationAnxiety,
  gatherSeparationAnxiety,
  renderEmpty,
  gatherEmpty,
  renderFooter,
  gatherFooter,
  gatherThankYou,
  renderThankYou,
} from './report';
import messages from '../messages';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
  text-align: left;
  h3 {
    margin-bottom: 30px;
  }
`;

const buttonStlye = {
  marginTop: 10,
  backgroundColor: '#b2ddfc',
  backgroundImage: 'unset',
  color: deepSeaBlue,
  textTransform: 'uppercase',
};

/* eslint-disable react/prefer-stateless-function */
class FinalReport extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      reportLoading: false,
    };
  }

  componentDidMount() {
    const { currentAssessment } = this.props;

    this.setState({ reportLoading: true });
    this.props.fetchReport(currentAssessment.get('id'), null, 'cs2', resp =>
      this.props.fetchReport(
        currentAssessment.get('id'),
        this.getLines(fromJS(resp), true),
        'cs2',
        () => this.setState({ reportLoading: false }),
      ),
    );
  }

  componentWillUnmount() {
    this.props.clearEmailReport();
  }

  getLines = report => {
    const lines = [];
    const { locale } = this.props;

    gatherThankYou(lines, locale);
    if (this.isEmpty(report)) gatherEmpty(lines, locale);
    if (report.get('ocd_yn'))
      gatherOCD(lines, report.get('ocd_severity'), locale);
    if (report.get('phobia_yn'))
      gatherPhobia(lines, report.get('phobia_severity'), locale);
    if (report.get('dissociation_yn'))
      gatherDissociation(lines, report.get('dissociation_severity'), locale);
    if (report.get('personality_yn'))
      gatherPersonality(lines, report.get('personality_severity'), locale);
    if (report.get('personality_dis_yn'))
      gatherPersonalityDisinhibition(
        lines,
        report.get('personality_dis_severity'),
        locale,
      );
    if (report.get('personality_neg_yn'))
      gatherPersonalityNegative(
        lines,
        report.get('personality_neg_severity'),
        locale,
      );
    if (report.get('personality_pyscho_yn'))
      gatherPersonalityPyscho(
        lines,
        report.get('personality_pyscho_severity'),
        locale,
      );
    if (report.get('personality_det_yn'))
      gatherPersonalityDetach(
        lines,
        report.get('personality_det_severity'),
        locale,
      );
    if (report.get('personality_ant_yn'))
      gatherPersonalityAntagonism(
        lines,
        report.get('personality_ant_severity'),
        locale,
      );
    if (report.get('somatic_yn'))
      gatherSomatic(lines, report.get('somatic_severity'), locale);
    if (report.get('social_yn'))
      gatherSocial(lines, report.get('social_severity'), locale);
    if (report.get('general_anx_yn'))
      gatherGeneralAnxiety(lines, report.get('general_anx_severity'), locale);
    if (report.get('agoraphobia_yn'))
      gatherAgoraphobia(lines, report.get('agoraphobia_severity'), locale);
    if (report.get('separation_yn'))
      gatherSeparationAnxiety(lines, report.get('separation_severity'), locale);
    gatherFooter(lines, locale);

    return lines;
  };

  isEmpty = report => {
    if (
      report.get('ocd_yn') ||
      report.get('phobia_yn') ||
      report.get('dissociation_yn') ||
      report.get('personality_yn') ||
      report.get('personality_dis_yn') ||
      report.get('personality_neg_yn') ||
      report.get('personality_pyscho_yn') ||
      report.get('personality_det_yn') ||
      report.get('personality_ant_yn') ||
      report.get('somatic_yn') ||
      report.get('social_yn') ||
      report.get('general_anx_yn') ||
      report.get('agoraphobia_yn') ||
      report.get('separation_yn')
    ) {
      return false;
    }
    return true;
  };

  handleUserClick = () => {
    this.props.trackUserClick({
      article_name: 'Virtual counselling after final report',
    });
  };

  emailButtonLabel = () => {
    const { email } = this.props;

    if (email.sent) return <FormattedMessage {...messages.emailSent} />;
    if (email.error) return <FormattedMessage {...messages.emailError} />;

    return <FormattedMessage {...messages.emailReport} />;
  };

  emailReport = () => {
    const { currentAssessment, report } = this.props;
    const lines = this.getLines(report, true);

    this.props.emailReport(currentAssessment.get('id'), lines);
  };

  render() {
    const { report, locale, email } = this.props;
    const { reportLoading } = this.state;

    return (
      <div>
        <AssessmentLayout>
          {reportLoading ? (
            <LoadingIndicator />
          ) : (
            <>
              <H1 fontSize="38px">
                <FormattedMessage {...messages.reportSummaryReport} />
              </H1>
              <Wrapper>
                <H3>
                  <FormattedMessage {...messages.reportResultSummary} />
                </H3>
                {renderThankYou(locale)}
                {this.isEmpty(report) ? renderEmpty(locale) : null}
                {report.get('ocd_yn') &&
                  renderOCD(report.get('ocd_severity'), locale)}
                {report.get('phobia_yn') &&
                  renderPhobia(report.get('phobia_severity'), locale)}
                {report.get('dissociation_yn') &&
                  renderDissociation(
                    report.get('dissociation_severity'),
                    locale,
                  )}
                {report.get('personality_yn') &&
                  renderPersonality(report.get('personality_severity'), locale)}
                {report.get('personality_dis_yn') &&
                  renderPersonalityDisinhibition(
                    report.get('personality_dis_severity'),
                    locale,
                  )}
                {report.get('personality_neg_yn') &&
                  renderPersonalityNegative(
                    report.get('personality_neg_severity'),
                    locale,
                  )}
                {report.get('personality_pyscho_yn') &&
                  renderPersonalityPyscho(
                    report.get('personality_pyscho_severity'),
                    locale,
                  )}
                {report.get('personality_det_yn') &&
                  renderPersonalityDetach(
                    report.get('personality_det_severity'),
                    locale,
                  )}
                {report.get('personality_ant_yn') &&
                  renderPersonalityAntagonism(
                    report.get('personality_ant_severity'),
                    locale,
                  )}
                {report.get('somatic_yn') &&
                  renderSomatic(report.get('somatic_severity'), locale)}
                {report.get('social_yn') &&
                  renderSocial(report.get('social_severity'), locale)}
                {report.get('general_anx_yn') &&
                  renderGeneralAnxiety(
                    report.get('general_anx_severity'),
                    locale,
                  )}
                {report.get('agoraphobia_yn') &&
                  renderAgoraphobia(report.get('agoraphobia_severity'), locale)}
                {report.get('separation_yn') &&
                  renderSeparationAnxiety(
                    report.get('separation_severity'),
                    locale,
                  )}
                {renderFooter(locale)}
              </Wrapper>

              <InkblotTheme>
                <div>
                  {this.props.showDetail && (
                    <Button
                      people
                      label="More detailed evaluation"
                      style={buttonStlye}
                      onClick={() =>
                        this.props.onDetailClick('screen_cross_part2_md')
                      }
                    />
                  )}
                </div>
                <div>
                  <SpinnerButton
                    people
                    label={this.emailButtonLabel()}
                    loading={email.sending}
                    disabled={email.sent}
                    style={buttonStlye}
                    onClick={this.emailReport}
                  />
                </div>
                <div>
                  <a href={report.get('url')} download target="_blank">
                    <Button
                      people
                      label={
                        <FormattedMessage {...messages.reportDownloadReport} />
                      }
                      style={buttonStlye}
                    />
                  </a>
                </div>
                <div>
                  <Link
                    to="/virtual_counselling"
                    onClick={this.handleUserClick}
                  >
                    <Button
                      people
                      label={
                        <FormattedMessage {...messages.reportStartVirtual} />
                      }
                      style={{ textTransform: 'uppercase', marginTop: 10 }}
                    />
                  </Link>
                </div>
              </InkblotTheme>
              <div>
                <Link to="/dashboard">
                  <Anchor onClick={() => {}}>
                    <FormattedMessage {...messages.reportReturnToDashboard} />
                  </Anchor>
                </Link>
              </div>
            </>
          )}
        </AssessmentLayout>
        <LogEventOnMount
          eventType="finish assessment"
          eventProperties={{ report_type: 'final' }}
        />
      </div>
    );
  }
}

FinalReport.propTypes = {
  onDetailClick: PropTypes.func.isRequired,
  showDetail: PropTypes.func.isRequired,
  report: PropTypes.object.isRequired,
  currentAssessment: PropTypes.object,
  fetchReport: PropTypes.func,
  locale: PropTypes.string.isRequired,
  trackUserClick: PropTypes.func.isRequired,
  email: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  report: makeSelectReport(),
  locale: makeSelectLocale(),
  email: makeSelectEmailReport(),
});

const mapDispatchToProps = dispatch => ({
  trackUserClick: values => dispatch(trackUserClick(values)),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'employee_resources', reducer });
const withSaga = injectSaga({ key: 'employee_resources', saga });

export default compose(
  withConnect,
  withReducer,
  withSaga,
)(FinalReport);
