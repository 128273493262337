import styled from 'styled-components';
import { tabletSize } from 'global-styles';
const Navigation = styled.nav`
  width: 35%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  @media (max-width: ${tabletSize}) {
    display: none;
  }
`;

export default Navigation;
