import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the marketingPage state domain
 */

const selectChangePassword = state => state.get('changePassword', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by MarketingPage
 */

const makeChangePasswordPage = () =>
  createSelector(selectChangePassword, substate => substate.toJS());

export default makeChangePasswordPage;
export { selectChangePassword };
