/*
 * Booking Messages
 *
 * This contains all the text for the Booking container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.MarketingPage';

export default defineMessages({
  growingBusinessIssue: {
    id: `${scope}.growingBusinessIssue`,
    defaultMessage:
      'Mental health is a growing business issue, <b>let’s solve it with People Connect.</b>',
  },
  growingBusinessIssueEap: {
    id: `${scope}.growingBusinessIssueEap`,
    defaultMessage:
      ' Life can be challenging. <b>Find the right support with People Connect EAP.</b>',
  },
  getStarted: {
    id: `${scope}.getStarted`,
    defaultMessage: 'Get Started',
  },
  privacyPolicy: {
    id: `${scope}.privacyPolicy`,
    defaultMessage: 'Privacy Policy',
  },
  termsAndConditions: {
    id: `${scope}.termsAndConditions`,
    defaultMessage: 'Terms and Conditions',
  },
});
