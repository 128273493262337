export const FETCH_USER = 'peopleCorp/App/FETCH_USER';
export const FETCH_USER_SUCCESS = 'peopleCorp/App/FETCH_USER_SUCCESS';
export const FETCH_USER_ERROR = 'peopleCorp/App/FETCH_USER_ERROR';
export const UPDATE_USER = 'peopleCorp/App/UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'peopleCorp/App/UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'peopleCorp/App/UPDATE_USER_ERROR';

export const CONFIRM_EMAIL = 'peopleCorp/App/CONFIRM_EMAIL';
export const CONFIRM_EMAIL_SUCCESS = 'peopleCorp/App/CONFIRM_EMAIL_SUCCESS';
export const CONFIRM_EMAIL_ERROR = 'peopleCorp/App/CONFIRM_EMAIL_ERROR';
