/*
 * Signup Messages
 *
 * This contains all the text for the Signup container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Signin';

export default defineMessages({
  email: {
    id: `${scope}.email`,
    defaultMessage: 'Email',
  },
  password: {
    id: `${scope}.password`,
    defaultMessage: 'Password',
  },
  signIn: {
    id: `${scope}.signIn`,
    defaultMessage: 'SIGN IN',
  },
  welcome: {
    id: `${scope}.welcome`,
    defaultMessage: 'Welcome to {bold}, please log in.',
  },
  peopleCare: {
    id: `${scope}.peopleCare`,
    defaultMessage: 'People Connect',
  },
  bbdEap: {
    id: `${scope}.bbdEap`,
    defaultMessage: 'People Connect EAP',
  },
  bbdBap: {
    id: `${scope}.bbdBap`,
    defaultMessage: 'People Connect Carepath BAP',
  },
  dontHaveAccount: {
    id: `${scope}.dontHaveAccount`,
    defaultMessage: "Don't have an account? {item}",
  },
  signUp: {
    id: `${scope}.signUp`,
    defaultMessage: 'Sign up',
  },
  forgotMyPass: {
    id: `${scope}.forgotMyPass`,
    defaultMessage: 'Forgot My Password',
  },
  azureProfileIdExists: {
    id: `${scope}.azureProfileIdExists`,
    defaultMessage: 'This account may already exist. Check your email inbox to complete registration for People Connect Carepath BAP.',
  },
  azureProfileIdExistsEap: {
    id: `${scope}.azureProfileIdExistsEap`,
    defaultMessage: 'This account may already exist. Check your email inbox to complete registration for People Connect EAP.',
  },
  emailError: {
    id: `${scope}.emailError`,
    defaultMessage: 'Incorrect sign in credentials',
  },
});
