import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

import ArticleBody from '../ArticleBody';
import messages from '../../../messages';

const HowManagersCanBuild = () => (
  <div>
    <ArticleBody>
      <FormattedHTMLMessage tagName="p" {...messages.howManagersCanBody} />
    </ArticleBody>
  </div>
);

HowManagersCanBuild.propTypes = {};

export default HowManagersCanBuild;
