import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import ActionCable from 'actioncable';

import { fetchUserSettings } from 'containers/UpdatePopover/actions';

import { getToken } from '../../../utils/auth';
import { SERVER_URL } from '../../../utils/environment';

class NotificationManager extends Component {
  constructor() {
    super();
    this.cable = null;
    this.channel = null;
  }

  componentDidMount() {
    this.subscribe();
  }

  subscribe() {
    const token = getToken();

    if (token) {
      this.cable = ActionCable.createConsumer(
        `${SERVER_URL}/cable?token=${token}`,
      );
      this.channel = this.cable.subscriptions.create(
        { channel: 'WebNotificationChannel' },
        {
          received: this.handleReceived,
          initialized: () => {},
          connected: () => {},
          rejected: () => {
            if (this.cable) this.cable.disconnect();
          },
          disconnected: () => {},
        },
      );
    }
  }

  unsubscribe() {
    if (this.channel) {
      this.channel.unsubscribe();
      this.channel = null;
    }

    if (this.cable) {
      this.cable.disconnect();
      this.cable = null;
    }
  }

  handleReceived = notification => {
    if (notification.type === 'new_update_people') {
      this.props.fetchUserSettings();
    }
  };

  render() {
    return <noscript />;
  }
}

NotificationManager.propTypes = {
  fetchUserSettings: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    fetchUserSettings: () => dispatch(fetchUserSettings()),
  };
}

const withConnect = connect(
  null,
  mapDispatchToProps,
);

export default withRouter(compose(withConnect)(NotificationManager));
