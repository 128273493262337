/*
 *
 * Assessment Introduction
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { InkblotTheme, Button } from 'inkblot-ui';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import AssessmentLayout from 'components/AssessmentLayout';
import H1 from 'components/H1';
import P from 'components/P';
import LogEventOnMount from 'components/LogEventOnMount';
import { phoneSize } from '../../global-styles';
import messages from './messages';

const Center = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 60px;
  max-width: 45%;
  @media (max-width: ${phoneSize}) {
    max-width: 90%;
  }
`;

const DashboardLinkWrapper = styled.div`
  padding-top: 20px;
`;
/* eslint-disable react/prefer-stateless-function */
const Introduction = props => (
  <div>
    <AssessmentLayout>
      <H1 fontSize="38px" style={{ marginTop: '40px' }}>
        <FormattedMessage {...messages.introPeopleCare} />
      </H1>
      <Center>
        <P fontSize="18px">
          <FormattedHTMLMessage {...messages.introWelcome} />
        </P>
        <P fontSize="18px">
          <FormattedMessage {...messages.introThisTool} />
        </P>
        <P fontSize="18px">
          <FormattedMessage {...messages.introYourResponses} />
        </P>
        <P fontSize="18px">
          <FormattedMessage {...messages.introAutoSave} />
        </P>
        <P fontSize="18px">
          <FormattedMessage {...messages.introEmergency} />
        </P>
      </Center>
      <InkblotTheme>
        <Button
          people
          label={<FormattedMessage {...messages.introGetStarted} />}
          onClick={() => props.goToNextPage()}
        />
      </InkblotTheme>
      <DashboardLinkWrapper>
        <Link to="/dashboard">
          <P fontSize="14px">
            <span style={{ textDecoration: 'underline' }}>
              <FormattedMessage {...messages.introReturnToDashboard} />
            </span>
          </P>
        </Link>
      </DashboardLinkWrapper>
      <Link to="/privacy">
        <P fontSize="12px" style={{ marginTop: '60px' }}>
          <FormattedMessage {...messages.privacyPolicy} />
        </P>
      </Link>
    </AssessmentLayout>
    <LogEventOnMount eventType="start assessment" />
  </div>
);

Introduction.propTypes = {
  goToNextPage: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(
  null,
  mapDispatchToProps,
)(Introduction);
