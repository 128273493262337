/**
 *
 * LogEventOnMount
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import { logEvent } from 'utils/amplitude';

/* eslint-disable react/prefer-stateless-function */
class LogEventOnMount extends React.PureComponent {
  componentDidMount() {
    const { eventType, eventProperties } = this.props;

    logEvent(eventType, eventProperties);
  }

  render() {
    return <div />;
  }
}

LogEventOnMount.propTypes = {
  eventType: PropTypes.string.isRequired,
  eventProperties: PropTypes.object,
};

export default LogEventOnMount;
