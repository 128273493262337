import React from 'react';
import { FormattedMessage } from 'react-intl';

import BuildTeamsImg from 'images/articles/banner/build-teams.png';
import SixWaysImg from 'images/articles/banner/six-ways.png';
import BurnoutImg from 'images/articles/banner/burnout.png';
import ReturnImg from 'images/articles/banner/return-work.png';
import BestQualitiesImg from 'images/articles/banner/best-qualities.png';
import UnderstandingImpactImg from 'images/articles/banner/understanding-impact.png';
import effectsOfIsolationImg from 'images/articles/banner/effects-of-isolation.jpg';
import covidTransformationImg from 'images/articles/banner/covid-transformation.jpg';
import quietQuittingImg from '../../../images/articles/banner/quiet-quitting.jpg';
import AdjustingHybridImg from '../../../images/articles/banner/adjusting-to-hybrid.png';
import Authors from './Authors';
import messages from '../messages';

import HowManagersCanBuild from './ArticleContents/HowManagersCanBuild';
import HelpEmployeesThroughChange from './ArticleContents/HelpEmployeesThroughChange';
import SpottingTheSignsOfStress from './ArticleContents/SpottingTheSignsOfStress';
import ReturnToWork from './ArticleContents/ReturnToWork';
import BestQualitiesOfAManager from './ArticleContents/BestQualitiesOfAManager';
import ImpactOfCultureOnMentalHealth from './ArticleContents/ImpactOfCultureOnMentalHealth';
import effectsOfIsolation from './ArticleContents/EffectOfIsolation';
import covidTransformation from './ArticleContents/CovidTransformation';
import quietQuitting from './ArticleContents/QuietQuitting';
import AdjustingToHybrid from './ArticleContents/AdjustingToHybrid/Index';

const ManagerArticles = {
  covid_transformation: {
    id: 'covid_transformation',
    title: <FormattedMessage {...messages.covidTransformationTitle} />,
    description: <FormattedMessage {...messages.covidTransformationDesc} />,
    author: Authors.judyPlotkinTwo,
    ArticleBody: covidTransformation,
    bannerImage: covidTransformationImg,
    supportsFrench: true,
  },

  effects_of_isolation: {
    id: 'effects_of_isolation',
    title: <FormattedMessage {...messages.effectsOfIsolationTitle} />,
    description: <FormattedMessage {...messages.effectsOfIsolationDesc} />,
    author: Authors.judyPlotkinTwo,
    ArticleBody: effectsOfIsolation,
    bannerImage: effectsOfIsolationImg,
    supportsFrench: true,
  },

  how_managers_can_build: {
    id: 'how_managers_can_build',
    title: <FormattedMessage {...messages.howManagersCanTitle} />,
    description: <FormattedMessage {...messages.howManagersCanDesc} />,
    author: Authors.andreaBassett,
    bannerImage: BuildTeamsImg,
    ArticleBody: HowManagersCanBuild,
    sources: [
      'https://www.psychologytoday.com/ca/blog/pressure-proof/201410/seven-things-resilient-employees-do-differently',
      'https://www.fastcompany.com/3060447/7-habits-of-resilient-teams',
    ],
    supportsFrench: true,
  },
  help_employees_through_change: {
    id: 'help_employees_through_change',
    title: <FormattedMessage {...messages.sixWaysManagersTitle} />,
    description: <FormattedMessage {...messages.sixWaysManagersDesc} />,
    author: Authors.andreaBassett,
    bannerImage: SixWaysImg,
    ArticleBody: HelpEmployeesThroughChange,
    sources: [
      'https://www.mckinsey.com/featured-insights/leadership/changing-change-management',
      'https://www.prosci.com/resources/articles/change-management-communication-checklist',
    ],
    supportsFrench: true,
  },
  spotting_the_signs_of_stress_and_flagging_mental_health: {
    id: 'spotting_the_signs_of_stress_and_flagging_mental_health',
    title: <FormattedMessage {...messages.spottingTheSignsTitle} />,
    description: <FormattedMessage {...messages.spottingTheSignsDesc} />,
    author: Authors.judyPlotkin,
    bannerImage: BurnoutImg,
    ArticleBody: SpottingTheSignsOfStress,
    sources: [
      'https://www.canada.ca/en/employment-social-development/services/health-safety/reports/psychological-health.html',
      'https://www.forbes.com/sites/davidsturt/2018/03/08/10-shocking-workplace-stats-you-need-to-know/#87b10aef3afe',
      'https://www.webmd.com/balance/stress-management/stress-level-too-high#1',
      'https://www.workplacestrategiesformentalhealth.com/managing-workplace-issues/burnout-response',
      'https://www.webmd.com/depression/mental-health-warning-signs',
    ],
    supportsFrench: true,
  },
  return_to_work: {
    id: 'return_to_work',
    title: <FormattedMessage {...messages.returnToWorkTitle} />,
    description: <FormattedMessage {...messages.returnToWorkDesc} />,
    author: Authors.isabelleLipari,
    ArticleBody: ReturnToWork,
    bannerImage: ReturnImg,
    supportsFrench: true,
  },
  best_qualities_of_a_manager: {
    id: 'best_qualities_of_a_manager',
    title: <FormattedMessage {...messages.bestQualitiesTitle} />,
    description: <FormattedMessage {...messages.bestQualitiesDesc} />,
    author: Authors.isabelleLipari,
    ArticleBody: BestQualitiesOfAManager,
    bannerImage: BestQualitiesImg,
    sources: [
      'Merriam-Webster Dictionary.',
      '2. Emotional Intelligence, Daniel Goleman, 2005.',
    ],
    supportsFrench: true,
  },
  understanding_the_impact_of_culture_on_mental_health: {
    id: 'understanding_the_impact_of_culture_on_mental_health',
    title: 'Understanding the impact of culture on mental health',
    description:
      'To understand the impact of culture on mental health, we must first define what organizational culture is. Organizational culture is a set of values...',
    author: Authors.isabelleLipari,
    ArticleBody: ImpactOfCultureOnMentalHealth,
    bannerImage: UnderstandingImpactImg,
    sources: [
      'http://www.businessdictionary.com/definition/organizational-culture.html',
      'https://www.guardingmindsatwork.ca/about/about-psychosocial-factors',
      'https://www.drmichellerozen.com/success/the-seven-characteristics-of-successful-company-cultures/',
    ],
  },
  quiet_quitting: {
    id: 'quiet_quitting',
    title: <FormattedMessage {...messages.quietQuittingTitle} />,
    description: <FormattedMessage {...messages.quietQuittingDesc} />,
    author: Authors.andreaBassettTwo,
    ArticleBody: quietQuitting,
    bannerImage: quietQuittingImg,
    supportsFrench: true,
  },
  adjusting_to_hybrid: {
    id: 'adjusting_to_hybrid',
    title: <FormattedMessage {...messages.adjustHybridTitle} />,
    description: <FormattedMessage {...messages.adjustHybridDesc} />,
    author: Authors.andreaBassettTwo,
    ArticleBody: AdjustingToHybrid,
    bannerImage: AdjustingHybridImg,
    supportsFrench: true,
  },
  specialized_services: {
    id: 'specialized_services',
    title: '',
    description: <FormattedMessage {...messages.specializedServices} />,
    type: 'noButton',
    supportsFrench: true,
  },
};

export default ManagerArticles;
