/**
 *
 * SupportFaq
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import MailIcon from 'images/mail.png';
import PortalLayout from 'components/PortalLayout';
import H3 from 'components/H3';
import { isEapAndPeopleLeader } from 'containers/Dashboard/helpers';
import { BBDBAP, BBDEAP } from 'utils/environment';
import Card from './Card';
import messages from './messages';
import FaqEmployee from './FaqEmployee';
import FaqPeopleLeader from './FaqPeopleLeader';
import FaqEmployeeEap from './FaqEmployeeEap';
import FaqPeopleLeaderBap from './FaqPeopleLeaderBap';
import FaqEmployeeBap from './FaqEmployeeBap';

class SupportFaq extends React.PureComponent {
  render() {
    const { peopleCare, company, intl } = this.props;

    const renderFaq = () => {
      if (isEapAndPeopleLeader(company, peopleCare) || BBDEAP) {
        return <FaqEmployeeEap />;
      }

      if (BBDBAP) {
        if (peopleCare === 'People Leader') {
          return <FaqPeopleLeaderBap locale={intl.locale} />;
        }
        return <FaqEmployeeBap />;
      }
      if (peopleCare === 'People Leader') {
        return <FaqPeopleLeader company={company} />;
      }
      return <FaqEmployee />;
    };

    const shouldShowSupportBox = () => {
      if (!company) return;
      const planType = company.plan_type;

      switch (planType) {
        case 'bbd_eap':
          return false;
        case 'bbd_bap':
          return false;
        default:
          return true;
      }
    };

    return (
      <div>
        <Helmet>
          <title>{intl.formatMessage(messages.supportAndFaq)}</title>
          <meta name="description" content="Support and FAQ" />
        </Helmet>
        <PortalLayout
          title={<FormattedMessage {...messages.supportAndFaq} />}
          activeTab="faq"
        >
          {shouldShowSupportBox() && (
            <Card>
              <H3 className="title">
                <FormattedMessage {...messages.support} />
              </H3>
              <H3>
                <FormattedMessage
                  {...messages.haveAQuestionEap}
                  values={{
                    phone: <a href="tel:1-855-933-0103">1-855-933-0103</a>,
                  }}
                />
              </H3>
              <div className="email">
                <img className="mail" src={MailIcon} alt="mail" />
                <a href="mailto:support@pcpeopleconnect.com" className="email">
                  support@pcpeopleconnect.com
                </a>
              </div>
            </Card>
          )}
          {renderFaq()}
        </PortalLayout>
      </div>
    );
  }
}

SupportFaq.propTypes = {
  peopleCare: PropTypes.string,
  company: PropTypes.object,
  intl: intlShape,
};

function mapStateToProps(state) {
  const peopleCare = state.toJS().app.user.data.people_care;
  // eslint-disable-next-line prefer-destructuring
  const company = state.toJS().app.user.data.company;
  return { peopleCare, company };
}

const withConnect = connect(
  mapStateToProps,
  null,
);

export default compose(withConnect)(injectIntl(SupportFaq));
