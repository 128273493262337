/**
 *
 * CriticalIncidentServices
 *
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import {
  injectIntl,
  intlShape,
  FormattedMessage,
  FormattedHTMLMessage,
} from 'react-intl';
import { compose } from 'redux';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';

import PortalLayout from 'components/PortalLayout';
import CriticalIncidentImg from 'images/dashboard/crit-inc-services.jpg';
import { BBDBAP } from 'utils/environment';
import { fetchUser } from '../App/actions';
import { trackUserClick } from '../EmployeeResources/actions';
import Card from './Card';
import messages from './messages';
import reducer from '../EmployeeResources/reducer';
import saga from '../EmployeeResources/saga';

// eslint-disable-next-line react/prefer-stateless-function
class CriticalIncidentServices extends Component {
  render() {
    const { intl, status } = this.props;

    const defaultBody = () => (
      <>
        <h1>
          <FormattedMessage {...messages.critServTitle} />
        </h1>
        <h2>
          <FormattedMessage {...messages.critServSubtitle} />
        </h2>
        <p>
          <FormattedMessage {...messages.announcement} />
        </p>
        <p>
          <FormattedMessage {...messages.criticalIncidentSupport} />
        </p>
        <p>
          <FormattedMessage {...messages.traumaInformedDebriefings} />
        </p>
        <p>
          <FormattedMessage {...messages.traumaInformedFollowUp} />
        </p>
        <h3>
          <FormattedMessage {...messages.workplaceDisruptionsTypes} />
        </h3>
        <h4>
          <FormattedMessage {...messages.acuteDisruptions} />
        </h4>
        <p>
          <FormattedMessage {...messages.whetherTheDisruption} />
        </p>
        <p>
          <i>
            <FormattedMessage {...messages.deathOfACoworker} />
          </i>
        </p>
        <h4>
          <FormattedMessage {...messages.criticalIncidents} />
        </h4>
        <p>
          <FormattedMessage {...messages.criticalIncidentsTend} />
        </p>
        <p>
          <i>
            <FormattedMessage {...messages.naturalDisasters} />
          </i>
        </p>
        <h4>
          <FormattedMessage {...messages.systemicDisruptions} />
        </h4>
        <p>
          <FormattedMessage {...messages.systemicDisruptionsInvolve} />
        </p>
        <p>
          <i>
            <FormattedMessage {...messages.systemicStress} />
          </i>
        </p>
        <h3>
          <FormattedMessage {...messages.traumaInformedFollowUpHeading} />
        </h3>
        <p>
          <FormattedMessage {...messages.traumaInformedFollowUpDetails} />
        </p>
        <p>
          <FormattedMessage {...messages.traumaInformedFollowUpDetails2} />
        </p>
        <p>
          <FormattedMessage {...messages.traumaInformedFollowUpDetails3} />
        </p>
        <p>
          <FormattedMessage {...messages.traumaInformedFollowUpDetails4} />
        </p>
        <h3>
          <FormattedMessage {...messages.clinicalImpactAssessment} />
        </h3>
        <p>
          <FormattedMessage {...messages.clinicalImpactAssessmentDetails} />
        </p>
        {status === 'People Leader' && (
          <>
            <h3>
              <FormattedMessage {...messages.feesHeading} />
            </h3>
            <h3>
              <FormattedMessage {...messages.disruptionFees} />
            </h3>
            <p>
              <FormattedMessage {...messages.disruptionFeesDetails} />
            </p>
            <p>
              <FormattedMessage {...messages.travelFeesDetails} />
            </p>
            <h3>
              <FormattedMessage {...messages.traumaSupportFees} />
            </h3>
            <p>
              <FormattedMessage {...messages.traumaSupportFeesDetails} />
            </p>
            <p>
              <FormattedMessage {...messages.traumaSupportFeesDetails2} />
            </p>
            <h3>
              <FormattedMessage {...messages.contactDetails} />
            </h3>
            <FormattedHTMLMessage tagName="p" {...messages.email} />
            <p>
              <FormattedMessage {...messages.telephone} />
            </p>
          </>
        )}
        {status === 'Employee/Member' && (
          <>
            <h3>
              <FormattedMessage {...messages.helpingWorkplaces} />
            </h3>
            <p>
              <FormattedMessage {...messages.theseAreServices} />
            </p>
          </>
        )}
      </>
    );

    const bapBody = () => (
      <>
        <FormattedHTMLMessage tagName="h1" {...messages.titleBap} />
        <FormattedHTMLMessage tagName="h3" {...messages.subtitleBap} />
        <FormattedHTMLMessage tagName="p" {...messages.body1} />
        <FormattedHTMLMessage tagName="p" {...messages.body2} />
        <FormattedHTMLMessage tagName="p" {...messages.body3} />
        <FormattedHTMLMessage tagName="p" {...messages.body4} />
        <FormattedHTMLMessage tagName="p" {...messages.body5} />
        <FormattedHTMLMessage tagName="p" {...messages.body6} />
        <FormattedHTMLMessage tagName="p" {...messages.body7} />
        <FormattedHTMLMessage tagName="p" {...messages.body8} />
        <FormattedHTMLMessage tagName="p" {...messages.body9} />
        <FormattedHTMLMessage tagName="p" {...messages.body10} />
        <FormattedHTMLMessage tagName="p" {...messages.body11} />
        <FormattedHTMLMessage tagName="p" {...messages.body12} />
        <FormattedHTMLMessage tagName="p" {...messages.body13} />
        <FormattedHTMLMessage tagName="p" {...messages.body14} />
        <FormattedHTMLMessage tagName="p" {...messages.body15} />
        <FormattedHTMLMessage tagName="p" {...messages.body16} />
        <FormattedHTMLMessage tagName="p" {...messages.body17} />
        <FormattedHTMLMessage tagName="p" {...messages.body18} />
        <FormattedHTMLMessage tagName="p" {...messages.body19} />
        <FormattedHTMLMessage tagName="p" {...messages.body20} />
        <FormattedHTMLMessage tagName="p" {...messages.body21} />
        <FormattedHTMLMessage tagName="p" {...messages.body22} />
        <FormattedHTMLMessage tagName="p" {...messages.body23} />
        <FormattedHTMLMessage tagName="p" {...messages.body24} />
        <FormattedHTMLMessage tagName="p" {...messages.body25} />
        <FormattedHTMLMessage tagName="p" {...messages.body26} />
        <FormattedHTMLMessage tagName="p" {...messages.body27} />
        <FormattedHTMLMessage tagName="p" {...messages.body28} />
        <FormattedHTMLMessage tagName="p" {...messages.body29} />
        <FormattedHTMLMessage tagName="p" {...messages.body30} />
        <FormattedHTMLMessage tagName="p" {...messages.body31} />
      </>
    );

    return (
      <div>
        <Helmet>
          <title>{intl.formatMessage(messages.critServTitle)}</title>
          <meta
            name="description"
            content="Description of Critical Incident Services"
          />
        </Helmet>
        <PortalLayout activeTab="critical_incident_services">
          <Card>
            <img
              src={CriticalIncidentImg}
              className="headerImg"
              alt="two women sitting at a table"
            />
            {BBDBAP ? bapBody() : defaultBody()}
          </Card>
        </PortalLayout>
      </div>
    );
  }
}

CriticalIncidentServices.propTypes = {
  intl: intlShape,
  trackUserClick: PropTypes.func.isRequired,
  fetchUser: PropTypes.func,
  copmany: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    trackUserClick: values => dispatch(trackUserClick(values)),
    fetchUser: callback => dispatch(fetchUser(callback)),
  };
}

function mapStateToProps(state) {
  // eslint-disable-next-line prefer-destructuring
  const company = state.toJS().app.user.data.company;
  const status = state.toJS().app.user.data.people_care;
  return { company, status };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'employee_resources', reducer });
const withSaga = injectSaga({ key: 'employee_resources', saga });

export default compose(
  withConnect,
  withReducer,
  withSaga,
)(injectIntl(CriticalIncidentServices));
