/*
 * ConfirmSignUp Messages
 *
 * This contains all the text for the ConfirmSignUp component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.ConfirmSignUp';

export default defineMessages({
  confirmAccount: {
    id: `${scope}.confirmAccount`,
    defaultMessage: 'Confirm Your Account!',
  },
  alreadyConfirmed: {
    id: `${scope}.alreadyConfirmed`,
    defaultMessage: 'Already Confirmed?',
  },
  signIn: {
    id: `${scope}.signIn`,
    defaultMessage: 'Sign In',
  },
  sentConfirmation: {
    id: `${scope}.sentConfirmation`,
    defaultMessage:
      'We have sent you a confirmation, please check your email and follow the link provided',
  },
});
