import { css } from 'styled-components';
import { deepSeaBlue } from '../../global-styles';

const anchorStyles = css`
  color: ${deepSeaBlue};
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.6px;
  cursor: pointer;
  text-align: left;
  text-decoration: underline;
`;

export default anchorStyles;
