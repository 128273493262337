/**
 *
 * MarketingPage
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { withRouter, Link } from 'react-router-dom';
import styled from 'styled-components';
import { reduxForm, Field } from 'redux-form/immutable';
import { compose } from 'redux';
import Body from 'components/Body';
import { setToken } from 'utils/auth';
import { ROOT_URL, API_URL, US_URL, BBDEAP, BBDBAP } from 'utils/environment';
import SpinnerButton from 'components/SpinnerButton';
import PasswordInput from 'components/PasswordInput';

import TextField from './TextField';
import { errorRed, tabletSize } from '../../../global-styles';
import { signIn } from '../actions';
import messages from '../messages';
import { validateRequired } from './validations';

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;

const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  .input-container {
    width: 100%;
    margin-bottom: 20px;
    p {
      text-transform: uppercase;
      font-size: 12px;
      &.error-text {
        text-transform: none;
        color: ${errorRed};
        margin-top: 5px;
      }
    }
    input {
      width: 100%;
      margin: 10px 0 0 0;
      height: 40px;
      background-color: rgba(192, 208, 223, 0.26);
      padding: 10px;
      :first-of-type {
        margin-right: 20px;
      }
      ::placeholder {
        font-family: 'Montserrat', sans-serif;
        color: #8e8e8e;
      }
    }
    .eye-icon {
      right: 10px;
    }
  }
`;

const Center = styled.div`
  @media (max-width: ${tabletSize}) {
    text-align: center;
  }
`;

class SignInForm extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      loading: false,
    };
  }

  onSubmit = values => {
    const params = {...values.toJS(), locale: this.props.locale}
    this.setState({ loading: true });
    this.props.signIn(
      params,
      API_URL,
      token => {
        setToken(token);
        window.setTimeout(() => this.props.history.push('/dashboard'), 0);
        this.setState({ loading: false });
      },
      () => {
        if(this.props.loginError === 'azure-profile-id') {
          this.setState({loading: false})
        } else {
          this.props.signIn(
            params,
            US_URL,
            token => {
              setToken(token);
              window.location.href = `https://us.pcpeopleconnect.com/redirect/${token}`;
            },
            () => this.setState({ loading: false }),
          );
        }
      },
    );
  };

  render() {
    const { handleSubmit, loginError } = this.props;
    const { loading } = this.state;

    const parseLoginError = () => {
      if (loginError.toLowerCase() === 'azure-profile-id' && BBDBAP) {
        return <FormattedHTMLMessage tagName="span" {...messages.azureProfileIdExists}/>
      } else if (loginError.toLowerCase() === 'azure-profile-id' && BBDEAP) {
        return <FormattedHTMLMessage tagName="span" {...messages.azureProfileIdExistsEap}/>
      } else if (loginError.toLocaleLowerCase() === 'azure-profile-id') {
        return <FormattedHTMLMessage tagName="span" {...messages.emailError}/>;
      } else {
        return loginError
      }
    }

    return (
      <div>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <FormWrapper id="signupForm">
            <InputRow>
              <Field
                label={<FormattedMessage {...messages.email} />}
                component={TextField}
                validate={validateRequired}
                name="email"
                id="email"
              />
            </InputRow>
            <InputRow>
              <Field
                label={<FormattedMessage {...messages.password} />}
                component={PasswordInput}
                validate={[validateRequired]}
                name="password"
                id="password"
              />
            </InputRow>
          </FormWrapper>
          {loginError && (
            <p style={{ color: '#cf1a1a', marginBottom: '40px' }}>
              {parseLoginError()}
            </p>
          )}
          <Center>
            <SpinnerButton
              type="submit"
              id="submit"
              label={<FormattedMessage {...messages.signIn} />}
              people
              style={{ marginBottom: 30 }}
              loading={loading}
            />
            <Body>
              <FormattedMessage
                {...messages.dontHaveAccount}
                values={{
                  item: (
                    <Link to="/signup">
                      <FormattedMessage {...messages.signUp} />
                    </Link>
                  ),
                }}
              />
            </Body>
            <a href={`${ROOT_URL}/forgot_password`}>
              <Body bold>
                <FormattedMessage {...messages.forgotMyPass} />
              </Body>
            </a>
          </Center>
        </form>
      </div>
    );
  }
}

SignInForm.propTypes = {};

function mapStateToProps(state) {
  const loginError = state.getIn(['signin', 'error', 'loginError']);
  const locale = state.getIn(['language', 'locale']);
  return {
    loginError,
    locale
  };
}

function mapDispatchToProps(dispatch) {
  return {
    signIn: (values, url, callback, errCallback) =>
      dispatch(signIn(values, url, callback, errCallback)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect)(
  withRouter(
    reduxForm({
      form: 'SignInForm',
      enableReinitialize: true,
    })(SignInForm),
  ),
);
