/**
 *
 * ConfirmSignUp
 *
 */

import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import H2 from 'components/H2';
import Body from 'components/Body';
import messages from './messages';

const ConfirmSignUpWrapper = styled.div`
  background-color: #ffffff;
  width: 66%;
  padding: 60px;
  margin: 0 auto;
  margin-top: 200px;
`;

function ConfirmSignUp() {
  return (
    <ConfirmSignUpWrapper>
      <H2>
        <FormattedMessage {...messages.confirmAccount} />
      </H2>
      <Body style={{ marginBottom: '20px' }}>
        <FormattedMessage {...messages.alreadyConfirmed} />{' '}
        <a href="/signin" style={{ color: '#2e5fca' }}>
          <FormattedMessage {...messages.signIn} />
        </a>
      </Body>
      <Body>
        <FormattedMessage {...messages.sentConfirmation} />{' '}
      </Body>
    </ConfirmSignUpWrapper>
  );
}

ConfirmSignUp.propTypes = {};

export default ConfirmSignUp;
