import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { reduxForm, Field } from 'redux-form/immutable';
import { connect } from 'react-redux';
import H2 from 'components/H2';
import { FormattedMessage } from 'react-intl';
import { updateUser } from 'containers/App/actions';
import PasswordInput from 'components/PasswordInput';
import { fetchStatuses, changePassword } from './actions';
import SelectInput from './SelectInput';
import messages from './messages';
import { errorRed } from '../../global-styles';
import {
  validateRequired,
  validatePasswordLength,
  validatePasswordsMatch,
} from './helpers';

const SettingsFormWrapper = styled.div`
  p.sub-header {
    text-transform: uppercase;
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #00568e;
    margin-bottom: 20px;
  }
  p.information {
    font-family: 'Lato';
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1d212c;
    margin-bottom: 40px;
  }
  .input-container {
    width: 100%;
    margin-bottom: 20px;
    p {
      text-transform: uppercase;
      font-size: 14px;

      &.error-text {
        text-transform: none;
        color: ${errorRed};
        margin-top: 5px;
      }
    }
    input {
      width: 200px;
      margin: 10px 0 0px 0;
      height: 40px;
      padding-left: 10px;
      background-color: rgba(192, 208, 223, 0.26);
      ::placeholder {
        font-family: 'Montserrat', sans-serif;
        color: #8e8e8e;
      }
    }
  }
`;

class SettingsForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.fetchStatuses();
  }

  onSubmit = values => {
    const status = values.get('status');
    if (
      values.toJS().password &&
      values.toJS().current_password &&
      values.toJS().confirm_password
    ) {
      this.props.changePassword(values.toJS(), res => console.log(res, 'cp'));
    }

    if (status) {
      this.props.updateUser({ status }, res => console.log(res, 'uu'));
    }
    this.props.toggleForm();
  };

  render() {
    const { statuses, user, handleSubmit, incorrectPasswordError } = this.props;

    return (
      <SettingsFormWrapper>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <H2 style={{ display: 'inline', fontWeight: 500, fontSize: '18px' }}>
            <FormattedMessage {...messages.settings} />
          </H2>
          <button
            type="submit"
            className="new-button"
            // onClick={this.props.toggleForm}
          >
            <FormattedMessage {...messages.saveChanges} />
          </button>
          <div style={{ marginTop: '40px' }}>
            <p className="sub-header">
              <FormattedMessage {...messages.email} />
            </p>
            <p className="information">{user && user.email}</p>
            <Field
              name="current_password"
              component={PasswordInput}
              label={<FormattedMessage {...messages.currentPassword} />}
              validate={[validateRequired]}
              bError={'' || incorrectPasswordError}
              wide
            />
            <Field
              name="password"
              component={PasswordInput}
              label={<FormattedMessage {...messages.newPassword} />}
              validate={[validateRequired, validatePasswordLength]}
              wide
            />
            <Field
              name="confirm_password"
              component={PasswordInput}
              label={<FormattedMessage {...messages.confirmPassword} />}
              validate={[
                validateRequired,
                validatePasswordLength,
                validatePasswordsMatch,
              ]}
              wide
            />
            <Field
              component={SelectInput}
              label={<FormattedMessage {...messages.userType} />}
              isSearchable
              options={statuses.map(status => {
                const newStatus = status.label
                  .toLowerCase()
                  .replace(/\s/g, '')
                  .replace('/', '');
                return {
                  value: status.value,
                  label: <FormattedMessage {...messages[newStatus]} />,
                };
              })}
              name="status"
            />
          </div>
        </form>
      </SettingsFormWrapper>
    );
  }
}
SettingsForm.propTypes = {
  statuses: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  user: PropTypes.object,
  toggleForm: PropTypes.func,
  handleSubmit: PropTypes.func,
  changePassword: PropTypes.func,
  incorrectPasswordError: PropTypes.string,
};

function mapStateToProps(state) {
  const { settings } = state.toJS();
  const status =
    state.toJS().app &&
    state.toJS().app.user.data &&
    state.toJS().app.user.data.people_care;
  const { statuses } = settings;

  function search(nameKey, myArray) {
    for (let i = 0; i < myArray.length; i += 1) {
      if (myArray[i].label === nameKey) {
        return myArray[i];
      }
    }
    return null;
  }
  const matchingSearch =
    statuses &&
    status &&
    search(status, statuses) &&
    search(status, statuses).value;
  const incorrectPasswordError =
    settings.error &&
    settings.error.data &&
    settings.error.data.incorrectPasswordError;
  return {
    statuses,
    incorrectPasswordError,
    initialValues: {
      status: matchingSearch,
    },
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchStatuses: () => dispatch(fetchStatuses()),
    updateUser: (values, callback) => dispatch(updateUser(values, callback)),
    changePassword: (values, callback) =>
      dispatch(changePassword(values, callback)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm({ form: 'SettingsForm', enableReinitialize: true })(SettingsForm));
