import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { web, phone } from '../sizes';
import messages from './messages';

const ManageWrapper = styled.div`
  width: 50%;
  margin-left: 100px;
  h2 {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.7px;
    color: #3c9dd7;
    margin-bottom: 20px;
  }
  h1 {
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 0.2px;
    color: #1f275b;
    margin-bottom: 30px;
  }
  span {
    display: block;
    font-family: Lato;
    font-size: 14px;
    line-height: 1.36;
    letter-spacing: 0.1px;
    color: #1f275b;
    margin-bottom: 60px;
  }
  button {
    display: block;
  }
  @media (max-width: ${web}) {
    margin-left: 50px;
  }
  @media (max-width: ${phone}) {
    width: 100%;
    margin-left: 0px;
    margin-bottom: 60px;
  }
`;

const Manage = props => (
  <ManageWrapper>
    <h2>
      {
        props.eap ?
        <FormattedMessage {...messages.eapCoreElements} /> :
        <FormattedMessage {...messages.coreElements} />
      }
    </h2>
    <h1>
      {
        props.eap ?
          <FormattedMessage {...messages.eapManageTheChallenge} /> :
          <FormattedMessage {...messages.manageTheChallenge} />
      }
    </h1>
    <span>
      {
        props.eap ? 
          <FormattedHTMLMessage tagName="span" {...messages.eapOfferLeaderResources} /> :
          <FormattedHTMLMessage tagName="span" {...messages.offerLeaderResources} />
      }
    </span>
    <Link to={props.acrc ? '/signup?acrc=true' : '/signup'}>
      <button type="button" className="get-started">
        <FormattedMessage {...messages.getStarted} />
      </button>
    </Link>
  </ManageWrapper>
);

Manage.propTypes = {
  acrc: PropTypes.bool.isRequired,
};

export default Manage;
