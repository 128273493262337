import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import messages from './messages';
import GuyGirlLaptop from './guy-girl-laptop.png';
import BottomBlob from './bottom-blob.png';
import { web, tablet, phone } from '../sizes';

const StyledFooter = styled.div`
  overflow-x: hidden;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 400px;
  padding-left: 200px;
  padding-right: 100px;
  padding-bottom: 200px;
  background-image: url(${BottomBlob});
  background-position: bottom;
  background-size: 100% 924px;
  .left-container {
    h1 {
      max-width: 520px;
      font-size: 34px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      margin-bottom: 60px;
    }
    .get-started {
      margin-bottom: 200px;
    }
    .links-container {
      a {
        white-space: nowrap;
        font-size: 14px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 1.4px;
        color: #ffffff;
        text-transform: uppercase;
        text-decoration: none;
        margin-right: 60px;
        &:last-child {
          margin-right: 0px;
        }
        &:focus {
          padding: 6px 0;
          border: 2px solid #0a5dc2;
          border-radius: 5px;
          position: relative;
        }
      }
    }
  }
  .right-container {
    margin-top: -400px;
    img {
      margin-top: 10px;
      width: 440px;
      height: 519px;
    }
    span {
      text-align: right;
      display: block;
      margin-top: 300px;
      font-family: 'Lato';
      font-size: 10px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 2.3px;
      color: #ffffff;
    }
  }
  @media (max-width: ${web}) {
    padding-top: 200px;
    padding-left: 70px;
    padding-right: 70px;
    .right-container {
      margin-top: -200px;
      img {
        width: 391px;
        height: 461px;
      }
    }
  }
  @media (max-width: ${tablet}) {
    flex-direction: column;
    padding-top: 300px;
    .left-container {
      max-width: 300px;
      margin-right: 50px;
      h1 {
        font-size: 24px;
      }
    }

    .right-container {
      img {
        display: none;
      }
    }
  }
  @media (max-width: ${phone}) {
    flex-direction: column;
    padding-top: 100px;
    padding-left: 20px;
    padding-right: 0px;
    background-size: 540px 1500px;
    .left-container {
      margin-right: 0px;
      .links-container {
        text-align: center;
        a {
          display: block;
          margin-right: 0px;
          margin-bottom: 60px;
        }
      }
    }
    .right-container {
      img {
        display: none;
      }
    }
  }
`;

const Footer = props => (
  <StyledFooter>
    <div className="left-container">
      {props.eap ? (
        <FormattedHTMLMessage
          tagName="h1"
          {...messages.growingBusinessIssueEap}
        />
      ) : (
        <FormattedHTMLMessage tagName="h1" {...messages.growingBusinessIssue} />
      )}
      <Link tabIndex={-1} to={props.acrc ? '/signup?acrc=true' : '/signup'}>
        <button type="button" className="get-started">
          <FormattedMessage {...messages.getStarted} />
        </button>
      </Link>
      <div className="links-container">
        <Link tabIndex={0} to="/privacy">
          <FormattedMessage {...messages.privacyPolicy} />
        </Link>
        <Link tabIndex={0} to="/conditions">
          <FormattedMessage {...messages.termsAndConditions} />
        </Link>
      </div>
    </div>
    <div className="right-container">
      <img src={GuyGirlLaptop} alt="gg-l" />
      <span>
        {props.locale === 'fr'
          ? `© ${new Date().getFullYear()} LA CORPORATION PEOPLE`
          : `© ${new Date().getFullYear()} PEOPLE CORPORATION`}
      </span>
    </div>
  </StyledFooter>
);

Footer.propTypes = {
  acrc: PropTypes.bool.isRequired,
  eap: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  locale: makeSelectLocale(),
});

export default connect(mapStateToProps)(Footer);
