export const validateRequired = value =>
  value ? undefined : 'Please complete';

export const validateEmail = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Please enter a valid email'
    : undefined;

export const validateEmailMatch = (value, allValues) =>
  value !== allValues.get('email_new') ? "Emails don't match" : undefined;

export const validateBirthday = dateString => {
  // First check for the pattern
  if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) return 'Not a valid date.';

  // Parse the date parts to integers
  const parts = dateString.split('/');
  const month = parseInt(parts[1], 10);
  const day = parseInt(parts[0], 10);
  const year = parseInt(parts[2], 10);

  const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0))
    monthLength[1] = 29;

  // Check range of year
  if (year < 1880 || year > new Date().getFullYear()) {
    return 'Year is out of range. Please use DD/MM/YYYY';
  }

  // Check the ranges of month
  if (month === 0 || month > 12) {
    return 'Month is out of range. Please use DD/MM/YYYY';
  }

  // Check the range of the day
  if (!(day > 0 && day <= monthLength[month - 1])) {
    return 'Day is out of range. Please use DD/MM/YYYY';
  }
  return false;
};

export const normalizeDate = value => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 2) {
    return onlyNums;
  }
  if (onlyNums.length <= 4) {
    return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2)}`;
  }
  return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}/${onlyNums.slice(
    4,
    8,
  )}`;
};

export const validatePasswordLength = value =>
  value && value.length < 8 ? 'Password must be at least 8 characters' : '';

export const validatePasswordsMatch = (value, allValues) =>
  value !== allValues.get('password') ? "Passwords don't match" : undefined;
