import { createGlobalStyle } from 'styled-components';

export const black = '#1d212c';
export const deepSeaBlue = '#00568e';
export const darkSlateBlue = '#1f275b';
export const errorRed = '#cf1a1a';
export const green = '#1fb18c';

export const teenyWeenySize = '375px';
export const phoneSize = '540px';
export const phoneSizeEnd = '1023.9px';
export const tabletSize = '1024px';
export const desktopSize = '1440px';

const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
  }

  body {
    font-family: 'Lato', sans-serif;
  }

  body.fontLoaded {
    font-family: 'Lato', sans-serif;
  }

  .no-scroll {
    overflow: hidden;
  }

  a.link {
    cursor: pointer;
    text-decoration: none;
    font-size: 12px;
    font-weight: 600;
  }

  #app {
    background-color: #eff3f7;
    min-height: 100%;
    min-width: 100%;
  }

  h1,
  h2,
  h3 {
    font-family: 'Montserrat', sans-serif;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }
  a {
    text-decoration: none;
  }
  a.dashText {
    color: #00568e;
    text-decoration: underline;
  }

  .ors-custom-slider {
    .rs-slider-handle {
      &::before {
        top: -8px;
        border: 10px solid #6F74FF;
        width: 30px;
        height: 30px;
        margin-left: -14px;
      }
      .rs-tooltip {
        top: -40px;
        padding: 0;
        line-height: 0.8;
        .rs-tooltip-inner {
          color: #6F74FF;
          font-size: 36px;
          background-color: #fafafa;
          top: -30px;
          margin: 0 10px;
          padding: 0
        }
        .rs-tooltip-arrow {
          display: none;
        }
      }
    }
    .rs-slider-graduator {
      ul {
        li {
          &::before {
            background-color: transparent;
            border: none;
          }
          &::after {
            background-color: transparent;
            border: none;
          }
        }
      }
      .rs-slider-mark {
        top: -40px;
      }
      ul > li {
       &::after {
        border: none;
      }
    }
  }
}

// Safari 7+ suport for ORS slider
_::-webkit-full-page-media, _:future, :root .ors-custom-slider .rs-slider-handle::before { top: 5px }
_::-webkit-full-page-media, _:future, :root .ors-custom-slider .rs-slider-graduator .rs-slider-mark { top: -45px }
_::-webkit-full-page-media, _:future, :root .ors-custom-slider .rs-slider-handle .rs-tooltip { top: -30px }


`;

export default GlobalStyle;
