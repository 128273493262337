/*
 *
 * EmployeeResources
 *
 */

import React from 'react';
import { Helmet } from 'react-helmet';

import PortalLayout from 'components/PortalLayout';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import _ from 'lodash';
import messages from 'containers/Dashboard/messages';
import { createStructuredSelector } from 'reselect';
import { makeSelectApp } from 'containers/App/selectors';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import ArticleCard from './ArticleCard';
import { EmployeeArticles as articles } from '../Articles/Articles';
import { EmployeeVideos as videos } from '../Articles/Videos';
import { PandemicMentalHealthAudio } from '../Articles/Audios';

import reducer from './reducer';
import saga from './saga';
/* eslint-disable react/prefer-stateless-function */
export class EmployeeResources extends React.PureComponent {
  render() {
    const { intl } = this.props;
    const status = this.props.user.data.people_care;

    return (
      <div>
        <Helmet>
          <title>{intl.formatMessage(messages.employeeResources)}</title>
          <meta
            name="employee resources"
            content="Resources for people corp employees"
          />
        </Helmet>
        <PortalLayout
          title={<FormattedMessage {...messages.employeeResources} />}
          activeTab="employee_resources"
        >
          {_.map(
            { ...PandemicMentalHealthAudio, ...articles, ...videos },
            (article, i) => {
              if (
                (intl.locale === 'fr' && article.excludeFr) ||
                (status === 'People Leader' && article.excludeManager)
              )
                return null;

              return <ArticleCard key={i} {...article} />;
            },
          )}
        </PortalLayout>
      </div>
    );
  }
}

EmployeeResources.propTypes = {
  intl: intlShape,
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

const mapStateToProps = createStructuredSelector({
  user: makeSelectApp(),
  locale: makeSelectLocale(),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'employee_resources', reducer });
const withSaga = injectSaga({ key: 'employee_resources', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(injectIntl(EmployeeResources));
