import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import ArticleBody from '../ArticleBody';
import messages from '../../../messages';
const ProtectOurselvesAgainstBullying = () => (
  <div>
    <ArticleBody>
      <FormattedHTMLMessage
        tagName="p"
        {...messages.protectAgainstBullyingBody}
      />
    </ArticleBody>
  </div>
);

ProtectOurselvesAgainstBullying.propTypes = {};

export default ProtectOurselvesAgainstBullying;
