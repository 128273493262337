import { takeLatest, put, call } from 'redux-saga/effects';
import axios from 'axios';
import { API_URL } from 'utils/environment';
import { headers } from 'utils/auth';
import { get } from 'utils/request';

import { FETCH_USER_SETTINGS, UPDATE_USER_SETTINGS } from './constants';
import {
  updateUserSettingsSuccess,
  updateUserSettingsError,
  fetchUserSettingsSuccess,
  fetchUserSettingsError,
} from './actions';

function* fetchUserSettings(values) {
  const requestURL = `${API_URL}/user_settings/me`;

  try {
    const settings = yield call(get, requestURL);
    yield put(fetchUserSettingsSuccess(settings));
    if (values.callback) {
      yield values.callback(settings);
    }
  } catch (error) {
    yield put(fetchUserSettingsError(error));
  }
}

const updateApi = values => {
  const requestURL = `${API_URL}/user_settings/me`;
  return axios
    .put(requestURL, values, headers())
    .then(response => response.data)
    .catch(error => {
      throw error.response;
    });
};

function* updateUserSettings({ data, callback }) {
  try {
    const settings = yield call(updateApi, data);
    yield put(updateUserSettingsSuccess(settings));
    if (callback) {
      yield callback(settings);
    }
  } catch (error) {
    yield put(updateUserSettingsError(error));
    if (callback) {
      yield callback(null, error);
    }
  }
}

// Individual exports for testing
export default function* userInformationSaga() {
  yield takeLatest(FETCH_USER_SETTINGS, fetchUserSettings);
  yield takeLatest(UPDATE_USER_SETTINGS, updateUserSettings);
}
