/**
 *
 * Options Settings
 *
 */

import styled from 'styled-components';

const Settings = styled.button`
  margin-left: 20px;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
  border: none;
  background-color: transparent;
`;

export default Settings;
