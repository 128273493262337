/**
 *
 * SecondOpinionServices
 *
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { InkblotTheme, Button } from 'inkblot-ui';
import {
  injectIntl,
  intlShape,
  FormattedMessage,
  FormattedHTMLMessage,
} from 'react-intl';
import { compose } from 'redux';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';

import PortalLayout from 'components/PortalLayout';
import bbdLogoFr from 'images/pcCarePathBapFr.png';
import bbdLogo from 'images/pcCarePathBap.png';
import { fetchUser } from '../App/actions';
import { trackUserClick } from '../EmployeeResources/actions';
import Card from './Card';
import messages from './messages';
import reducer from '../EmployeeResources/reducer';
import saga from '../EmployeeResources/saga';

// eslint-disable-next-line react/prefer-stateless-function
class SecondOpinionServices extends Component {
  render() {
    const { intl } = this.props;
    return (
      <div>
        <Helmet>
          <title>{intl.formatMessage(messages.title)}</title>
          <meta name="description" content="Description of Health Navigation" />
        </Helmet>
        <PortalLayout activeTab="second_opinion">
          <Card>
            <h1>
              <FormattedMessage {...messages.title} />
            </h1>
            <p>
              <FormattedHTMLMessage tagName="p" {...messages.body1} />
            </p>
            <p>
              <b>
                <FormattedMessage {...messages.body2} />
              </b>
            </p>
            <p>
              <FormattedMessage {...messages.body3} />
            </p>
            <p>
              <FormattedMessage {...messages.body4} />
            </p>
            <p>
              <FormattedMessage {...messages.body5} />
            </p>
            <b>
              <FormattedHTMLMessage tagName="p" {...messages.body6} />
            </b>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <InkblotTheme>
                <a
                  href={
                    intl.locale === 'fr'
                      ? 'https://peopleconnectcarepath.ca/fr#form'
                      : 'https://peopleconnectcarepath.ca/#form'
                  }
                >
                  <Button
                    people
                    label={<FormattedMessage {...messages.button} />}
                    style={{
                      marginTop: '20px',
                      marginBottom: '20px',
                    }}
                  />
                </a>
              </InkblotTheme>
              <b>
                <FormattedHTMLMessage tagName="p" {...messages.body7} />
              </b>
              <img
                src={intl.locale === 'en' ? bbdLogo : bbdLogoFr}
                alt="bbd bap logo"
                style={{ width: '250px' }}
              />
            </div>
          </Card>
        </PortalLayout>
      </div>
    );
  }
}

SecondOpinionServices.propTypes = {
  intl: intlShape,
  trackUserClick: PropTypes.func.isRequired,
  fetchUser: PropTypes.func,
  copmany: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    trackUserClick: values => dispatch(trackUserClick(values)),
    fetchUser: callback => dispatch(fetchUser(callback)),
  };
}

function mapStateToProps(state) {
  // eslint-disable-next-line prefer-destructuring
  const company = state.toJS().app.user.data.company;
  const status = state.toJS().app.user.data.people_care;
  return { company, status };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'employee_resources', reducer });
const withSaga = injectSaga({ key: 'employee_resources', saga });

export default compose(
  withConnect,
  withReducer,
  withSaga,
)(injectIntl(SecondOpinionServices));
