import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import H2 from 'components/H2';
import H4 from 'components/H4';
import SVG from 'react-inlinesvg';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { compose } from 'redux';
import injectReducer from 'utils/injectReducer';
import { phoneSize } from '../../global-styles';
import TextDocument from '../../images/text-document.svg';
import { makeSelectAssessments } from './selectors';
import { fetchAssessments } from './actions';
import reducer from './reducer';
import messages from './messages';

const AssessmentHistoryWrapper = styled.div`
  p.sub-header {
    text-transform: uppercase;
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #00568e;
    margin-bottom: 20px;
  }
  p.information {
    font-family: 'Lato';
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1d212c;
    margin-bottom: 40px;
  }
`;
const AssessmentWrapper = styled.div`
  width: 100%;
  border: solid 1px #dfe7f7;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 40px;
  margin-top: 40px;
  &:last-child {
    margin-bottom: 0px;
  }
  p {
    font-family: 'Lato';
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.1px;
    color: #2e5fca;
  }
`;

const AssessmentContentWrapper = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  a {
    color: #3c9dd7;
    font-family: 'Montserrat', sans-serif;
  }
  span {
    margin-right: 10px;
  }
  :first-of-type {
    height: 40px;
    border-bottom: 1px solid #dfe7f7;
    @media (max-width: ${phoneSize}) {
      display: block;
    }
  }
  @media (max-width: ${phoneSize}) {
    height: 60px;
    display: block;
  }
`;

class AssessmentHistory extends React.PureComponent {
  componentWillMount() {
    this.props.fetchAssessments();
  }

  renderAssessments = assessments =>
    assessments.toJS().map(assessment => (
      <AssessmentWrapper>
        <AssessmentContentWrapper>
          <H4 style={{ fontWeight: 600 }}>
            {moment(assessment.updated_at).format('dddd MMMM D, YYYY')} |{' '}
            {moment(assessment.updated_at).format('hh:mmA')}
          </H4>
        </AssessmentContentWrapper>
        {assessment.reports.map(report => (
          <AssessmentContentWrapper key={report.id}>
            <SVG src={TextDocument} />
            <a href={report.file} download target="_blank">
              {report.report_type === 'interim' && (
                <FormattedMessage {...messages.downloadPDFInterim} />
              )}
              {report.report_type === 'final' && (
                <FormattedMessage {...messages.downloadPDFFinal} />
              )}
            </a>
          </AssessmentContentWrapper>
        ))}
      </AssessmentWrapper>
    ));

  render() {
    const { assessments } = this.props;

    return (
      <AssessmentHistoryWrapper>
        <H2
          style={{
            display: 'inline',
            fontWeight: 500,
            fontSize: '18px',
          }}
        >
          <FormattedMessage {...messages.assessmentHistory} />
        </H2>
        {assessments &&
          assessments.size > 0 &&
          this.renderAssessments(assessments)}
      </AssessmentHistoryWrapper>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAssessments: () => dispatch(fetchAssessments()),
  };
}

const mapStateToProps = createStructuredSelector({
  assessments: makeSelectAssessments(),
});

AssessmentHistory.propTypes = {
  fetchAssessments: PropTypes.func,
  assessments: PropTypes.object,
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'settings', reducer });

export default compose(
  withReducer,
  withConnect,
)(AssessmentHistory);
