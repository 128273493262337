/* eslint indent: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Recorder from 'react-media-recorder';
import Webcam from 'react-webcam';
import { Map } from 'immutable';
import styled from 'styled-components';

import AssessmentLayout from 'components/AssessmentLayout';
import Anchor from 'components/Button';
import H1 from 'components/H1';
import H3 from 'components/H3';
import P from 'components/P';
import Body from 'components/Body';
import { InkblotTheme, Button } from 'inkblot-ui';
import messages from '../messages';
import { deepSeaBlue, errorRed } from '../../../global-styles';

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 10%;
  h1 {
    margin-bottom: 40px;
  }
  h3 {
    margin-bottom: 20px;
  }
  h4 {
    margin-bottom: 40px;
  }
`;

const VideoWrapper = styled.div`
  height: 20%;
  margin: 40px auto;
  position: relative;

  video {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
`;

const RecordButton = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 0 10px 0 rgba(99, 140, 177, 0.2);
  background-color: #ffffff;
  position: relative;
  cursor: pointer;

  &:before {
    position: absolute;
    content: '';
    top: 13px;
    left: 13px;
    width: 14px;
    height: 14px;
    border-radius: ${({ type }) => (type === 'record' ? '50%' : '0px')};
    background-color: ${({ type }) =>
      type === 'record' ? errorRed : deepSeaBlue};
  }
`;

class RecordVideo extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      allowRecording: false,
      uploading: false,
      videoUrl: null,
    };
  }

  saveVideo = video => {
    this.setState({
      videoUrl: video,
    });
  };

  uploadVideo = () => {
    const { currentAssessment, goToNextPage, uploadVideo } = this.props;
    const { videoUrl } = this.state;

    if (videoUrl) {
      fetch(videoUrl)
        .then(response => response.blob())
        .then(blob => {
          const video = new File([blob], 'video.mp4', {
            type: 'video/mp4',
          });
          const params = new FormData();

          params.append('video', video);
          params.append('id', currentAssessment.get('id'));

          this.setState({ uploading: true, allowRecording: false });
          uploadVideo(params, () => {
            this.setState({ uploading: false });
            goToNextPage();
          });
        });
    } else {
      this.setState({ allowRecording: false }, () => goToNextPage());
    }
  };

  allowRecording = () => this.setState({ allowRecording: true });

  render() {
    const { openExitDialog, percent } = this.props;
    const { allowRecording, uploading } = this.state;

    return (
      <AssessmentLayout
        sidebar
        percent={percent}
        openExitDialog={openExitDialog}
      >
        <StyledWrapper>
          <H1 fontSize="38px">
            <FormattedMessage {...messages.videoOptionalVideoClip} />
          </H1>
          <P fontSize="18px" bold>
            <FormattedMessage {...messages.videoWeUse} />
          </P>
          <H3>
            <FormattedMessage {...messages.videoThisIs} />
          </H3>
          <P fontSize="18px" bold>
            <FormattedMessage {...messages.videoInGeneral} />
          </P>
          {!allowRecording && (
            <div style={{ marginBottom: '100px' }}>
              <Anchor onClick={this.allowRecording}>
                <FormattedMessage {...messages.videoAllowRecording} />
              </Anchor>
            </div>
          )}
          {allowRecording && (
            <Recorder
              video
              whenStopped={this.saveVideo}
              render={({
                status,
                startRecording,
                stopRecording,
                mediaBlob,
              }) => (
                <VideoWrapper>
                  {status === 'permission_denied' && (
                    <Body>
                      <FormattedMessage {...messages.videoEnableCamera} />
                    </Body>
                  )}
                  {status === 'recording' && (
                    <Body>
                      <FormattedMessage {...messages.videoRecording} />
                    </Body>
                  )}
                  {(status === 'idle' || status === 'recording') && <Webcam />}
                  {status === 'stopped' && (
                    /* eslint-disable jsx-a11y/media-has-caption */
                    <video src={mediaBlob} autoPlay loop />
                  )}
                  {(status === 'idle' || status === 'stopped') && (
                    <button
                      onClick={startRecording}
                      type="button"
                      style={{ outline: 'none' }}
                    >
                      <RecordButton type="record" />
                    </button>
                  )}
                  {status === 'recording' && (
                    <button
                      onClick={stopRecording}
                      type="button"
                      style={{ outline: 'none' }}
                    >
                      <RecordButton type="stop" />
                    </button>
                  )}
                </VideoWrapper>
              )}
            />
          )}
          <InkblotTheme>
            <Button
              people
              label={<FormattedMessage {...messages.next} />}
              style={{ textTransform: 'uppercase' }}
              type="button"
              onClick={this.uploadVideo}
            />
          </InkblotTheme>
          <Anchor onClick={this.props.goToPrevPage}>
            <FormattedMessage {...messages.previous} />
          </Anchor>
        </StyledWrapper>
        {uploading && (
          <P>
            <FormattedMessage {...messages.videoUploadingVideo} />
          </P>
        )}
      </AssessmentLayout>
    );
  }
}

RecordVideo.propTypes = {
  currentAssessment: PropTypes.instanceOf(Map),
  goToNextPage: PropTypes.func,
  goToPrevPage: PropTypes.func,
  percent: PropTypes.number,
  uploadVideo: PropTypes.func,
  openExitDialog: PropTypes.func,
};

export default RecordVideo;
