/*
 *
 * LanguageProvider reducer
 *
 */

import { fromJS } from 'immutable';
import { ENVIRONMENT as ENV, FRENCH_URL } from 'utils/environment';

import { CHANGE_LOCALE } from './constants';

const hostname = window && window.location && window.location.hostname;
const secondLevelDomain = () => {
  const parsedUrl = hostname.replace(/^(https?:\/\/)?(www\.)?/i, '');
  const parts = parsedUrl.split('.');

  if (parts.length >= 2) {
    if (parts[parts.length - 2].length <= 3) {
      return parts[parts.length - 3];
    }
    return parts[parts.length - 2];
  }
  return null;
};

const devLocale =
  ENV === 'DEVELOPMENT' ? localStorage.getItem('initLocale') : null;
export const initialState = fromJS({
  locale: devLocale || (FRENCH_URL.includes(secondLevelDomain()) ? 'fr' : 'en'),
});

function languageProviderReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_LOCALE:
      return state.set('locale', action.locale);
    default:
      return state;
  }
}

export default languageProviderReducer;
