/**
 *
 * Panel
 *
 */

import styled from 'styled-components';
import { phoneSize } from 'global-styles';

const Panel = styled.div`
  background-color: #f2f6f8;
  width: 100%;
  padding: 0 40px;
  h1 {
    padding: 50px 0;
  }

  @media (max-width: ${phoneSize}) {
    padding: 0 20px;
  }
`;

export default Panel;
