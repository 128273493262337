import { takeLatest, put as reduxPut, call } from 'redux-saga/effects';
import { post, get, put } from 'utils/request';
import { API_URL } from 'utils/environment';
import {
  onboardSuccess,
  fetchOnboardSuccess,
  fetchStatusesSuccess,
  onboardError,
  fetchOnboardError,
  fetchStatusesError,
} from './actions';
import { ONBOARD, FETCH_ONBOARD, FETCH_STATUSES } from './constants';

function* onboard(values) {
  const requestURL = `${values.url}/people/onboardings/${values.user.token}`;

  try {
    const response = yield call(put, requestURL, values.user);
    yield reduxPut(onboardSuccess(response));
    if (response.access_token) {
      values.callback(response.access_token);
    }
  } catch (error) {
    yield reduxPut(onboardError(error));
    if (values.errCallback) {
      values.errCallback();
    }
  }
}

function* fetchOnboard({ token, callback }) {
  const requestURL = `${API_URL}/people/onboardings/${token}`;

  try {
    const response = yield call(get, requestURL);
    yield reduxPut(fetchOnboardSuccess(response));
    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield reduxPut(fetchOnboardError(error));
  }
}

function* fetchStatuses() {
  const requestURL = `${API_URL}/people/people_care_status`;
  try {
    const companies = yield call(get, requestURL);
    yield reduxPut(fetchStatusesSuccess(companies));
  } catch (error) {
    yield reduxPut(fetchStatusesError(error));
  }
}

export default function* signupSaga() {
  yield takeLatest(ONBOARD, onboard);
  yield takeLatest(FETCH_ONBOARD, fetchOnboard);
  yield takeLatest(FETCH_STATUSES, fetchStatuses);
}
