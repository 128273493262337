/*
 *
 * Assessment reducer
 *
 */

import { fromJS } from 'immutable';
import {
  FETCH_ASSESSMENT,
  FETCH_ASSESSMENT_SUCCESS,
  FETCH_ASSESSMENT_ERROR,
  UPDATE_ASSESSMENT,
  UPDATE_ASSESSMENT_SUCCESS,
  UPDATE_ASSESSMENT_ERROR,
  FETCH_REPORT,
  FETCH_REPORT_SUCCESS,
  FETCH_REPORT_ERROR,
  UPLOAD_VIDEO,
  UPLOAD_VIDEO_ERROR,
  UPLOAD_VIDEO_SUCCESS,
  CLEAR_ASSESSMENT,
  CLEAR_ASSESSMENT_SUCCESS,
  CLEAR_ASSESSMENT_ERROR,
  EMAIL_REPORT,
  EMAIL_REPORT_ERROR,
  EMAIL_REPORT_SUCCESS,
  CLEAR_EMAIL_REPORT,
} from './constants';

export const initialState = fromJS({
  currentAssessment: {},
  currentAssessmentLoaded: false,
  report: {},
  error: false,
  loading: false,
  uploading: false,
  emailReport: {
    sending: false,
    error: false,
    sent: false,
  },
});

function assessmentReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_ASSESSMENT:
    case FETCH_ASSESSMENT:
      return state.set('currentAssessmentLoaded', false);
    case FETCH_REPORT:
      return state.set('loading', true);
    case CLEAR_ASSESSMENT:
      return state
        .set('currentAssessmentLoaded', false)
        .set('currentAssessment', fromJS({}));
    case FETCH_ASSESSMENT_ERROR:
    case UPDATE_ASSESSMENT_ERROR:
    case CLEAR_ASSESSMENT_ERROR:
    case FETCH_REPORT_ERROR:
      return state
        .set('currentAssessmentLoaded', false)
        .set('loading', false)
        .set('error', action.error);
    case FETCH_ASSESSMENT_SUCCESS:
    case UPDATE_ASSESSMENT_SUCCESS:
      return state
        .set('currentAssessmentLoaded', true)
        .mergeDeep({ currentAssessment: action.assessment });
    case FETCH_REPORT_SUCCESS:
      return state
        .set('loading', false)
        .mergeDeep({ report: action.assessment });
    case CLEAR_ASSESSMENT_SUCCESS:
      return state
        .set('currentAssessmentLoaded', true)
        .mergeDeep({ currentAssessment: action.assessment });
    case UPLOAD_VIDEO:
      return state.set('uploading', true);
    case UPLOAD_VIDEO_ERROR:
      return state.set('uploading', false).set('uploadError', action.error);
    case UPLOAD_VIDEO_SUCCESS:
      return state
        .set('uploading', false)
        .mergeDeep({ currentAssessment: action.assessment });
    case EMAIL_REPORT:
      return state
        .setIn(['emailReport', 'sending'], true)
        .setIn(['emailReport', 'error'], false);
    case EMAIL_REPORT_ERROR:
      return state
        .setIn(['emailReport', 'sending'], false)
        .setIn(['emailReport', 'error'], action.error);
    case EMAIL_REPORT_SUCCESS:
      return state
        .setIn(['emailReport', 'sending'], false)
        .setIn(['emailReport', 'sent'], true);
    case CLEAR_EMAIL_REPORT:
      return state.set('emailReport', initialState.get('emailReport'));
    default:
      return state;
  }
}

export default assessmentReducer;
