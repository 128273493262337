/*
 *
 * MarketingPage reducer
 *
 */

import { fromJS } from 'immutable';
import {
  FETCH_STATUSES,
  FETCH_STATUSES_SUCCESS,
  FETCH_STATUSES_ERROR,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  FETCH_ASSESSMENTS,
  FETCH_ASSESSMENTS_SUCCESS,
  FETCH_ASSESSMENTS_ERROR,
} from './constants';

export const initialState = fromJS({
  statuses: [],
  assessments: [],
  error: {
    error: '',
  },
});

function settingHistoryReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_STATUSES:
    case FETCH_ASSESSMENTS:
      return state.set('loading', true);
    case FETCH_STATUSES_ERROR:
    case FETCH_ASSESSMENTS_ERROR:
      return state.set('loading', false).set('error', action.error);
    case FETCH_STATUSES_SUCCESS:
      return state
        .set('loading', false)
        .setIn(['statuses'], Array.from(action.statuses));
    case CHANGE_PASSWORD:
      return state.set('loading', true);
    case CHANGE_PASSWORD_ERROR:
      return state.set('loading', false).set('error', action.error);
    case CHANGE_PASSWORD_SUCCESS:
      return state.set('loading', false);
    case FETCH_ASSESSMENTS_SUCCESS:
      return state
        .set('loading', false)
        .mergeDeep({ assessments: action.assessments });
    default:
      return state;
  }
}

export default settingHistoryReducer;
