import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import messages from '../../../messages';
import ArticleBody from '../ArticleBody';
const HelpingTroubledCoWorkers = () => (
  <ArticleBody>
    <FormattedHTMLMessage tagName="p" {...messages.helpingTroubledBody} />
  </ArticleBody>
);

HelpingTroubledCoWorkers.propTypes = {};

export default HelpingTroubledCoWorkers;
