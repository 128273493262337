import React from 'react';
import VideoBody from './VideoBody';

const GlobalConflict = () => (
  <div>
    <VideoBody>
      <iframe
        src="https://player.vimeo.com/video/745034277?h=968b3b56de&amp;app_id=122963"
        width="884"
        height="497"
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
        title="Global Conflict: Understanding and managing our reactions"
      />
    </VideoBody>
  </div>
);
export default GlobalConflict;
