/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can change our
 * application state.
 * To add a new action, add it to the switch statement in the reducer function
 *
 * Example:
 * case YOUR_ACTION_CONSTANT:
 *   return state.set('yourStateVariable', true);
 */

import { fromJS } from 'immutable';

import {
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_ERROR,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  CONFIRM_EMAIL,
  CONFIRM_EMAIL_ERROR,
  CONFIRM_EMAIL_SUCCESS,
} from './constants';

// The initial state of the App
const initialState = fromJS({
  user: {
    loading: false,
    error: false,
    data: false,
  },
});

function appReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_USER:
      return state.setIn(['user', 'loading'], true);
    case FETCH_USER_ERROR:
      return state
        .setIn(['user', 'loading'], false)
        .setIn(['user', 'error'], action.error);
    case FETCH_USER_SUCCESS:
      return state
        .setIn(['user', 'loading'], false)
        .setIn(['user', 'data'], fromJS(action.user));
    case UPDATE_USER:
      return state.setIn(['user', 'loading'], true);
    case UPDATE_USER_ERROR:
      return state
        .setIn(['user', 'loading'], false)
        .setIn(['user', 'error'], action.error);
    case UPDATE_USER_SUCCESS:
      return state
        .setIn(['user', 'loading'], false)
        .setIn(['user', 'data'], fromJS(action.user));
    case CONFIRM_EMAIL:
      return state.set('loading', true);
    case CONFIRM_EMAIL_ERROR:
      return state.set('loading', false).set('error', action.error);
    case CONFIRM_EMAIL_SUCCESS:
      return state.set('loading', false);
    default:
      return state;
  }
}

export default appReducer;
