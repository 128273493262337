import React from 'react';
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { makeSelectApp } from 'containers/App/selectors';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';

import EmployeeResources from 'images/dashboard/employee-resources.png';
import ManagerResources from 'images/dashboard/manager-resources.png';
import MentalHealth from 'images/dashboard/mental-health-assessment.png';
import Training from 'images/dashboard/training-and-consulting.png';
import VirtualCounselling from 'images/dashboard/virtual-counselling.png';
import ExecutiveConvoImg from 'images/dashboard/executive-convo.png';
import IcbtImg from 'images/dashboard/icbt.png';
import CriticalIncidentImg from 'images/dashboard/crit-inc-services.jpg';
import SpecServicesImg from 'images/dashboard/spec-services.jpg';
import ManagementConsultingImg from 'images/dashboard/mgmt-consulting.jpg';
import HealthNavImg from 'images/dashboard/health-nav.png';
import CancerAssistanceImg from 'images/dashboard/cancer-assistance.png';
import SecondOpinionImg from 'images/dashboard/second-opinion.png';
import FinancialLegalImg from 'images/dashboard/financial-legal.png';

import { BBDBAP, BBDEAP } from 'utils/environment';
import { isEap, isPeopleLeader } from '../helpers';
import messages from '../messages';
import Panel from './Panel';

const DashboardPanels = props => {
  const status = props.user.data.people_care;
  const { company } = props.user.data;

  const eapList = [
    'virtualCounselling',
    'mentalHealth',
    'icbt',
    'criticalIncident',
    'specServices',
    'managementConsulting',
    'employeeResources',
    'training',
    'managerResources',
    'executiveConvo',
  ];

  const bbdBapList = [
    'mentalHealthSupport',
    'mentalHealth',
    'healthNavigation',
    'cancerAssistance',
    'secondOpinionServices',
    'managementConsulting',
    'financialLegal',
    'criticalIncident',
    'employeeResources',
    'managerResources',
    'executiveConvo',
  ];

  const bbdEapList = [
    'virtualCounselling',
    'mentalHealth',
    'icbt',
    'employeeResources',
    'carepath',
    'businessAssistance',
    'criticalIncident',
    'specServices',
    'managementConsulting',
    'training',
    'managerResources',
    'executiveConvo',
  ];

  const defaultList = [
    'virtualCounselling',
    'mentalHealth',
    'employeeResources',
    'training',
    'managerResources',
    'executiveConvo',
  ];

  const panelsData = [
    {
      id: 'mentalHealthSupport',
      img: VirtualCounselling,
      conditions: {},
      header:
        BBDBAP && isPeopleLeader(status) ? (
          <FormattedMessage {...messages.getSupport} />
        ) : (
          <FormattedMessage {...messages.mentalHealthSupport} />
        ),
      body:
        BBDBAP && isPeopleLeader(status) ? (
          <FormattedHTMLMessage tagName="div" {...messages.getSupportBody} />
        ) : (
          <FormattedMessage {...messages.mentalHealthSupportBody} />
        ),
      route: '/virtual_counselling',
      buttonBody: <FormattedMessage {...messages.moreInformation} />,
    },
    {
      id: 'virtualCounselling',
      img: VirtualCounselling,
      conditions: {},
      header: isEap(company) ? (
        <FormattedMessage {...messages.workHealthLife} />
      ) : (
        <FormattedMessage {...messages.virtualTherapy} />
      ),
      body: isEap(company) ? (
        <FormattedMessage {...messages.workHealthLifeBody} />
      ) : (
        <FormattedMessage {...messages.matchAndVideo} />
      ),
      route: '/virtual_counselling',
      buttonBody: <FormattedMessage {...messages.moreInformation} />,
    },
    {
      id: 'mentalHealth',
      img: MentalHealth,
      conditions: {},
      header: <FormattedMessage {...messages.mentalHealthAssessment} />,
      body: <FormattedMessage {...messages.answerAVariety} />,
      route: '/assessment',
      buttonBody: <FormattedMessage {...messages.startAssessment} />,
    },
    {
      id: 'icbt',
      img: IcbtImg,
      conditions: {},
      header: <FormattedHTMLMessage tagName="span" {...messages.icbt} />,
      body: <FormattedMessage {...messages.icbtBody} />,
      externalRoute: BBDEAP
        ? 'https://engagementrx.com/pceap/app2/#!/landing'
        : 'https://www.engagementrx.com/fv2/pceap/boubYz',
      buttonBody: <FormattedMessage {...messages.getStartedIcbt} />,
    },
    {
      id: 'criticalIncident',
      img: CriticalIncidentImg,
      conditions: isPeopleLeader(status),
      header: (
        <FormattedHTMLMessage
          tagName="span"
          {...messages.criticalIncidentTitle}
        />
      ),
      body: BBDBAP ? (
        <FormattedHTMLMessage
          tagName="div"
          {...messages.criticalIncidentBodyBap}
        />
      ) : (
        <FormattedMessage {...messages.criticalIncidentBody} />
      ),
      route: '/critical_incident_services',
      buttonBody: <FormattedMessage {...messages.moreInformation} />,
    },
    {
      id: 'specServices',
      img: SpecServicesImg,
      conditions: isPeopleLeader(status),
      header: (
        <FormattedHTMLMessage tagName="span" {...messages.specServicesTitle} />
      ),
      body: <FormattedMessage {...messages.specServicesBody} />,
      route: '/specialized_services',
      buttonBody: <FormattedMessage {...messages.moreInformation} />,
    },
    {
      id: 'managementConsulting',
      img: ManagementConsultingImg,
      conditions: isPeopleLeader(status),
      header: BBDBAP ? (
        <FormattedMessage {...messages.managementConsultingTitleBap} />
      ) : (
        <FormattedMessage {...messages.managementConsultingTitle} />
      ),
      body: BBDBAP ? (
        <FormattedMessage {...messages.managementConsultingBodyBap} />
      ) : (
        <FormattedMessage {...messages.managementConsultingBody} />
      ),
      route: '/management_consulting',
      buttonBody: <FormattedMessage {...messages.moreInformation} />,
    },
    {
      id: 'employeeResources',
      img: EmployeeResources,
      conditions: {},
      header: <FormattedMessage {...messages.employeeResources} />,
      body: <FormattedMessage {...messages.articlesAndPodcasts} />,
      route: '/employee_resources',
      buttonBody: <FormattedMessage {...messages.seeMore} />,
    },
    {
      id: 'training',
      img: Training,
      conditions: isPeopleLeader(status),
      header: <FormattedMessage {...messages.trainingAndConsulting} />,
      body: <FormattedMessage {...messages.learnAbout} />,
      route: '/training',
      buttonBody: <FormattedMessage {...messages.seeMore} />,
    },
    {
      id: 'managerResources',
      img: ManagerResources,
      conditions: isPeopleLeader(status),
      header: <FormattedMessage {...messages.peopleLeaderResources} />,
      body: <FormattedMessage {...messages.videosOnManagement} />,
      route: '/manager_resources',
      buttonBody: <FormattedMessage {...messages.seeMore} />,
    },
    {
      id: 'executiveConvo',
      img: ExecutiveConvoImg,
      conditions: isPeopleLeader(status),
      header: <FormattedMessage {...messages.planSponsorResources} />,
      body: <FormattedMessage {...messages.learnPlanSponsor} />,
      route: '/plan_sponsors',
      buttonBody: <FormattedMessage {...messages.seeMore} />,
    },
    {
      id: 'healthNavigation',
      img: HealthNavImg,
      conditions: {},
      header: <FormattedMessage {...messages.healthNavigation} />,
      body: <FormattedMessage {...messages.healthNavigationBody} />,
      route: '/health_navigation',
      buttonBody: <FormattedMessage {...messages.moreInformation} />,
    },
    {
      id: 'cancerAssistance',
      img: CancerAssistanceImg,
      conditions: {},
      header: <FormattedMessage {...messages.cancerAssistance} />,
      body: <FormattedMessage {...messages.cancerAssistanceBody} />,
      route: '/cancer_assistance',
      buttonBody: <FormattedMessage {...messages.moreInformation} />,
    },
    {
      id: 'secondOpinionServices',
      img: SecondOpinionImg,
      conditions: {},
      header: <FormattedMessage {...messages.secondOpinionServices} />,
      body: <FormattedMessage {...messages.secondOpinionServicesBody} />,
      route: '/second_opinion',
      buttonBody: <FormattedMessage {...messages.moreInformation} />,
    },
    {
      id: 'carepath',
      img: SecondOpinionImg,
      conditions: {},
      header: <FormattedMessage {...messages.carepath} />,
      body: <FormattedHTMLMessage tagName="div" {...messages.carepathBody} />,
      route: '/carepath',
      buttonBody: <FormattedMessage {...messages.moreInformation} />,
    },
    {
      id: 'businessAssistance',
      img: FinancialLegalImg,
      conditions: isPeopleLeader(status),
      header: <FormattedMessage {...messages.businessAssistance} />,
      body: (
        <FormattedHTMLMessage
          tagName="p"
          {...messages.businessAssistanceBody}
        />
      ),
      route: '/business_assistance',
      buttonBody: <FormattedMessage {...messages.moreInformation} />,
    },
    {
      id: 'financialLegal',
      img: FinancialLegalImg,
      conditions: isPeopleLeader(status),
      header: <FormattedMessage {...messages.financialLegal} />,
      body: <FormattedMessage {...messages.financialLegalBody} />,
      route: '/financial_legal_services',
      buttonBody: <FormattedMessage {...messages.moreInformation} />,
    },
  ];

  const renderPlanTypeDashboard = () => {
    let list;
    if (BBDBAP) {
      list = bbdBapList;
    } else if (BBDEAP) {
      list = bbdEapList;
    } else if (company && company.plan_type === 'eap') {
      list = eapList;
    } else {
      list = defaultList;
    }

    return panelsData
      .filter(panel => list.includes(panel.id))
      .sort((a, b) => list.indexOf(a.id) - list.indexOf(b.id))
      .map(panel => {
        if (panel.conditions) {
          return (
            <Panel
              key={panel.id}
              img={panel.img}
              header={panel.header}
              body={panel.body}
              route={panel.route}
              buttonBody={panel.buttonBody}
              externalRoute={panel.externalRoute}
            />
          );
        }
        return null;
      });
  };

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {renderPlanTypeDashboard()}
    </div>
  );
};

DashboardPanels.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectApp(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(DashboardPanels),
);
