import React from 'react';
import ArticleBody from '../ArticleBody';
const TwelveSteps = () => (
  <div>
    <ArticleBody>
      The National Standard of Canada for Psychological Health and Safety in the
      Workplace (the Standard), is a set of voluntary guidelines, tools and
      resources intended to guide organizations in promoting mental health and
      preventing psychological harm at work 1. Organizations that implement the
      Standard are, on average, better performers in all key performance
      categories from health and safety to key human resource measures to
      shareholder returns 1.
      <br />
      <br />
      <b>
        Implementing the Standard: A Psychological Health and Safety Management
        Strategy
      </b>
      <br />
      <br />
      Key to success of the Standard is a disciplined, managed process of
      implementation. The Standard states “The key to successful and sustainable
      implementation of this Standard is the involvement of those affected by
      the changes. Engagement of workers in the development and planning stages
      must be carried over into the implementation stage to ensure that the
      changes are communicated effectively and that the process of
      implementation does not cause undue stress or harm (Annex B, 4.4) 1. The
      Standard recommends that organizations look to implement a Psychological
      Health and Safety Management System (PHSMS) to assess how policies,
      processes and interactions in the workplace might impact the psychological
      health and safety of employees. There are many options for implementing
      the standard 2,3, and we offer a 12-step implementation model for
      consideration, here.
      <br />
      <br />
      <b>1. Secure leadership and stakeholder commitment</b>
      <br />
      <br />
      Encourage leadership to actively support, encourage, and engage
      stakeholders at all stages and decision points for implementing the
      Standard, and securing commitment to a psychologically healthy and safe
      work environment. If your organization has a Health and Safety Committee,
      include these members as they have expertise, training, and a passion for
      health and safety.
      <br />
      <br />
      <b>2. Create a policy statement, governance plan.</b>
      <br />
      <br />
      Define, document and communicate your organization’s commitment to
      developing a systematic approach for managing health and safety in the
      workplace. Additionally, create a joint governance structure with unions
      and employees, confirm the terms of reference of all committees involved
      in implementing the Standard within the PHSMS framework, and communicate
      the governance to all employees. Finally, involve managers, employees and
      unions in developing a communication plan explaining how aligning with the
      National Standard of Canada for Psychological Health and Safety in the
      Workplace will have a positive impact in the workplace.
      <br />
      <br />
      <b>3. Ensure confidentiality and diversity</b>
      <br />
      <br />
      Confidentiality and diversity are an integral part of the PHSMS. All
      stakeholders and participants should be guaranteed confidentiality, and
      they should represent the diversity of your organization (e.g.
      union/non-union).
      <br />
      <br />
      <b>4. Establish a baseline and data gathering process</b>
      <br />
      <br />
      Implementing a PHSMS requires an assessment of the organization beginning
      with a psychological health and safety hazard analysis to determine the
      starting point, or baseline. The analysis should identify hazards to
      psychological health and safety and identify existing policies,
      procedures, and programs that affect psychological health and safety. List
      all existing policies, procedures, and programs that affect psychological
      health and safety. Finally, assess data to identify problem areas and
      develop recommendations for the next steps. Note: The National Standard
      includes an Audit Tool in Annex E (see “Additional Resources below).
      <br />
      <br />
      <b>5. Create a resourcing plan</b>
      <br />
      <br />
      The resourcing plan is a list of all requirements to achieve stated
      objectives, targets and time lines. For example, financial resources may
      be required for awareness training, or a consultant may be required to
      conduct focus groups with employees.
      <br />
      <br />
      <b>6. Build a change management plan</b>
      <br />
      <br />
      Ensure that individuals are motivated and able to appropriately perform
      new roles required due to the implementation of a new business strategy.
      <br />
      <br />
      <b>7. Build an evaluation plan</b>
      <br />
      <br />
      Identify the components to be measured and how to assess whether actions
      are achieving the desired objectives, the individuals who will provide
      input, and short-term and long-term objectives.
      <br />
      <br />
      <b>8. Develop a work plan</b>
      <br />
      <br />
      It is imperative to develop a work plan for the first three years. When
      developing a work plan consider your high-level strategy and vision: What
      is it that you’d like to achieve, what might this look like, and how long
      might this take you to achieve? The vision should be created with input
      from senior leadership and representation from the entire workforce. Focus
      on two or three main priorities, don’t get lost in the details, start at
      the beginning and define milestones.
      <br />
      <br />
      <b>9. Prepare for critical events</b>
      <br />
      <br />
      The Standard calls for a process to ensure that the psychological health
      of workers is considered and protected as much as possible in the crisis
      stage of a critical event.
      <br />
      <br />
      <b>10: Review incident reporting</b>
      <br />
      <br />
      The Canada Labour Code requires that employers report, at a minimum, on
      any hazard, and stresses the need to prevent such situations in the
      future. Consequently, establish and maintain procedures for reporting and
      investigating work-related psychological health and safety incidents such
      as psychological injuries, illnesses, and acute traumatic events.
      <br />
      <br />
      <b>11: Monitor performance</b>
      <br />
      <br />
      Assess progress toward intended outcomes and to measure the extent of
      improvement in overall psychological health and safety. The frequency of
      monitoring performance is not specified in the Standard and is to be
      determined by each organization based on current management practices and
      the complexity of the PHSMS. During the performance monitoring process,
      suggestions and ideas for continuous improvement or corrective actions
      should be developed jointly.
      <br />
      <br />
      <b>12: Conduct a joint management review</b>
      <br />
      <br />
      The final element of the monitoring component of the PHSMS is a joint
      management review of the PHSMS, consisting of a formalized, complete
      high-level system review. The review is to be conducted by senior joint
      leadership and ensures ongoing monitoring of relevant policies,
      procedures, results, and deficiencies. The joint management review of the
      PHSMS is a cumulative and ongoing process that supports the continuous
      improvement of the system.
      <br />
      <br />
      <b>Key Takeaway</b>
      <br />
      <br />
      Beginning a dialogue in the workplace about psychological health and
      safety can be one of the first steps of implementation. For ongoing
      sustainability, embed psychological health and safety into your
      organization's policies, processes, and decision-making.
      <br />
      <br />
      <b>Additional Resources</b>
      <br />
      <br />
      The Standard provides a link to a free interactive downloadable auditing
      template.
      <br />
      <br />
      Retrieve the template here: workplacestrategiesformentalhealth.com/pdf/
      Also available as a free download is the guide{' '}
      <b>
        Assembling the Pieces –An Implementation Guide to the National Standard
        for Psychological Health and Safety.
      </b>{' '}
      The guide was created by the Mental Health Commission of Canada in
      partnership with the CSA Group and with the support of the Great-West Life
      Centre for Mental Health in the Workplace and offers a roadmap to
      implementation of the Standard through four key steps: building the
      foundation, identifying opportunities, setting objectives, and
      implementation. Retrieve the Guide here:{' '}
      <a href="https://store.csagroup.org/">https://store.csagroup.org/</a>
    </ArticleBody>
  </div>
);

TwelveSteps.propTypes = {};

export default TwelveSteps;
