import React from 'react';
import { Player } from 'video-react';
import VideoBody from './VideoBody';
import MindfulVideo from './Mindfulness.mp4';

const Mindfulness = () => (
  <div>
    <VideoBody>
      <Player playsInline src={MindfulVideo} />
    </VideoBody>
  </div>
);
export default Mindfulness;
