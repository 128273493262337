import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the marketingPage state domain
 */

const selectSignupPageDomain = state => state.get('signup', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by MarketingPage
 */

const makeSelectSignupPage = () =>
  createSelector(selectSignupPageDomain, substate => substate.toJS());

const makeSelectEmailError = () =>
  createSelector(selectSignupPageDomain, substate =>
    substate.getIn(['error', 'emailError']),
  );

const makeSelectCompanies = () =>
  createSelector(selectSignupPageDomain, substate => substate.get('companies'));

const makeSelectUSCompanies = () =>
  createSelector(selectSignupPageDomain, substate =>
    substate.get('us_companies').toJS(),
  );

const makeSelectStatuses = () =>
  createSelector(selectSignupPageDomain, substate => substate.get('statuses'));

export {
  selectSignupPageDomain,
  makeSelectSignupPage,
  makeSelectCompanies,
  makeSelectStatuses,
  makeSelectUSCompanies,
  makeSelectEmailError,
};
