import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import UpdatePopover from 'containers/UpdatePopover';
import IdleManager from '../IdleManager';
import Background from '../Background';
import { tabletSize, phoneSize } from '../../../global-styles';

const MinHeight = styled.div`
  min-height: 500px;
  max-width: 1440px;
  margin: 0 auto;
`;

const LayoutWrapper = styled.div`
  padding-top: 40px;
  padding-left: 7.5%;
  padding-right: 7.5%;
  padding-bottom: 110px;
  position: relative;
  z-index: 1;

  @media (max-width: ${tabletSize}) {
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 200px;
  }
  @media (max-width: ${phoneSize}) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const AuthedLayout = props => (
  <LayoutWrapper>
    <Background />
    <IdleManager />
    <UpdatePopover />
    <MinHeight>{props.children}</MinHeight>
  </LayoutWrapper>
);

AuthedLayout.propTypes = {
  children: PropTypes.node,
};

export default AuthedLayout;
