/*
 *
 * Metrics actions
 *
 */

import {
  FETCH_METRICS,
  FETCH_METRICS_SUCCESS,
  FETCH_METRICS_ERROR,
  FETCH_COMPANIES,
  FETCH_COMPANIES_ERROR,
  FETCH_COMPANIES_SUCCESS,
  CLEAR_COMPANIES,
} from './constants';

export function fetchMetrics(company, startRange, endRange) {
  return {
    type: FETCH_METRICS,
    company,
    startRange,
    endRange,
  };
}

export function fetchMetricsSuccess() {
  return {
    type: FETCH_METRICS_SUCCESS,
  };
}

export function fetchMetricsError(error) {
  return {
    type: FETCH_METRICS_ERROR,
    error,
  };
}

export function fetchCompanies(query, callback) {
  return {
    type: FETCH_COMPANIES,
    query,
    callback,
  };
}

export function fetchCompaniesSuccess(companies) {
  return {
    type: FETCH_COMPANIES_SUCCESS,
    companies,
  };
}

export function fetchCompaniesError(error) {
  return {
    type: FETCH_COMPANIES_ERROR,
    error,
  };
}

export function clearCompanies() {
  return {
    type: CLEAR_COMPANIES,
  };
}
