import * as Sentry from '@sentry/browser';
import { takeLatest, put as reduxPut, call } from 'redux-saga/effects';
import { get, put } from 'utils/request';
import { API_URL } from 'utils/environment';
import ReactGA from 'react-ga';
import {
  fetchUserSuccess,
  fetchUserError,
  updateUserSuccess,
  updateUserError,
  confirmEmailError,
  confirmEmailSuccess,
} from './actions';
import { FETCH_USER, UPDATE_USER, CONFIRM_EMAIL } from './constants';

function* fetchUser({ callback }) {
  const requestURL = `${API_URL}/users/me`;
  try {
    const user = yield call(get, requestURL);
    yield reduxPut(fetchUserSuccess(user));
    ReactGA.set({
      userId: user.id,
    });

    if (callback) {
      yield callback(user);
    }
    Sentry.configureScope(scope => {
      scope.setUser({ id: user.id, email: user.email });
    });
  } catch (error) {
    yield reduxPut(fetchUserError(error));
  }
}

function* updateUser({ user, callback }) {
  const requestURL = `${API_URL}/people/users/me`;
  try {
    const updatedUser = yield call(put, requestURL, { ...user });
    yield reduxPut(updateUserSuccess(updatedUser));
    if (callback) yield call(callback);
  } catch (error) {
    yield reduxPut(updateUserError(error));
  }
}
function* confirmEmail({ data, callback }) {
  const requestURL = `${API_URL}/people/auths/confirm`;

  try {
    const tokenInfo = yield call(put, requestURL, data);
    yield reduxPut(confirmEmailSuccess(tokenInfo));
    if (callback) {
      yield callback(tokenInfo);
    }
  } catch (error) {
    yield reduxPut(confirmEmailError(error));
  }
}

export default function* appSaga() {
  yield takeLatest(FETCH_USER, fetchUser);
  yield takeLatest(UPDATE_USER, updateUser);
  yield takeLatest(CONFIRM_EMAIL, confirmEmail);
}
