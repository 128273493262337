/**
 *
 * VerticalRadioButton
 *
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import P from 'components/P';
import { deepSeaBlue, errorRed } from '../../global-styles';

const RadioIcon = props => (
  // eslint-disable-next-line react/prop-types
  <div className={props.checked ? 'vri-out-checked' : 'vri-out'}>
    <div className="vri-wrapper">
      <div className={props.checked ? 'vri-in-checked' : 'vri-in'} />
    </div>
  </div>
);

const RadioLabel = props => (
  // eslint-disable-next-line react/prop-types
  <div className={props.checked ? 'vrl-label-checked' : 'vri-label'}>
    {/* eslint-disable-next-line react/prop-types */}
    {props.children}
  </div>
);

const StyledContainer = styled.div`
  text-align: left;
`;

const StyledGroup = styled.div`
  /* empty */
`;

const StyledRadioButton = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
  .vri-out,
  .vri-out-checked {
    min-width: 34px;
    height: 34px;
    margin-right: 20px;
    background-color: #ffffff;
    border-radius: 50%;
  }
  .vri-out {
    box-shadow: 0 0 10px 0 rgba(99, 140, 177, 0.1);
    border: solid 1px rgba(46, 95, 202, 0.1);
  }
  .vri-out-checked {
    border: solid 2px ${deepSeaBlue};
  }
  .vri-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .vri-in-checked {
    width: 10px;
    height: 10px;
    background-color: ${deepSeaBlue};
    border-radius: 50%;
  }
  .vri-label,
  .vrl-label-checked {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #0f2045;
  }
  .vrl-label-checked {
    color: ${deepSeaBlue};
    text-shadow: 1px 0 0 ${deepSeaBlue};
  }
`;

const StyledP = styled(P)`
  text-align: center;
  margin-bottom: 0;
`;

function VerticalRadioButton({ input, meta, options }) {
  const { value, onChange } = input;
  const { touched, error } = meta;

  return (
    <StyledContainer>
      <StyledGroup>
        {options.map(option => (
          <StyledRadioButton
            onClick={() => onChange(option.value)}
            key={`vr-key-${option.value}`}
          >
            <RadioIcon checked={option.value.toString() === value.toString()} />
            <RadioLabel checked={option.value.toString() === value.toString()}>
              {option.label}
            </RadioLabel>
          </StyledRadioButton>
        ))}
      </StyledGroup>
      <StyledP color={errorRed}>{touched ? error : ''}</StyledP>
    </StyledContainer>
  );
}

VerticalRadioButton.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  options: PropTypes.array,
};

export default VerticalRadioButton;
