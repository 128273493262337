/*
 * Signup Messages
 *
 * This contains all the text for the Signup container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Onboarding';

export default defineMessages({
  welcome: {
    id: `${scope}.welcome`,
    defaultMessage: 'Welcome to {bold}, please register your new account.',
  },
  peopleCare: {
    id: `${scope}.peopleCare`,
    defaultMessage: 'People Connect',
  },
  preferredName: {
    id: `${scope}.preferredName`,
    defaultMessage: 'Preferred Name',
  },
  status: {
    id: `${scope}.status`,
    defaultMessage: 'Status',
  },
  password: {
    id: `${scope}.password`,
    defaultMessage: 'Password',
  },
  confirmPassword: {
    id: `${scope}.confirmPassword`,
    defaultMessage: 'Confirm Password',
  },
  peopleleader: {
    id: `${scope}.peopleleader`,
    defaultMessage: 'People Leader',
  },
  spouse: {
    id: `${scope}.spouse`,
    defaultMessage: 'Spouse',
  },
  dependant: {
    id: `${scope}.dependant`,
    defaultMessage: 'Dependant',
  },
  dependent: {
    id: `${scope}.dependent`,
    defaultMessage: 'Dependent',
  },
  employeemember: {
    id: `${scope}.employeemember`,
    defaultMessage: 'Employee/Member',
  },
  employeemanager: {
    id: `${scope}.employeemanager`,
    defaultMessage: 'Employee/Member',
  },
  pleaseComplete: {
    id: `${scope}.pleaseComplete`,
    defaultMessage: 'Please complete',
  },
  passwordLength: {
    id: `${scope}.passwordLength`,
    defaultMessage: 'Password must be at least 6 characters',
  },
  passwordMatch: {
    id: `${scope}.passwordMatch`,
    defaultMessage: 'Passwords do not match',
  },
  register: {
    id: `${scope}.register`,
    defaultMessage: 'REGISTER',
  },
});
