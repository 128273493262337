/**
 *
 * PortalLayout
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PanelWrapper from 'components/PanelWrapper';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import ProgressBar from 'components/ProgressBar';
import SVG from 'react-inlinesvg';

import Logo from 'images/PC-Nav.svg';
import LogoFrench from 'images/PC-PeopleCare-Logos_FR.svg';
import EapLogo from 'images/pc-eap-eng-logo.png';
import EapLogoFr from 'images/pc-eap-fr-logo.png';
import BbdLogo from 'images/pcCarePathBap.png';
import BbdLogoFr from 'images/pcCarePathBapFr.png';
import Close from 'images/dialog/close.svg';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { hidePercentagePages } from 'containers/Assessment/naviHelpers';
import { makeSelectApp } from 'containers/App/selectors';
import { BBDBAP, BBDEAP } from 'utils/environment';
import { tabletSize, phoneSize } from '../../global-styles';
import Wrapper from './Wrapper';

const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  @media (max-width: ${tabletSize}) {
    flex-direction: column;
  }
`;

const LogoWrapper = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  width: 200px;
  @media (max-width: ${phoneSize}) {
    width: 200px;
  }
  cursor: pointer;

  img {
    width: 100%;
  }
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  cursor: pointer;
  top: 15px;
  right: 20px;
`;

const Sidebar = styled.div`
  width: 25%;
  display: flex;
  justify-content: center;
  padding-top: 100px;
  background-color: #fff;
  @media (max-width: ${tabletSize}) {
    width: 100%;
    min-height: 175px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : '#fafafa'};
  width: ${props => (props.width ? props.width : '75%')};
  @media (max-width: ${tabletSize}) {
    width: 100%;
    height: 100%;
  }
`;

const Content = styled.div`
  max-width: 100%;
  padding: 50px;
  text-align: center;
  h1 {
    margin-bottom: 40px;
  }
  @media (max-width: ${tabletSize}) {
    padding: 60px 50px;
  }
  @media (max-width: ${phoneSize}) {
    padding: 60px 20px;
  }
`;

function AssessmentLayout({
  children,
  sidebar,
  percent,
  openExitDialog,
  page,
  locale,
  user,
}) {
  const { company } = user && user.data;

  const renderLogo = () => {
    if (BBDBAP) {
      return locale === 'en' ? (
        <img src={BbdLogo} alt="bbd logo bap" />
      ) : (
        <img src={BbdLogoFr} alt="bbd logo bap" />
      );
    }
    if (BBDEAP) {
      return locale === 'en' ? (
        <img src={EapLogo} alt="bbd eap logo" />
      ) : (
        <img src={EapLogoFr} alt="bbd eap logo" />
      );
    }
    if (company.plan_type === 'eap') {
      return locale === 'en' ? (
        <img src={EapLogo} alt="eap logo" />
      ) : (
        <img src={EapLogoFr} alt="eap logo" />
      );
    }

    return locale === 'fr' ? (
      <SVG style={{ width: '200px' }} src={LogoFrench} />
    ) : (
      <SVG style={{ width: '250px' }} src={Logo} />
    );
  };

  return (
    <Wrapper>
      <PanelWrapper>
        <Container>
          <LogoWrapper onClick={openExitDialog}>{renderLogo()}</LogoWrapper>
          {openExitDialog && (
            <CloseButtonWrapper onClick={openExitDialog}>
              <SVG src={Close} />
            </CloseButtonWrapper>
          )}
          {sidebar && (
            <Sidebar>
              {!hidePercentagePages.includes(page) && (
                <ProgressBar percent={percent} />
              )}
            </Sidebar>
          )}
          <ContentContainer
            backgroundColor={sidebar ? '#fafafa' : '#fff'}
            width={sidebar ? '75%' : '100%'}
          >
            <Content>{children}</Content>
          </ContentContainer>
        </Container>
      </PanelWrapper>
    </Wrapper>
  );
}

AssessmentLayout.propTypes = {
  children: PropTypes.node,
  sidebar: PropTypes.bool,
  openExitDialog: PropTypes.func,
  percent: PropTypes.number,
  locale: PropTypes.string,
  page: PropTypes.string,
  user: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  locale: makeSelectLocale(),
  user: makeSelectApp(),
});

export default connect(mapStateToProps)(AssessmentLayout);
