import React from 'react';
import { FormattedMessage } from 'react-intl';

import StigmaImg from 'images/articles/banner/stigma.png';
import MindfulnessImg from 'images/articles/banner/mindfulness.png';
import StayingResilientImg from 'images/articles/banner/staying-resilient.png';
import MentalIllnessAndWorkImg from 'images/articles/banner/mental-illness.png';
import MentalHealthImg from 'images/articles/banner/mh-awareness.png';
import GlobalConflictImg from 'images/articles/banner/global-conflict.jpg';
import RespondingWithEmpathyImg from 'images/articles/banner/responding-empathy.png';
import RespondingWithEmpathy from './RespondingWithEmpathy';
import Mindfulness from './Mindfulness';
import Stigma from './Stigma';
import StayingResilient from './StayingResilient';
import MentalIllnessAndWork from './MentalIllnessAndWork';
import MentalHealthAwareness from './MentalHealthAwareness';
import Authors from '../Articles/Authors';
import messages from '../messages';
import GlobalConflict from './GlobalConflict';

export const EmployeeVideos = {
  mindfulness: {
    id: 'mindfulness',
    title: <FormattedMessage {...messages.mindfullness} />,
    description: <FormattedMessage {...messages.mindfullnessDescription} />,
    author: Authors.dannaHurst,
    ArticleBody: Mindfulness,
    type: 'video',
    bannerImage: MindfulnessImg,
  },
  stigma: {
    id: 'stigma',
    title: <FormattedMessage {...messages.stigma} />,
    description: <FormattedMessage {...messages.stigmaDescription} />,
    author: Authors.dannaHurst,
    ArticleBody: Stigma,
    type: 'video',
    bannerImage: StigmaImg,
  },
  staying_resilient: {
    id: 'staying_resilient',
    title: <FormattedMessage {...messages.stayingResilient} />,
    description: <FormattedMessage {...messages.stayingResilientDescription} />,
    author: Authors.dannaHurst,
    ArticleBody: StayingResilient,
    type: 'video',
    bannerImage: StayingResilientImg,
  },
  global_conflict: {
    id: 'global_conflict',
    title: <FormattedMessage {...messages.globalConflictTitle} />,
    // description: <FormattedMessage {...messages.globalConflictDesc} />,
    // author: Authors.dannaHurst,
    ArticleBody: GlobalConflict,
    type: 'video',
    bannerImage: GlobalConflictImg,
    bannerImagePosition: 'top',
    excludeFr: true,
  },
  responding_with_empathy: {
    id: 'responding_with_empathy',
    title: <FormattedMessage {...messages.respondingWithEmpathyTitle} />,
    // description: <FormattedMessage {...messages.globalConflictDesc} />,
    // author: Authors.dannaHurst,
    ArticleBody: RespondingWithEmpathy,
    type: 'video',
    bannerImage: RespondingWithEmpathyImg,
    excludeManager: true,
  },
};

export const ManagerVideos = {
  mental_illness_and_work: {
    id: 'mental_illness_and_work',
    title: <FormattedMessage {...messages.mentalIllnessAndWork} />,
    description: (
      <FormattedMessage {...messages.mentalIllnessAndWorkDescription} />
    ),
    author: Authors.dannaHurst,
    ArticleBody: MentalIllnessAndWork,
    type: 'video',
    bannerImage: MentalIllnessAndWorkImg,
  },
};

export const PlanSponsorsVideos = {
  mental_health_awareness: {
    id: 'mental_health_awareness',
    title: <FormattedMessage {...messages.mentalHealthAwareness} />,
    description: (
      <FormattedMessage {...messages.mentalHealthAwarenessDescription} />
    ),
    author: Authors.judyPlotkin,
    ArticleBody: MentalHealthAwareness,
    bannerImage: MentalHealthImg,
    type: 'video',
  },
};
export const Videos = {
  ...EmployeeVideos,
  ...ManagerVideos,
  ...PlanSponsorsVideos,
};
