import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import ArticleBody from '../ArticleBody';
import messages from '../../../messages';
const StressManagement = () => (
  <div>
    <ArticleBody>
      <FormattedHTMLMessage tagName="p" {...messages.stressManagementBody} />
    </ArticleBody>
  </div>
);

StressManagement.propTypes = {};

export default StressManagement;
