/**
 *
 * DatePicker
 *
 */

import React from 'react';
import styled from 'styled-components';
import AntdDatePicker from 'antd/lib/date-picker';
import PropTypes from 'prop-types';

import { deepSeaBlue, errorRed } from 'global-styles';

import 'antd/dist/antd.css';

const Label = styled.p`
  color: ${deepSeaBlue};
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.1px;
  line-height: normal;
  margin-bottom: 14px;
  text-transform: uppercase;
`;

const ErrorText = styled.span`
  display: block;
  color: ${errorRed};
  font-size: 9px;
  margin-top: 8px;
`;

function DatePicker({
  style,
  input,
  label,
  meta: { touched, error, warning },
  ...props
}) {
  return (
    <div>
      <Label>{label}</Label>
      <AntdDatePicker
        {...props}
        onChange={input.onChange}
        format="DD/MM/YYYY"
        style={{ zIndex: '0', ...style }}
      />
      {touched &&
        ((error && <ErrorText>{error}</ErrorText>) ||
          (warning && <ErrorText>{warning}</ErrorText>))}
    </div>
  );
}

DatePicker.propTypes = {
  input: PropTypes.object.isRequired,
  style: PropTypes.object,
  label: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.object.isRequired,
  normalize: PropTypes.func,
  validate: PropTypes.func,
};

export default DatePicker;
