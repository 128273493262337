/**
 *
 * LogEventOnRoute
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { logEvent } from 'utils/amplitude';

/* eslint-disable react/prefer-stateless-function */
class LogEventOnRoute extends React.PureComponent {
  componentDidUpdate(prevProps) {
    const { eventType, eventProperties, location } = this.props;

    if (location !== prevProps.location) {
      logEvent(eventType, eventProperties);
    }
  }

  render() {
    return <div>{this.props.children}</div>;
  }
}

LogEventOnRoute.propTypes = {
  children: PropTypes.node,
  eventType: PropTypes.string.isRequired,
  eventProperties: PropTypes.object,
  location: PropTypes.object.isRequired,
};

export default withRouter(LogEventOnRoute);
