import React from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import OnLeftSvg from './on-left.svg';
import OnRightSvg from './on-right.svg';

const StyledContainer = styled.div`
  position: absolute;
  background-color: #eff3f7;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  .bgc-inner {
    overflow-y: hidden;
    height: 100%;

    .bgc-item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20%;
      .bgci-left {
        svg {
          width: 100%;
        }
      }
      .bgci-right {
        margin-left: -10%;
        margin-top: 15%;
        svg {
          width: 100%;
        }
      }
    }
  }
`;

const BGItem = () => (
  <div className="bgc-item">
    <div className="bgci-left">
      <SVG src={OnLeftSvg} />
    </div>
    <div className="bgci-right">
      <SVG src={OnRightSvg} />
    </div>
  </div>
);

const Background = () => (
  <StyledContainer>
    <div className="bgc-inner">
      <BGItem />
      <BGItem />
      <BGItem />
      <BGItem />
      <BGItem />
    </div>
  </StyledContainer>
);

export default Background;
