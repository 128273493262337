/*
 *
 * Assessment Result
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { InkblotTheme, Button } from 'inkblot-ui';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import { Link } from 'react-router-dom';
import AssessmentLayout from 'components/AssessmentLayout';
import H1 from 'components/H1';
import H3 from 'components/H3';
import Anchor from 'components/Button';
import { fromJS } from 'immutable';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import LogEventOnMount from 'components/LogEventOnMount';
import LoadingIndicator from 'components/LoadingIndicator';
import SpinnerButton from 'components/SpinnerButton';
import { deepSeaBlue } from '../../../global-styles';
import { trackUserClick } from '../../EmployeeResources/actions';
import reducer from '../../EmployeeResources/reducer';
import saga from '../../EmployeeResources/saga';
import {
  renderOrsHeader,
  renderFooter,
  renderAddiction,
  renderSymHeader,
  renderOrsCondition,
  renderDepression,
  renderAnxiety,
  renderPTSD,
  renderSubstance,
  renderSymptomFooter,
  renderBipolar,
  renderEmpty,
  gatherOrsHeader,
  gatherOrsCondition,
  gatherAddiction,
  gatherFooter,
  gatherSymHeader,
  gatherEmpty,
  gatherDepression,
  gatherAnxiety,
  gatherBipolar,
  gatherSubstance,
  gatherPTSD,
  gatherSymptomFooter,
} from './report';
import { makeSelectEmailReport, makeSelectReport } from '../selectors';
import messages from '../messages';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
  text-align: left;
  h3 {
    margin-bottom: 30px;
  }
`;

const buttonStlye = {
  marginTop: 10,
  backgroundColor: '#b2ddfc',
  backgroundImage: 'unset',
  color: deepSeaBlue,
  textTransform: 'uppercase',
};

/* eslint-disable react/prefer-stateless-function */
class InterimReport extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      reportLoading: false,
    };
  }

  componentDidMount() {
    const { showORS, currentAssessment } = this.props;
    let reportType;

    if (showORS) {
      reportType = 'ors';
    } else {
      reportType = 'cs1';
    }

    this.setState({ reportLoading: true });
    this.props.fetchReport(
      currentAssessment.get('id'),
      null,
      reportType,
      resp =>
        this.props.fetchReport(
          currentAssessment.get('id'),
          this.getLines(fromJS(resp), showORS),
          reportType,
          () => this.setState({ reportLoading: false }),
        ),
    );
  }

  componentWillUnmount() {
    this.props.clearEmailReport();
  }

  isEmpty = report => {
    if (
      report.get('depression_yn') ||
      report.get('anxiety_yn') ||
      report.get('bipolar_yn') ||
      report.get('substance_yn') ||
      report.get('ptsd_yn')
    ) {
      return false;
    }
    return true;
  };

  getLines = (report, showORS) => {
    const { locale } = this.props;
    const lines = [];
    if (showORS) {
      gatherOrsHeader(lines, locale);
      gatherOrsCondition(lines, report.get('diagnosis'), locale);
      if (report.get('substance_yn')) gatherAddiction(lines, locale);
      gatherFooter(lines, locale);
    } else if (!showORS) {
      gatherSymHeader(lines, locale);
      if (this.isEmpty(report)) gatherEmpty(lines, locale);
      if (report.get('depression_yn'))
        gatherDepression(lines, report.get('depression_severity'), locale);
      if (report.get('anxiety_yn'))
        gatherAnxiety(lines, report.get('anxiety_severity'), locale);
      if (report.get('bipolar_yn'))
        gatherBipolar(lines, report.get('bipolar_severity'), locale);
      if (report.get('substance_yn'))
        gatherSubstance(lines, report.get('substance_severity'), locale);
      if (report.get('ptsd_yn'))
        gatherPTSD(lines, report.get('ptsd_severity'), locale);
      gatherSymptomFooter(lines, locale);
    }
    return lines;
  };

  handleUserClick = () => {
    this.props.trackUserClick({
      article_name: 'Virtual counselling after interim report',
    });
  };

  emailButtonLabel = () => {
    const { email } = this.props;

    if (email.sent) return <FormattedMessage {...messages.emailSent} />;
    if (email.error) return <FormattedMessage {...messages.emailError} />;

    return <FormattedMessage {...messages.emailReport} />;
  };

  emailReport = () => {
    const { currentAssessment, report, showORS } = this.props;
    const lines = this.getLines(report, showORS);

    this.props.emailReport(currentAssessment.get('id'), lines);
  };

  render() {
    const { report, showORS, locale, showDetail, email } = this.props;
    const { reportLoading } = this.state;

    return (
      <div>
        <AssessmentLayout>
          {reportLoading ? (
            <LoadingIndicator />
          ) : (
            <>
              <H1 fontSize="38px">
                {showORS ? (
                  <FormattedMessage {...messages.reportFinalReport} />
                ) : (
                  <FormattedMessage {...messages.reportInterimReport} />
                )}
              </H1>
              <Wrapper>
                <H3>
                  <FormattedMessage {...messages.reportResultSummary} />
                </H3>
                {showORS && renderOrsHeader(locale)}
                {!showORS && renderSymHeader(locale)}
                {!showORS && this.isEmpty(report) ? renderEmpty(locale) : null}
                {showORS && renderOrsCondition(report.get('diagnosis'), locale)}
                {!showORS &&
                  report.get('depression_yn') &&
                  renderDepression(report.get('depression_severity'), locale)}
                {!showORS &&
                  report.get('anxiety_yn') &&
                  renderAnxiety(report.get('anxiety_severity'), locale)}
                {!showORS &&
                  report.get('bipolar_yn') &&
                  renderBipolar(report.get('bipolar_severity'), locale)}
                {!showORS &&
                  report.get('substance_yn') &&
                  renderSubstance(report.get('substance_severity'), locale)}
                {!showORS &&
                  report.get('ptsd_yn') &&
                  renderPTSD(report.get('ptsd_severity'), locale)}
                {showORS &&
                  report.get('substance_yn') &&
                  renderAddiction(locale)}
                {showORS && renderFooter(locale)}
                {!showORS && renderSymptomFooter(locale)}
              </Wrapper>

              <InkblotTheme>
                <div>
                  {showDetail && (
                    <Button
                      people
                      label={
                        <FormattedMessage {...messages.reportMoreDetailed} />
                      }
                      style={buttonStlye}
                      onClick={() =>
                        this.props.onDetailClick('screen_cross_part2_md')
                      }
                    />
                  )}
                </div>
                <div>
                  <SpinnerButton
                    people
                    label={this.emailButtonLabel()}
                    loading={email.sending}
                    disabled={email.sent}
                    style={buttonStlye}
                    onClick={this.emailReport}
                  />
                </div>
                <div>
                  <a href={report.get('url')} download target="_blank">
                    <Button
                      people
                      label={
                        <FormattedMessage {...messages.reportDownloadReport} />
                      }
                      style={buttonStlye}
                    />
                  </a>
                </div>
                <div>
                  <Link
                    to="/virtual_counselling"
                    onClick={this.handleUserClick}
                  >
                    <Button
                      people
                      label={
                        <FormattedMessage {...messages.reportStartVirtual} />
                      }
                      style={{ textTransform: 'uppercase', marginTop: 10 }}
                    />
                  </Link>
                </div>
              </InkblotTheme>
              <div>
                <Link to="/dashboard">
                  <Anchor onClick={() => {}}>
                    <FormattedMessage {...messages.reportReturnToDashboard} />
                  </Anchor>
                </Link>
              </div>
            </>
          )}
        </AssessmentLayout>
        <LogEventOnMount
          eventType="finish assessment"
          eventProperties={{ report_type: showDetail ? 'interim' : 'final' }}
        />
      </div>
    );
  }
}

InterimReport.propTypes = {
  onDetailClick: PropTypes.func.isRequired,
  showDetail: PropTypes.func.isRequired,
  report: PropTypes.object.isRequired,
  showORS: PropTypes.bool.isRequired,
  currentAssessment: PropTypes.object,
  fetchReport: PropTypes.func,
  locale: PropTypes.string.isRequired,
  trackUserClick: PropTypes.func.isRequired,
  email: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  report: makeSelectReport(),
  locale: makeSelectLocale(),
  email: makeSelectEmailReport(),
});

const mapDispatchToProps = dispatch => ({
  trackUserClick: values => dispatch(trackUserClick(values)),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'employee_resources', reducer });
const withSaga = injectSaga({ key: 'employee_resources', saga });

export default compose(
  withConnect,
  withReducer,
  withSaga,
)(InterimReport);
