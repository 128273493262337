/*
 *
 * Signup constants
 *
 */

export const DEFAULT_ACTION = 'app/Signup/DEFAULT_ACTION';

export const SIGN_UP = 'app/Signup/SIGN_UP';
export const SIGN_UP_SUCCESS = 'app/Signup/SIGN_UP_SUCCESS';
export const SIGN_UP_ERROR = 'app/Signup/SIGN_UP_ERROR';

export const SIGN_IN = 'app/Signup/SIGN_IN';
export const SIGN_IN_SUCCESS = 'app/Signup/SIGN_IN_SUCCESS';
export const SIGN_IN_ERROR = 'app/Signup/SIGN_IN_ERROR';

export const SEARCH_COMPANIES = 'app/Signup/SEARCH_COMPANIES';
export const SEARCH_COMPANIES_SUCCESS = 'app/Signup/SEARCH_COMPANIES_SUCCESS';
export const SEARCH_COMPANIES_ERROR = 'app/Signup/SEARCH_COMPANIES_ERROR';

export const FETCH_US_COMPANIES = 'app/Signup/FETCH_US_COMPANIES';
export const FETCH_US_COMPANIES_SUCCESS =
  'app/Signup/FETCH_US_COMPANIES_SUCCESS';
export const FETCH_US_COMPANIES_ERROR = 'app/Signup/FETCH_US_COMPANIES_ERROR';

export const FETCH_STATUSES = 'app/Signup/FETCH_STATUSES';
export const FETCH_STATUSES_SUCCESS = 'app/Signup/FETCH_STATUSES_SUCCESS';
export const FETCH_STATUSES_ERROR = 'app/Signup/FETCH_STATUSES_ERROR';

export const CLEAR_COMPANIES = 'app/Signup/CLEAR_COMPANIES';
