import { takeLatest, put as reduxPut, call } from 'redux-saga/effects';
import { API_URL } from 'utils/environment';
import { put } from 'utils/request';
import { changePasswordSuccess, changePasswordError } from './actions';
import { CHANGE_PASSWORD } from './constants';

function* changePassword(values) {
  const requestURL = `${API_URL}/people/forgot_passwords/me`;

  try {
    const response = yield call(put, requestURL, values.user);
    yield reduxPut(changePasswordSuccess(response));
    if (response.access_token) {
      values.callback(response.access_token);
    }
  } catch (error) {
    yield reduxPut(changePasswordError(error));
  }
}

export default function* changePasswordSaga() {
  yield takeLatest(CHANGE_PASSWORD, changePassword);
}
