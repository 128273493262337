import React from 'react';
import P from 'components/P';
import { black } from '../../../global-styles';

const isFrench = locale => locale === 'fr';

export const renderOrsHeader = locale =>
  isFrench(locale) ? (
    <div>
      <P color={black} fontSize="16px">
        Merci d’avoir complété l’évaluation de People vous connecte
      </P>
      <P color={black} fontSize="16px">
        Présentement, vous semblez bien aller. Cela signifie qu’en ce moment, la
        probabilité que vous répondiez aux critères d’une maladie mentale
        clinique significative est faible.
      </P>
      <P color={black} fontSize="16px">
        Nous n’avons pas tenté d’effectuer une évaluation détaillée, parce que
        cela pourrait faire croire aux gens qu’ils souffrent d’une maladie,
        alors qu’ils pourraient simplement avoir du mal à s’ajuster à certaines
        situations.{' '}
      </P>
    </div>
  ) : (
    <div>
      <P color={black} fontSize="16px">
        Thank you for completing People Connect Assessment!
      </P>
      <P color={black} fontSize="16px">
        Currently, you seem to be doing ok. This means that right now, the
        likelihood of you meeting the criteria for a significant clinical mental
        illness is low.
      </P>
      <P color={black} fontSize="16px">
        We did not attempt to do a detailed assessment, because it is more
        likely to incorrectly tell individuals that they have an illness when
        instead, they may be having a hard time adjusting to situations.
      </P>
    </div>
  );

export const gatherOrsHeader = (lines, locale) => {
  if (isFrench(locale)) {
    lines.push('Merci d’avoir complété l’évaluation de People vous connecte');
    lines.push(
      'Présentement, vous semblez bien aller. Cela signifie qu’en ce moment, la probabilité que vous répondiez aux critères d’une maladie mentale clinique significative est faible.',
    );
    lines.push(
      'Nous n’avons pas tenté d’effectuer une évaluation détaillée, parce que cela pourrait faire croire aux gens qu’ils souffrent d’une maladie, alors qu’ils pourraient simplement avoir du mal à s’ajuster à certaines situations.',
    );
  } else {
    lines.push('Thank you for completing People Connect Assessment!');
    lines.push(
      'Currently, you seem to be doing ok. This means that right now, the likelihood of you meeting the criteria for a significant clinical mental illness is low.',
    );
    lines.push(
      'We did not attempt to do a detailed assessment, because it is more likely to incorrectly tell individuals that they have an illness when instead, they may be having a hard time adjusting to situations.',
    );
  }

  return lines;
};

export const renderSymHeader = locale =>
  isFrench(locale) ? (
    <div>
      <P color={black} fontSize="16px">
        Merci pour vos réponses! Ceci est une évaluation de vos réponses jusqu’à
        maintenant :
      </P>
    </div>
  ) : (
    <div>
      <P color={black} fontSize="16px">
        Thank you for your responses! This is an evaluation of your responses so
        far:
      </P>
    </div>
  );

export const gatherSymHeader = (lines, locale) => {
  if (isFrench(locale)) {
    lines.push(
      'Merci pour vos réponses! Ceci est une évaluation de vos réponses jusqu’à maintenant :',
    );
  } else {
    lines.push(
      'Thank you for your responses! This is an evaluation of your responses so far:',
    );
  }
};

export const renderOrsCondition = (condition, locale) => {
  if (condition === 'tension') {
    if (isFrench(locale)) {
      return (
        <div>
          <P color={black} fontSize="16px">
            Vos réponses aux quelques questions posées laissent croire que
            souffrez de tension accrue.
          </P>
          <P color={black} fontSize="16px">
            Si cette situation empire, cette tension accrue pourrait rendre vos
            interactions sociales plus difficiles. Toutefois, en général, votre
            maîtrise de vous-même et votre résilience émotionnelle ne seront pas
            affectées.
          </P>
        </div>
      );
    }

    return (
      <div>
        <P color={black} fontSize="18px">
          The few questions we asked suggest you may have increased tension.
        </P>
        <P color={black} fontSize="18px">
          If it worsens, the increase in tension may make social functioning
          more difficult. However, in general, your self-control and emotional
          resilience are not affected.
        </P>
      </div>
    );
  }
  if (condition === 'anxious arousal') {
    if (isFrench(locale)) {
      return (
        <div>
          <P color={black} fontSize="16px">
            Vos réponses aux quelques questions posées laissent croire que vous
            avez une montée d’anxiété.
          </P>
          <P color={black} fontSize="16px">
            Si cette situation empire, cela pourrait affecter votre maîtrise de
            vous-même, vos interactions sociales et votre résilience
            émotionnelle.
          </P>
        </div>
      );
    }

    return (
      <div>
        <P color={black} fontSize="18px">
          The few questions we asked suggest that you may have increased anxious
          arousal.
        </P>
        <P color={black} fontSize="18px">
          If it worsens, it may affect your self-control, social function and
          emotional resilience.
        </P>
      </div>
    );
  }
  if (condition === 'low anxiety') {
    if (isFrench(locale)) {
      return (
        <div>
          <P color={black} fontSize="16px">
            Vos réponses aux quelques questions que nous avons posées laissent
            croire que vous avez une baisse de l’humeur et une montée d’anxiété.
          </P>
          <P color={black} fontSize="16px">
            Si cette situation empire, cela pourrait avoir un impact négatif sur
            votre maîtrise de vous-même, vos interactions sociales et votre
            résilience émotionnelle.
          </P>
        </div>
      );
    }
    return (
      <div>
        <P color={black} fontSize="18px">
          The few questions we asked suggest you may have lowered mood and
          increased anxiety.
        </P>
        <P color={black} fontSize="18px">
          If it worsens, it may negatively affect your self-control, social
          function and emotional resilience.
        </P>
      </div>
    );
  }
  if (condition === 'general anxiety' || condition === 'specific anxiety') {
    if (isFrench(locale)) {
      return (
        <div>
          <P color={black} fontSize="16px">
            Vos réponses aux quelques questions posées laissent croire que vous
            pourriez souffrir d’une anxiété accrue.
          </P>
          <P color={black} fontSize="16px">
            Si cette situation empire, cela pourrait affecter votre maîtrise de
            vous-même et vos interactions sociales. Toutefois, cela ne devrait
            normalement avoir aucun impact sur votre résilience émotionnelle.
          </P>
        </div>
      );
    }

    return (
      <div>
        <P color={black} fontSize="16px">
          The few questions we asked suggest you may have lowered mood and
          increased anxiety.
        </P>
        <P color={black} fontSize="16px">
          If it worsens, it may negatively affect your self-control, social
          function and emotional resilience.
        </P>
      </div>
    );
  }
  if (condition === 'anhedonia') {
    if (isFrench(locale)) {
      return (
        <div>
          <P color={black} fontSize="16px">
            Vos réponses aux quelques questions que nous avons posées laissent
            croire que vous avez une baisse d’intérêt pour les activités de la
            vie courante.
          </P>
          <P color={black} fontSize="16px">
            Si cette situation empire, cela pourrait avoir un impact négatif sur
            vos interactions sociales et votre résilience émotionnelle.
            Toutefois, cela ne devrait normalement avoir aucun impact sur votre
            maîtrise de vous-même.
          </P>
        </div>
      );
    }

    return (
      <div>
        <P color={black} fontSize="16px">
          The few questions we asked suggest you may have lowered mood and
          increased anxiety.
        </P>
        <P color={black} fontSize="16px">
          If it worsens, it may negatively affect your self-control, social
          function and emotional resilience.
        </P>
      </div>
    );
  }
  if (condition === 'melancholia') {
    if (isFrench(locale)) {
      return (
        <div>
          <P color={black} fontSize="16px">
            Vos réponses aux quelques questions que nous avons posées laissent
            croire que vous pourriez vous sentir déprimé et avoir moins de
            motivation.
          </P>
          <P color={black} fontSize="16px">
            Si cette situation empire, cela pourrait avoir un impact négatif sur
            votre maîtrise de vous-même, vos interactions sociales et votre
            résilience émotionnelle.
          </P>
        </div>
      );
    }

    return (
      <div>
        <P color={black} fontSize="16px">
          The few questions we asked suggest you may have lowered mood and
          increased anxiety.
        </P>
        <P color={black} fontSize="18px">
          If it worsens, it may negatively affect your self-control, social
          function and emotional resilience.
        </P>
      </div>
    );
  }

  if (isFrench(locale)) {
    return (
      <div>
        <P color={black} fontSize="16px">
          Vos réponses aux quelques questions que nous avons posées indiquent
          une faible probabilité que vous souffriez de stress, d’anxiété ou de
          dépression graves.
        </P>
      </div>
    );
  }

  return (
    <div>
      <P color={black} fontSize="16px">
        The few questions we asked scored low for severe stress, anxiety or
        depression.
      </P>
    </div>
  );
};

export const gatherOrsCondition = (lines, condition, locale) => {
  if (condition === 'tension') {
    if (isFrench(locale)) {
      lines.push(
        'Vos réponses aux quelques questions posées laissent croire que souffrez de tension accrue.',
      );
      lines.push(
        'Si cette situation empire, cette tension accrue pourrait rendre vos interactions sociales plus difficiles. Toutefois, en général, votre maîtrise de vous-même et votre résilience émotionnelle ne seront pas affectées.',
      );
    } else {
      lines.push(
        'The few questions we asked suggest you may have increased tension.',
      );
      lines.push(
        'If it worsens, the increase in tension may make social functioning more difficult. However, in general, your self-control and emotional resilience are not affected.',
      );
    }
  }
  if (condition === 'anxious arousal') {
    if (isFrench(locale)) {
      lines.push(
        'Vos réponses aux quelques questions posées laissent croire que vous avez une montée d’anxiété.',
      );
      lines.push(
        'Si cette situation empire, cela pourrait affecter votre maîtrise de vous-même, vos interactions sociales et votre résilience émotionnelle.',
      );
    } else {
      lines.push(
        'The few questions we asked suggest that you may have increased anxious arousal.',
      );
      lines.push(
        'If it worsens, it may affect your self-control, social function and emotional resilience.',
      );
    }
  }
  if (condition === 'low anxiety') {
    if (isFrench(locale)) {
      lines.push(
        'Vos réponses aux quelques questions que nous avons posées laissent croire que vous avez une baisse de l’humeur et une montée d’anxiété.',
      );
      lines.push(
        'Si cette situation empire, cela pourrait avoir un impact négatif sur votre maîtrise de vous-même, vos interactions sociales et votre résilience émotionnelle.',
      );
    } else {
      lines.push(
        'The few questions we asked suggest you may have lowered mood and increased anxiety.',
      );
      lines.push(
        'If it worsens, it may negatively affect your self-control, social function and emotional resilience.',
      );
    }
  }
  if (condition === 'general anxiety' || condition === 'specific anxiety') {
    if (isFrench(locale)) {
      lines.push(
        'Vos réponses aux quelques questions posées laissent croire que vous pourriez souffrir d’une anxiété accrue.',
      );
      lines.push(
        'Si cette situation empire, cela pourrait affecter votre maîtrise de vous-même et vos interactions sociales. Toutefois, cela ne devrait normalement avoir aucun impact sur votre résilience émotionnelle.',
      );
    } else {
      lines.push(
        'The few questions we asked suggest you may have increased anxiety.',
      );
      lines.push(
        'If it worsens, it can affect your self-control and social function. However, it doesn’t typically impact your emotional resilience.',
      );
    }
  }
  if (condition === 'anhedonia') {
    if (isFrench(locale)) {
      lines.push(
        'Vos réponses aux quelques questions que nous avons posées laissent croire que vous avez une baisse d’intérêt pour les activités de la vie courante.',
      );
      lines.push(
        'Si cette situation empire, cela pourrait avoir un impact négatif sur vos interactions sociales et votre résilience émotionnelle.  Toutefois, cela ne devrait normalement avoir aucun impact sur votre maîtrise de vous-même.',
      );
    } else {
      lines.push(
        'The few questions we asked suggest that you may have lowered interest in life activities.',
      );
      lines.push(
        'If it worsens, it may negatively affect your social function and emotional resilience. However, it doesn’t typically impact your self-control.',
      );
    }
  }
  if (condition === 'melancholia') {
    if (isFrench(locale)) {
      lines.push(
        'Vos réponses aux quelques questions que nous avons posées laissent croire que vous pourriez vous sentir déprimé et avoir moins de motivation.',
      );
      lines.push(
        'Si cette situation empire, cela pourrait avoir un impact négatif sur votre maîtrise de vous-même, vos interactions sociales et votre résilience émotionnelle.',
      );
    } else {
      lines.push(
        'The few questions we asked suggest that you may be feeling down and have lowered motivation.',
      );
      lines.push(
        'If it worsens, it may negatively affect your self-control, social function and emotional resilience.',
      );
    }
  }
  if (condition === 'normal') {
    if (isFrench(locale)) {
      lines.push(
        'Vos réponses aux quelques questions que nous avons posées indiquent une faible probabilité que vous souffriez de stress, d’anxiété ou de dépression graves.',
      );
    } else {
      lines.push(
        'The few questions we asked scored low for severe stress, anxiety or depression.',
      );
    }
  }
  return lines;
};

export const renderAddiction = locale =>
  isFrench(locale) ? (
    <div>
      <P color={black} fontSize="16px">
        De plus, il se peut que vous soyez plus à d’éprouver des difficultés
        liées à la consommation de substances.
      </P>
    </div>
  ) : (
    <div>
      <P color={black} fontSize="16px">
        As well, you may be at a higher risk of substance use difficulties.
      </P>
    </div>
  );

export const gatherAddiction = (lines, locale) => {
  if (isFrench(locale)) {
    lines.push(
      'De plus, il se peut que vous soyez plus à d’éprouver des difficultés liées à la consommation de substances.',
    );
  } else {
    lines.push(
      'As well, you may be at a higher risk of substance use difficulties.',
    );
  }
};

export const renderFooter = locale =>
  isFrench(locale) ? (
    <div>
      <P color={black} fontSize="16px">
        En général, augmenter l’activité physique, améliorer le sommeil et
        passer plus de temps avec sa famille/ses amis est bénéfique.
      </P>
      <P color={black} fontSize="16px">
        Veuillez noter qu’aucune évaluation officielle n’a été effectuée. Il
        s’agit plutôt d’une tentative d’interprétation des réponses à quelques
        questions. Cela signifie que le présent rapport ne doit pas être
        considéré comme exact.
      </P>
      <P color={black} fontSize="16px">
        Vous devriez envisager de parler avec un professionnel en utilisant la
        thérapie virtuelle. La première session est gratuite. Vous pouvez
        décider de déclarer les coûts liés à cette thérapie auprès du régime
        d’avantages sociaux fourni par votre employeur. Assurez-vous de vérifier
        les détails de votre couverture dans la brochure des avantages sociaux
        ou en contactant l’administrateur de votre régime.
      </P>
      <P color={black} fontSize="16px">
        Si vous ne vous sentez pas en sécurité ou que vous pourriez blesser
        d’autres personnes, veuillez appeler le 9-1-1 ou chercher des services
        d’urgence.
      </P>
      <P color={black} fontSize="16px">
        Merci!
      </P>
    </div>
  ) : (
    <div>
      <P color={black} fontSize="16px">
        In general, increasing physical activity, improving sleep, and spending
        more time with family/friends is beneficial.
      </P>
      <P color={black} fontSize="16px">
        Please note that a formal assessment was not completed. An attempt was
        made to interpret the results from a few questions. This means that this
        report is not to be considered accurate.
      </P>
      <P color={black} fontSize="16px">
        You may want to consider speaking to a professional using virtual
        counselling. The first session is complimentary. You may choose to
        submit the costs for this therapy through the employee benefits provided
        by your employer. Please ensure you check your coverage details by
        checking your benefits booklet or contacting your plan administrator.
      </P>
      <P color={black} fontSize="16px">
        If you are not feeling safe or may harm others, please call 911 or seek
        out emergency services.
      </P>
      <P color={black} fontSize="16px">
        Thank you!
      </P>
    </div>
  );

export const gatherFooter = (lines, locale) => {
  if (isFrench(locale)) {
    lines.push(
      'En général, augmenter l’activité physique, améliorer le sommeil et passer plus de temps avec sa famille/ses amis est bénéfique.',
    );
    lines.push(
      'Veuillez noter qu’aucune évaluation officielle n’a été effectuée. Il s’agit plutôt d’une tentative d’interprétation des réponses à quelques questions. Cela signifie que le présent rapport ne doit pas être considéré comme exact.',
    );
    lines.push(
      'Vous devriez envisager de parler avec un professionnel en utilisant la thérapie virtuelle. La première session est gratuite. Vous pouvez décider de déclarer les coûts liés à cette thérapie auprès du régime d’avantages sociaux fourni par votre employeur.  Assurez-vous de vérifier les détails de votre couverture dans la brochure des avantages sociaux ou en contactant l’administrateur de votre régime.',
    );
    lines.push(
      'Si vous ne vous sentez pas en sécurité ou que vous pourriez blesser d’autres personnes, veuillez appeler le 9-1-1 ou chercher des services d’urgence.',
    );
    lines.push('Merci!');
  } else {
    lines.push(
      'In general, increasing physical activity, improving sleep, and spending more time with family/friends is beneficial.',
    );
    lines.push(
      'Please note that a formal assessment was not completed. An attempt was made to interpret the results from a few questions. This means that this report is not to be considered accurate.',
    );
    lines.push(
      'You may want to consider speaking to a professional using virtual counselling. The first session is complimentary. You may choose to submit the costs for this therapy through the employee benefits provided by your employer. Please ensure you check your coverage details by checking your benefits booklet or contacting your plan administrator.',
    );
    lines.push(
      'If you are not feeling safe or may harm others, please call 911 or seek out emergency services.',
    );
    lines.push('Thank you!');
  }
  return lines;
};

export const renderDepression = (description, locale) =>
  isFrench(locale) ? (
    <div>
      <P color={black} fontSize="16px">
        Vous semblez présenter des symptômes de dépression. L’évaluation laisse
        croire que la gravité de vos symptômes est <span>{description}</span>.
      </P>
      <P color={black} fontSize="16px">
        La dépression est un problème de santé mentale commun. Ceux qui
        souffrent de dépression éprouvent un sentiment persistant de tristesse
        et de désespoir, ainsi qu’une perte d’intérêt pour les activités qu’ils
        appréciaient autrefois. Hormis les problèmes émotionnels causés par la
        dépression, des symptômes physiques comme la douleur chronique et les
        problèmes digestifs peuvent également se présenter.
      </P>
      <P color={black} fontSize="16px">
        Les personnes souffrant de dépression sont souvent irritables; elles
        boudent et ruminent de façon obsessive. Très souvent, les personnes
        souffrant de dépression rapportent souffrir d’anxiété et sont à plus
        haut risque de développer des dépendances.
      </P>
      <P color={black} fontSize="16px">
        Quelle est la différence entre la dépression et la tristesse? La
        dépression est bien plus que de la simple tristesse. La différence se
        situe dans la combinaison de la durée, de la présence d’autres
        symptômes, ainsi que des impacts sur le corps et sur le fonctionnement
        quotidien..
      </P>
    </div>
  ) : (
    <div>
      <P color={black} fontSize="16px">
        You seem to have depressive symptoms. The evaluation suggests the
        severity is <span>{description}</span>.
      </P>
      <P color={black} fontSize="16px">
        Depression is a common mental health difficulty. Those who suffer from
        depression experience persistent feelings of sadness and hopelessness
        and lose interest in activities they once enjoyed. Aside from the
        emotional problems caused by depression, individuals can also present
        with physical symptoms such as chronic pain or digestive issues.
      </P>
      <P color={black} fontSize="16px">
        Depression often causes irritability, brooding, and obsessive
        rumination. Oftentimes, people with depression report anxiety and are at
        higher risk of addictions.
      </P>
      <P color={black} fontSize="16px">
        What is the difference between depression and sadness? Depression is
        more than just sadness. The difference lies in the combination of
        duration, other symptoms, bodily impact and its impact on daily
        function.
      </P>
    </div>
  );

export const gatherDepression = (lines, description, locale) => {
  if (isFrench(locale)) {
    lines.push(
      `Vous semblez présenter des symptômes de dépression. L’évaluation laisse croire que la gravité de vos symptômes est <b>${description}</b>.`,
    );
    lines.push(
      'La dépression est un problème de santé mentale commun. Ceux qui souffrent de dépression éprouvent un sentiment persistant de tristesse et de désespoir, ainsi qu’une perte d’intérêt pour les activités qu’ils appréciaient autrefois.  Hormis les problèmes émotionnels causés par la dépression, des symptômes physiques comme la douleur chronique et les problèmes digestifs peuvent également se présenter.',
    );
    lines.push(
      'Les personnes souffrant de dépression sont souvent irritables; elles boudent et ruminent de façon obsessive. Très souvent, les personnes souffrant de dépression rapportent souffrir d’anxiété et sont à plus haut risque de développer des dépendances.',
    );
    lines.push(
      'Quelle est la différence entre la dépression et la tristesse? La dépression est bien plus que de la simple tristesse. La différence se situe dans la combinaison de la durée, de la présence d’autres symptômes, ainsi que des impacts sur le corps et sur le fonctionnement quotidien.',
    );
  } else {
    lines.push(
      `You seem to have depressive symptoms. The evaluation suggests the severity is <b>${description}</b>.`,
    );
    lines.push(
      'Depression is a common mental health difficulty. Those who suffer from depression experience persistent feelings of sadness and hopelessness and lose interest in activities they once enjoyed. Aside from the emotional problems caused by depression, individuals can also present with physical symptoms such as chronic pain or digestive issues.',
    );
    lines.push(
      'Depression often causes irritability, brooding, and obsessive rumination. Oftentimes, people with depression report anxiety and are at higher risk of addictions',
    );
    lines.push(
      'What is the difference between depression and sadness? Depression is more than just sadness. The difference lies in the combination of duration, other symptoms, bodily impact and its impact on daily function.',
    );
  }
  return lines;
};

export const renderAnxiety = (description, locale) =>
  isFrench(locale) ? (
    <div>
      <P color={black} fontSize="16px">
        Vos réponses laissent croire à une augmentation de l’anxiété. La gravité
        est évaluée à <span>{description}</span>.
      </P>
      <P color={black} fontSize="16px">
        L’anxiété est une réaction normale au stress et peut être bénéfique dans
        certaines situations. Elle peut nous alerter en cas de danger et nous
        aider à nous préparer et à prêter attention. Les troubles anxieux sont
        différents des sentiments normaux de nervosité et d’inquiétude, et
        impliquent de la peur ou de l’anxiété excessives. Les troubles anxieux
        représentent le trouble mental le plus commun et affectent près de 30
        pour cent des adultes à un certain moment de leur vie.
      </P>
      <P color={black} fontSize="16px">
        L’anxiété se rapporte à l’anticipation d’une préoccupation future et est
        plus souvent associée à la tension des muscles et au comportement
        d’évitement.
      </P>
      <P color={black} fontSize="16px">
        La peur est une réponse émotionnelle à un danger immédiat et est plus
        souvent associée avec la réaction de lutte ou de fuite – rester pour se
        battre ou fuir pour échapper au danger.
      </P>
      <P color={black} fontSize="16px">
        En général, pour qu’un diagnostic de trouble anxieux soit émis, la peur
        ou l’anxiété doit :
        <ul>
          <li>
            Être démesurée pour la situation ou inappropriée pour votre âge
          </li>
          <li>Entraver votre capacité à fonctionner normalement</li>
        </ul>
        Il y a plusieurs types de troubles anxieux, dont le trouble d’anxiété
        généralisée, le trouble panique, les phobies spécifiques, l’agoraphobie,
        le trouble d’anxiété sociale et le trouble d’anxiété de séparation.
        Certains de ces derniers seront évalués si vous décidez de faire l’objet
        d’une évaluation plus détaillée.
      </P>
    </div>
  ) : (
    <div>
      <P color={black} fontSize="16px">
        Your responses suggested increased anxiety. The severity was evaluated
        to be <span>{description}</span>.
      </P>
      <P color={black} fontSize="16px">
        Anxiety is a normal reaction to stress and can be beneficial in some
        situations. It can alert us to dangers and help us prepare and pay
        attention. Anxiety disorders differ from normal feelings of nervousness
        or anxiousness, and involve excessive fear or anxiety. Anxiety disorders
        are the most common of mental disorders and affect nearly 30 percent of
        adults at some point in their lives.
      </P>
      <P color={black} fontSize="16px">
        Anxiety refers to the anticipation of a future concern and is more
        associated with muscle tension and avoidance behaviour.
      </P>
      <P color={black} fontSize="16px">
        Fear is an emotional response to an immediate threat and is more
        associated with a fight or flight reaction – either staying to fight or
        leaving to escape danger. Anxiety disorders can cause people to try to
        avoid situations that trigger or worsen their symptoms. Job performance,
        school work and personal relationships can be affected.
      </P>
      <P color={black} fontSize="16px">
        In general, for a person to be diagnosed with an anxiety disorder, the
        fear or anxiety must:
        <ul>
          <li>Be out of proportion to the situation or age inappropriate</li>
          <li>Hinder your ability to function normally</li>
        </ul>
        There are several types of anxiety disorders, including generalized
        anxiety disorder, panic disorder, specific phobias, agoraphobia, social
        anxiety disorder and separation anxiety disorder. Some of these are
        evaluated if you do a more detailed evaluation.
      </P>
    </div>
  );

export const gatherAnxiety = (lines, description, locale) => {
  if (isFrench(locale)) {
    lines.push(
      `Vos réponses laissent croire à une augmentation de l’anxiété. La gravité est évaluée à <b>${description}</b>.`,
    );
    lines.push(
      'L’anxiété est une réaction normale au stress et peut être bénéfique dans certaines situations. Elle peut nous alerter en cas de danger et nous aider à nous préparer et à prêter attention. Les troubles anxieux sont différents des sentiments normaux de nervosité et d’inquiétude, et impliquent de la peur ou de l’anxiété excessives. Les troubles anxieux représentent le trouble mental le plus commun et affectent près de 30 pour cent des adultes à un certain moment de leur vie.',
    );
    lines.push(
      'L’anxiété se rapporte à l’anticipation d’une préoccupation future et est plus souvent associée à la tension des muscles et au comportement d’évitement.',
    );
    lines.push(
      'La peur est une réponse émotionnelle à un danger immédiat et est plus souvent associée avec la réaction de lutte ou de fuite – rester pour se battre ou fuir pour échapper au danger.',
    );
    lines.push(
      'En général, pour qu’un diagnostic de trouble anxieux soit émis, la peur ou l’anxiété doit :',
    );
    lines.push(
      '<space>• Être démesurée pour la situation ou inappropriée pour votre âge<br><space>• Entraver votre capacité à fonctionner normalement',
    );
    lines.push(
      'Il y a plusieurs types de troubles anxieux, dont le trouble d’anxiété généralisée, le trouble panique, les phobies spécifiques, l’agoraphobie, le trouble d’anxiété sociale et le trouble d’anxiété de séparation. Certains de ces derniers seront évalués si vous décidez de faire l’objet d’une évaluation plus détaillée.',
    );
  } else {
    lines.push(
      `Your responses suggested increased anxiety. The severity was evaluated to be <b>${description}</b>.`,
    );
    lines.push(
      'Anxiety is a normal reaction to stress and can be beneficial in some situations. It can alert us to dangers and help us prepare and pay attention. Anxiety disorders differ from normal feelings of nervousness or anxiousness, and involve excessive fear or anxiety. Anxiety disorders are the most common of mental disorders and affect nearly 30 percent of adults at some point in their lives.',
    );
    lines.push(
      'Anxiety refers to the anticipation of a future concern and is more associated with muscle tension and avoidance behaviour.',
    );
    lines.push(
      'Fear is an emotional response to an immediate threat and is more associated with a fight or flight reaction – either staying to fight or leaving to escape danger. Anxiety disorders can cause people to try to avoid situations that trigger or worsen their symptoms. Job performance, school work and personal relationships can be affected.',
    );
    lines.push(
      'In general, for a person to be diagnosed with an anxiety disorder, the fear or anxiety must:',
    );
    lines.push(
      '<space>• Be out of proportion to the situation or age inappropriate<br><space>• Hinder your ability to function normally',
    );
    lines.push(
      'There are several types of anxiety disorders, including generalized anxiety disorder, panic disorder, specific phobias, agoraphobia, social anxiety disorder and separation anxiety disorder. Some of these are evaluated if you do a more detailed evaluation.',
    );
  }
  return lines;
};

export const renderBipolar = (description, locale) =>
  isFrench(locale) ? (
    <div>
      <P color={black} fontSize="16px">
        Certains symptômes laissent croire à de l’hypomanie, qui, dans certaines
        situations, évoque un trouble bipolaire. Sachez qu’il est très difficile
        de diagnostiquer le trouble bipolaire. Ce n’est pas un diagnostic qui
        peut être établi au moyen d’un outil automatisé.
      </P>
      <P color={black} fontSize="16px">
        Le trouble bipolaire était autrefois appelé maniaco-dépression. Il peut
        causer des sautes d’humeur extrêmes avec des sentiments d’euphorie
        (manie ou hypomanie) et de dysphorie (dépression). La période de
        dysphorie est marquée par la présence d’un sentiment de désespoir et
        d’une perte d’intérêt ou de plaisir pour la plupart des activités. La
        période d’euphorie est marquée par la présence de beaucoup d’énergie et
        d’irritabilité. Les sautes d’humeur peuvent affecter le sommeil,
        l’énergie, l’activité, le jugement, le comportement et la capacité à
        réfléchir lucidement.
      </P>
      <P color={black} fontSize="16px">
        Les sautes d’humeur peuvent survenir rarement ou plusieurs fois par
        année. Alors que la plupart des gens éprouvent des symptômes émotionnels
        entre les épisodes, certains pourraient n’en éprouver aucun.
      </P>
      <P color={black} fontSize="16px">
        Si vous croyez que vous présentez des symptômes de trouble bipolaire, il
        est important d’en discuter pleinement avec votre prestataire de soins
        de santé.
      </P>
    </div>
  ) : (
    <div>
      <P color={black} fontSize="16px">
        There were some symptoms suggestive of (hypo)mania which can in some
        situations suggest bipolar disorder. Please note: it is very difficult
        to diagnose bipolar disorder. It is not a diagnosis that can be made
        using an automated tool.
      </P>
      <P color={black} fontSize="16px">
        Bipolar disorder was previously called manic depression. It can cause
        extreme mood swings with emotional highs (mania or hypomania) and low
        (depression). During the lows, there is a sense of hopelessness, and
        loss of interest or pleasure in most activities. When the mood is high,
        there is euphoria, high energy, and irritability. The mood swings can
        affect sleep, energy, activity, judgement, behaviour and the ability to
        think clearly.
      </P>
      <P color={black} fontSize="16px">
        Mood swings can occur rarely or multiple times a year. While most people
        will experience some emotional symptoms between episodes, some may not
        experience any.
      </P>
      <P color={black} fontSize="16px">
        If you feel you may have symptoms of bipolar disorder, it is important
        to fully discuss with your healthcare provider.
      </P>
    </div>
  );

export const gatherBipolar = (lines, description, locale) => {
  if (isFrench(locale)) {
    lines.push(
      'Certains symptômes laissent croire à de l’hypomanie, qui, dans certaines situations, évoque un trouble bipolaire. Sachez qu’il est très difficile de diagnostiquer le trouble bipolaire. Ce n’est pas un diagnostic qui peut être établi au moyen d’un outil automatisé.',
    );
    lines.push(
      'Le trouble bipolaire était autrefois appelé maniaco-dépression. Il peut causer des sautes d’humeur extrêmes avec des sentiments d’euphorie (manie ou hypomanie) et de dysphorie (dépression). La période de dysphorie est marquée par la présence d’un sentiment de désespoir et d’une perte d’intérêt ou de plaisir pour la plupart des activités. La période d’euphorie est marquée par la présence de beaucoup d’énergie et d’irritabilité. Les sautes d’humeur peuvent affecter le sommeil, l’énergie, l’activité, le jugement, le comportement et la capacité à réfléchir lucidement.',
    );
    lines.push(
      'Les sautes d’humeur peuvent survenir rarement ou plusieurs fois par année. Alors que la plupart des gens éprouvent des symptômes émotionnels entre les épisodes, certains pourraient n’en éprouver aucun.',
    );
    lines.push(
      'Si vous croyez que vous présentez des symptômes de trouble bipolaire, il est important d’en discuter pleinement avec votre prestataire de soins de santé.',
    );
  } else {
    lines.push(
      'There were some symptoms suggestive of (hypo)mania which can in some situations suggest bipolar disorder. Please note: it is very difficult to diagnose bipolar disorder. It is not a diagnosis that can be made using an automated tool.',
    );
    lines.push(
      'Bipolar disorder was previously called manic depression. It can cause extreme mood swings with emotional highs (mania or hypomania) and low (depression). During the lows, there is a sense of hopelessness, and loss of interest or pleasure in most activities. When the mood is high, there is euphoria, high energy, and irritability. The mood swings can affect sleep, energy, activity, judgement, behaviour and the ability to think clearly.',
    );
    lines.push(
      'Mood swings can occur rarely or multiple times a year. While most people will experience some emotional symptoms between episodes, some may not experience any.',
    );
    lines.push(
      'If you feel you may have symptoms of bipolar disorder, it is important to fully discuss with your healthcare provider.',
    );
  }
  return lines;
};

export const renderSubstance = (description, locale) =>
  isFrench(locale) ? (
    <div>
      <P color={black} fontSize="16px">
        Certaines de vos réponses laissent croire à la possibilité d’un trouble
        lié à un abus d’alcool ou d’autres drogues (dépendance). En se basant
        sur les substances dépistées, l’évaluation de la gravité est{' '}
        <span>{description}</span>.
      </P>
      <P color={black} fontSize="16px">
        Les dépendances mènent à une incapacité à contrôler la consommation
        d’une drogue légale ou illégale, ou d’un médicament. La consommation
        d’une drogue ou l’adoption continue d’un comportement, malgré les
        dommages que cela entraîne, est un signe de dépendance.
      </P>
      <P color={black} fontSize="16px">
        Les différentes substances peuvent causer différents symptômes de
        sevrage et de surdose. Certaines sont également considérées comme plus
        addictives et dommageables que d’autres.
      </P>
      <P color={black} fontSize="16px">
        En général, il existe onze caractéristiques communes au trouble lié à un
        abus d’alcool ou d’autres drogues :
        <ol>
          <li>
            Consommer la substance en quantité plus importante ou plus longtemps
            que vous le devriez.
          </li>
          <li>
            Vouloir réduire ou cesser la consommation de la substance, mais ne
            pas y parvenir.
          </li>
          <li>
            Passer beaucoup de temps à obtenir ou à consommer une substance, ou
            à se remettre de sa consommation.
          </li>
          <li>Désirs et envies pressantes de consommer une substance.</li>
          <li>
            Ne pas arriver à gérer les tâches au travail, à la maison ou à
            l’école en raison de la consommation d’une substance.
          </li>
          <li>
            Continuer la consommation, même lorsqu’elle cause des problèmes dans
            les relations interpersonnelles.
          </li>
          <li>
            Abandonner des activités sociales, professionnelles ou récréatives
            importantes en raison de la consommation de substances.
          </li>
          <li>
            Consommer une substance encore et encore, même lorsque c’est
            dangereux.
          </li>
          <li>
            Continuer la consommation, même en sachant qu’un problème physique
            ou psychologique pourrait avoir été causé ou aggravé par la
            substance.
          </li>
          <li>
            Nécessiter une quantité plus grande de la substance pour obtenir
            l’effet désiré (tolérance).
          </li>
          <li>
            Développer des symptômes de sevrage, qui peuvent être soulagés en
            consommant encore plus de la substance.
          </li>
        </ol>
      </P>
    </div>
  ) : (
    <div>
      <P color={black} fontSize="16px">
        Some of your responses suggest the possibility of a substance use
        disorder (addiction). Based on the substances screened, the severity was
        assessed to be <span>{description}</span>.
      </P>
      <P color={black} fontSize="16px">
        Addictions lead to an inability to control the use of a legal or illegal
        drug or medication. A hallmark of addiction is the use of a drug or
        continuing a behaviour despite the harm that it causes.
      </P>
      <P color={black} fontSize="16px">
        Different substances cause different withdrawal and overdose symptoms.
        Some are also considered more addictive and harmful than others.
      </P>
      <P color={black} fontSize="16px">
        In general, there are eleven common features of substance use disorder:
        <ol>
          <li>
            Taking the substance in larger amounts or for longer than
            you&apos;re meant to.
          </li>
          <li>
            Wanting to cut down or stop using the substance but not managing to.
          </li>
          <li>
            Spending a lot of time getting, using, or recovering from use of the
            substance
          </li>
          <li>Cravings and urges to use the substance.</li>
          <li>
            Not managing to do what you should at work, home, or school because
            of substance use.
          </li>
          <li>
            Continuing to use, even when it causes problems in relationships.
          </li>
          <li>
            Giving up important social, occupational, or recreational activities
            because of substance use.
          </li>
          <li>
            Using substances again and again, even when it puts you in danger.
          </li>
          <li>
            Continuing to use, even when you know you have a physical or
            psychological problem that could have been caused or made worse by
            the substance
          </li>
          <li>
            Needing more of the substance to get the effect you want
            (tolerance).
          </li>
          <li>
            Development of withdrawal symptoms, which can be relieved by taking
            more of the substance.
          </li>
        </ol>
      </P>
    </div>
  );

export const gatherSubstance = (lines, description, locale) => {
  if (isFrench(locale)) {
    lines.push(
      `Certaines de vos réponses laissent croire à la possibilité d’un trouble lié à un abus d’alcool ou d’autres drogues (dépendance). En se basant sur les substances dépistées, l’évaluation de la gravité est <b>${description}</b>.`,
    );
    lines.push(
      'Les dépendances mènent à une incapacité à contrôler la consommation d’une drogue légale ou illégale, ou d’un médicament. La consommation d’une drogue ou l’adoption continue d’un comportement, malgré les dommages que cela entraîne, est un signe de dépendance.',
    );
    lines.push(
      'Les différentes substances peuvent causer différents symptômes de sevrage et de surdose. Certaines sont également considérées comme plus addictives et dommageables que d’autres.',
    );
    lines.push(
      'En général, il existe onze caractéristiques communes au trouble lié à un abus d’alcool ou d’autres drogues :',
    );
    lines.push(
      '<space>1. Consommer la substance en quantité plus importante ou plus longtemps que vous le devriez.',
    );
    lines.push(
      '<space>2. Vouloir réduire ou cesser la consommation de la substance, mais ne pas y parvenir.',
    );
    lines.push(
      '<space>3. Passer beaucoup de temps à obtenir ou à consommer une substance, ou à se remettre de sa consommation.',
    );
    lines.push(
      '<space>4. Désirs et envies pressantes de consommer une substance.',
    );
    lines.push(
      '<space>5. Ne pas arriver à gérer les tâches au travail, à la maison ou à l’école en raison de la consommation d’une substance.',
    );
    lines.push(
      '<space>6. Continuer la consommation, même lorsqu’elle cause des problèmes dans les relations interpersonnelles.',
    );
    lines.push(
      '<space>7. Abandonner des activités sociales, professionnelles ou récréatives importantes en raison de la consommation de substances.',
    );
    lines.push(
      '<space>8. Consommer une substance encore et encore, même lorsque c’est dangereux.',
    );
    lines.push(
      '<space>9. Continuer la consommation, même en sachant qu’un problème physique ou psychologique pourrait avoir été causé ou aggravé par la substance.',
    );
    lines.push(
      '<space>10. Nécessiter une quantité plus grande de la substance pour obtenir l’effet désiré (tolérance).',
    );
    lines.push(
      '<space>11. Développer des symptômes de sevrage, qui peuvent être soulagés en consommant encore plus de la substance.',
    );
  } else {
    lines.push(
      `Some of your responses suggest the possibility of a substance use disorder (addiction). Based on the substances screened, it was found to be of <b>${description}</b>.`,
    );
    lines.push(
      'Addictions lead to an inability to control the use of a legal or illegal drug or medication. A hallmark of addiction is the use of a drug or continuing a behaviour despite the harm that it causes.',
    );
    lines.push(
      'Different substances cause different withdrawal and overdose symptoms. Some are also considered more addictive and harmful than others.',
    );
    lines.push(
      'In general, there are eleven common features of substance use disorder:',
    );
    lines.push(
      "<space>1. Taking the substance in larger amounts or for longer than you're meant to.",
    );
    lines.push(
      '<space>2. Wanting to cut down or stop using the substance but not managing to.',
    );
    lines.push(
      '<space>3. Spending a lot of time getting, using, or recovering from use of the substance.',
    );
    lines.push('<space>4. Cravings and urges to use the substance.');
    lines.push(
      '<space>5. Not managing to do what you should at work, home, or school because of substance use.',
    );
    lines.push(
      '<space>6. Continuing to use, even when it causes problems in relationships.',
    );
    lines.push(
      '<space>7. Giving up important social, occupational, or recreational activities because of substance use.',
    );
    lines.push(
      '<space>8. Using substances again and again, even when it puts you in danger.',
    );
    lines.push(
      '<space>9. Continuing to use, even when you know you have a physical or psychological problem that could have been caused or made worse by the substance',
    );
    lines.push(
      '<space>10. Needing more of the substance to get the effect you want (tolerance).',
    );
    lines.push(
      '<space>11. Development of withdrawal symptoms, which can be relieved by taking more of the substance.',
    );
  }
  return lines;
};

export const renderPTSD = (description, locale) =>
  isFrench(locale) ? (
    <div>
      <P color={black} fontSize="16px">
        Certaines de vos réponses laissent croire à une possibilité de trouble
        de stress post-traumatique (TSPT), une maladie que certaines personnes
        développent après avoir vécu ou été témoins d’un événement traumatisant
        comme une catastrophe naturelle, un grave accident, un acte terroriste,
        la guerre ou un combat, un viol ou d’autres types d’agressions
        violentes.
      </P>
      <P color={black} fontSize="16px">
        Vos réponses laissent croire que la probabilité que vous souffriez du
        TSPT est <span>{description}</span>.
      </P>
      <P color={black} fontSize="16px">
        Les personnes souffrant du TSPT ont des pensées et des sentiments
        intenses et troublants en lien avec leur expérience, et ce, longtemps
        après que l’événement traumatisant soit terminé. Elles peuvent revivre
        l’événement à travers des flashbacks ou des cauchemars; elles peuvent
        ressentir de la tristesse, de la peur ou de la colère; et elles peuvent
        se sentir détachées ou coupées des autres personnes. Les personnes
        souffrant du TSPT peuvent éviter des situations ou des personnes qui
        leur rappellent l’événement traumatisant; elles peuvent également avoir
        de fortes réactions négatives à des situations aussi banales qu’un bruit
        sourd ou un contact accidentel.
      </P>
      <P color={black} fontSize="16px">
        Afin de recevoir un diagnostic de TSPT, vous devez avoir été exposé à un
        événement traumatisant et bouleversant. Toutefois, l’exposition pourrait
        avoir été indirecte plutôt que directe. Par exemple, le TSPT pourrait
        survenir chez une personne qui apprend la mort violente d’un membre de
        sa famille immédiate. Il peut également survenir à la suite d’une
        exposition répétée à des détails horribles d’un traumatisme, comme les
        policiers qui sont exposés aux détails des cas d’abus d’enfants.
      </P>
      <P color={black} fontSize="16px">
        De nombreuses personnes qui sont exposées à des événements traumatisants
        ressentent des symptômes similaires à ceux décrits ci-dessus au cours
        des jours qui suivent cet événement. Toutefois, pour qu’une personne
        reçoive un diagnostic de TSPT, ces symptômes doivent durer pendant plus
        d’un mois et souvent persister pendant des mois et des années. Plusieurs
        individus développent des symptômes dans les trois mois qui suivent le
        traumatisme, mais ces symptômes peuvent également apparaître plus tard.
        Pour les personnes souffrant de TSPT, ces symptômes peuvent causer une
        détresse importante ou perturber leur fonctionnement. Le TSPT se
        manifeste souvent en même temps que d’autres problèmes comme la
        dépression, la consommation d’alcool ou d’autres drogues, les problèmes
        de mémoire, ainsi que d’autres problèmes physiques et mentaux.
      </P>
      <P color={black} fontSize="16px">
        Des traitements efficaces existent pour le TSPT et ils peuvent réduire
        les symptômes de façon significative.
      </P>
    </div>
  ) : (
    <div>
      <P color={black} fontSize="16px">
        Some of your responses suggest a possibility of post-traumatic stress
        disorder (PTSD): a condition that some people develop after witnessing
        or living through a traumatic event such as a natural disaster, a
        serious accident, a terrorist act, war/combat, rape or other violent
        personal assault.
      </P>
      <P color={black} fontSize="16px">
        Your answers suggest your likelihood of having PTSD is{' '}
        <span>{description}</span>.
      </P>
      <P color={black} fontSize="16px">
        People with PTSD have intense, disturbing thoughts and feelings related
        to their experience that last long after the traumatic event has ended.
        They may relive the event through flashbacks or nightmares; they may
        feel sadness, fear or anger; and they may feel detached or estranged
        from other people. People with PTSD may avoid situations or people that
        remind them of the traumatic event, and they may have strong negative
        reactions to something as ordinary as a loud noise or an accidental
        touch.
      </P>
      <P color={black} fontSize="16px">
        A diagnosis of PTSD requires exposure to an upsetting traumatic event.
        However, exposure could be indirect rather than first hand. For example,
        PTSD could occur in an individual learning about the violent death of a
        close family. It can also occur as a result of repeated exposure to
        horrible details of trauma such as police officers exposed to details of
        child abuse cases.
      </P>
      <P color={black} fontSize="16px">
        Many people who are exposed to a traumatic event experience symptoms
        like those described above in the days following the event. For a person
        to be diagnosed with PTSD, however, symptoms last for more than a month
        and often persist for months and sometimes years. Many individuals
        develop symptoms within three months of the trauma, but symptoms may
        appear later. For people with PTSD the symptoms cause significant
        distress or problems functioning. PTSD often occurs with other related
        conditions, such as depression, substance use, memory problems and other
        physical and mental health problems.
      </P>
      <P color={black} fontSize="16px">
        There are effective treatments for PTSD that can significantly reduce
        symptoms.
      </P>
    </div>
  );

export const gatherPTSD = (lines, description, locale) => {
  if (isFrench(locale)) {
    lines.push(
      'Certaines de vos réponses laissent croire à une possibilité de trouble de stress post-traumatique (TSPT), une maladie que certaines personnes développent après avoir vécu ou été témoins d’un événement traumatisant comme une catastrophe naturelle, un grave accident, un acte terroriste, la guerre ou un combat, un viol ou d’autres types d’agressions violentes.',
    );
    lines.push(
      `Vos réponses laissent croire que la probabilité que vous souffriez du TSPT est <b>${description}</b>`,
    );
    lines.push(
      'Les personnes souffrant du TSPT ont des pensées et des sentiments intenses et troublants en lien avec leur expérience, et ce, longtemps après que l’événement traumatisant soit terminé. Elles peuvent revivre l’événement à travers des flashbacks ou des cauchemars; elles peuvent ressentir de la tristesse, de la peur ou de la colère; et elles peuvent se sentir détachées ou coupées des autres personnes. Les personnes souffrant du TSPT peuvent éviter des situations ou des personnes qui leur rappellent l’événement traumatisant; elles peuvent également avoir de fortes réactions négatives à des situations aussi banales qu’un bruit sourd ou un contact accidentel.',
    );
    lines.push(
      'Afin de recevoir un diagnostic de TSPT, vous devez avoir été exposé à un événement traumatisant et bouleversant. Toutefois, l’exposition pourrait avoir été indirecte plutôt que directe. Par exemple, le TSPT pourrait survenir chez une personne qui apprend la mort violente d’un membre de sa famille immédiate. Il peut également survenir à la suite d’une exposition répétée à des détails horribles d’un traumatisme, comme les policiers qui sont exposés aux détails des cas d’abus d’enfants.',
    );
    lines.push(
      'De nombreuses personnes qui sont exposées à des événements traumatisants ressentent des symptômes similaires à ceux décrits ci-dessus au cours des jours qui suivent cet événement. Toutefois, pour qu’une personne reçoive un diagnostic de TSPT, ces symptômes doivent durer pendant plus d’un mois et souvent persister pendant des mois et des années. Plusieurs individus développent des symptômes dans les trois mois qui suivent le traumatisme, mais ces symptômes peuvent également apparaître plus tard. Pour les personnes souffrant de TSPT, ces symptômes peuvent causer une détresse importante ou perturber leur fonctionnement. Le TSPT se manifeste souvent en même temps que d’autres problèmes comme la dépression, la consommation d’alcool ou d’autres drogues, les problèmes de mémoire, ainsi que d’autres problèmes physiques et mentaux.',
    );
    lines.push(
      'Des traitements efficaces existent pour le TSPT et ils peuvent réduire les symptômes de façon significative.',
    );
  } else {
    lines.push(
      'Some of your responses suggest a possibility of post-traumatic stress disorder (PTSD): a condition that some people develop after witnessing or living through a traumatic event such as a natural disaster, a serious accident, a terrorist act, war/combat, rape or other violent personal assault.',
    );
    lines.push(
      `Your answers suggest your likelihood of having PTSD is <b>${description}</b>`,
    );
    lines.push(
      'People with PTSD have intense, disturbing thoughts and feelings related to their experience that last long after the traumatic event has ended. They may relive the event through flashbacks or nightmares; they may feel sadness, fear or anger; and they may feel detached or estranged from other people. People with PTSD may avoid situations or people that remind them of the traumatic event, and they may have strong negative reactions to something as ordinary as a loud noise or an accidental touch.',
    );
    lines.push(
      'A diagnosis of PTSD requires exposure to an upsetting traumatic event. However, exposure could be indirect rather than first hand. For example, PTSD could occur in an individual learning about the violent death of a close family. It can also occur as a result of repeated exposure to horrible details of trauma such as police officers exposed to details of child abuse cases.',
    );
    lines.push(
      'Many people who are exposed to a traumatic event experience symptoms like those described above in the days following the event. For a person to be diagnosed with PTSD, however, symptoms last for more than a month and often persist for months and sometimes years. Many individuals develop symptoms within three months of the trauma, but symptoms may appear later. For people with PTSD the symptoms cause significant distress or problems functioning. PTSD often occurs with other related conditions, such as depression, substance use, memory problems and other physical and mental health problems.',
    );
    lines.push(
      'There are effective treatments for PTSD that can significantly reduce symptoms.',
    );
  }
};

export const renderEmpty = locale =>
  isFrench(locale) ? (
    <div>
      <P color={black} fontSize="16px">
        Vos réponses aux questions préliminaires de dépistage étaient positives,
        mais suite à une évaluation plus détaillée, vos symptômes ne se sont pas
        révélés suffisamment graves pour atteindre le seuil clinique.
      </P>
      <P color={black} fontSize="16px">
        Vous pouvez cliquer sur le lien ci-dessous pour effectuer une évaluation
        plus détaillée, afin d’évaluer les problèmes de santé supplémentaires.
      </P>
    </div>
  ) : (
    <div>
      <P color={black} fontSize="16px">
        Your preliminary screening questions were positive, but on more detailed
        assessment, you did not have sufficiently severe symptoms to meet
        clinical threshold.
      </P>
      <P color={black} fontSize="16px">
        You can click below for a more detailed assessment that will evaluate
        for additional conditions.
      </P>
    </div>
  );

export const gatherEmpty = (lines, locale) => {
  if (isFrench(locale)) {
    lines.push(
      'Vos réponses aux questions préliminaires de dépistage étaient positives, mais suite à une évaluation plus détaillée, vos symptômes ne se sont pas révélés suffisamment graves pour atteindre le seuil clinique. preliminary screening questions were positive, but on more detailed assessment, you did not have sufficiently severe symptoms to meet clinical threshold.',
    );
    lines.push(
      'Vous pouvez cliquer sur le lien ci-dessous pour effectuer une évaluation plus détaillée, afin d’évaluer les problèmes de santé supplémentaires.',
    );
  } else {
    lines.push(
      'Your preliminary screening questions were positive, but on more detailed assessment, you did not have sufficiently severe symptoms to meet clinical threshold.',
    );
    lines.push(
      'You can click below for a more detailed assessment that will evaluate for additional conditions.',
    );
  }
  return lines;
};

export const renderSymptomFooter = locale =>
  isFrench(locale) ? (
    <div>
      <P color={black} fontSize="16px">
        En général, augmenter l’activité physique, améliorer le sommeil et
        passer plus de temps avec sa famille/ses amis est bénéfique.
      </P>
      <P color={black} fontSize="16px">
        Vous devriez envisager de parler avec un professionnel en utilisant la
        thérapie virtuelle. La première session est gratuite. Vous pouvez
        décider de déclarer les coûts liés à cette thérapie auprès du régime
        d’avantages sociaux fourni par votre employeur. Assurez-vous de vérifier
        les détails de votre couverture dans la brochure des avantages sociaux
        ou en contactant l’administrateur de votre régime.
      </P>
      <P color={black} fontSize="16px">
        Veuillez noter que ces résultats ne constituent pas un diagnostic et
        qu’ils ne devraient pas être considérés comme une évaluation médicale.
      </P>
      <P color={black} fontSize="16px">
        Si vous ne vous sentez pas en sécurité ou que vous pourriez blesser
        d’autres personnes, veuillez appeler le 9-1-1 ou chercher des services
        d’urgence.
      </P>
      <P color={black} fontSize="16px">
        Merci!
      </P>
    </div>
  ) : (
    <div>
      <P color={black} fontSize="16px">
        In general, increasing physical activity, improving sleep, and spending
        more time with family/friends is beneficial.
      </P>
      <P color={black} fontSize="16px">
        You may want to consider speaking to a professional using virtual
        counselling. The first session is complimentary. You may choose to
        submit the costs for this therapy through the employee benefits provided
        by your employer. Please ensure you check your coverage details by
        checking your benefits booklet or contacting your plan administrator.
      </P>
      <P color={black} fontSize="16px">
        Please note that our results are not diagnostic and should not be
        considered a medical assessment.
      </P>
      <P color={black} fontSize="16px">
        If you are not feeling safe or may harm others, please call 911 or seek
        out emergency services.
      </P>
      <P color={black} fontSize="16px">
        Thank you!
      </P>
    </div>
  );

export const gatherSymptomFooter = (lines, locale) => {
  if (isFrench(locale)) {
    lines.push(
      'En général, augmenter l’activité physique, améliorer le sommeil et passer plus de temps avec sa famille/ses amis est bénéfique.',
    );
    lines.push(
      'Vous devriez envisager de parler avec un professionnel en utilisant la thérapie virtuelle. La première session est gratuite. Vous pouvez décider de déclarer les coûts liés à cette thérapie auprès du régime d’avantages sociaux fourni par votre employeur.  Assurez-vous de vérifier les détails de votre couverture dans la brochure des avantages sociaux ou en contactant l’administrateur de votre régime.',
    );
    lines.push(
      'Veuillez noter que ces résultats ne constituent pas un diagnostic et qu’ils ne devraient pas être considérés comme une évaluation médicale.',
    );
    lines.push(
      'Si vous ne vous sentez pas en sécurité ou que vous pourriez blesser d’autres personnes, veuillez appeler le 9-1-1 ou chercher des services d’urgence.',
    );
    lines.push('Merci!');
  } else {
    lines.push(
      'In general, increasing physical activity, improving sleep, and spending more time with family/friends is beneficial.',
    );
    lines.push(
      'You may want to consider speaking to a professional using virtual counselling. The first session is complimentary. You may choose to submit the costs for this therapy through the employee benefits provided by your employer. Please ensure you check your coverage details by checking your benefits booklet or contacting your plan administrator.',
    );
    lines.push(
      'Please note that our results are not diagnostic and should not be considered a medical assessment.',
    );
    lines.push(
      'If you are not feeling safe or may harm others, please call 911 or seek out emergency services.',
    );
    lines.push('Thank you!');
  }
  return lines;
};
