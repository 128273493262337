/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import PeopleCareLogo from 'images/PC-Nav.svg';
import IntegratedSolutionsLogo from 'images/IS-Logo.jpg';
import FrenchLogo from 'images/PC-PeopleCare-Logos_FR.svg';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import Scroll from 'react-scroll-to-element';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import LanguageSelect from 'containers/LanguageSelect';
import EAPLogoFr from '../../../images/pc-eap-fr-logo.png';
import EAPLogo from '../../../images/pc-eap-eng-logo.png';
import messages from './messages';
import { phone } from '../sizes';
import Hamburger from './hamburger.png';
import MobileNavigationOverlay from './MobileNavigationOverlay';

const NavigationWrapper = styled.div`
  display: flex;
  padding-left: 200px;
  padding-right: 200px;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  .mobile {
    display: none;
  }
  .desktop {
    display: flex;
    align-items: center;
  }
  img {
    width: 241px;
    height: 27px;
    object-fit: contain;
  }
  span,
  .link {
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.7px;
    color: #1f275b;
    margin-right: 40px;
  }
  @media (max-width: 1400px) {
    padding-left: 70px;
    padding-right: 70px;
  }
  @media (max-width: 1140px) {
    .right .desktop {
      display: none;
    }
    .right .mobile {
      display: inline-block;
      img {
        width: 25px;
        height: 20px;
      }
    }
  }
  @media (max-width: ${phone}) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const LogoWrapper = styled.div`
  width: 300px;
  img,
  span,
  svg {
    width: 100%;
  }
  @media (max-width: ${phone}) {
    width: 200px;
  }
`;

const Link = styled(RouterLink)`
  // focus visible style for accessibility
  &:focus {
    &.logo {
      border: 8px solid transparent;
      border-radius: 3px;
      outline: 2px solid #0a5dc2;
      outline-offset: 2px;
      left: -8px;
      position: relative;
    }
    &:not(.logo) {
      > span {
        padding: 2px 0;
        border: 2px solid #0a5dc2;
        border-radius: 5px;
        position: relative;
      }
    }
  }
`;

const LanguageWrapper = styled.div`
  margin-right: 40px;
`;

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = { mobileNavOpen: false };
  }

  toggleNavigation = () => {
    this.setState(prevState => ({
      mobileNavOpen: !prevState.mobileNavOpen,
    }));
  };

  renderLogo = locale => {
    if (this.props.location.pathname.toLowerCase() === '/integratedsolutions') {
      return (
        <img
          src={IntegratedSolutionsLogo}
          alt="logo"
          style={{ height: '40px' }}
        />
      );
    }
    if (locale === 'en' && this.props.eap) {
      return <img src={EAPLogo} alt="logo" style={{ height: '40px' }} />;
    }
    if (locale === 'fr' && this.props.eap) {
      return <img src={EAPLogoFr} alt="logo" style={{ height: '40px' }} />;
    }
    if (locale === 'fr') {
      return <SVG src={FrenchLogo} alt="logo" />;
    }
    return <SVG src={PeopleCareLogo} alt="logo" />;
  };

  render() {
    const {
      locale,
      location: { pathname },
      acrc,
      eap,
    } = this.props;
    const showNavItems =
      pathname === '/' ||
      pathname.toLowerCase() === '/acrc' ||
      pathname.toLowerCase() === '/integratedsolutions';

    return (
      <NavigationWrapper>
        <LogoWrapper>
          <Link className="logo" tabIndex={0} to="/">
            {this.renderLogo(locale)}
          </Link>
        </LogoWrapper>
        <div className="right">
          <div className="desktop">
            {showNavItems && (
              <Scroll type="id" element="features">
                <button tabIndex={0} type="button" className="link">
                  <FormattedMessage {...messages.features} />
                </button>
              </Scroll>
            )}
            <Link tabIndex={0} to="/signin" element="signIn">
              <span>
                <FormattedMessage {...messages.signIn} />
              </span>
            </Link>
            <LanguageWrapper>
              <LanguageSelect {...locale} eap={eap} />
            </LanguageWrapper>
            <Link tabIndex={-1} to={acrc ? '/signup?acrc=true' : '/signup'}>
              <button type="button" className="get-started">
                <FormattedMessage {...messages.getStarted} />
              </button>
            </Link>
          </div>
          <div className="mobile">
            <button type="button" onClick={this.toggleNavigation}>
              <img src={Hamburger} alt="Menu" />
            </button>
            <MobileNavigationOverlay
              locale={locale}
              toggleNavigation={this.toggleNavigation}
              open={this.state.mobileNavOpen}
              showNavItems={showNavItems}
            />
          </div>
        </div>
      </NavigationWrapper>
    );
  }
}

Navigation.propTypes = {
  acrc: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  locale: makeSelectLocale(),
});

export default connect(mapStateToProps)(withRouter(Navigation));
