import styled from 'styled-components';
import { deepSeaBlue } from '../../global-styles';

const P = styled.p`
  font-family: 'Lato', sans-serif;
  font-size: ${props => (props.fontSize ? props.fontSize : '16px')};
  font-weight: normal;
  color: ${props => (props.color ? props.color : deepSeaBlue)};
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.1px;
  overflow-wrap: break-word;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '20px')};
  span {
    font-weight: 600;
  }
`;

export default P;
