import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import messages from '../../../messages';
import ArticleBody from '../ArticleBody';
const MyBossIsStressingMeOut = () => (
  <ArticleBody>
    <ArticleBody>
      <FormattedHTMLMessage tagName="p" {...messages.bossIsStressBody} />
    </ArticleBody>
  </ArticleBody>
);

MyBossIsStressingMeOut.propTypes = {};

export default MyBossIsStressingMeOut;
