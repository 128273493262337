/*
 * Booking Messages
 *
 * This contains all the text for the Booking container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.MarketingPage';

export default defineMessages({
  everyWeek: {
    id: `${scope}.everyWeek`,
    defaultMessage:
      'Every week 500,000 Canadians will miss work because of mental health issues.',
  },
  eap: {
    id: `${scope}.eap`,
    defaultMessage: 'We offer a full-service EAP.',
  },
  eapBody: {
    id: `${scope}.eapBody`,
    defaultMessage:
      'Supporting employees and dependent family members with counselling, work-life services, and individual learning modules. <br/>With People Connect, employees can also transition seamlessly to longer term support when needed.',
  },
});
