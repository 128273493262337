/*
 *
 * MarketingPage constants
 *
 */

export const CHANGE_PASSWORD = 'app/ForgotPassword/CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS =
  'app/ForgotPassword/CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'app/ForgotPassword/CHANGE_PASSWORD_ERROR';
