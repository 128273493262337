import { takeLatest, put, call } from 'redux-saga/effects';
import { post, get } from 'utils/request';
import { API_URL } from 'utils/environment';
import {
  signUpSuccess,
  signUpError,
  signInSuccess,
  signInError,
  searchCompaniesSuccess,
  searchCompaniesError,
  fetchStatusesSuccess,
  fetchStatusesError,
  fetchUSCompaniesSuccess,
  fetchUSCompaniesError,
} from './actions';
import {
  SIGN_UP,
  SIGN_IN,
  SEARCH_COMPANIES,
  FETCH_STATUSES,
  FETCH_US_COMPANIES,
} from './constants';

function* signUp({ user, url, callback, errCallback }) {
  const requestURL = `${url}/people/auths/sign_up`;

  try {
    const response = yield call(post, requestURL, user);
    yield put(signUpSuccess(response));
    if (response.access_token) {
      callback(response.access_token);
    }
  } catch (error) {
    yield put(signUpError(error));
    if (errCallback) {
      errCallback(error);
    }
  }
}

function* signIn(values) {
  const requestURL = `${API_URL}/people/auths/login`;

  try {
    const response = yield call(post, requestURL, values.user);
    yield put(signInSuccess(response));
    if (response.access_token) {
      values.callback(response.access_token);
    }
  } catch (error) {
    yield put(signInError(error));
  }
}

function* searchCompanies({ query, planType, callback }) {
  const encodedQuery = encodeURIComponent(query);

  const planTypeUrl = planType && `&${planType}=true`;
  const requestURL = `${API_URL}/people/people_care_companies?query=${encodedQuery}${planTypeUrl}`;

  try {
    const companies = yield call(get, requestURL);
    yield put(searchCompaniesSuccess(companies));
    if (callback) yield call(callback);
  } catch (error) {
    yield put(searchCompaniesError(error));
  }
}

function* fetchStatuses() {
  const requestURL = `${API_URL}/people/people_care_status`;
  try {
    const companies = yield call(get, requestURL);
    yield put(fetchStatusesSuccess(companies));
  } catch (error) {
    yield put(fetchStatusesError(error));
  }
}

function* fetchUSCompanies() {
  const requestURL = `${API_URL}/people/people_care_company/us_companies`;
  try {
    const companies = yield call(get, requestURL);
    yield put(fetchUSCompaniesSuccess(companies));
  } catch (error) {
    yield put(fetchUSCompaniesError(error));
  }
}

export default function* signupSaga() {
  yield takeLatest(SIGN_UP, signUp);
  yield takeLatest(SIGN_IN, signIn);
  yield takeLatest(SEARCH_COMPANIES, searchCompanies);
  yield takeLatest(FETCH_STATUSES, fetchStatuses);
  yield takeLatest(FETCH_US_COMPANIES, fetchUSCompanies);
}
