/*
 *
 * MarketingPage reducer
 *
 */

import { fromJS, List } from 'immutable';
import {
  SIGN_UP,
  SIGN_UP_ERROR,
  SIGN_UP_SUCCESS,
  SIGN_IN,
  SIGN_IN_ERROR,
  SIGN_IN_SUCCESS,
  SEARCH_COMPANIES,
  SEARCH_COMPANIES_SUCCESS,
  SEARCH_COMPANIES_ERROR,
  FETCH_STATUSES,
  FETCH_STATUSES_SUCCESS,
  FETCH_STATUSES_ERROR,
  CLEAR_COMPANIES,
  FETCH_US_COMPANIES,
  FETCH_US_COMPANIES_ERROR,
  FETCH_US_COMPANIES_SUCCESS,
} from './constants';

export const initialState = fromJS({
  authenticated: false,
  companies: [],
  us_companies: [],
  statuses: [],
  error: {
    emailError: '',
  },
});

function signupReducer(state = initialState, action) {
  switch (action.type) {
    case SIGN_UP:
    case SIGN_IN:
      return state.set('loading', true);
    case SIGN_UP_ERROR:
      return state
        .set('loading', false)
        .setIn(['error', 'emailError'], action.error.data.error);
    case SIGN_IN_ERROR:
      return state
        .set('loading', false)
        .setIn(['error', 'loginError'], action.error.data.error);
    case SIGN_UP_SUCCESS:
      return state
        .set('loading', false)
        .set('authenticated', true)
        .setIn(['error', 'emailError'], '');
    case SIGN_IN_SUCCESS:
      return state
        .set('loading', false)
        .set('authenticated', true)
        .setIn(['error', 'loginError'], '');
    case SEARCH_COMPANIES:
      return state.set('loading', true);
    case SEARCH_COMPANIES_ERROR:
      return state.set('loading', false).set('error', action.error);
    case SEARCH_COMPANIES_SUCCESS:
      return state
        .set('loading', false)
        .set('companies', List())
        .mergeDeep({ companies: action.companies });
    case FETCH_US_COMPANIES:
      return state.set('loading', true);
    case FETCH_US_COMPANIES_ERROR:
      return state.set('loading', false).set('error', action.error);
    case FETCH_US_COMPANIES_SUCCESS:
      return state
        .set('loading', false)
        .set('us_companies', List())
        .mergeDeep({ us_companies: action.companies });
    case FETCH_STATUSES:
      return state.set('loading', true);
    case FETCH_STATUSES_ERROR:
      return state.set('loading', false).set('error', action.error);
    case FETCH_STATUSES_SUCCESS:
      return state
        .set('loading', false)
        .setIn(['statuses'], Array.from(action.statuses));
    case CLEAR_COMPANIES:
      return state.set('companies', List());
    default:
      return state;
  }
}

export default signupReducer;
