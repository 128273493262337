import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import messages from './messages';
import { tablet, phone } from '../sizes';

const TackleWorkplaceWrapper = styled.div`
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;
  background-color: #1f275b;

  h2 {
    font-size: 28px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin: 0 auto;
    max-width: 750px;
    margin-bottom: 60px;
  }

  .people-care-video {
    margin-bottom: 100px;
    width: 768px;
    height: 428px;
  }

  @media (max-width: ${tablet}) {
    padding-top: 50px;
    padding-bottom: 50px;

    h2 {
      font-size: 20px;
      max-width: 500px;
    }

    .people-care-video {
      margin-bottom: 50px;
      width: 426px;
      height: 240px;
    }
  }
  @media (max-width: ${phone}) {
    h2 {
      max-width: 300px;
    }

    .people-care-video {
      width: 100%;
    }
  }
`;

const TackleWorkplace = props => (
  <TackleWorkplaceWrapper>
    {props.eap ? (
      <div>
        <h2>
          <FormattedMessage {...messages.overview} />
        </h2>
        <iframe
          className="people-care-video"
          src={
            props.locale === 'en'
              ? 'https://player.vimeo.com/video/869630845'
              : 'https://player.vimeo.com/video/871134257'
          }
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
          title="People Connect"
        />
      </div>
    ) : (
      <iframe
        className="people-care-video"
        src={
          props.locale === 'en'
            ? 'https://player.vimeo.com/video/738734767'
            : 'https://player.vimeo.com/video/738967866'
        }
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
        title="People Connect"
      />
    )}
    <h2>
      {props.eap ? (
        <FormattedMessage {...messages.tackleEap} />
      ) : (
        <FormattedMessage {...messages.tackle} />
      )}
    </h2>
    <Link to={props.acrc ? '/signup?acrc=true' : '/signup'}>
      <button type="button" className="get-started">
        <FormattedMessage {...messages.getStarted} />
      </button>
    </Link>
  </TackleWorkplaceWrapper>
);

TackleWorkplace.propTypes = {
  acrc: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  locale: makeSelectLocale(),
});

export default connect(mapStateToProps)(TackleWorkplace);
