/*
 * SettingsHistory Messages
 *
 * This contains all the text for the SettingsHistory container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.SettingsHistory';

export default defineMessages({
  settingsAndHistory: {
    id: `${scope}.settingsAndHistory`,
    defaultMessage: 'Settings & history',
  },
  settings: {
    id: `${scope}.settings`,
    defaultMessage: 'Settings',
  },
  modify: {
    id: `${scope}.modify`,
    defaultMessage: 'Modify',
  },
  saveChanges: {
    id: `${scope}.saveChanges`,
    defaultMessage: 'Save Changes',
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: 'Email',
  },
  password: {
    id: `${scope}.password`,
    defaultMessage: 'Password',
  },
  userType: {
    id: `${scope}.userType`,
    defaultMessage: 'User Type',
  },
  currentPassword: {
    id: `${scope}.currentPassword`,
    defaultMessage: 'Current Password',
  },
  newPassword: {
    id: `${scope}.newPassword`,
    defaultMessage: 'Enter New Password',
  },
  confirmPassword: {
    id: `${scope}.confirmPassword`,
    defaultMessage: 'Confirm New Password',
  },
  peopleLeader: {
    id: `${scope}.peopleLeader`,
    defaultMessage: 'People Leader',
  },
  spouse: {
    id: `${scope}.spouse`,
    defaultMessage: 'Spouse',
  },
  dependant: {
    id: `${scope}.dependant`,
    defaultMessage: 'Dependant',
  },
  employeeManager: {
    id: `${scope}.employeeManager`,
    defaultMessage: 'Employee/Manager',
  },
  assessmentHistory: {
    id: `${scope}.assessmentHistory`,
    defaultMessage: 'Assessment History',
  },
  downloadPDFInterim: {
    id: `${scope}.downloadPDFInterim`,
    defaultMessage: 'Download PDF (Interim)',
  },
  downloadPDFFinal: {
    id: `${scope}.downloadPDFFinal`,
    defaultMessage: 'Download PDF (Final)',
  },
  peopleleader: {
    id: `${scope}.peopleleader`,
    defaultMessage: 'People Leader',
  },
  dependent: {
    id: `${scope}.dependent`,
    defaultMessage: 'Dependent',
  },
  employeemember: {
    id: `${scope}.employeemember`,
    defaultMessage: 'Employee/Member',
  },
  employeemanager: {
    id: `${scope}.employeemanager`,
    defaultMessage: 'Employee/Member',
  },
});
