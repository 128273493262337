import React from 'react';
import H3 from 'components/H3';
import styled from 'styled-components';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import Card from './Card';
import messages from './messages';

const FaqWrapper = styled.div`
  .header {
    padding: 0px;
    font-family: 'Montserrat';
    font-size: 22px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: #1f275b;
    margin-bottom: 20px;
  }
  .body {
    font-family: 'Lato';
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: #0f2045;
    margin-bottom: 80px;
  }
  .note {
    font-family: 'Lato';
    font-size: 12px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: #0f2045;
    margin-bottom: 80px;
  }
`;

const FaqSection = ({ question, answer }) => (
  <>
    <h1 className="header">
      <FormattedMessage {...question} />
    </h1>
    <div className="body">
      <FormattedHTMLMessage tagName="p" {...answer} />
    </div>
  </>
);

const FaqEmployeeBap = () => (
  <Card>
    <H3 className="title">
      <FormattedMessage {...messages.bapFaqHeader} />
    </H3>
    <FaqWrapper>
      <FaqSection question={messages.bapTitle} answer={messages.bapSubtitle} />
      <FaqSection
        question={messages.dependantUse}
        answer={messages.dependantUseBody}
      />
      <FaqSection
        question={messages.secondOpinion}
        answer={messages.secondOpinionBody}
      />
      <FaqSection
        question={messages.nurseNavigator}
        answer={messages.nurseNavigatorBody}
      />
      <FaqSection
        question={messages.referrals}
        answer={messages.referralsBody}
      />
      <FaqSection
        question={messages.assessment}
        answer={messages.assessmentBody}
      />
      <FaqSection
        question={messages.cancerAssistance}
        answer={messages.cancerAssistanceBody}
      />
      <FaqSection
        question={messages.nurseNavigatorLimit}
        answer={messages.nurseNavigatorLimitBody}
      />
      <FaqSection
        question={messages.mentalHealthSupport}
        answer={messages.mentalHealthSupportBody}
      />
      <FaqSection
        question={messages.employerKnowledge}
        answer={messages.employerKnowledgeBody}
      />
      <FaqSection
        question={messages.serviceUsage}
        answer={messages.serviceUsageBody}
      />
    </FaqWrapper>
  </Card>
);

export default FaqEmployeeBap;
