import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the marketingPage state domain
 */

const selectSignupPageDomain = state =>
  state.get('forgotPassword', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by MarketingPage
 */

const makeSelectforgotPassword = () =>
  createSelector(selectSignupPageDomain, substate => substate.toJS());

export default makeSelectforgotPassword;
export { selectSignupPageDomain };
