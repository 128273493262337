/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import AudioBody from './AudioBody';
import PandemicMp3 from './PandemicAndMyMentalHealth.mp3';

const PandemicMentalHealth = () => (
  <div>
    <AudioBody>
      <audio controls src={PandemicMp3} />
    </AudioBody>
  </div>
);
export default PandemicMentalHealth;
