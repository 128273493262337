/**
 *
 * SignupForm
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { reduxForm, Field } from 'redux-form/immutable';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';

import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import { API_URL } from 'utils/environment';
import { setToken } from 'utils/auth';
import { logEvent } from 'utils/amplitude';
import SpinnerButton from 'components/SpinnerButton';
import PasswordInput from 'components/PasswordInput';

import H2 from 'components/H2';
import TextField from 'containers/Signup/SignupForm/TextField';
import {
  validateRequired,
  validatePasswordLength,
  validatePasswordsMatch,
} from 'containers/Signup/SignupForm/validations';
import SVG from 'react-inlinesvg';
import Logo from 'images/PC-Nav.svg';
import SignupImg from 'images/PC-Sign.svg';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import SelectInput from './form/SelectInput';
import { errorRed, tabletSize, phoneSize } from '../../global-styles';
import { onboard, fetchStatuses, fetchOnboard } from './actions';
import messages from './messages';
import { makeSelectStatuses } from './selectors';
import saga from './saga';
import reducer from './reducer';

const SignupWrapper = styled.div`
  display: flex;
  max-width: 905px;
  min-height: 740px;
  background-color: #ffffff;
  z-index: 1;
  position: relative;
  margin: 70px auto;
  box-shadow: 0 0 50px 0 rgba(40, 71, 140, 0.2);

  @media (max-width: ${tabletSize}) {
    width: 90%;
  }
`;

const InfoContainer = styled.div`
  padding: 40px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;

  h2 {
    margin-bottom: 40px;
  }

  @media (max-width: ${tabletSize}) {
    padding-bottom: 200px;
    margin: 0 auto;
    width: 70%;

    h2 {
      text-align: center;
    }
  }

  @media (max-width: ${phoneSize}) {
    padding-bottom: 150px;
    width: 100%;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 58%;

  @media (max-width: ${tabletSize}) {
    width: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  margin: 40px;
  margin-right: 0;

  @media (max-width: ${tabletSize}) {
    margin-bottom: 100px;
  }

  @media (max-width: ${phoneSize}) {
    margin-bottom: 80px;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;

const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  .input-container {
    width: 100%;
    margin-bottom: 20px;
    p {
      text-transform: uppercase;
      font-size: 14px;
      &.error-text {
        font-family: 'Lato', sans-serif;
        text-transform: none;
        color: ${errorRed};
        margin-top: 5px;
      }
    }
    input {
      width: 90%;
      margin: 10px 0 0px 0;
      height: 40px;
      padding-left: 10px;
      background-color: rgba(192, 208, 223, 0.26);
      ::placeholder {
        font-family: 'Montserrat', sans-serif;
        color: #8e8e8e;
      }
    }
  }

  #custom {
    width: 95%;

    @media (max-width: ${phoneSize}) {
      width: 100%;
    }
  }

  @media (max-width: ${phoneSize}) {
    flex-wrap: wrap;

    .input-container {
      width: 70%;
      margin-left: auto;
      margin-right: auto;

      input {
        width: 100%;
      }
    }
  }
`;

const ImageWrapper = styled.div`
  width: 42%;
  background-color: #f6f8fa;

  img {
    height: 100%;
    width: 100%;
    margin-left: -30px;
  }

  @media (max-width: ${tabletSize}) {
    display: none;
  }
`;

const Center = styled.div`
  @media (max-width: ${tabletSize}) {
    text-align: center;
  }
`;

class Onboarding extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    this.props.fetchOnboard(this.props.match.params.eligibility_token, resp => {
      if (!resp.password_change_yn) {
        this.props.history.push('/dashboard');
      } else {
        this.props.fetchStatuses();
      }
    });
  }

  onSubmit = values => {
    const valuesToSubmit = values.toJS();
    valuesToSubmit.token = this.props.match.params.eligibility_token;
    valuesToSubmit.locale = this.props.locale;

    this.setState({ loading: true });
    this.props.onboard(
      valuesToSubmit,
      API_URL,
      token => {
        logEvent('completed onboarding', { domain: window.location.hostname });
        setToken(token);
        window.setTimeout(() => this.props.history.push('/dashboard'), 0);
        this.setState({ loading: false });
      },
      () => this.setState({ loading: false }),
    );
  };

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  render() {
    const { handleSubmit, statuses } = this.props;
    const { loading } = this.state;

    return (
      <div>
        <Helmet>
          <title>People Corp - Onboarding</title>
          <meta name="description" content="People Corp Onboarding" />
        </Helmet>
        <SignupWrapper id="signup">
          <LeftContainer>
            <Header>
              <SVG style={{ width: '250px' }} src={Logo} alt="logo" />
            </Header>
            <InfoContainer>
              <H2>
                <FormattedMessage
                  {...messages.welcome}
                  values={{
                    bold: (
                      <span style={{ fontWeight: '600' }}>
                        <FormattedMessage {...messages.peopleCare} />
                      </span>
                    ),
                  }}
                />
              </H2>
              <form onSubmit={handleSubmit(this.onSubmit)}>
                <FormWrapper id="onboardingForm">
                  <InputRow>
                    <Field
                      label={<FormattedMessage {...messages.preferredName} />}
                      placeholder="John"
                      component={TextField}
                      name="preferred_name"
                      inputStyle={{ width: '95.5%' }}
                    />
                  </InputRow>
                  <InputRow>
                    <Field
                      label={<FormattedMessage {...messages.status} />}
                      component={SelectInput}
                      isSearchable
                      options={statuses.map(status => {
                        const newStatus = status.label
                          .toLowerCase()
                          .replace(/\s/g, '')
                          .replace('/', '');
                        return {
                          value: status.value,
                          label: <FormattedMessage {...messages[newStatus]} />,
                        };
                      })}
                      name="status"
                      validate={[validateRequired]}
                    />
                  </InputRow>
                  <InputRow>
                    <Field
                      label={<FormattedMessage {...messages.password} />}
                      component={PasswordInput}
                      validate={[validateRequired, validatePasswordLength]}
                      name="password"
                    />
                    <Field
                      label={<FormattedMessage {...messages.confirmPassword} />}
                      component={PasswordInput}
                      validate={[validateRequired, validatePasswordsMatch]}
                      name="confirm_password"
                    />
                  </InputRow>
                </FormWrapper>
                <Center>
                  <SpinnerButton
                    type="submit"
                    label={<FormattedMessage {...messages.register} />}
                    people
                    style={{ marginBottom: 30 }}
                    loading={loading}
                  />
                </Center>
              </form>
            </InfoContainer>
          </LeftContainer>
          <ImageWrapper>
            <img src={SignupImg} alt="signup" />
          </ImageWrapper>
        </SignupWrapper>
      </div>
    );
  }
}

Onboarding.propTypes = {
  statuses: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  intl: intlShape,
};

const mapStateToProps = createStructuredSelector({
  statuses: makeSelectStatuses(),
  locale: makeSelectLocale(),
});

function mapDispatchToProps(dispatch) {
  return {
    onboard: (values, url, callback, errCallback) =>
      dispatch(onboard(values, url, callback, errCallback)),
    fetchStatuses: () => dispatch(fetchStatuses()),
    fetchOnboard: (values, callback) =>
      dispatch(fetchOnboard(values, callback)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'onboarding', reducer });
const withSaga = injectSaga({ key: 'onboarding', saga });

export default compose(
  withConnect,
  withReducer,
  withSaga,
)(
  withRouter(
    reduxForm({
      form: 'OnboardingForm',
      enableReinitialize: true,
    })(injectIntl(Onboarding)),
  ),
);
