import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import messages from './messages';
import PCMonitor from '../../../images/marketing/pc-people-care-brochure-graphic@2x.png';
import BlueBlob from './blue-blob.png';
import { web, tablet, phone } from '../sizes';

const EveryWeekWrapper = styled.div`
  overflow-x: hidden;
  padding-top: 100px;
  padding-bottom: 200px;
  padding-left: 200px;
  padding-right: 200px;
  display: flex;
  align-items: center;
  background-image: url(${BlueBlob});
  background-position: bottom;
  background-size: 100% 541px;
  img {
    width: 441px;
    height: 452px;
    object-fit: contain;
    margin-right: 200px;
  }
  h1 {
    font-size: 34px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: #1f275b;
    max-width: 380px;
    margin-bottom: 200px;
  }
  @media (max-width: ${web}) {
    padding-left: 80px;
    padding-right: 80px;
    img {
      width: 362px;
      height: 371px;
      margin-right: 100px;
    }
  }
  @media (max-width: ${tablet}) {
    img {
      width: 283px;
      height: 290px;
      margin-right: 50px;
    }
    h1 {
      font-size: 24px;
    }
  }
  @media (max-width: ${phone}) {
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 100px;
    flex-direction: column-reverse;
    background-size: ${phone} 264px;

    h1 {
      margin-bottom: 60px;
    }
    img {
      margin: 0 auto;
      width: 258px;
      height: 264px;
      object-fit: contain;
    }
  }
`;

const EAPWrapper = styled.div`
  h1 {
    white-space: nowrap;
    margin-bottom: 12px;
    margin-top: 24px;
  }
`;

const EveryWeek = ({ eap }) => (
  <EveryWeekWrapper>
    <img src={PCMonitor} alt="monitor" />
    {eap ? (
      <EAPWrapper>
        <h1>
          <FormattedMessage {...messages.eap} />
        </h1>
        <FormattedHTMLMessage tagName="p" {...messages.eapBody} />
      </EAPWrapper>
    ) : (
      <h1>
        <FormattedMessage {...messages.everyWeek} />
      </h1>
    )}
  </EveryWeekWrapper>
);

EveryWeek.propTypes = {};

export default EveryWeek;
