/*
 *
 * Assessment BackToScreen
 *
 */

/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { InkblotTheme, Button } from 'inkblot-ui';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import AssessmentLayout from 'components/AssessmentLayout';
import MacScreen from 'images/mac-screen.png';
import H1 from 'components/H1';
import P from 'components/P';
import messages from './messages';

const BackToWrapper = styled.div`
  .mac-screen {
    height: 250px;
    width: 250px;
    margin-bottom: 40px;
  }
  p {
    margin-bottom: 45px;
    color: #0f2045;
  }
  .back-button {
    cursor: pointer;
    height: 40px;
    border-radius: 20px;
    background-image: linear-gradient(to left, #9b92c6, #c1bcf2);
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1.5px;
    text-align: center;
    color: #ffffff;
    padding-left: 40px;
    padding-right: 40px;
    text-transform: uppercase;
  }
`;

/* eslint-disable react/prefer-stateless-function */
const BackToScreen = props => (
  <BackToWrapper className="back-to-screen">
    <AssessmentLayout openExitDialog={props.openExitDialog}>
      <img className="mac-screen" src={MacScreen} alt="mac" />
      <H1 fontSize="38px">
        <FormattedMessage {...messages.backYouAre} />
      </H1>
      <P fontSize="16px">
        <FormattedMessage {...messages.backWeNoticed} />
      </P>
      <InkblotTheme>
        <Button
          people
          label={<FormattedMessage {...messages.backContinue} />}
          style={{ textTransform: 'uppercase', marginBottom: '40px' }}
          onClick={() => props.goToCurrentPage()}
        />
      </InkblotTheme>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link to="#">
        <button
          style={{ cursor: 'pointer' }}
          type="button"
          className="back-button"
          onClick={() => props.startFresh()}
        >
          <FormattedMessage {...messages.backStartFresh} />
        </button>
      </Link>
    </AssessmentLayout>
  </BackToWrapper>
);

BackToScreen.propTypes = {
  startFresh: PropTypes.func,
  goToCurrentPage: PropTypes.func,
  openExitDialog: PropTypes.func,
};

// const mapStateToProps = createStructuredSelector({
//   assessment: makeSelectAssessment(),
// });

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(
  null,
  mapDispatchToProps,
)(BackToScreen);
