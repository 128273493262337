import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isAuthenticated } from 'utils/auth';
import AuthedLayout from './AuthedLayout';

import GlobalStyle from '../../../global-styles';
import UserInfo from '../UserInfo';

const AuthRoute = ({
  component: Component,
  render: renderFunction,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (isAuthenticated() === true) {
        if (Component) {
          return (
            <AuthedLayout>
              <UserInfo />
              <Component {...props} />
              <GlobalStyle />
            </AuthedLayout>
          );
        }

        return renderFunction({ ...props });
      }

      return (
        <Redirect
          to={{
            pathname: '/signin',
            state: { from: props.location },
          }}
        />
      );
    }}
  />
);

export default AuthRoute;
