import React from 'react';
import ArticleBody from '../ArticleBody';
const ExecutiveConversations = () => (
  <div>
    <ArticleBody>
      The National Standard of Canada for Psychological Health and Safety in the
      Workplace (the Standard), is a set of voluntary guidelines, tools and
      resources intended to guide organizations in promoting mental health and
      preventing psychological harm at work 1. Since its development in 2013,
      the Standard has garnered international attention for positing an
      aspirational, progressive vision of the modern workplace and employee
      wellness. As the most sophisticated set of guidelines on the subject, the
      Standard is considered as a model for the first international standard for
      psychological health in the workplace by the International Organization
      for Standardization 2.
      <br />
      <br />
      The Standard includes information on:
      <br />
      <ul>
        <li>
          The identification of psychological hazards in the workplace (e.g.
          stressors due to organizational change or reasonable job demands);{' '}
        </li>
        <li>
          The implementation of practices that support and promote psychological
          health and safety in the workplace;{' '}
        </li>
        <li>
          The growth of a culture that promotes psychological health and safety
          in the workplace; The implementation of systems of measurement and
          review to ensure sustainability of the overall approach.{' '}
        </li>
      </ul>
      Of those organizations that have implemented the Standard:
      <br />
      <br />
      <ul>
        <li>
          Ninety-one per cent of the organizations implemented the Standard
          because it is “the right thing to do”. Other reasons included “to
          protect the psychological health of employees” (84%) and “increase
          employee engagement” (72%);
        </li>
        <li>
          Seventy-eight per cent implemented respectful workplace policies and
          educational initiatives;
        </li>
        <li>
          Seventy per cent provided early intervention through employee and
          family assistance programs and services addressing mental health;
        </li>
        <li>
          Sixty-six per cent enhanced awareness of mental health among
          employees; and
        </li>
        <li>
          Participating organizations achieved on average 72 per cent compliance
          with the five elements (commitment and policy, planning,
          implementation, evaluation and corrective action, management review)
          in the Standard, a remarkable improvement from 55 per cent compliance
          at the baseline stage.
        </li>
      </ul>
      <br />
      <br />
      <b>The Journey to the Standard</b>
      <br />
      <br />
      The Standard is the first of its kind in the world, the seeds of which
      were first planted in 2010 when the Mental Health Commission of Canada
      contracted Dr. Martin Shain (then senior scientist for The Centre For
      Addiction and Mental Health) to prepare the report “Tracking the Perfect
      Legal Storm” 4. The report asserted that there is an emerging legal duty
      in Canada for employers to provide and maintain a psychologically safe
      workplace. The report led to the discussion of a sustainable, systematic
      approach including development of a national standard for managing
      psychological health and safety in the workplace.
      <br />
      <br />
      The Standard officially launched in January 2013 as a shared initiative of
      the Mental Health Commission of Canada, the Bureau de normalisation du
      Québec (BNQ) and CSA Group, and funded through Human Resources and Skills
      Development Canada, Health Canada, and the Public Health Agency of Canada,
      as well as through a financial contribution from the Great-West Life
      Centre for Mental Health in the Workplace and Bell Canada.
      <br />
      <br />
      <b>Advantages of Implementing the Standard</b>
      <br />
      <br />
      <ul>
        <li>
          The Standard provides a framework to help employers create policies
          and processes to promote good mental health;
        </li>
        <li>
          The Standard helps to inform the development of training programs;
        </li>
        <li>
          As employers are increasingly held legally responsible for
          psychological health and safety issues it is in employers' best
          interest to act in a manner that prevents the risk of legal
          consequences.;
        </li>
        <li>
          The Standard helps ensure that an organization is effective in
          managing human resources; The Standard helps employers ensure their
          organization is effective in maximizing employee potential and
          productivity;
        </li>
        <li>
          A supportive work environment can reduce the onset, severity, impact
          and duration of a mental health disorder;{' '}
        </li>
        <li>
          Organizations that make the effort to identify psychosocial risks and
          to create a psychologically healthy workplace reap benefits in reduced
          absenteeism, higher levels of employee engagement, lower rates of
          error and physical injuries, and greater potential for becoming an
          employer of choice.
        </li>
      </ul>
      <b>How Workplaces Benefit</b>
      <br />
      <br />
      The Standard is striking a chord with businesses--there have been more
      than 16,000 downloads of the Standard internationally and across
      organizations of all sectors and sizes.
      <br />
      For employees, benefits include protection from psychological harm and
      promotion of psychological well-being, creating an environment in which
      workers can focus on achieving their full potential:
      <br />
      <ul>
        <li>
          less absenteeism and lower short‐ and long‐term disability costs;
        </li>
        <li>reduced turnover and enhanced recruitment;</li>
        <li>
          higher levels of employee engagement, creativity and innovation; and,{' '}
        </li>
        <li>lower rates of error and physical injuries.</li>
      </ul>
      <br />
      <br />
      For employers, benefits include a stronger competitive advantage and
      greater organizational effectiveness resulting from:
      <br />
      <ul>
        <li>
          less absenteeism and lower short‐ and long‐term disability costs;
        </li>
        <li>reduced turnover and enhanced recruitment;</li>
        <li>
          higher levels of employee engagement, creativity and innovation; and
        </li>
        <li>lower rates of error and physical injuries.</li>
      </ul>
      <br />
      <br />
      <b>What does the future hold for the Standard?</b>
      <br />
      <br />
      There is little doubt going forward that the approach of working together
      to improve psychological health and safety will continue. Research and
      collaboration will also continue and what is learned will be turned into
      practical workplace strategies.
      <br />
      <br />
      <ul>
        <li>
          Employers will be increasingly proactive at preventing, identifying,
          and providing support for mental health issues;
        </li>
        <li>
          There will be a widened use of the Standard among industry and labour;
        </li>
        <li>
          Employees will expect to work in psychologically safe workplaces;
        </li>
        <li>
          Recruiting, hiring, and promoting leaders could be tied in part to
          their ability to support psychologically safe environments;
        </li>
        <li>
          Measurement of leadership success may include the impact the leader
          has on the psychological safety of the workplace;
        </li>
        <li>
          The concept of psychological health and safety will be embedded as
          part of the overall orientation or safety training or an organization;
        </li>
        <li>
          Physical and psychological health and safety will be combined into a
          single organizational approach; and
        </li>
        <li>
          Systems, standards, and regulations pertaining to psychological health
          and safety will evolve.
        </li>
      </ul>
    </ArticleBody>
  </div>
);

ExecutiveConversations.propTypes = {};

export default ExecutiveConversations;
