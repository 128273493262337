/**
 *
 * Options Wrapper
 *
 */

import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
`;

export default Wrapper;
