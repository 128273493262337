import styled from 'styled-components';
import { darkSlateBlue } from '../../global-styles';

const H3 = styled.h3`
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: ${darkSlateBlue};
`;

export default H3;
