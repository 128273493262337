/*
 *
 * MarketingPage reducer
 *
 */

import { fromJS } from 'immutable';
import {
  ONBOARD,
  ONBOARD_SUCCESS,
  ONBOARD_ERROR,
  FETCH_STATUSES,
  FETCH_STATUSES_SUCCESS,
  FETCH_STATUSES_ERROR,
  FETCH_ONBOARD,
  FETCH_ONBOARD_SUCCESS,
  FETCH_ONBOARD_ERROR,
} from './constants';

export const initialState = fromJS({
  statuses: [],
  error: '',
  onboardingInfo: '',
});

function signupReducer(state = initialState, action) {
  switch (action.type) {
    case ONBOARD:
    case FETCH_ONBOARD:
    case FETCH_STATUSES:
      return state.set('loading', true);
    case ONBOARD_ERROR:
    case FETCH_ONBOARD_ERROR:
    case FETCH_STATUSES_ERROR:
      return state.set('loading', false).set('error', action.error);
    case FETCH_STATUSES_SUCCESS:
      return state
        .set('loading', false)
        .setIn(['statuses'], Array.from(action.statuses));
    case FETCH_ONBOARD_SUCCESS:
    case ONBOARD_SUCCESS:
      return state.set('loading', false).set('onboardingInfo', action.user);
    default:
      return state;
  }
}

export default signupReducer;
