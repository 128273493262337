import styled from 'styled-components';
import { phoneSize } from '../../global-styles';

const Container = styled.div`
  font-size: 12px;
  width: fit-content;
  padding-top: 2px;
  display: flex;
  justify-content: space-between;
  letter-spacing: 1.1px;
  color: #444e69;

  @media (max-width: ${phoneSize}) {
    width: 80px;
  }
`;

export default Container;
