const pathOpening = [
  'intro',
  'ors1',
  'ors2',
  'ors3',
  'ors4',
  'dass1',
  'dass5',
  'dass2',
  'dass6',
  'dass3',
  'dass7',
  'dass4',
  'gen',
  'phys',
  'dx',
  'stress',
  'ws',
];
const pathWork = ['work', 'wsize'];
const pathSchool = ['school', 'school_level'];
const pathCrossPart1 = ['cross-p1-s1', 'cross-p1-s2', 'cross-p1-s3'];
const pathORSInterimReport = ['interim-ors-report'];
const pathCrossPart2 = ['cross-p2-s1', 'cross-p2-s2', 'cross-p2-s3'];
const pathDepression = [
  'depression-worthless',
  'depression-nothing',
  'depression-helpless',
  'depression-sad',
  'depression-depressed',
  'depression-unhappy',
  'depression-hopeless',
];
const pathAnxiety = [
  'anxiety-fearful',
  'anxiety-anxious',
  'anxiety-worried',
  'anxiety-focus',
  'anxiety-nervous',
  'anxiety-uneasy',
  'anxiety-tense',
];
const pathBipolar = [
  'bipolar-happier',
  'bipolar-self',
  'bipolar-sleep',
  'bipolar-talk',
  'bipolar-active',
];
const pathSubstance = [
  'severity-substance1',
  'severity-substance2',
  'severity-substance3',
  'severity-substance4',
  'severity-substance5',
  'severity-substance6',
  'severity-substance7',
  'severity-substance8',
  'severity-substance9',
  'severity-substance10',
];
const pathPTSD = [
  'ptsd-flashbacks',
  'ptsd-upset',
  'ptsd-avoid',
  'ptsd-event',
  'ptsd-negative',
  'ptsd-interest',
  'ptsd-alert',
  'ptsd-noise',
  'ptsd-angry',
];
const pathInterimReport = ['interim-report'];
const pathCrossPart2Md = ['cross-p2-s1', 'cross-p2-s2', 'cross-p2-s3'];
const pathOCD = [
  'ocd-time',
  'ocd-distress',
  'ocd-control',
  'ocd-avoid',
  'ocd-interfere',
];
const pathPhobia = ['phobia-item'];
const pathPhobiaDetails = [
  'phobia-moment',
  'phobia-anxious',
  'phobia-injured',
  'phobia-sweaty',
  'phobia-tense',
  'phobia-avoid',
  'phobia-away',
  'phobia-prepare',
  'phobia-distract',
  'phobia-help',
];
const pathDissociation = [
  'dissociation-stare',
  'dissociation-strange',
  'dissociation-remember',
  'dissociation-alone',
  'dissociation-fog',
  'dissociation-pain',
  'dissociation-act',
  'dissociation-do',
];
const pathPersonality = [
  'personality-disinhibition1',
  'personality-disinhibition2',
  'personality-disinhibition3',
  'personality-detachment1',
  'personality-disinhibition4',
  'personality-disinhibition5',
  'personality-psychoticism1',
  'personality-negative1',
  'personality-negative2',
  'personality-negative3',
  'personality-negative4',
  'personality-psychoticism2',
  'personality-detachment2',
  'personality-detachment3',
  'personality-negative5',
  'personality-detachment4',
  'personality-antagonism1',
  'personality-detachment5',
  'personality-antagonism2',
  'personality-antagonism3',
  'personality-psychoticism3',
  'personality-antagonism4',
  'personality-psychoticism4',
  'personality-psychoticism5',
  'personality-antagonism5',
];
const pathSomatic = [
  'somatic-stomach',
  'somatic-back',
  'somatic-arm',
  'somatic-headache',
  'somatic-chest',
  'somatic-dizziness',
  'somatic-fainting',
  'somatic-heart',
  'somatic-breath',
  'somatic-sexual',
  'somatic-constipation',
  'somatic-nausea',
  'somatic-tired',
  'somatic-sleep',
];
const pathSocialAnxiety = [
  'social-fear',
  'social-anxious',
  'social-reject',
  'social-sweaty',
  'social-tense',
  'social-avoid',
  'social-leave',
  'social-prepare',
  'social-distract',
  'social-help',
];
const pathGeneralAnxiety = [
  'genanx-fear',
  'genanx-anxious',
  'genanx-bad',
  'genanx-sweaty',
  'genanx-tense',
  'genanx-avoid',
  'genanx-leave',
  'genanx-decision',
  'genanx-reassurance',
  'genanx-help',
];
const pathAgoraphobia = [
  'agoraphobia-fear',
  'agoraphobia-anxious',
  'agoraphobia-panic',
  'agoraphobia-sweaty',
  'agoraphobia-tense',
  'agoraphobia-avoid',
  'agoraphobia-away',
  'agoraphobia-prepare',
  'agoraphobia-distract',
  'agoraphobia-help',
];
const pathSeparationAnxiety = [
  'separation-fear',
  'separation-anxious',
  'separation-bad',
  'separation-sweaty',
  'separation-tense',
  'separation-avoid',
  'separation-home',
  'separation-prepare',
  'separation-distract',
  'separation-help',
];
export const hidePercentagePages = [
  'ors1',
  'ors2',
  'ors3',
  'ors4',
  'dass1',
  'dass2',
  'dass3',
  'dass3',
  'dass4',
  'dass5',
  'dass6',
  'dass7',
  'gen',
  'dx',
  'phys',
  'stress',
  'ws',
];

const pathClosing = ['video', 'final-report', 'end!'];

const rootPath = [
  { valueName: 'opening', path: { 0: pathOpening } },
  {
    valueName: 'ws_type',
    path: {
      0: pathWork,
      1: pathSchool,
      2: [...pathWork, ...pathSchool],
      3: [],
    },
  },
  { valueName: 'screen_cross_part1', path: { 0: [], 1: pathCrossPart1 } },
  { valueName: 'screen_ors_report', path: { 0: [], 1: pathORSInterimReport } },
  { valueName: 'screen_cross_part2', path: { 0: [], 1: pathCrossPart2 } },
  { valueName: 'screen_depression', path: { 0: [], 1: pathDepression } },
  { valueName: 'screen_anxiety', path: { 0: [], 1: pathAnxiety } },
  { valueName: 'screen_bipolar', path: { 0: [], 1: pathBipolar } },
  { valueName: 'screen_substance', path: { 0: [], 1: pathSubstance } },
  { valueName: 'screen_ptsd', path: { 0: [], 1: pathPTSD } },
  { valueName: 'screen_interim_report', path: { 0: [], 1: pathInterimReport } },
  { valueName: 'screen_cross_part2_md', path: { 0: [], 1: pathCrossPart2Md } },
  { valueName: 'screen_ocd', path: { 0: [], 1: pathOCD } },
  { valueName: 'screen_phobia', path: { 0: [], 1: pathPhobia } },
  { valueName: 'screen_phobia_details', path: { 0: [], 1: pathPhobiaDetails } },
  { valueName: 'screen_dissociation', path: { 0: [], 1: pathDissociation } },
  { valueName: 'screen_personality', path: { 0: [], 1: pathPersonality } },
  { valueName: 'screen_somatic', path: { 0: [], 1: pathSomatic } },
  { valueName: 'screen_social', path: { 0: [], 1: pathSocialAnxiety } },
  { valueName: 'screen_genanx', path: { 0: [], 1: pathGeneralAnxiety } },
  { valueName: 'screen_agoraphobia', path: { 0: [], 1: pathAgoraphobia } },
  { valueName: 'screen_separation', path: { 0: [], 1: pathSeparationAnxiety } },
  { valueName: 'closing', path: { 0: [], 1: pathClosing } },
];
/* eslint-enable prettier/prettier */
export const getNextPageId = (current, values, forward = true) => {
  for (let i = 0; i < rootPath.length; i += 1) {
    const path = rootPath[i].path[values[rootPath[i].valueName]];
    if (!path) {
      return '';
    }

    // look for index of current Id
    const index = path.indexOf(current);
    if (index < 0) {
      continue; // eslint-disable-line no-continue
    }

    // search direction
    if (forward) {
      // next page
      if (index < path.length - 1) {
        // in the same path array
        return path[index + 1];
      }

      // in the next path array
      for (let j = 0; j < rootPath.length - i; j += 1) {
        const nextPath =
          rootPath[i + j + 1].path[values[rootPath[i + j + 1].valueName]];

        // out of range
        if (!nextPath) {
          return '';
        }

        // skip empty path array
        if (nextPath.length <= 0) {
          continue; // eslint-disable-line no-continue
        }

        return nextPath[0];
      }
    }

    // previous page
    if (index >= 1) {
      // in the same path array
      return path[index - 1];
    }

    // in the previous path array
    for (let j = 0; j < i; j += 1) {
      const prevPath =
        rootPath[i - j - 1].path[values[rootPath[i - j - 1].valueName]];

      // out of range
      if (!prevPath) {
        return '';
      }

      // skip empty path array
      if (prevPath.length <= 0) {
        continue; // eslint-disable-line no-continue
      }

      return prevPath[prevPath.length - 1];
    }
  }

  // no matches
  return '';
};

export const getProgressPercentage = (current, values) => {
  let isMatched = false;
  let totalStep = -Math.abs(hidePercentagePages.length);
  let currentStep = -Math.abs(hidePercentagePages.length);

  for (let i = 0; i < rootPath.length; i += 1) {
    const path = rootPath[i].path[values[rootPath[i].valueName]];
    if (!path) {
      break;
    }

    if (!isMatched) {
      const indexCurrent = path.indexOf(current);
      if (indexCurrent >= 0) {
        currentStep += indexCurrent;
        isMatched = true;
      } else {
        currentStep += path.length;
      }
    }

    const indexEnd = path.indexOf('end!');
    if (indexEnd >= 0) {
      totalStep += indexEnd;
      break;
    } else {
      totalStep += path.length;
    }
  }

  const percentage = parseInt((currentStep / totalStep) * 100, 10);
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(percentage) || percentage < 0) {
    return 0;
  }
  return percentage;
};

export const singleSelectAnswer = (currentAssessment, questionCode) => {
  const answers = currentAssessment.get('answers');
  let value;

  if (answers) {
    value = answers.filter(
      answer => answer.get('question_code') === questionCode,
    );

    if (value && value.get(0)) {
      value = parseInt(value.get(0).get('value'), 10);
    }
  }

  return value;
};

export const multiSelectAnswers = (currentAssessment, questionCode) => {
  const answers = currentAssessment.get('answers');
  const values = {};
  if (answers) {
    const filteredAnswers = answers.filter(
      answer => answer.get('question_code') === questionCode,
    );

    for (let i = 0; i < filteredAnswers.size; i += 1) {
      const value = filteredAnswers.get(i).get('value');
      values[value] = true;
    }
  }
  return values;
};

export const getCurrentScreen = pageId => {
  const paths = [
    { screenName: 'opening', path: pathOpening },
    { screenName: 'ws_type', path: [...pathSchool, ...pathWork] },
    { screenName: 'screen_cross_part1', path: pathCrossPart1 },
    { screenName: 'screen_ors_report', path: pathORSInterimReport },
    { screenName: 'screen_cross_part2', path: pathCrossPart2 },
    { screenName: 'screen_depression', path: pathDepression },
    { screenName: 'screen_anxiety', path: pathAnxiety },
    { screenName: 'screen_bipolar', path: pathBipolar },
    { screenName: 'screen_substance', path: pathSubstance },
    { screenName: 'screen_ptsd', path: pathPTSD },
    { screenName: 'screen_interim_report', path: pathInterimReport },
    { screenName: 'screen_cross_part2_md', path: pathCrossPart2Md },
    { screenName: 'screen_ocd', path: pathOCD },
    { screenName: 'screen_phobia', path: pathPhobia },
    { screenName: 'screen_phobia_details', path: pathPhobiaDetails },
    { screenName: 'screen_dissociation', path: pathDissociation },
    { screenName: 'screen_personality', path: pathPersonality },
    { screenName: 'screen_somatic', path: pathSomatic },
    { screenName: 'screen_social', path: pathSocialAnxiety },
    { screenName: 'screen_genanx', path: pathGeneralAnxiety },
    { screenName: 'screen_agoraphobia', path: pathAgoraphobia },
    { screenName: 'screen_separation', path: pathSeparationAnxiety },
    { screenName: 'closing', path: pathClosing },
  ];
  let curScreen = null;
  for (let i = 0; i < paths.length || curScreen == null; i += 1) {
    if (paths[i].path.includes(pageId)) {
      curScreen = paths[i].screenName;
    }
  }
  return curScreen;
};
