/**
 *
 * Signin
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { tabletSize, phoneSize, errorRed } from 'global-styles';
import { Link } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form/immutable';
import { InkblotTheme, Button } from 'inkblot-ui';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import P from 'components/P';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import H2 from 'components/H2';
import makeSelectforgotPassword from './selectors';
import reducer from './reducer';
import saga from './saga';
import TextField from './TextField';
import { resetPassword } from './actions';
import messages from './messages';

const SignupWrapper = styled.div`
  display: flex;
  max-width: 375px;
  min-height: 300px;
  background-color: #ffffff;
  z-index: 1;
  position: relative;
  margin: 150px auto;
  box-shadow: 0 0 50px 0 rgba(40, 71, 140, 0.2);
`;

const InfoContainer = styled.div`
  padding: 40px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  width: 75%;

  h2 {
    margin-top: 40px;
  }

  .input-container {
    width: 100%;
    margin-bottom: 20px;
    p {
      text-transform: uppercase;
      font-size: 12px;
      &.error-text {
        text-transform: none;
        color: ${errorRed};
        margin-top: 5px;
      }
    }
    input {
      width: 90%;
      margin: 10px 0 0px 0;
      height: 40px;
      padding-left: 10px;
      background-color: rgba(192, 208, 223, 0.26);
      ::placeholder {
        font-family: 'Montserrat', sans-serif;
        color: #8e8e8e;
      }
    }
  }

  @media (max-width: ${tabletSize}) {
    padding-bottom: 200px;
    margin: 0 auto;
    width: 60%;

    h2 {
      text-align: center;
    }
  }

  @media (max-width: ${phoneSize}) {
    padding-bottom: 150px;
    width: 100%;
  }
`;

class ForgotPassword extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onSubmit = values =>
    this.props.resetPassword(values.toJS(), res => console.log(res));

  render() {
    const { handleSubmit, forgotPassword, intl } = this.props;

    return (
      <div>
        <Helmet>
          <title>{intl.formatMessage(messages.title)}</title>
          <meta name="description" content="Forgot Password" />
        </Helmet>
        <SignupWrapper id="forgotPassword">
          <InfoContainer>
            <H2>
              <FormattedMessage {...messages.title} />
            </H2>
            <P>
              <FormattedMessage {...messages.alreadyHavePassword} />{' '}
              <Link to="/signin">
                <FormattedMessage {...messages.signIn} />
              </Link>
              .
            </P>
            <form onSubmit={handleSubmit(this.onSubmit)}>
              <Field
                name="email"
                component={TextField}
                label={<FormattedMessage {...messages.email} />}
              />
              <InkblotTheme>
                <Button
                  people
                  label={<FormattedMessage {...messages.submit} />}
                  type="submit"
                />
              </InkblotTheme>
            </form>
            {forgotPassword.message && (
              <P color="black" style={{ fontSize: '18px' }}>
                {forgotPassword.message}
              </P>
            )}
          </InfoContainer>
        </SignupWrapper>
      </div>
    );
  }
}

ForgotPassword.propTypes = {
  resetPassword: PropTypes.func,
  handleSubmit: PropTypes.func,
  forgotPassword: PropTypes.object,
  intl: intlShape,
};

const mapStateToProps = createStructuredSelector({
  forgotPassword: makeSelectforgotPassword(),
});

function mapDispatchToProps(dispatch) {
  return {
    resetPassword: (value, callback) =>
      dispatch(resetPassword(value, callback)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'forgotPassword', reducer });
const withSaga = injectSaga({ key: 'forgotPassword', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(
  reduxForm({
    form: 'ForgotPasswordForm',
  })(injectIntl(ForgotPassword)),
);
