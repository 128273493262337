import styled from 'styled-components';

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 59px;
  width: 100%;
  background-color: #fff;
  border-bottom: solid 1px #dae7f0;
  padding: 0 30px;
`;

export default TopBar;
