import React from 'react';
import IdleTimer from 'react-idle-timer';
import { ENVIRONMENT } from 'utils/environment';
import { getToken, signOut, lastTimestampName } from 'utils/auth';

// timeout: 2 hours (development server: 30 days)
const logoutMinutes = ENVIRONMENT !== 'DEVELOPMENT' ? 120 : 60 * 24 * 7;
const actionThrottle = 3000;

const logoutTimeout = 1000 * 60 * logoutMinutes;

class IdleManager extends React.PureComponent {
  componentDidMount() {
    const token = getToken();
    const lastTimestamp = localStorage.getItem(lastTimestampName);

    if (token) {
      if (
        lastTimestamp &&
        Date.now() - lastTimestamp > logoutTimeout - 1000 * 60
      ) {
        signOut();
      } else {
        this.saveTimestamp();
      }
    }
  }

  handleIdle = () => {
    const token = getToken();
    const lastTimestamp = localStorage.getItem(lastTimestampName);
    const nowTimestamp = Date.now();

    if (token) {
      if (
        lastTimestamp &&
        nowTimestamp - lastTimestamp > logoutTimeout - 1000 * 60
      ) {
        signOut();
      }
    } else {
      signOut();
    }
  };

  handleActive = () => {
    const token = getToken();

    if (!token) {
      signOut();
    }
  };

  handleAction = () => {
    this.saveTimestamp();
  };

  saveTimestamp = () => {
    const token = getToken();

    if (token) {
      localStorage.setItem(lastTimestampName, Date.now());
    }
  };

  render() {
    return (
      <IdleTimer
        element={document}
        onIdle={this.handleIdle}
        onAction={this.handleAction}
        onActive={this.handleActive}
        throttle={actionThrottle}
        timeout={logoutTimeout}
      />
    );
  }
}

export default IdleManager;
