import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { setToken } from '../../../utils/auth';

class ExternalTokenRedirect extends React.PureComponent {
  componentDidMount() {
    const { history, match } = this.props;

    if (match.params.token) {
      setToken(match.params.token);
      history.push('/');
      window.location.reload();
    }
  }

  render() {
    return <p>Redirecting....</p>;
  }
}

ExternalTokenRedirect.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withRouter(ExternalTokenRedirect);
