/*
 *
 * MarketingPage actions
 *
 */

import { API_URL } from 'utils/environment';
import { SIGN_IN, SIGN_IN_SUCCESS, SIGN_IN_ERROR } from './constants';

export function signIn(user, url = API_URL, callback, errCallback) {
  return {
    type: SIGN_IN,
    user,
    url,
    callback,
    errCallback,
  };
}
export function signInSuccess(user, callback) {
  return {
    type: SIGN_IN_SUCCESS,
    user,
    callback,
  };
}
export function signInError(error, errCallback) {
  return {
    type: SIGN_IN_ERROR,
    error,
    errCallback,
  };
}
