/*
 * Signup Messages
 *
 * This contains all the text for the Signup container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Signup';

export default defineMessages({
  welcome: {
    id: `${scope}.welcome`,
    defaultMessage: 'Welcome to {bold}, please register your new account.',
  },
  welcomeMeditation: {
    id: `${scope}.welcomeMeditation`,
    defaultMessage: 'Welcome to {bold}, please signup below.',
  },
  peopleCare: {
    id: `${scope}.peopleCare`,
    defaultMessage: 'People Connect',
  },
  peopleCareMeditation: {
    id: `${scope}.peopleCareMeditation`,
    defaultMessage: 'People Connect Meditation',
  },
  thanksMeditation: {
    id: `${scope}.thanksMeditation`,
    defaultMessage: 'Thanks for registering for People Connect Meditation.',
  },
  upcomingMeditation: {
    id: `${scope}.upcomingMeditation`,
    defaultMessage:
      'You will receive an email in a few days with details of upcoming sessions.',
  },
  goToMeditation: {
    id: `${scope}.goToMeditation`,
    defaultMessage: 'Go to People Connect',
  },
  firstName: {
    id: `${scope}.firstName`,
    defaultMessage: 'First Name',
  },
  lastName: {
    id: `${scope}.lastName`,
    defaultMessage: 'Last Name',
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: 'Email',
  },
  company: {
    id: `${scope}.company`,
    defaultMessage: 'Company/Union',
  },
  companyOptional: {
    id: `${scope}.companyOptional`,
    defaultMessage: 'Organization (Optional)',
  },
  policyNumber: {
    id: `${scope}.policyNumber`,
    defaultMessage: 'Policy Number',
  },
  employeeType: {
    id: `${scope}.employeeType`,
    defaultMessage: 'Employee Type',
  },
  status: {
    id: `${scope}.status`,
    defaultMessage: 'Status',
  },
  password: {
    id: `${scope}.password`,
    defaultMessage: 'Password',
  },
  confirmPassword: {
    id: `${scope}.confirmPassword`,
    defaultMessage: 'Confirm Password',
  },
  gender: {
    id: `${scope}.gender`,
    defaultMessage: 'gender',
  },
  male: {
    id: `${scope}.male`,
    defaultMessage: 'Male',
  },
  female: {
    id: `${scope}.female`,
    defaultMessage: 'Female',
  },
  custom: {
    id: `${scope}.custom`,
    defaultMessage: 'Custom',
  },
  unspecified: {
    id: `${scope}.unspecified`,
    defaultMessage: 'Prefer not to say',
  },
  genderTooltip: {
    id: `${scope}.genderTooltip`,
    defaultMessage:
      'We ask about gender to better understand the diversity of our clients and improve our services.',
  },
  TOSAgree: {
    id: `${scope}.TOSAgree`,
    defaultMessage: `By creating an account, I agree to the conditions and privacy which contain important legal information. Please note: if you are a minor, your parent or legal guardian must consent to the Terms and Conditions and Privacy Policy.`,
  },
  newsletterAgree: {
    id: `${scope}.newsletterAgree`,
    defaultMessage:
      'I consent to receiving articles, information, or requests for feedback in the future (can opt out at any time.)',
  },
  signIn: {
    id: `${scope}.signIn`,
    defaultMessage: 'Sign in',
  },
  register: {
    id: `${scope}.register`,
    defaultMessage: 'REGISTER',
  },
  alreadyHaveAccount: {
    id: `${scope}.alreadyHaveAccount`,
    defaultMessage: 'Already have an account? {item}',
  },
  peopleleader: {
    id: `${scope}.peopleleader`,
    defaultMessage: 'People Leader',
  },
  spouse: {
    id: `${scope}.spouse`,
    defaultMessage: 'Spouse',
  },
  dependant: {
    id: `${scope}.dependant`,
    defaultMessage: 'Dependant',
  },
  dependent: {
    id: `${scope}.dependent`,
    defaultMessage: 'Dependent',
  },
  employeemember: {
    id: `${scope}.employeemember`,
    defaultMessage: 'Employee/Member',
  },
  employeemanager: {
    id: `${scope}.employeemanager`,
    defaultMessage: 'Employee/Member',
  },
  pleaseComplete: {
    id: `${scope}.pleaseComplete`,
    defaultMessage: 'Please complete',
  },
  validEmail: {
    id: `${scope}.validEmail`,
    defaultMessage: 'Please enter a valid email',
  },
  passwordLength: {
    id: `${scope}.passwordLength`,
    defaultMessage: 'Password must be at least 8 characters',
  },
  passwordMatch: {
    id: `${scope}.passwordMatch`,
    defaultMessage: 'Passwords do not match',
  },
  dob: {
    id: `${scope}.dob`,
    defaultMessage: 'Date of Birth',
  },
  dobPlaceholder: {
    id: `${scope}.dobPlaceholder`,
    defaultMessage: 'DD/MM/YYYY',
  },
  bbdEap: {
    id: `${scope}.bbdEap`,
    defaultMessage: 'People Connect EAP',
  },
  bbdBap: {
    id: `${scope}.bbdBap`,
    defaultMessage: 'People Connect Carepath BAP',
  },
  emailError: {
    id: `${scope}.emailError`,
    defaultMessage: 'An account using this email address may already be in use in the system. Try another email address or connect with <a href="support@inkblottherapy.com">support@inkblottherapy.com</a>',
  },
  azureProfileIdExists: {
    id: `${scope}.azureProfileIdExists`,
    defaultMessage: 'This account may already exist. Check your email inbox to complete registration for <b>People Connect Carepath BAP</b>.',
  },
  azureProfileIdExistsEap: {
    id: `${scope}.azureProfileIdExistsEap`,
    defaultMessage: 'This account may already exist. Check your email inbox to complete registration for <b>People Connect EAP</b>.',
  },
});
