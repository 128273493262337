import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
  isEap,
  isEapAndPeopleLeader,
  isPeopleLeader,
} from 'containers/Dashboard/helpers';

import LanguageSelect from 'containers/LanguageSelect';
import { signOut, allowedToViewMetrics } from 'utils/auth';
import messages from 'components/PortalLayout/messages';
import { connect } from 'react-redux';
import { makeSelectApp } from 'containers/App/selectors';
import { createStructuredSelector } from 'reselect';
import { BBDEAP, BBDBAP } from 'utils/environment';
import Close from './close.png';

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  display: ${props => (props.open ? 'block' : 'none')};
  opacity: ${props => (props.open ? 1 : 0)};
  transition: opacity 0.5s;
`;

const NavigationContent = styled.div`
  height: 100%;
  top: 0;
  z-index: 200;
  background-color: #ffffff;
  box-sizing: border-box;
  position: fixed;
  width: 260px;
  right: ${props => (props.open ? '0px' : '-260px')};
  overflow-y: scroll;
  transition: 0.5s;
  .close-button-container {
    position: fixed;
    top: 14px;
    right: ${props => (props.open ? '20px' : '-280px')};
    transition: 0.5s;
    .close {
      height: 12px;
      width: 12px;
    }
  }
  a.active {
    background-color: #eae7fd;
    border-left: 3px solid #00568e;
    font-weight: 600;
  }
  a,
  p {
    padding-left: 20px;
    display: block;
    font-family: Lato, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.6px;
    color: #1f275b;
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

const MobileNavigationOverlay = props => {
  const url = props.location.pathname;
  const { status, user } = props;
  const { company } = user.data;

  return (
    <div>
      <Overlay {...props} onClick={props.toggleNavigation} />
      <NavigationContent {...props}>
        <div className="close-button-container">
          <img
            className="close"
            src={Close}
            alt="close"
            role="presentation"
            onClick={props.toggleNavigation}
          />
        </div>
        <p style={{ textTransform: 'uppercase' }}>
          <FormattedMessage {...messages.menu} />
        </p>
        <Link
          className={url.includes('dashboard') ? 'active' : ''}
          to="/dashboard"
        >
          <FormattedMessage {...messages.dashboard} />
        </Link>
        {isEap(company) && (
          <Link
            className={url.includes('virtual_counselling') ? 'active' : ''}
            to="/virtual_counselling"
          >
            <FormattedMessage {...messages.eapNavHeader} />
          </Link>
        )}
        <Link
          className={url.includes('assessment') ? 'active' : ''}
          to="/assessment"
        >
          <FormattedMessage {...messages.mentalHealthAssessment} />
        </Link>
        {isEap(company) && (
          <Link
            className={url.includes('virtual_counselling') ? 'active' : ''}
            to="/virtual_counselling"
          >
            <FormattedMessage {...messages.virtualCounselling} />
          </Link>
        )}
        {isEapAndPeopleLeader(company) && (
          <Link
            to="/specialized_services"
            className={url.includes('specialized_services') ? 'active' : ''}
          >
            <FormattedMessage {...messages.specializedServices} />
          </Link>
        )}
        {isEapAndPeopleLeader(company, status) && (
          <Link
            to="/critical_incident_services"
            className={
              url.includes('critical_incident_services') ? 'active' : ''
            }
          >
            <FormattedMessage {...messages.criticalIncidentServices} />
          </Link>
        )}
        {isEapAndPeopleLeader(company, status) && (
          <Link
            to="/management_consulting"
            className={url.includes('management_consulting') ? 'active' : ''}
          >
            <FormattedMessage {...messages.managementConsulting} />
          </Link>
        )}
        {BBDEAP && (
          <>
            <Link
              to="/carepath"
              className={url.includes('carepath') ? 'active' : ''}
            >
              <FormattedMessage {...messages.carepath} />
            </Link>
            {isPeopleLeader && (
              <Link
                to="/business_assistance"
                className={url.includes('business_assistance') ? 'active' : ''}
              >
                <FormattedMessage {...messages.businessAssistance} />
              </Link>
            )}
          </>
        )}
        {BBDBAP && (
          <>
            <Link
              to="/health_navigation"
              className={url.includes('health_navigation') ? 'active' : ''}
            >
              <FormattedMessage {...messages.healthNavigation} />
            </Link>
            <Link
              to="/cancer_assistance"
              className={url.includes('cancer_assistance') ? 'active' : ''}
            >
              <FormattedMessage {...messages.cancerAssistance} />
            </Link>
            <Link
              to="/second_opinion"
              className={url.includes('second_opinion') ? 'active' : ''}
            >
              <FormattedMessage {...messages.secondOpinionServices} />
            </Link>
            {isPeopleLeader && (
              <Link
                to="/financial_legal_services"
                className={
                  url.includes('financial_legal_services') ? 'active' : ''
                }
              >
                <FormattedMessage {...messages.financialLegalServices} />
              </Link>
            )}
          </>
        )}
        <Link
          className={url.includes('employee_resources') ? 'active' : ''}
          to="/employee_resources"
        >
          <FormattedMessage {...messages.employeeMemberResources} />
        </Link>
        {status === 'People Leader' ? (
          <Link
            className={url.includes('manager_resources') ? 'active' : ''}
            to="/manager_resources"
          >
            <FormattedMessage {...messages.peopleLeaderResources} />
          </Link>
        ) : null}
        {status === 'People Leader' ? (
          <Link
            to="/plan_sponsors"
            className={url.includes('plan_sponsors') ? 'active' : ''}
          >
            <FormattedMessage {...messages.planSponsorResources} />
          </Link>
        ) : null}
        {status === 'People Leader' ? (
          <Link
            className={url.includes('training') ? 'active' : ''}
            to="/training"
          >
            <FormattedMessage {...messages.trainingAndConsulting} />
          </Link>
        ) : null}
        <Link
          className={url.includes('settings') ? 'active' : ''}
          to="/settings"
        >
          <FormattedMessage {...messages.settingsAndHistory} />
        </Link>
        <Link className={url.includes('faq') ? 'active' : ''} to="/faq">
          Support & FAQ
        </Link>
        {allowedToViewMetrics() && (
          <Link
            className={url.includes('metrics') ? 'active' : ''}
            to="/metrics"
          >
            Metrics
          </Link>
        )}
        <div
          style={{
            paddingTop: '50px',
            paddingBottom: '50px',
            paddingLeft: '20px',
          }}
        >
          <LanguageSelect />
        </div>
        <p
          role="presentation"
          onClick={signOut}
          style={{ textTransform: 'uppercase', marginBottom: 50 }}
        >
          <FormattedMessage {...messages.signOut} />
        </p>
      </NavigationContent>
    </div>
  );
};

MobileNavigationOverlay.propTypes = {
  status: propTypes.string.isRequired,
  user: propTypes.object,
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectApp(),
});

export default connect(mapStateToProps)(withRouter(MobileNavigationOverlay));
