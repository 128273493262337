import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { ENVIRONMENT } from 'utils/environment';
import Speech from './speech@3x.png';
import Psychology from './psychology@3x.png';
import Video from './video@3x.png';
import Team from './team-1@3x.png';
import MentalHealthSupport from './MentalHealthSupport.png';
import SelfHelpWorksCBT from './SelfHelpWorksCBT.png';
import WorkHealthLifeSupport from './WorkHealthLifeSupport.png';
import { tablet, phone } from '../sizes';
import messages from './messages';

const ElementsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 50%;
  .element {
    width: 200px;
    margin: 0 10px 60px 10px;
    img {
      width: 50px;
      height: 50px;
      object-fit: contain;
      margin-bottom: 20px;
    }
    h1 {
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.19;
      letter-spacing: 0.1px;
      color: #1f275b;
      margin-bottom: 10px;
    }
    p {
      margin: 0;
      font-family: 'Lato';
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.36;
      letter-spacing: 0.1px;
    }
  }
  @media (max-width: ${tablet}) {
    width: 100%;
  }
  @media (max-width: ${phone}) {
    width: 100%;
    .element {
      width: 125px;
      margin-bottom: 60px;
    }
  }
`;

const Elements = ({ eap }) => (
  <ElementsWrapper>
    {eap && (
      <div className="element">
        <img src={MentalHealthSupport} alt="video" />
        <h1>
          <FormattedMessage {...messages.mentalHealthSupport} />
        </h1>
        <p>
          <FormattedMessage {...messages.mentalHealthBody} />
        </p>
      </div>
    )}
    {eap && (
      <div className="element">
        <img src={WorkHealthLifeSupport} alt="video" />
        <h1>
          <FormattedMessage {...messages.workHealthLife} />
        </h1>
        <p>
          <FormattedMessage {...messages.workHealthLifeBody} />
        </p>
      </div>
    )}
    <div className="element">
      <img src={Video} alt="video" />
      <h1>
        <FormattedMessage {...messages.resourceBlog} />
      </h1>
      <p>
        <FormattedMessage {...messages.resourceBlogText} />
      </p>
    </div>
    {eap && (
      <div className="element">
        <img src={SelfHelpWorksCBT} alt="video" />
        <h1>
          <FormattedMessage {...messages.selfHelpCbt} />
        </h1>
        <p>
          <FormattedMessage {...messages.selfHelpCbtBody} />
        </p>
      </div>
    )}
    <div className="element">
      <img src={Psychology} alt="video" />
      <h1>
        <FormattedMessage {...messages.assessmentTool} />
      </h1>
      <p>
        <FormattedMessage {...messages.assessmentToolText} />
      </p>
    </div>
    <div className="element">
      <img src={Speech} alt="video" />
      <h1>
        {eap ? (
          <FormattedMessage {...messages.eapVideoTherapy} />
        ) : (
          <FormattedMessage {...messages.videoTherapy} />
        )}
      </h1>
      <p>
        {/* eslint-disable-next-line no-nested-ternary */}
        {eap ? (
          <FormattedMessage {...messages.eapVideoTherapyText} />
        ) : window.location.pathname.toLowerCase() === '/acrc' ? (
          <FormattedMessage {...messages.noCreditCard} />
        ) : (
          <FormattedMessage {...messages.videoTherapyText} />
        )}
      </p>
    </div>
    {ENVIRONMENT !== 'US_PRODUCTION' && (
      <div className="element">
        <img src={Team} alt="video" />
        <h1>
          <FormattedMessage {...messages.virtualCollaboration} />
        </h1>
        <p>
          <FormattedMessage {...messages.virtualCollaborationText} />
        </p>
      </div>
    )}
  </ElementsWrapper>
);

Elements.propTypes = {};

export default Elements;
