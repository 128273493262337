import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isAuthenticated } from 'utils/auth';

const UnauthRoute = ({
  component: Component,
  render: renderFunction,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (isAuthenticated() !== true) {
        if (Component) {
          return <Component {...props} />;
        }

        return renderFunction({ ...props });
      }

      return (
        <Redirect
          to={{
            pathname: '/',
            state: { from: props.location },
          }}
        />
      );
    }}
  />
);

export default UnauthRoute;
