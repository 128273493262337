import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import messages from '../../../messages';
import ArticleBody from '../ArticleBody';
const MindfulMeditation = () => (
  <ArticleBody>
    <ArticleBody>
      <FormattedHTMLMessage tagName="p" {...messages.mindfulMeditationBody} />
    </ArticleBody>
  </ArticleBody>
);

MindfulMeditation.propTypes = {};

export default MindfulMeditation;
