import styled from 'styled-components';

const MarketingStyles = styled.div`
  font-family: 'Montserrat', sans-serif;
  h1,
  h2,
  h3,
  h4,
  p,
  ul,
  span,
  li {
    margin: 0px;
    color: #1f275b;
  }
  ul {
    padding: 0;
  }
  a {
    text-decoration: none;
  }
  button.get-started {
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.3px;
    text-align: center;
    color: #ffffff;
    padding: 10px 30px;
    border-radius: 20px;
    background-image: linear-gradient(to left, #e3546f, #ff6b8a);
    cursor: pointer;
    transition: box-shadow 0.2s ease-in-out;
    &:hover {
      box-shadow: 0 0 20px 1px #ff6b8a;
    }
  }
  .section-1 {
    background-color: #fff;
    padding: 80px 0 160px 130px;
    h1 {
      font-weight: 700;
      line-height: 1.29;
      letter-spacing: 0.17px;
      color: #1a2249;
    }
    p {
      margin-top: 20px;
      line-height: 1.7;
      color: #1a2249;
    }
    button {
      cursor: pointer;
      border-radius: 25px;
      border: 2px solid #2a5ccd;
      background-color: inherit;
      color: #2a5ccd;
      font-weight: 600;
      letter-spacing: 0.6px;
      padding: 12px 75px;
      margin: 40px 0;
    }

    .flex-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      @media only screen and (min-width: 1140px) {
        max-width: 1135px;
        margin: 0 auto;
      }
      @media only screen and (min-width: 960px) and (max-width: 1140px) {
        max-width: 84%;
      }
    }

    .half-column {
      width: 50%;
      img {
        max-width: 525px;
        width: 100%;
      }
    }
    @media only screen and (max-width: 1024px) {
      img {
        margin-bottom: 30px;
      }
    }
    @media only screen and (max-width: 960px) {
      padding-left: 0;

      > .flex-row {
        flex-direction: column-reverse;

        .half-column:first-child {
          max-width: 650px;
          width: 90%;
        }

        .half-column:last-child {
          max-width: 450px;
          width: 65%;
        }
      }
    }
  }
`;

export default MarketingStyles;
