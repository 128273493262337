import styled from 'styled-components';
import { black, deepSeaBlue } from '../../global-styles';

const NavigationItem = styled.div`
  font-size: 14px;
  font-family: 'Lato', sans-serif;
  color: ${black};
  font-weight: normal;
  height: 50px;
  padding-left: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.active,
  &:hover {
    background-color: #eae7fd;
    font-weight: 600;
    padding-left: 27px;
    border-left: 3px solid ${deepSeaBlue};
  }
`;

export default NavigationItem;
