/*
 * CriticalIncidentServices Messages
 *
 * This contains all the text for the CriticalIncidentServices container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.ManagementConsulting';

export default defineMessages({
  managementTitle: {
    id: `${scope}.managementTitle`,
    defaultMessage: 'Management consultation',
  },
  summaryOfService: {
    id: `${scope}.summaryOfService`,
    defaultMessage:
      'Please note that you must be a manager or supervisor with supervisees/direct reports to access this service.',
  },
  asPartOf: {
    id: `${scope}.asPartOf`,
    defaultMessage:
      'As part of your Employee Assistance Program, you receive complimentary HR and management consultation regarding employee mental health, and specific complex situations. These services are available to assist people leaders (those who have direct reports) on how to appropriately discuss directions and options with your employees, as well as coach on how to approach sensitive employee matters in addition to your existing HR policies and regulations. ',
  },
  toRequest: {
    id: `${scope}.toRequest`,
    defaultMessage:
      'To request a virtual management consultation contact <a href="mailto:consultation@inkblottherapy.com">consultation@inkblottherapy.com.</a> <br/>For immediate consultations call: 1-855-933-0103',
  },
  body1: {
    id: `${scope}.body1`,
    defaultMessage:
      'Please note that you must be a business owner or supervisor with employees/direct reports to access this service.',
  },
  body2: {
    id: `${scope}.body2`,
    defaultMessage:
      'As part of your Business Assistance Program, you receive complimentary HR and management consultation regarding challenging employee issues, including performance management, absenteeism, conflict, difficult behaviour, and return-to-work strategies. These services are available to assist business owners and people leaders on how to appropriately discuss options with employees, as well as coach on how to approach sensitive employee matters in conjunction with existing HR policies and regulations.',
  },
  body3: {
    id: `${scope}.body3`,
    defaultMessage:
      'Manager and HR consultations are unlimited and can be accessed as often as required.',
  },
  body4: {
    id: `${scope}.body4`,
    defaultMessage:
      'To request a virtual management consultation contact <a href="mailto:consultation@inkblottherapy.com">consultation@inkblottherapy.com.</a> <br/>For immediate support call: 1-855-933-0103',
  },
});
