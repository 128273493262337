import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 945px;
  margin: 0 auto;
  box-shadow: 0 0 50px 0 rgba(40, 71, 140, 0.2);
`;

export default Wrapper;
