/**
 *
 * PanelWrapper
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import Wrapper from './Wrapper';

function PanelWrapper(props) {
  return <Wrapper>{props.children}</Wrapper>;
}

PanelWrapper.propTypes = {
  children: PropTypes.node,
};

export default PanelWrapper;
