import React from 'react';
import { Player } from 'video-react';
import VideoBody from './VideoBody';
import MentalHealthAwarenessVideo from './MentalHealthAwareness.mp4';

const MentalHealthAwareness = () => (
  <div>
    <VideoBody>
      <Player playsInline src={MentalHealthAwarenessVideo} />
    </VideoBody>
  </div>
);
export default MentalHealthAwareness;
